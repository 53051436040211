define('modules/enviroment/services/packages', ['require'], function (require) {
    'use strict';
    return {
        name: '$ayEnviromentPackages',
        component: 'service',
        fn: [
            '$log',
            '$rootScope',
            '$q',
            '$timeout',
            '$location',
            '$ayEnviromentCore',
            '$ME',
            '$route',
            'srvcConfig',
            function ($log, $rootScope, $q, $timeout, $location, $ayEnviromentCore, $ME, $route, srvcConfig) {
                var self = this;
                self._installDefer = null;
                self._installedVerison = null;
                self.isInstalled = false;
                self.isLoading = false;
                self._packageId = 'app-package';
                self.isMobile = $ayEnviromentCore.isMobile();
                self.v2 = {
                    appName: 'amity-app-v2',
                    script: 'static/build/v2.min.js',
                    styles: '<link rel="stylesheet" type="text/css" href="/static/build/v2.css"/>',
                    installed: false,
                    mobileUrl: '/csm/plays',
                    appUrl: '/csm/accounts'
                };
                self.installed = function () {
                    self.isInstalled = true;
                    self.isLoading = false;
                    self._installDefer.resolve(true);
                    $rootScope.$broadcast('app-initd');
                    $rootScope.$broadcast('app-loading', false, 'installed');
                    srvcConfig.initLog();
                };
                self._addStyles = function () {
                    var head = document.head, styles = angular.element(self._installedVerison.styles);
                    self._installedVerison.stylesNode = styles;
                    head.appendChild(self._installedVerison.stylesNode[0]);
                };
                self._removeStyles = function () {
                    self._installedVerison.stylesNode.remove();
                };
                self._removeScripts = function () {
                    self._installedVerison.packageElement.remove();
                };
                self.install = function () {
                    self._addStyles();
                    self._installDefer = $q.defer();
                    require([self._installedVerison.script], function () {
                        self._installedVerison.packageElement = angular.element('[src="' + self._installedVerison.script + '"]');
                    });
                    $log.info('package loaded', self._installedVerison);
                    return self._installDefer.promise;
                };
                self.uninstall = function () {
                    self.isInstalled = false;
                    window.location.reload(true);
                };
                self.reset = function () {
                    self._removeStyles();
                    self._removeScripts();
                };
                self.routeToVersion = function () {
                    function setRoute(path) {
                        $timeout(function () {
                            if (path === '/csm') {
                                if (self.isMobile) {
                                    $location.url(self._installedVerison.mobileUrl);
                                } else {
                                    $location.url(self._installedVerison.appUrl);
                                }
                            }
                            self.installed();
                        });
                    }
                    $timeout(function () {
                        var route, path = $location.path();
                        for (var rPath in $route.routes) {
                            if (path.match($route.routes[rPath].regexp)) {
                                route = $route.routes[rPath];
                                break;
                            }
                        }
                        if (!route) {
                            $rootScope.$broadcast('platform-content-change', { viewUrl: 'login/views/main.html' });
                            $location.url('/csm/not-found');
                            $rootScope.$broadcast('app-loading', false, 'not-found');
                            self._installDefer.resolve(false);
                        } else {
                            $rootScope.$broadcast('platform-content-change', { viewUrl: 'app.html' });
                            setRoute(path);
                        }
                    }, 250);
                };
                self.loadVersion = function () {
                    var loadDefer = $q.defer();
                    self.isLoading = true;
                    $rootScope.$broadcast('app-loading', true, 'loadVersion');
                    $rootScope.$broadcast('platform-content-change', { viewUrl: 'login/views/loading.html' });
                    var userPreference = $ME.preferences.apps.get('user'), appVersion = userPreference.state('version');
                    if (!appVersion && !self.isMobile) {
                        appVersion = $ME.workspace.default_ui_version;
                    } else if (self.isMobile) {
                        appVersion = 'v2';
                    }
                    if (!self.isInstalled) {
                        self._installedVerison = self[appVersion];
                        self.install().then(loadDefer.resolve);
                    } else {
                        self._addStyles();
                        self.routeToVersion();
                    }
                    return loadDefer.promise;
                };
            }
        ]
    };
});