define('modules/form-inputs/directives/menu', [], function () {
    return {
        component: 'directive',
        name: 'ayFormMenu',
        fn: [
            '$timeout',
            '$document',
            function ($timeout, $document) {
                return {
                    restrict: 'E',
                    templateUrl: 'form-inputs/views/menu.html',
                    scope: { options: '=' },
                    controller: function () {
                        var ctrl = this;
                        ctrl.menu = {
                            show: false,
                            styling: {
                                position: 'absolute',
                                top: '7px',
                                left: '100%',
                                'min-width': '200px',
                                'border': 'none'
                            }
                        };
                        ctrl.open = function () {
                            ctrl.menu.show = true;
                        };
                        ctrl.close = function () {
                            ctrl.menu.show = false;
                        };
                    },
                    controllerAs: 'formInput',
                    bindToController: true,
                    link: function (scope, elem, attrs) {
                        var placeholderMenu, placeholderMenuOptions = {
                                select: function (ev, ui) {
                                    if (ui && ui.item) {
                                        var value = ui.item[0].dataset.value;
                                        if (value) {
                                            scope.formInput.options.onSelect(value);
                                            scope.formInput.close();
                                        }
                                    }
                                }
                            };
                        scope.formInput.init = function () {
                            placeholderMenu = angular.element('#menu-' + scope.$id);
                            placeholderMenu.menu(placeholderMenuOptions);
                        };
                        function cyclePhase() {
                            if (!scope.$$phase && !scope.$root.$$phase) {
                                scope.$apply();
                            }
                        }
                        function detect(source, event) {
                            var isTarget = false, containsTarget = false, locator = event.toElement ? 'toElement' : 'target';
                            if (source && source[0]) {
                                isTarget = source[0] === event[locator];
                                containsTarget = source[0].contains(event[locator]);
                            }
                            return isTarget || containsTarget;
                        }
                        function toggleMenu(event) {
                            var isComponent = detect(elem, event);
                            if (!isComponent) {
                                scope.formInput.close();
                                cyclePhase();
                            }
                        }
                        $document.on('click', toggleMenu);
                        scope.$on('$destroy', function () {
                            $document.off('click', toggleMenu);
                        });
                        $timeout(function () {
                            scope.formInput.init();
                        });
                    }
                };
            }
        ]
    };
});