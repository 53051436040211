define('modules/form-inputs/directives/input-radio', [], function () {
    return {
        component: 'directive',
        name: 'ayFormInputRadio',
        fn: [
            '$timeout',
            function ($timeout) {
                return {
                    restrict: 'E',
                    templateUrl: 'form-inputs/views/input-radio.html',
                    require: '?ngModel',
                    scope: {
                        label: '@',
                        helpText: '@',
                        name: '@',
                        isDisabled: '=?',
                        tabIndex: '@?'
                    },
                    controller: function () {
                    },
                    controllerAs: 'formInput',
                    bindToController: true,
                    link: function (scope, elem, attrs, ngModel) {
                        var width;
                        scope.formInput.id = scope.formInput.name + '-' + scope.$id;
                        scope.formInput.styling = { width: scope.formInput.width || '100%' };
                        scope.formInput.msgStyling = { visibility: 'hidden' };
                        function setValue(newVal) {
                            scope.formInput.value = newVal;
                        }
                        function parseValue(newVal) {
                            scope.formInput.value = scope.$eval(newVal) || scope.$parent.$eval(newVal);
                            scope.formInput.model = scope.formInput.value;
                        }
                        scope.formInput.onChange = function (event) {
                            width = parseInt(elem.find('.form-input-ctrl').css('width').replace('px', ''), 10);
                            if (attrs.parseValue) {
                                parseValue(attrs.parseValue);
                            }
                            ngModel.$setViewValue(scope.formInput.value, event);
                        };
                        ngModel.$viewChangeListeners.push(function () {
                            if (attrs.onChange) {
                                scope.$parent.$eval(attrs.onChange);
                            }
                        });
                        function checkInputMsg(newVal) {
                            var inputMsg = elem.find('.form-input-msg');
                            function updateMsg() {
                                var msgWidth = parseInt(inputMsg.css('width').replace('px', ''), 10) + 5;
                                if (width > msgWidth) {
                                    scope.formInput.msgStyling.position = 'initial';
                                    scope.formInput.msgStyling['white-space'] = 'normal';
                                } else {
                                    scope.formInput.msgStyling['margin-bottom', '22px'];
                                }
                                scope.formInput.msgStyling.visibility = 'visible';
                            }
                            if (inputMsg[0]) {
                                scope.formInput.msgStyling.visibility = 'hidden';
                                scope.formInput.msgStyling.position = 'absolute';
                                scope.formInput.msgStyling['white-space'] = 'nowrap';
                                elem.css('margin-bottom', '0px');
                                $timeout(updateMsg, 10);
                            }
                        }
                        function init() {
                            if (attrs.overflowError && attrs.overflowError === 'true') {
                                scope.$watch('formInput.validationMsg', checkInputMsg);
                                scope.$watch('formInput.helpText', checkInputMsg);
                                checkInputMsg();
                            } else {
                                scope.formInput.msgStyling.visibility = 'visible';
                            }
                        }
                        attrs.$observe('value', setValue);
                        ngModel.$render = function () {
                            scope.formInput.model = ngModel.$modelValue;
                            $timeout(init, 1);
                        };
                    }
                };
            }
        ]
    };
});