define('sharedAppcues', [], function () {
    window.AppcuesSettings = { skipAMD: true };
    return {
        load: function (loadFn) {
            var cues = document.createElement('script'), header = document.getElementsByTagName('script')[0];
            cues.type = 'text/javascript';
            cues.async = true;
            cues.src = 'https://fast.appcues.com/28181.js';
            if (loadFn) {
                cues.onload = loadFn;
            }
            header.parentNode.insertBefore(cues, header);
        }
    };
});