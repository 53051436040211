define('modules/rest/services/connection', [], function () {
    'use strict';
    return {
        component: 'service',
        name: '$ay_connection',
        fn: [
            '$timeout',
            '$q',
            '$http',
            'errorDetection',
            function Connection_Service($timeout, $q, $http, errorDetection) {
                var self = this, DEFAULT_DELAY = 60000, RETRY_CNT = 0, RETRY_MAX = 12;
                self.online = true;
                self.status = true;
                self._observers = [];
                self._notifiers = [];
                self._listeners = [];
                function send_nofitications(notifier) {
                    $timeout(function () {
                        notifier(self.status && self.online);
                    }, 1);
                }
                function trigger_nofitications() {
                    var i;
                    for (i = 0; i < self._notifiers.length; i++) {
                        send_nofitications(self._notifiers[i].notify);
                    }
                }
                function End_Notification(id) {
                    var ender = this;
                    ender.id = id;
                    return function ender() {
                        self._notifiers.splice(self._notifiers._index_of_by_prop('id', ender.id), 1);
                    };
                }
                function triggerListeners() {
                    for (var i = 0, ii = self._listeners.length; i < ii; i++) {
                        self._listeners[i].listener.notify({ online: window.navigator.onLine });
                    }
                }
                function browser_offline() {
                    self.online = false;
                    trigger_nofitications();
                    triggerListeners();
                }
                function browser_online() {
                    self.online = true;
                    function ping() {
                        $http({
                            url: '/rest/v1/config',
                            method: 'GET'
                        }).then(trigger_watchers, trigger_watchers);
                    }
                    function trigger_watchers(response) {
                        var i, observer, error = errorDetection(response.status);
                        triggerListeners();
                        if (response.error && (response.error.status === 0 || response.data === null) && RETRY_CNT < RETRY_MAX) {
                            RETRY_CNT++;
                            $timeout(ping, DEFAULT_DELAY / RETRY_MAX);
                        } else {
                            RETRY_CNT = 0;
                            trigger_nofitications();
                            for (i = self._observers.length - 1; i > -1; i--) {
                                observer = self._observers.splice(i, 1)[0];
                                if (observer.timer) {
                                    $timeout.cancel(observer.timer);
                                }
                                observer.resolve();
                            }
                        }
                    }
                    ping();
                }
                self._has_nav = function check_nav() {
                    return !window.hasOwnProperty('navigator') || !window.navigator.hasOwnProperty('onLine') ? false : true;
                };
                self._force_check = function force_connection_check() {
                    if (!self._has_nav()) {
                        self.online = true;
                    } else {
                        self.online = window.navigator.onLine;
                    }
                };
                self._reset_observers = function reset_observers() {
                    self._observers = [];
                };
                self.wait_for_reconnect = function create_reconnection_observer(indefinite, delay) {
                    var max_wait_timer, index, id = self._observers._generate_id(), reconnection_defer = $q.defer();
                    if (!indefinite) {
                        max_wait_timer = $timeout(function () {
                            index = self._observers._index_of_by_prop('id', id);
                            self._observers.splice(index, 1);
                            reconnection_defer.reject('reconnection timeout');
                        }, delay || DEFAULT_DELAY);
                    }
                    self._observers.push({
                        id: id,
                        timer: max_wait_timer,
                        resolve: reconnection_defer.resolve
                    });
                    return reconnection_defer.promise;
                };
                self.connection_notifier = function connection_notifier(fn) {
                    var notifier = $q.defer(), id = self._notifiers._generate_id(), end_fn = new End_Notification(id);
                    notifier.promise.then(end_fn, end_fn, fn);
                    self._notifiers.push({
                        id: id,
                        notify: notifier.notify
                    });
                    return notifier.reject;
                };
                self.listener = function (fn) {
                    var self = this, listenDefer = $q.defer(), id = self._listeners._generate_id();
                    listenDefer.promise.then(null, null, fn);
                    self._listeners.push({
                        id: id,
                        listener: listenDefer
                    });
                };
                self.update_status = function update_status(status) {
                    if (status !== self.stats) {
                        self.stats = status;
                        trigger_nofitications();
                    }
                };
                self.is_connected = function is_connected() {
                    return self.status && self.online;
                };
                if (self._has_nav()) {
                    self._force_check();
                }
                window.addEventListener('offline', browser_offline);
                window.addEventListener('online', browser_online);
                return self;
            }
        ]
    };
});