define('modules/rest/factories/participant', [], function () {
    'use strict';
    return {
        component: 'service',
        name: 'modelParticipant',
        fn: [
            '$log',
            '$schemaValidator',
            'errorDetection',
            'restFactoryUser',
            '$q',
            '$timeout',
            '$ay_requester',
            function participant_factory($log, $schemaValidator, errorDetection, restFactoryUser, $q, $timeout, $ay_requester) {
                var LOCATION = 'participants', factory = { media_type: 'application/vnd.amity.hub.participant+json' };
                function Participant_Model(data) {
                    var self = this;
                    self.data_type = 'participant';
                    self.entity_type = 'participant';
                    self.id = null;
                    self.location = LOCATION + '/';
                    self.linkUrl = null;
                    self.etag = null;
                    self.workspace_id = null;
                    self.tenancy_id = null;
                    self.associations = null;
                    self.associationLinkUrl = null;
                    self.external_ids = null;
                    self.media_type = null;
                    self.created_at = null;
                    self.full_name = null;
                    self.birthday = null;
                    self.gender = null;
                    self.salutation = null;
                    self.notes = null;
                    self.avatar_url = null;
                    self.gravatar_hash = null;
                    self.last_activity = null;
                    self.last_activity_name = null;
                    self.last_engagement = null;
                    self.urls = null;
                    self.type = null;
                    self.tags = null;
                    self._tags = null;
                    self.first_name = null;
                    self.last_name = null;
                    self.description = null;
                    self.role_id = null;
                    self.custom_properties = null;
                    self.associated_accounts = null;
                    self.notes = null;
                    self.nps_comment = null;
                    self.nps_rating = null;
                    self.nps_response_date = null;
                    self.engagements_total = null;
                    self.last_chat_engagement = null;
                    self.last_engagement = null;
                    self.last_incoming_email_engagement = null;
                    self.last_meeting_engagement = null;
                    self.last_outgoing_email_engagement = null;
                    self.last_phone_engagement = null;
                    self.last_ticket_created_at = null;
                    self.last_ticket_display_id = null;
                    self.oldest_open_ticket_created_at = null;
                    self.oldest_open_ticket_display_id = null;
                    self.tickets_open = null;
                    self.tickets_total = null;
                    self.work_items_incomplete = null;
                    self.work_items_overdue = null;
                    self.work_items_total = null;
                    self.bulk_email_opt_out = null;
                    self.do_not_call = null;
                    self.email_opt_out = null;
                    if (data) {
                        self.set(data);
                    }
                }
                Participant_Model.prototype.getEtag = function () {
                    var self = this, etagDefer = $q.defer();
                    if (self.etag) {
                        $timeout(function () {
                            etagDefer.resolve({ etag: self.etag });
                        }, 1);
                    } else {
                        $ay_requester.GET(self.location).then(function (results) {
                            if (!results.error) {
                                self.etag = results.data.etag;
                                results.etag = self.etag;
                            }
                            etagDefer.resolve(results);
                        });
                    }
                    return etagDefer.promise;
                };
                Participant_Model.prototype.updateNotes = function (index) {
                    var self = this, saveDefer = $q.defer(), validation_res = $schemaValidator.validate(angular.copy(self), factory.schema);
                    self.getEtag().then(function (results) {
                        if (results.error) {
                            saveDefer.resolve(results);
                        } else {
                            if (validation_res.error) {
                                saveDefer.resolve(validation_res);
                            } else {
                                if (!angular.equals(validation_res.data.notes[index].html_body, self.notes[index].html_body)) {
                                    $log.error({
                                        error: { message: 'Account Note: unexpected note change' },
                                        accountId: self.id,
                                        noteIndex: index,
                                        noteValidated: validation_res.data.notes[index],
                                        noteLocal: angular.copy(self.notes[index])
                                    });
                                    validation_res.notes[index].html_body = self.notes[index].html_body;
                                }
                                $ay_requester.PUT(self.location, {
                                    etag: results.etag,
                                    data: validation_res.data
                                }).then(function complete_request(results) {
                                    if (!results.error) {
                                        self.set(results.data);
                                        results.data = self;
                                    }
                                    saveDefer.resolve(results);
                                });
                            }
                        }
                    });
                    return saveDefer.promise;
                };
                Participant_Model.prototype.save = function () {
                    var self = this, data = angular.copy(self), saveDefer = $q.defer();
                    data.associations = data.associations.map(function (assoc) {
                        if (assoc.account && assoc.account.removeEmptyListObjects) {
                            assoc.account.roles = assoc.account.removeEmptyListObjects(assoc.account.roles, 'user_id');
                        }
                        return assoc;
                    });
                    var validation_res = $schemaValidator.validate(data, factory.schema);
                    self.getEtag().then(function (results) {
                        if (results.error) {
                            saveDefer.resolve(results);
                        } else {
                            if (validation_res.error) {
                                saveDefer.resolve(validation_res);
                            } else {
                                $ay_requester.PUT(self.location, {
                                    etag: results.etag,
                                    data: validation_res.data
                                }).then(function complete_request(results) {
                                    if (!results.error) {
                                        self.set(results.data);
                                        results.data = self;
                                    }
                                    saveDefer.resolve(results);
                                });
                            }
                        }
                    });
                    return saveDefer.promise;
                };
                Participant_Model.prototype.toggle_key_contact = function toggle_key_contact(account_id) {
                    var self = this, requestDefer = $q.defer(), pos = self.associations._index_of_by_prop('account.id', account_id);
                    if (pos !== -1) {
                        self.associations[pos].account_key_contact = !self.associations[pos].account_key_contact;
                        self.save().then(function (results) {
                            if (!results.error) {
                                self.etag = results.data.etag;
                            }
                            requestDefer.resolve(results);
                        });
                    } else {
                        $timeout(function () {
                            requestDefer.resolve({ error: errorDetection(1002) });
                        }, 1);
                    }
                    return requestDefer.promise;
                };
                Participant_Model.prototype.remove = function () {
                    var self = this;
                    return $ay_requester.DELETE(self.location, {});
                };
                Participant_Model.prototype.update = function (fields) {
                    function createTargets(item, index) {
                        var target = fields[index], change = {
                                op: target.op ? target.op : 'replace',
                                path: '/' + target.field.replace(/^_/g, '').replace(/(\[\")|(\.)/g, '/').replace(/(\"\])/g, '')
                            };
                        if ((change.op === 'replace' || change.op === 'add' || change.op === 'test') && target.value === undefined) {
                            change.value = s[target.field];
                        } else {
                            change.value = target.value;
                        }
                        return change;
                    }
                    var s = this, params = {
                            fields: fields,
                            media_type: s.media_type,
                            data: Array.apply(null, new Array(fields.length)).map(createTargets)
                        };
                    return $ay_requester.PATCH(s.location, params);
                };
                Participant_Model.prototype.set = function (data) {
                    var self = this;
                    self.id = data.id;
                    self.location = LOCATION + '/' + data.id;
                    self.linkUrl = '/csm/people/' + data.id;
                    self.etag = data.etag;
                    self.workspace_id = data.workspace_id;
                    self.tenancy_id = data.tenancy_id;
                    self.associations = data.associations ? data.associations : [];
                    self.external_ids = data.external_ids ? data.external_ids : [];
                    self.media_type = data.media_type;
                    self.created_at = data.created_at ? new Date(data.created_at) : null;
                    self.full_name = data.full_name;
                    self.birthday = data.birthday ? new Date(data.birthday) : null;
                    self.salutation = data.salutation;
                    self.gender = data.gender;
                    self.notes = data.notes ? data.notes : [];
                    self.avatar_url = data.avatar_url;
                    self.gravatar_hash = data.gravatar_hash;
                    self.last_activity = data.last_activity;
                    self.last_activity_name = data.last_activity_name;
                    self.urls = data.urls;
                    self.type = data.type;
                    self.tags = data.tags;
                    self._tags = self.tags && self.tags.length ? self.tags.join(', ') : '';
                    self.first_name = data.first_name;
                    self.last_name = data.last_name;
                    self.description = data.description;
                    self.role_id = data.role_id;
                    self.custom_properties = data.custom_properties || {};
                    self.associated_accounts = data.associated_accounts;
                    function basicListItem(item) {
                        if (typeof item === 'string') {
                            return item;
                        }
                        return item.value;
                    }
                    if (self.associations.length) {
                        if (self.associations[0].account) {
                            self.associationLinkUrl = '/csm/accounts/' + self.associations[0].account.id;
                        }
                        self.defaultAssociation = {
                            account: self.associations[0].account,
                            keyContact: self.associations[0].account_key_contact,
                            title: self.associations[0].title,
                            department: self.associations[0].department,
                            emails: [],
                            phones: []
                        };
                        if (self.associations[0].emails) {
                            self.defaultAssociation.emails = self.associations[0].emails.map(basicListItem);
                        }
                        if (self.associations[0].telephones) {
                            self.defaultAssociation.phones = self.associations[0].telephones.map(basicListItem);
                        }
                    }
                    self.notes = data.notes || [];
                    self.nps_comment = data.nps_comment;
                    self.nps_rating = data.nps_rating;
                    self.nps_response_date = data.nps_response_date;
                    self.engagements_total = data.engagements_total;
                    self.last_chat_engagement = data.last_chat_engagement ? new Date(data.last_chat_engagement) : null;
                    self.last_engagement = data.last_engagement ? new Date(data.last_engagement) : null;
                    self.last_incoming_email_engagement = data.last_incoming_email_engagement ? new Date(data.last_incoming_email_engagement) : null;
                    self.last_meeting_engagement = data.last_meeting_engagement ? new Date(data.last_meeting_engagement) : null;
                    self.last_outgoing_email_engagement = data.last_outgoing_email_engagement ? new Date(data.last_outgoing_email_engagement) : null;
                    self.last_phone_engagement = data.last_phone_engagement ? new Date(data.last_phone_engagement) : null;
                    self.last_ticket_created_at = data.last_ticket_created_at ? new Date(data.last_ticket_created_at) : null;
                    self.last_ticket_display_id = data.last_ticket_display_id;
                    self.oldest_open_ticket_created_at = data.oldest_open_ticket_created_at ? new Date(data.oldest_open_ticket_created_at) : null;
                    self.oldest_open_ticket_display_id = data.last_ticket_display_id;
                    self.tickets_open = data.tickets_open;
                    self.tickets_total = data.tickets_total;
                    self.work_items_incomplete = data.work_items_incomplete;
                    self.work_items_overdue = data.work_items_overdue;
                    self.work_items_total = data.work_items_total;
                    self.bulk_email_opt_out = data.bulk_email_opt_out;
                    self.do_not_call = data.do_not_call;
                    self.email_opt_out = data.email_opt_out;
                };
                factory._buildOptions = function (params) {
                    var value, options = {
                            f: [],
                            s: []
                        };
                    if (params.filters) {
                        options.f = angular.copy(params.filters);
                    }
                    if (params.sorts) {
                        options.s = angular.copy(params.sorts);
                    }
                    if (params.account_id && !params.account_name) {
                        options.f.push({
                            field: '/associations/account/id',
                            op: 'EQ',
                            value: params.account_id
                        });
                    }
                    if (params.accounts && params.accounts.length) {
                        options.f.push({
                            field: '/associations/account/id',
                            op: 'IN',
                            value: params.accounts
                        });
                    }
                    if (params.id && (!params.bulk_get || !params.bulk_get.length)) {
                        options.f.push({
                            field: '/id',
                            op: 'EQ',
                            value: params.id
                        });
                    }
                    if (params.full_name && !params.account_name) {
                        options.f.push({
                            field: '/full_name',
                            op: 'CONTAINS',
                            value: params.full_name
                        });
                    }
                    if (params.account_name) {
                        options.f.push({
                            field: '/associations/account/name',
                            op: 'EXISTS',
                            value: true
                        });
                    }
                    if (params.account_name && !params.full_name) {
                        options.f.push({
                            field: '/associations/account/name',
                            op: 'CONTAINS',
                            value: params.account_name
                        });
                    }
                    if (params.full_name && params.account_name) {
                        value = [
                            [{
                                    field: '/full_name',
                                    op: 'CONTAINS',
                                    value: params.full_name
                                }],
                            [{
                                    field: '/associations/account/name',
                                    op: 'CONTAINS',
                                    value: params.account_name
                                }]
                        ];
                        if (params.account_id) {
                            value.push([{
                                    field: '/associations/account/id',
                                    op: 'EQ',
                                    value: params.account_id
                                }]);
                        }
                        options.f.push({
                            op: 'OR',
                            value: value
                        });
                    }
                    if (params.bulk_get && params.bulk_get.length && !params.id) {
                        options.f.push({
                            field: '/id',
                            op: 'IN',
                            value: params.bulk_get
                        });
                    }
                    if (params.input) {
                        value = [
                            [{
                                    field: '/full_name',
                                    op: 'CONTAINS',
                                    value: params.input
                                }],
                            [{
                                    field: '/associations/emails/value',
                                    op: 'CONTAINS',
                                    value: params.input
                                }]
                        ];
                        options.f.push({
                            op: 'OR',
                            value: value
                        });
                    }
                    if (params.hasOwnProperty('key_contacts')) {
                        options.f.push({
                            field: '/associations/account_key_contact',
                            op: 'EQ',
                            value: params.key_contacts
                        });
                    }
                    return options;
                };
                factory.getSchema = function get_entity_schema() {
                    var s = this, schemaDefer = $q.defer();
                    function setEntitySchema(results) {
                        if (!results.error) {
                            s.schema = results.data;
                            schemaDefer.resolve(s.schema);
                        } else {
                            schemaDefer.resolve(results);
                        }
                    }
                    if (s.schema) {
                        $timeout(function () {
                            schemaDefer.resolve(s.schema);
                        }, 1);
                    } else {
                        $ay_requester.GET('schema/' + LOCATION).then(setEntitySchema);
                    }
                    return schemaDefer.promise;
                };
                factory.newModel = function (data) {
                    return new Participant_Model(data);
                };
                factory.get = function (params) {
                    var getPromise, drainPromise, self = this, cancelled = false, getDefer = $q.defer(), location = LOCATION, models = [];
                    function setModels(results) {
                        if (!cancelled) {
                            if (results.error) {
                                getDefer.resolve(results);
                            } else {
                                if (!results.data.hasOwnProperty('items')) {
                                    results.data = self.newModel(results.data);
                                    getDefer.resolve(results);
                                } else {
                                    models = models.concat(results.data.items.map(self.newModel));
                                    results.data = models;
                                    if (!params.drain) {
                                        getDefer.resolve(results);
                                    } else {
                                        if (!results.cursor) {
                                            getDefer.resolve(results);
                                        } else {
                                            params.cursor = results.cursor;
                                            drainPromise = $ay_requester.GET(location, params);
                                            drainPromise.then(setModels);
                                        }
                                    }
                                }
                            }
                        }
                    }
                    self.getSchema().then(function (results) {
                        params = params || {};
                        if (!cancelled) {
                            if (params.id) {
                                location += '/' + params.id;
                            } else {
                                params.options = self._buildOptions(params);
                                params.options.f.push(restFactoryUser.getApiExcludeFilter());
                            }
                            getPromise = $ay_requester.GET(location, params);
                            getPromise.then(setModels);
                        }
                    });
                    getDefer.promise.cancel = function () {
                        cancelled = true;
                        if (getPromise && getPromise.hasOwnProperty('cancel')) {
                            getPromise.cancel();
                        }
                        if (drainPromise && drainPromise.hasOwnProperty('cancel')) {
                            drainPromise.cancel();
                        }
                        getDefer.resolve({
                            cancelled: true,
                            error: errorDetection('-1')
                        });
                    };
                    return getDefer.promise;
                };
                factory.create = function (data) {
                    var self = this, createDefer = $q.defer();
                    function post() {
                        data.media_type = factory.schema.description;
                        data.associations = data.associations.map(function (assoc) {
                            if (assoc.account.removeEmptyListObjects) {
                                assoc.account.roles = assoc.account.removeEmptyListObjects(assoc.account.roles, 'user_id');
                            }
                            return assoc;
                        });
                        var validation_res = $schemaValidator.validate(data, factory.schema);
                        if (validation_res.error) {
                            $timeout(function () {
                                createDefer.resolve(validation_res);
                            }, 1);
                        } else {
                            $ay_requester.POST(LOCATION, { data: validation_res.data }).then(function (results) {
                                if (!results.error) {
                                    results.data = new Participant_Model(results.data);
                                }
                                createDefer.resolve(results);
                            });
                        }
                    }
                    self.getSchema().then(post);
                    return createDefer.promise;
                };
                return factory;
            }
        ]
    };
});