define('modules/rest/services/requester', [], function () {
    'use strict';
    return {
        component: 'service',
        name: '$ay_requester',
        fn: [
            'Phare',
            'global_utilities',
            '$log',
            '$http',
            '$q',
            '$timeout',
            'errorDetection',
            '$ay_session',
            '$ay_connection',
            '$location',
            function ay_requester(Phare, global_utilities, $log, $http, $q, $timeout, errorDetection, $ay_session, $ay_connection, $location) {
                function Requester_Service() {
                }
                Requester_Service.prototype.rest_api = '/rest/v1/';
                Requester_Service.prototype.default_range = 'cursor=100';
                Requester_Service.prototype._format_cursor = function format_cursor(_cursor) {
                    var sections, cursor = false;
                    if (_cursor) {
                        sections = _cursor.split(' ');
                        if (sections.length === 3) {
                            cursor = sections[0] + '=' + sections[1] + ' \'' + sections[2].replace('"', '').replace('"', '') + '\'';
                        }
                    }
                    return cursor;
                };
                Requester_Service.prototype._send_request = function send_request(payload, return_obj) {
                    var retrieveDefer, self = this, request_defer = $q.defer(), cancelDefer = $q.defer();
                    payload.timeout = cancelDefer.promise;
                    if (payload.url.indexOf('undefined') !== -1) {
                        $log.error({
                            error: {
                                message: 'Malformed url',
                                data: {
                                    url: payload.url,
                                    id: return_obj.data.id,
                                    media_type: return_obj.data.media_type,
                                    created_at: return_obj.data.created_at,
                                    workspace_id: return_obj.data.workspace_id,
                                    account_id: return_obj.data.account ? return_obj.data.account.id : undefined
                                }
                            }
                        });
                    }
                    function set_results(response) {
                        var reachable = true, retryAfter = response.headers('Retry-after');
                        return_obj.data = response.data;
                        return_obj.status = response.status;
                        return_obj.headers = response.headers;
                        return_obj.error = errorDetection(response.status);
                        return_obj.etag = response.headers('Etag');
                        return_obj.cursor = self._format_cursor(response.headers('Content-Range'));
                        return_obj.connectionStatus = $ay_connection.online;
                        if (retryAfter) {
                            retryAfter = parseFloat(retryAfter);
                            if (!isNaN(retryAfter)) {
                                return_obj.retryAfter = retryAfter;
                            }
                        }
                        if (return_obj.data === null && !return_obj.error) {
                            return_obj.error = errorDetection(1011);
                        }
                        if (!return_obj.error) {
                            if (return_obj.etag) {
                                if (return_obj.data && typeof return_obj.data !== 'string') {
                                    return_obj.data.etag = return_obj.etag.trim();
                                } else {
                                    return_obj.data = { etag: return_obj.etag.trim() };
                                }
                            }
                            if (!payload.retrieveEntity) {
                                request_defer.resolve(return_obj);
                            } else {
                                if (payload.method === 'POST' && response.status === 201) {
                                    retrieveDefer = self._send_request({
                                        url: payload.url + response.headers('Location').split(payload.url)[1],
                                        headers: payload.headers,
                                        method: 'GET'
                                    }, return_obj);
                                } else {
                                    retrieveDefer = self._send_request({
                                        url: payload.url,
                                        headers: payload.headers,
                                        method: 'GET'
                                    }, return_obj);
                                }
                                cancelDefer.promise.then(retrieveDefer.cancel);
                                retrieveDefer.then(function (retrieveRes) {
                                    request_defer.resolve(retrieveRes);
                                });
                            }
                        } else {
                            if (return_obj.data && angular.isObject(return_obj.data)) {
                                return_obj.data.error = errorDetection(return_obj.data);
                            }
                            if (return_obj.error.status !== 0) {
                                reachable = false;
                            }
                            switch (status) {
                            case 401:
                                var trace = [
                                    '401 detected',
                                    payload.headers
                                ];
                                $ay_session.renew().then(function (renewResults) {
                                    trace.push('session renewed');
                                    if (!renewResults.error) {
                                        trace.push(angular.copy(payload.headers));
                                        payload.headers.Authorization = renewResults.data.Authorization;
                                        trace.push(angular.copy(payload.headers));
                                        trace.push('call resent');
                                        self._send_request(payload, return_obj).then(function (retryResults) {
                                            trace.push('call completed: ' + retryResults.status);
                                            retryResults.trace = trace;
                                            request_defer.resolve(retryResults);
                                        });
                                    } else {
                                        trace.push(renewResults);
                                        $log.error('session failed to renew after a 401', trace);
                                        request_defer.resolve(renewResults);
                                    }
                                });
                                break;
                            default:
                                if (return_obj.error.loggable) {
                                    return_obj.error.sentryEventId = $log.error(return_obj);
                                }
                                request_defer.resolve(return_obj);
                                break;
                            }
                        }
                        $ay_connection.update_status(reachable);
                    }
                    function call_rest() {
                        if (cancelDefer.promise.$$state.status === 0) {
                            $http(payload).then(set_results, set_results);
                        } else {
                            return_obj.cancelled = true;
                            request_defer.reject(return_obj);
                        }
                    }
                    function call_fail() {
                        return_obj.data = {};
                        return_obj.error = errorDetection(1007);
                        request_defer.resolve(return_obj);
                    }
                    function rejectUrl() {
                        return_obj.error = errorDetection(1001);
                        return_obj.payload = payload;
                        $log.error(return_obj);
                        request_defer.resolve(return_obj);
                    }
                    function rejectPayload() {
                        return_obj.error = errorDetection(1000);
                        return_obj.payload = payload;
                        $log.error(return_obj);
                        request_defer.resolve(return_obj);
                    }
                    function rejectSuspension() {
                        return_obj.error = errorDetection(1012);
                        return_obj.payload = payload;
                        request_defer.resolve(return_obj);
                        Phare.track({ event: 'Account suspended (request redirect)' });
                        $location.path('/csm/account-suspended');
                    }
                    if ($ay_session.isSuspended) {
                        $timeout(rejectSuspension, 1);
                    } else {
                        if (!payload.url) {
                            $timeout(rejectUrl, 1);
                        } else if ((payload.method === 'POST' || payload.method === 'PUT') && !payload.data) {
                            $timeout(rejectPayload, 1);
                        } else {
                            if ($ay_connection.online) {
                                call_rest();
                            } else {
                                $ay_connection.wait_for_reconnect().then(call_rest, call_fail);
                            }
                        }
                    }
                    request_defer.promise.cancel = function () {
                        return_obj.cancelled = true;
                        cancelDefer.resolve();
                    };
                    return request_defer.promise;
                };
                Requester_Service.prototype.CONFIG = function config_request(params) {
                    var self = this, result = {};
                    return self._send_request({
                        url: self.rest_api + 'config',
                        method: 'GET'
                    }, result);
                };
                Requester_Service.prototype.LOGIN = function user_login_request(username, password) {
                    var self = this, result = {};
                    return self._send_request({
                        method: 'POST',
                        url: '/oauth/access_token',
                        data: {
                            grant_type: 'password',
                            username: username,
                            password: password
                        }
                    }, result);
                };
                Requester_Service.prototype.INFO = function user_info_request() {
                    var self = this, result = {};
                    return self._send_request({
                        method: 'GET',
                        headers: $ay_session.GET(),
                        url: self.rest_api + 'info'
                    }, result);
                };
                Requester_Service.prototype.GET = function get_request(location, params) {
                    params = params || {};
                    var requestDefer, cancelRequest = $q.defer(), self = this, url = self.rest_api + location, headers = $ay_session.GET(), result = {
                            type: params.type || '',
                            call_info: {
                                location: location,
                                params: params
                            }
                        };
                    headers.Range = params.cursor ? params.cursor : params.range ? 'cursor=' + params.range : self.default_range;
                    url = self.rest_api + location;
                    if (params.options) {
                        url += '?' + global_utilities.base64(params.options);
                    }
                    return self._send_request({
                        url: url,
                        headers: headers,
                        method: 'GET'
                    }, result);
                };
                Requester_Service.prototype.POST = function post_request(location, params) {
                    var self = this, url = self.rest_api + location, headers = $ay_session.GET(), result = {
                            type: params.type || '',
                            call_info: {
                                location: location,
                                params: params
                            }
                        }, payload = {
                            url: url,
                            headers: headers,
                            method: 'POST',
                            data: params.data,
                            retrieveEntity: params.hasOwnProperty('retrieveEntity') ? params.retrieveEntity : true
                        };
                    if (params.data.hasOwnProperty('items')) {
                        headers['Content-Type'] = 'application/vnd.amity.collection+json';
                        payload.retrieveEntity = false;
                    }
                    return self._send_request(payload, result);
                };
                Requester_Service.prototype.PUT = function put_request(location, params) {
                    var self = this, url = self.rest_api + location, headers = $ay_session.GET(), request_defer = $q.defer(), result = {
                            type: params.type || '',
                            call_info: {
                                location: location,
                                params: params
                            }
                        }, payload = {
                            url: url,
                            headers: headers,
                            method: 'PUT',
                            data: params.data,
                            retrieveEntity: true
                        };
                    if (params.etag) {
                        headers['If-Match'] = params.etag;
                    } else {
                        payload.retrieveEntity = params.retrieveEntity || false;
                    }
                    return self._send_request(payload, result);
                };
                Requester_Service.prototype.PATCH = function (location, params) {
                    var self = this, url = self.rest_api + location, headers = $ay_session.GET(), result = {
                            type: params.type || '',
                            call_info: {
                                location: location,
                                params: params
                            }
                        };
                    headers['Content-Type'] = 'application/json-patch+json';
                    return self._send_request({
                        url: url,
                        headers: headers,
                        method: 'PATCH',
                        data: params.data,
                        retrieveEntity: params.retrieveEntity
                    }, result);
                };
                Requester_Service.prototype.DELETE = function delete_request(location, params) {
                    var self = this, url = self.rest_api + location, headers = $ay_session.GET(), result = {
                            type: params.type || '',
                            call_info: {
                                location: location,
                                params: params
                            }
                        };
                    return self._send_request({
                        url: url,
                        headers: headers,
                        method: 'DELETE',
                        data: params.data
                    }, result);
                };
                Requester_Service.prototype.IMPERSONATE = function get_request(username, impersonator_id) {
                    var self = this, impersonate_defer = $q.defer(), location = '/oauth/access_token', headers = $ay_session.GET(), result = { call_info: { location: location } };
                    self._send_request({
                        url: location,
                        headers: headers,
                        method: 'POST',
                        data: {
                            grant_type: 'https://getamity.com/oauth/impersonate',
                            username: username
                        }
                    }, result).then(function (impersonate_results) {
                        impersonate_results.impersonator_id = impersonator_id;
                        impersonate_defer.resolve(impersonate_results);
                    });
                    return impersonate_defer.promise;
                };
                Requester_Service.prototype.SEND_EMAIL = function send_email_request(params) {
                    var self = this, url = self.rest_api + 'users/' + params.id, headers = $ay_session.GET(), data = {
                            action: '',
                            template_moniker: ''
                        }, result = {
                            type: params.type || '',
                            call_info: {
                                location: location,
                                params: params
                            }
                        };
                    switch (params.type) {
                    case 'welcome':
                        data.action = 'SendPasswordResetEmail';
                        data.template_moniker = 'welcome';
                        break;
                    case 'reset':
                        data.action = 'SendPasswordResetEmail';
                        data.template_moniker = 'password_reset';
                        break;
                    }
                    return self._send_request({
                        url: url,
                        headers: headers,
                        method: 'POST',
                        data: data
                    }, result);
                };
                Requester_Service.prototype.FILE = function find_request(url, headerParams, asBlob) {
                    var self = this, result = {}, options = {
                            url: url,
                            method: 'GET',
                            headers: $ay_session.GET()
                        };
                    if (headerParams) {
                        for (var k in headerParams) {
                            options.headers[k] = headerParams[k];
                        }
                    }
                    if (asBlob) {
                        options.responseType = 'blob';
                    }
                    return self._send_request(options, result);
                };
                Requester_Service.prototype.JSONP = function (url) {
                    var self = this, result = {}, options = {
                            method: 'JSONP',
                            headers: { 'Content-Type': 'text/html' },
                            url: url + '&callback=JSON_CALLBACK'
                        };
                    return self._send_request(options, result);
                };
                Requester_Service.prototype.resetUserPassword = function (username) {
                    var self = this;
                    return self._send_request({
                        url: self.rest_api + 'users/password_reset',
                        method: 'POST',
                        data: { username: username }
                    }, {});
                };
                return new Requester_Service();
            }
        ]
    };
});