define('modules/form-inputs/directives/color-picker', [], function () {
    'use strict';
    return {
        name: 'ayColorPicker',
        component: 'directive',
        fn: [
            '$rootScope',
            '$log',
            '$utls',
            '$compile',
            '$timeout',
            '$document',
            function color_picker($rootScope, $log, $utls, $compile, $timeout, $document) {
                return {
                    restrict: 'A',
                    replace: true,
                    scope: {
                        source: '=ayColorPicker',
                        options: '=?'
                    },
                    link: function ($scope, elem, attrs) {
                        var displayHex, linkFn, colorRegex = new RegExp(/\#[0-9A-F]{6}/gi), color_picker = angular.element('<section class="amity-picker white shadow-outline hide grid"><div class="pad"><div class="grid-wrapper pab btm"><div ng-repeat="shade in colorOptions.grey" ng-class="{\'mar is-larger right\':!$last}"><div class="color-option action grey {{shade.style}} pad is-larger bdr-in" ng-class="{\'is-selected\':shade.hex===colorOptions.selected}" ng-click="changes.colorSelected($event, shade)"></div></div></div></div><div class="grid-wrapper pad"><div ng-repeat="option in colorOptions.colors" ng-class="{\'mar is-larger right\':!$last}"><div ng-repeat="shade in option.shades" ng-class="{\'mar btm\':!$last, \'is-larger\':$first}"><div class="{{(option.color + \' \' + shade.style)}} color-option action pad is-larger bdr-in" ng-class="{\'is-selected\':shade.hex===colorOptions.selected}" ng-click="changes.colorSelected($event, shade)"></div></div></div></div><div class="color-picker-input pad"><div class="nav is-hoz is-vertical mar is-tinier is-between"><div ng-if="options.customable" class="nav is-hoz is-vertical"><label for="hexInput" class="mar is-larger right">HEX:</label><input class="pad" style="width:68px;" type="text" id="hexInput" ng-model="source" ng-change="changes.colorInputed(source)" ay-validate="changes.validColor"/><div class="display-wrapper mar is-larger top-o btm-o"><span class="display-hex pad is-larger bdr-in is-round"></span></div></div><div ng-if="options.clearable" class="hover-action" ng-click="changes.colorInputed()">Clear</div></div></div></section>'), parent_element = $document.find('body');
                        function cyclePhase() {
                            if (!$scope.$$phase && ($scope.$root && !$scope.$root.$$phase)) {
                                $scope.$apply();
                            }
                        }
                        $scope.isOpen = false;
                        $scope.colorOptions = {
                            selected: '',
                            grey: [
                                {
                                    style: 's-900',
                                    hex: '#212121'
                                },
                                {
                                    style: 's-800',
                                    hex: '#424242'
                                },
                                {
                                    style: 's-700',
                                    hex: '#616161'
                                },
                                {
                                    style: 's-600',
                                    hex: '#757575'
                                },
                                {
                                    style: 's-500',
                                    hex: '#9e9e9e'
                                },
                                {
                                    style: 's-400',
                                    hex: '#bdbdbd'
                                },
                                {
                                    style: 's-300',
                                    hex: '#e0e0e0'
                                },
                                {
                                    style: 's-200',
                                    hex: '#eeeeee'
                                },
                                {
                                    style: 's-100',
                                    hex: '#f5f5f5'
                                },
                                {
                                    style: 'white',
                                    hex: '#ffffff'
                                }
                            ],
                            colors: [
                                {
                                    color: 'brown',
                                    shades: [
                                        {
                                            style: 's-500',
                                            hex: '#795548'
                                        },
                                        {
                                            style: 's-100',
                                            hex: '#d7ccc8'
                                        },
                                        {
                                            style: 's-200',
                                            hex: '#bcaaa4'
                                        },
                                        {
                                            style: 's-300',
                                            hex: '#a1887f'
                                        },
                                        {
                                            style: 's-400',
                                            hex: '#8d6e63'
                                        },
                                        {
                                            style: 's-800',
                                            hex: '#4e342e'
                                        },
                                        {
                                            style: 's-900',
                                            hex: '#3e2723'
                                        }
                                    ]
                                },
                                {
                                    color: 'red',
                                    shades: [
                                        {
                                            style: 's-500',
                                            hex: '#f44336'
                                        },
                                        {
                                            style: 's-100',
                                            hex: '#ffcdd2'
                                        },
                                        {
                                            style: 's-200',
                                            hex: '#ef9a9a'
                                        },
                                        {
                                            style: 's-300',
                                            hex: '#e57373'
                                        },
                                        {
                                            style: 's-400',
                                            hex: '#ef5350'
                                        },
                                        {
                                            style: 's-800',
                                            hex: '#c62828'
                                        },
                                        {
                                            style: 's-900',
                                            hex: '#b71c1c'
                                        }
                                    ]
                                },
                                {
                                    color: 'orange',
                                    shades: [
                                        {
                                            style: 's-500',
                                            hex: '#ff9800'
                                        },
                                        {
                                            style: 's-100',
                                            hex: '#ffe0b2'
                                        },
                                        {
                                            style: 's-200',
                                            hex: '#ffcc80'
                                        },
                                        {
                                            style: 's-300',
                                            hex: '#ffb74d'
                                        },
                                        {
                                            style: 's-400',
                                            hex: '#ffa726'
                                        },
                                        {
                                            style: 's-800',
                                            hex: '#ef6c00'
                                        },
                                        {
                                            style: 's-900',
                                            hex: '#e65100'
                                        }
                                    ]
                                },
                                {
                                    color: 'amber',
                                    shades: [
                                        {
                                            style: 's-500',
                                            hex: '#ffc107'
                                        },
                                        {
                                            style: 's-100',
                                            hex: '#ffecb3'
                                        },
                                        {
                                            style: 's-200',
                                            hex: '#ffe082'
                                        },
                                        {
                                            style: 's-300',
                                            hex: '#ffd54f'
                                        },
                                        {
                                            style: 's-400',
                                            hex: '#ffca28'
                                        },
                                        {
                                            style: 's-800',
                                            hex: '#ff8f00'
                                        },
                                        {
                                            style: 's-900',
                                            hex: '#ff6f00'
                                        }
                                    ]
                                },
                                {
                                    color: 'green',
                                    shades: [
                                        {
                                            style: 's-500',
                                            hex: '#4caf50'
                                        },
                                        {
                                            style: 's-100',
                                            hex: '#c8e6c9'
                                        },
                                        {
                                            style: 's-200',
                                            hex: '#a5d6a7'
                                        },
                                        {
                                            style: 's-300',
                                            hex: '#81c784'
                                        },
                                        {
                                            style: 's-400',
                                            hex: '#66bb6a'
                                        },
                                        {
                                            style: 's-800',
                                            hex: '#2e7d32'
                                        },
                                        {
                                            style: 's-900',
                                            hex: '#1b5e20'
                                        }
                                    ]
                                },
                                {
                                    color: 'cyan',
                                    shades: [
                                        {
                                            style: 's-500',
                                            hex: '#00bcd4'
                                        },
                                        {
                                            style: 's-100',
                                            hex: '#b2ebf2'
                                        },
                                        {
                                            style: 's-200',
                                            hex: '#80deea'
                                        },
                                        {
                                            style: 's-300',
                                            hex: '#4dd0e1'
                                        },
                                        {
                                            style: 's-400',
                                            hex: '#26c6da'
                                        },
                                        {
                                            style: 's-800',
                                            hex: '#00838f'
                                        },
                                        {
                                            style: 's-900',
                                            hex: '#006064'
                                        }
                                    ]
                                },
                                {
                                    color: 'blue',
                                    shades: [
                                        {
                                            style: 's-500',
                                            hex: '#2196f3'
                                        },
                                        {
                                            style: 's-100',
                                            hex: '#bbdefb'
                                        },
                                        {
                                            style: 's-200',
                                            hex: '#90caf9'
                                        },
                                        {
                                            style: 's-300',
                                            hex: '#64b5f6'
                                        },
                                        {
                                            style: 's-400',
                                            hex: '#42a5f5'
                                        },
                                        {
                                            style: 's-800',
                                            hex: '#1565c0'
                                        },
                                        {
                                            style: 's-900',
                                            hex: '#0d47a1'
                                        }
                                    ]
                                },
                                {
                                    color: 'indigo',
                                    shades: [
                                        {
                                            style: 's-500',
                                            hex: '#3f51b5'
                                        },
                                        {
                                            style: 's-100',
                                            hex: '#c5cae9'
                                        },
                                        {
                                            style: 's-200',
                                            hex: '#9fa8da'
                                        },
                                        {
                                            style: 's-300',
                                            hex: '#7986cb'
                                        },
                                        {
                                            style: 's-400',
                                            hex: '#5c6bc0'
                                        },
                                        {
                                            style: 's-800',
                                            hex: '#283593'
                                        },
                                        {
                                            style: 's-900',
                                            hex: '#1a237e'
                                        }
                                    ]
                                },
                                {
                                    color: 'purple',
                                    shades: [
                                        {
                                            style: 's-500',
                                            hex: '#9c27b0'
                                        },
                                        {
                                            style: 's-100',
                                            hex: '#e1bee7'
                                        },
                                        {
                                            style: 's-200',
                                            hex: '#ce93d8'
                                        },
                                        {
                                            style: 's-300',
                                            hex: '#ba68c8'
                                        },
                                        {
                                            style: 's-400',
                                            hex: '#ab47bc'
                                        },
                                        {
                                            style: 's-800',
                                            hex: '#6a1b9a'
                                        },
                                        {
                                            style: 's-900',
                                            hex: '#4a148c'
                                        }
                                    ]
                                },
                                {
                                    color: 'pink',
                                    shades: [
                                        {
                                            style: 's-500',
                                            hex: '#e91e63'
                                        },
                                        {
                                            style: 's-100',
                                            hex: '#f8bbd0'
                                        },
                                        {
                                            style: 's-200',
                                            hex: '#f48fb1'
                                        },
                                        {
                                            style: 's-300',
                                            hex: '#f06292'
                                        },
                                        {
                                            style: 's-400',
                                            hex: '#ec407a'
                                        },
                                        {
                                            style: 's-800',
                                            hex: '#ad1457'
                                        },
                                        {
                                            style: 's-900',
                                            hex: '#880e4f'
                                        }
                                    ]
                                }
                            ]
                        };
                        function check_children(el) {
                            var list_items = color_picker.children(), found = false;
                            for (var i = 0; i < list_items.length; i++) {
                                found = list_items[i] === el;
                                if (found) {
                                    break;
                                }
                            }
                            return found;
                        }
                        $scope._paint = function (clr) {
                            var selectedColor;
                            if (!clr) {
                                selectedColor = $utls.color('transparent');
                                $scope.source = '';
                            } else {
                                selectedColor = $utls.color(clr);
                                $scope.source = '#' + selectedColor.toHex();
                            }
                            if (!$scope.options || $scope.options.colorLabel || !$scope.options.hasOwnProperty('colorLabel')) {
                                selectedColor.paintElement(elem);
                                selectedColor.paintElement(displayHex);
                            }
                            if ($scope.options && $scope.options.update) {
                                $scope.options.update($scope.source);
                            }
                        };
                        $scope.changes = {
                            colorSelected: function (ev, shade) {
                                $scope.colorOptions.selected = shade.hex;
                                $scope._paint(angular.element(ev.currentTarget).css('background-color'));
                                if ($scope.options && $scope.options.closeOnChange) {
                                    $timeout($scope.closeMenu, 10);
                                }
                            },
                            colorInputed: function (val) {
                                if (val && val.trim().length && val.match(colorRegex)) {
                                    $scope._paint(val);
                                } else {
                                    $scope._paint();
                                }
                            },
                            validColor: function (input, attr, ngModelCtrl, tipsy, context, modelVal, viewVal) {
                                var msg;
                                if (modelVal && !modelVal.match(colorRegex)) {
                                    msg = 'Not a valid color';
                                }
                                return msg;
                            }
                        };
                        $scope.openMenu = function () {
                            if (!$scope.isOpen) {
                                $scope.isOpen = true;
                                color_picker.removeClass('hide');
                                var top, xaxis, xpos, elemBounds = elem[0].getBoundingClientRect(), elemHalfWidth = elemBounds.width / 2, pickerBounds = color_picker[0].getBoundingClientRect(), pickerHalfWidth = pickerBounds.width / 2, contentBounds = parent_element[0].getBoundingClientRect(), scrollOffset = parent_element[0].scrollTop;
                                xaxis = elemBounds.left - pickerHalfWidth + elemHalfWidth;
                                top = scrollOffset + elemBounds.bottom;
                                xpos = pickerBounds.width + xaxis + 20;
                                if (xpos > contentBounds.width) {
                                    xaxis = xaxis - (xpos - contentBounds.width);
                                }
                                if (top + pickerBounds.height + 20 > contentBounds.height) {
                                    top = contentBounds.height - 20 - pickerBounds.height;
                                    if (top < elemBounds.top - 15) {
                                        xaxis = elemBounds.left - pickerBounds.width;
                                    }
                                }
                                color_picker.css('left', xaxis);
                                color_picker.css('top', top);
                            }
                        };
                        $scope.closeMenu = function () {
                            $scope.isOpen = false;
                            color_picker.addClass('hide');
                            if ($scope.ay_validate && $scope.ay_validate._validators[0] && $scope.ay_validate._validators[0].tip && $scope.ay_validate._validators[0].tip.$tip) {
                                $scope.ay_validate._validators[0].tip.$tip.remove();
                            }
                            cyclePhase();
                        };
                        parent_element.append(color_picker);
                        $scope.source = $scope.source ? '#' + $utls.color($scope.source).toHex() : '';
                        $scope.colorOptions.selected = $scope.source;
                        linkFn = $compile(color_picker);
                        linkFn($scope);
                        displayHex = color_picker.find('.color-picker-input .nav .display-wrapper .display-hex');
                        $scope._paint($scope.source);
                        if ($scope.options) {
                            $scope.options.picker = color_picker;
                            if (!$scope.options.hasOwnProperty('clearable')) {
                                $scope.options.clearable = true;
                            }
                            if (!$scope.options.hasOwnProperty('customable')) {
                                $scope.options.customable = true;
                            }
                        } else {
                            $scope.options = {
                                clearable: true,
                                customable: true
                            };
                        }
                        function detectTarget(source, event) {
                            var locator = event.toElement ? 'toElement' : 'target', isTarget = source === event[locator], containsTarget = source.contains(event[locator]);
                            return isTarget || containsTarget;
                        }
                        function toggleMenu(event) {
                            var locator = event.toElement ? 'toElement' : 'target', isTargetLink = detectTarget(elem[0], event), isTargetPicker = detectTarget(color_picker[0], event);
                            if (isTargetLink || isTargetPicker) {
                                if (!$scope.isOpen) {
                                    $scope.openMenu();
                                }
                            } else {
                                $scope.closeMenu();
                            }
                        }
                        $document.on('click', toggleMenu);
                        $scope.$on('$destroy', function (event) {
                            color_picker.remove();
                            $document.off('click', toggleMenu);
                        });
                    }
                };
            }
        ]
    };
});