define('modules/rest/factories/newsfeed', [], function () {
    'use strict';
    return {
        component: 'service',
        name: 'modelNewsfeed',
        fn: [
            '$ay_requester',
            'modelNotification',
            'modelEngagement',
            'modelTicket',
            'modelWorkitem',
            '$q',
            function newsfeed_factory($ay_requester, modelNotification, modelEngagement, modelTicket, modelWorkitem, $q) {
                var newsfeed = {
                    mediaTypes: {
                        engagement: modelEngagement.media_type,
                        notification: modelNotification.media_type,
                        task: modelWorkitem.media_type,
                        ticket: modelTicket.media_type
                    }
                };
                newsfeed._setModels = function (data) {
                    var models = [];
                    for (var i = 0, ii = data.items.length; i < ii; i++) {
                        switch (data.items[i].media_type) {
                        case modelNotification.media_type:
                            models.push(modelNotification.new(data.items[i]));
                            break;
                        case modelEngagement.media_type:
                            models.push(modelEngagement.newModel(data.items[i]));
                            break;
                        case modelTicket.media_type:
                            models.push(modelTicket.newModel(data.items[i]));
                            break;
                        case modelWorkitem.media_type:
                            models.push(modelWorkitem.newModel(data.items[i]));
                            break;
                        }
                    }
                    return models;
                };
                newsfeed._createMediaFilters = function (filters) {
                    var media_types = {
                        task: modelWorkitem.media_type,
                        ticket: modelTicket.media_type,
                        engagements: modelEngagement.media_type,
                        alert: modelNotification.media_type
                    };
                    function check(item) {
                        return media_types.hasOwnProperty(item);
                    }
                    function convert(item) {
                        return media_types[item];
                    }
                    return !filters || !Array.isArray(filters) || !filters.length ? [] : filters.filter(check).map(convert);
                };
                newsfeed._buildOptions = function (params) {
                    var data_options = {
                        f: [],
                        s: [{
                                field: '/created_at',
                                descending: true
                            }]
                    };
                    if (params.view) {
                        if (params.view.filters && params.view.filters.length) {
                            params.view.filters.map(function (option) {
                                data_options.f.push(option);
                            });
                        }
                        if (params.view.sorts && params.view.sorts.length) {
                            var created_at_override = params.view.sorts._index_of_by_prop('field', '/created_at');
                            if (created_at_override !== -1) {
                                data_options.s = params.view.sorts;
                            } else {
                                data_options.s = data_options.s.concat(params.view.sorts);
                            }
                        }
                    }
                    if (params.hasOwnProperty('filters') && Array.isArray(params.filters) && params.filters.length) {
                        data_options.f.push({
                            field: '/media_type',
                            op: 'IN',
                            value: newsfeed._createMediaFilters(params.filters)
                        });
                    }
                    if (params.date_after) {
                        data_options.f.push({
                            field: '/created_at',
                            op: 'GE',
                            value: new Date(params.date_after).toISOString()
                        });
                    }
                    if (params.date_before) {
                        data_options.f.push({
                            field: '/created_at',
                            op: 'LE',
                            value: new Date(params.date_before).toISOString()
                        });
                    }
                    if (params.account_name) {
                        data_options.f.push({
                            field: '/account/name',
                            op: 'CONTAINS',
                            value: params.account_name
                        });
                    }
                    if (params.account_id) {
                        data_options.f.push({
                            field: '/account/id',
                            op: 'EQ',
                            value: params.account_id
                        });
                    }
                    return data_options;
                };
                newsfeed.GET = function (params) {
                    var requestPromise, self = this, getDefer = $q.defer();
                    params = params || {};
                    params.options = self._buildOptions(params);
                    requestPromise = $ay_requester.GET('newsfeed', params);
                    requestPromise.then(function (results) {
                        if (!results.error) {
                            results.data = self._setModels(results.data);
                        }
                        getDefer.resolve(results);
                    });
                    getDefer.promise.cancel = function () {
                        requestPromise.cancel();
                    };
                    return getDefer.promise;
                };
                return newsfeed;
            }
        ]
    };
});