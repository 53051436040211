define('applications/login/index', ['applications/login/controllers/login'], function () {
    var def, module = angular.module('ay-login-mod', ['ngRoute']);
    module.config([
        '$routeProvider',
        function ($routeProvider) {
            $routeProvider.when('/csm', {
                templateUrl: 'login/views/login.html',
                resolve: {
                    appReady: [
                        '$rootScope',
                        '$q',
                        '$timeout',
                        '$ayAuthorizeRule',
                        '$ayEnviromentPackages',
                        '$location',
                        function ($rootScope, $q, $timeout, $ayAuthorizeRule, $ayEnviromentPackages, $location) {
                            var readyDefer = $q.defer();
                            function stopLoading() {
                                $timeout(function () {
                                    $rootScope.$broadcast('app-loading', false, 'csm-route');
                                });
                            }
                            $rootScope.$broadcast('app-loading', true, '/csm route');
                            if ($ayEnviromentPackages.isInstalled) {
                                $ayEnviromentPackages.reset();
                            }
                            $ayAuthorizeRule.auth().then(function (authRes) {
                                if (authRes.isAuthed) {
                                    if (!authRes.isSuspended) {
                                        $ayEnviromentPackages.loadVersion().then(stopLoading);
                                    } else {
                                        stopLoading();
                                        Phare.track({ event: 'Account suspended (authentication redirect)' });
                                        $location.path('/csm/account-suspended');
                                    }
                                } else {
                                    $rootScope.$broadcast('platform-content-change', { viewUrl: 'login/views/main.html' });
                                    stopLoading();
                                }
                                readyDefer.resolve(authRes);
                            });
                            return readyDefer.promise;
                        }
                    ]
                }
            }).when('/csm/password-reset', { templateUrl: 'password-reset/views/main.html' }).when('/csm/not-found', { templateUrl: 'login/views/not-found.html' }).when('/csm/account-suspended', {
                templateUrl: 'account-suspended/views/main.html',
                resolve: {
                    appReady: [
                        '$rootScope',
                        '$q',
                        '$timeout',
                        '$ayAuthorizeRule',
                        '$ayEnviromentPackages',
                        '$location',
                        function ($rootScope, $q, $timeout, $ayAuthorizeRule, $ayEnviromentPackages, $location) {
                            var readyDefer = $q.defer();
                            function stopLoading() {
                                $timeout(function () {
                                    $rootScope.$broadcast('app-loading', false, 'csm-route');
                                });
                            }
                            $rootScope.$broadcast('app-loading', true, '/csm/account-suspended route');
                            if ($ayEnviromentPackages.isInstalled) {
                                $ayEnviromentPackages.reset();
                            }
                            $ayAuthorizeRule.auth().then(function (authRes) {
                                if (authRes.isAuthed) {
                                    if (authRes.isSuspended) {
                                        $ayEnviromentPackages.loadVersion().then(stopLoading);
                                    } else {
                                        stopLoading();
                                        $location.path('/csm/');
                                    }
                                } else {
                                    $rootScope.$broadcast('platform-content-change', { viewUrl: 'login/views/main.html' });
                                    stopLoading();
                                }
                                readyDefer.resolve(authRes);
                            });
                            return readyDefer.promise;
                        }
                    ]
                }
            }).otherwise({
                template: '',
                resolve: {
                    appReady: [
                        '$rootScope',
                        '$q',
                        '$timeout',
                        '$ayAuthorizeRule',
                        '$ayEnviromentPackages',
                        '$location',
                        '$route',
                        function ($rootScope, $q, $timeout, $ayAuthorizeRule, $ayEnviromentPackages, $location, $route) {
                            var readyDefer = $q.defer();
                            function stopLoading() {
                                $timeout(function () {
                                    $rootScope.$broadcast('app-loading', false, 'otherwise-route');
                                });
                            }
                            $rootScope.$broadcast('app-loading', true, 'otherwise route');
                            if ($ayEnviromentPackages.isInstalled) {
                                $ayEnviromentPackages.reset();
                            }
                            $ayAuthorizeRule.auth().then(function (authRes) {
                                function completeLoad() {
                                    $route.reload();
                                    stopLoading();
                                }
                                if (authRes.isAuthed) {
                                    $ayEnviromentPackages.loadVersion().then(completeLoad);
                                } else {
                                    $location.path('/csm');
                                    stopLoading();
                                }
                                readyDefer.resolve(authRes);
                            });
                            return readyDefer.promise;
                        }
                    ]
                }
            });
        }
    ]);
    for (var i = 0, ii = arguments.length; i < ii; i++) {
        def = arguments[i];
        if (def.hasOwnProperty('name') && def.hasOwnProperty('fn') && def.hasOwnProperty('component')) {
            module[def.component](def.name, def.fn);
        }
    }
    return module;
});