define('modules/rest/services/auto-download', [], function () {
    'use strict';
    return {
        component: 'service',
        name: '$autoDownload',
        fn: [
            '$timeout',
            '$q',
            '$ay_requester',
            function ($timeout, $q, $ay_requester) {
                this.getMIME = function (name) {
                    var defaultMIME = 'application/octet-stream', MIMEs = [
                            {
                                'suffix': '.3dm',
                                'media': 'x-world/x-3dmf'
                            },
                            {
                                'suffix': '.3dmf',
                                'media': 'x-world/x-3dmf'
                            },
                            {
                                'suffix': '.a',
                                'media': 'application/octet-stream'
                            },
                            {
                                'suffix': '.aab',
                                'media': 'application/x-authorware-bin'
                            },
                            {
                                'suffix': '.aam',
                                'media': 'application/x-authorware-map'
                            },
                            {
                                'suffix': '.aas',
                                'media': 'application/x-authorware-seg'
                            },
                            {
                                'suffix': '.abc',
                                'media': 'text/vnd.abc'
                            },
                            {
                                'suffix': '.acgi',
                                'media': 'text/html'
                            },
                            {
                                'suffix': '.afl',
                                'media': 'video/animaflex'
                            },
                            {
                                'suffix': '.ai',
                                'media': 'application/postscript'
                            },
                            {
                                'suffix': '.aif',
                                'media': 'audio/aiff'
                            },
                            {
                                'suffix': '.aif',
                                'media': 'audio/x-aiff'
                            },
                            {
                                'suffix': '.aifc',
                                'media': 'audio/aiff'
                            },
                            {
                                'suffix': '.aifc',
                                'media': 'audio/x-aiff'
                            },
                            {
                                'suffix': '.aiff',
                                'media': 'audio/aiff'
                            },
                            {
                                'suffix': '.aiff',
                                'media': 'audio/x-aiff'
                            },
                            {
                                'suffix': '.aim',
                                'media': 'application/x-aim'
                            },
                            {
                                'suffix': '.aip',
                                'media': 'text/x-audiosoft-intra'
                            },
                            {
                                'suffix': '.ani',
                                'media': 'application/x-navi-animation'
                            },
                            {
                                'suffix': '.aos',
                                'media': 'application/x-nokia-9000-communicator-add-on-software'
                            },
                            {
                                'suffix': '.aps',
                                'media': 'application/mime'
                            },
                            {
                                'suffix': '.arc',
                                'media': 'application/octet-stream'
                            },
                            {
                                'suffix': '.arj',
                                'media': 'application/arj'
                            },
                            {
                                'suffix': '.arj',
                                'media': 'application/octet-stream'
                            },
                            {
                                'suffix': '.art',
                                'media': 'image/x-jg'
                            },
                            {
                                'suffix': '.asf',
                                'media': 'video/x-ms-asf'
                            },
                            {
                                'suffix': '.asm',
                                'media': 'text/x-asm'
                            },
                            {
                                'suffix': '.asp',
                                'media': 'text/asp'
                            },
                            {
                                'suffix': '.asx',
                                'media': 'application/x-mplayer2'
                            },
                            {
                                'suffix': '.asx',
                                'media': 'video/x-ms-asf'
                            },
                            {
                                'suffix': '.asx',
                                'media': 'video/x-ms-asf-plugin'
                            },
                            {
                                'suffix': '.au',
                                'media': 'audio/basic'
                            },
                            {
                                'suffix': '.au',
                                'media': 'audio/x-au'
                            },
                            {
                                'suffix': '.avi',
                                'media': 'application/x-troff-msvideo'
                            },
                            {
                                'suffix': '.avi',
                                'media': 'video/avi'
                            },
                            {
                                'suffix': '.avi',
                                'media': 'video/msvideo'
                            },
                            {
                                'suffix': '.avi',
                                'media': 'video/x-msvideo'
                            },
                            {
                                'suffix': '.avs',
                                'media': 'video/avs-video'
                            },
                            {
                                'suffix': '.bcpio',
                                'media': 'application/x-bcpio'
                            },
                            {
                                'suffix': '.bin',
                                'media': 'application/mac-binary'
                            },
                            {
                                'suffix': '.bin',
                                'media': 'application/macbinary'
                            },
                            {
                                'suffix': '.bin',
                                'media': 'application/octet-stream'
                            },
                            {
                                'suffix': '.bin',
                                'media': 'application/x-binary'
                            },
                            {
                                'suffix': '.bin',
                                'media': 'application/x-macbinary'
                            },
                            {
                                'suffix': '.bm',
                                'media': 'image/bmp'
                            },
                            {
                                'suffix': '.bmp',
                                'media': 'image/bmp'
                            },
                            {
                                'suffix': '.bmp',
                                'media': 'image/x-windows-bmp'
                            },
                            {
                                'suffix': '.boo',
                                'media': 'application/book'
                            },
                            {
                                'suffix': '.book',
                                'media': 'application/book'
                            },
                            {
                                'suffix': '.boz',
                                'media': 'application/x-bzip2'
                            },
                            {
                                'suffix': '.bsh',
                                'media': 'application/x-bsh'
                            },
                            {
                                'suffix': '.bz',
                                'media': 'application/x-bzip'
                            },
                            {
                                'suffix': '.bz2',
                                'media': 'application/x-bzip2'
                            },
                            {
                                'suffix': '.c',
                                'media': 'text/plain'
                            },
                            {
                                'suffix': '.c',
                                'media': 'text/x-c'
                            },
                            {
                                'suffix': '.c++',
                                'media': 'text/plain'
                            },
                            {
                                'suffix': '.cat',
                                'media': 'application/vnd.ms-pki.seccat'
                            },
                            {
                                'suffix': '.cc',
                                'media': 'text/plain'
                            },
                            {
                                'suffix': '.cc',
                                'media': 'text/x-c'
                            },
                            {
                                'suffix': '.ccad',
                                'media': 'application/clariscad'
                            },
                            {
                                'suffix': '.cco',
                                'media': 'application/x-cocoa'
                            },
                            {
                                'suffix': '.cdf',
                                'media': 'application/cdf'
                            },
                            {
                                'suffix': '.cdf',
                                'media': 'application/x-cdf'
                            },
                            {
                                'suffix': '.cdf',
                                'media': 'application/x-netcdf'
                            },
                            {
                                'suffix': '.cer',
                                'media': 'application/pkix-cert'
                            },
                            {
                                'suffix': '.cer',
                                'media': 'application/x-x509-ca-cert'
                            },
                            {
                                'suffix': '.cha',
                                'media': 'application/x-chat'
                            },
                            {
                                'suffix': '.chat',
                                'media': 'application/x-chat'
                            },
                            {
                                'suffix': '.class',
                                'media': 'application/java'
                            },
                            {
                                'suffix': '.class',
                                'media': 'application/java-byte-code'
                            },
                            {
                                'suffix': '.class',
                                'media': 'application/x-java-class'
                            },
                            {
                                'suffix': '.com',
                                'media': 'application/octet-stream'
                            },
                            {
                                'suffix': '.com',
                                'media': 'text/plain'
                            },
                            {
                                'suffix': '.conf',
                                'media': 'text/plain'
                            },
                            {
                                'suffix': '.cpio',
                                'media': 'application/x-cpio'
                            },
                            {
                                'suffix': '.cpp',
                                'media': 'text/x-c'
                            },
                            {
                                'suffix': '.cpt',
                                'media': 'application/mac-compactpro'
                            },
                            {
                                'suffix': '.cpt',
                                'media': 'application/x-compactpro'
                            },
                            {
                                'suffix': '.cpt',
                                'media': 'application/x-cpt'
                            },
                            {
                                'suffix': '.crl',
                                'media': 'application/pkcs-crl'
                            },
                            {
                                'suffix': '.crl',
                                'media': 'application/pkix-crl'
                            },
                            {
                                'suffix': '.crt',
                                'media': 'application/pkix-cert'
                            },
                            {
                                'suffix': '.crt',
                                'media': 'application/x-x509-ca-cert'
                            },
                            {
                                'suffix': '.crt',
                                'media': 'application/x-x509-user-cert'
                            },
                            {
                                'suffix': '.csh',
                                'media': 'application/x-csh'
                            },
                            {
                                'suffix': '.csh',
                                'media': 'text/x-script.csh'
                            },
                            {
                                'suffix': '.css',
                                'media': 'application/x-pointplus'
                            },
                            {
                                'suffix': '.css',
                                'media': 'text/css'
                            },
                            {
                                'suffix': '.cxx',
                                'media': 'text/plain'
                            },
                            {
                                'suffix': '.dcr',
                                'media': 'application/x-director'
                            },
                            {
                                'suffix': '.deepv',
                                'media': 'application/x-deepv'
                            },
                            {
                                'suffix': '.def',
                                'media': 'text/plain'
                            },
                            {
                                'suffix': '.der',
                                'media': 'application/x-x509-ca-cert'
                            },
                            {
                                'suffix': '.dif',
                                'media': 'video/x-dv'
                            },
                            {
                                'suffix': '.dir',
                                'media': 'application/x-director'
                            },
                            {
                                'suffix': '.dl',
                                'media': 'video/dl'
                            },
                            {
                                'suffix': '.dl',
                                'media': 'video/x-dl'
                            },
                            {
                                'suffix': '.doc',
                                'media': 'application/msword'
                            },
                            {
                                'suffix': '.dot',
                                'media': 'application/msword'
                            },
                            {
                                'suffix': '.dp',
                                'media': 'application/commonground'
                            },
                            {
                                'suffix': '.drw',
                                'media': 'application/drafting'
                            },
                            {
                                'suffix': '.dump',
                                'media': 'application/octet-stream'
                            },
                            {
                                'suffix': '.dv',
                                'media': 'video/x-dv'
                            },
                            {
                                'suffix': '.dvi',
                                'media': 'application/x-dvi'
                            },
                            {
                                'suffix': '.dwf',
                                'media': 'drawing/x-dwf (old)'
                            },
                            {
                                'suffix': '.dwf',
                                'media': 'model/vnd.dwf'
                            },
                            {
                                'suffix': '.dwg',
                                'media': 'application/acad'
                            },
                            {
                                'suffix': '.dwg',
                                'media': 'image/vnd.dwg'
                            },
                            {
                                'suffix': '.dwg',
                                'media': 'image/x-dwg'
                            },
                            {
                                'suffix': '.dxf',
                                'media': 'application/dxf'
                            },
                            {
                                'suffix': '.dxf',
                                'media': 'image/vnd.dwg'
                            },
                            {
                                'suffix': '.dxf',
                                'media': 'image/x-dwg'
                            },
                            {
                                'suffix': '.dxr',
                                'media': 'application/x-director'
                            },
                            {
                                'suffix': '.el',
                                'media': 'text/x-script.elisp'
                            },
                            {
                                'suffix': '.elc',
                                'media': 'application/x-bytecode.elisp (compiled elisp)'
                            },
                            {
                                'suffix': '.elc',
                                'media': 'application/x-elc'
                            },
                            {
                                'suffix': '.env',
                                'media': 'application/x-envoy'
                            },
                            {
                                'suffix': '.eps',
                                'media': 'application/postscript'
                            },
                            {
                                'suffix': '.es',
                                'media': 'application/x-esrehber'
                            },
                            {
                                'suffix': '.etx',
                                'media': 'text/x-setext'
                            },
                            {
                                'suffix': '.evy',
                                'media': 'application/envoy'
                            },
                            {
                                'suffix': '.evy',
                                'media': 'application/x-envoy'
                            },
                            {
                                'suffix': '.exe',
                                'media': 'application/octet-stream'
                            },
                            {
                                'suffix': '.f',
                                'media': 'text/plain'
                            },
                            {
                                'suffix': '.f',
                                'media': 'text/x-fortran'
                            },
                            {
                                'suffix': '.f77',
                                'media': 'text/x-fortran'
                            },
                            {
                                'suffix': '.f90',
                                'media': 'text/plain'
                            },
                            {
                                'suffix': '.f90',
                                'media': 'text/x-fortran'
                            },
                            {
                                'suffix': '.fdf',
                                'media': 'application/vnd.fdf'
                            },
                            {
                                'suffix': '.fif',
                                'media': 'application/fractals'
                            },
                            {
                                'suffix': '.fif',
                                'media': 'image/fif'
                            },
                            {
                                'suffix': '.fli',
                                'media': 'video/fli'
                            },
                            {
                                'suffix': '.fli',
                                'media': 'video/x-fli'
                            },
                            {
                                'suffix': '.flo',
                                'media': 'image/florian'
                            },
                            {
                                'suffix': '.flx',
                                'media': 'text/vnd.fmi.flexstor'
                            },
                            {
                                'suffix': '.fmf',
                                'media': 'video/x-atomic3d-feature'
                            },
                            {
                                'suffix': '.for',
                                'media': 'text/plain'
                            },
                            {
                                'suffix': '.for',
                                'media': 'text/x-fortran'
                            },
                            {
                                'suffix': '.fpx',
                                'media': 'image/vnd.fpx'
                            },
                            {
                                'suffix': '.fpx',
                                'media': 'image/vnd.net-fpx'
                            },
                            {
                                'suffix': '.frl',
                                'media': 'application/freeloader'
                            },
                            {
                                'suffix': '.funk',
                                'media': 'audio/make'
                            },
                            {
                                'suffix': '.g',
                                'media': 'text/plain'
                            },
                            {
                                'suffix': '.g3',
                                'media': 'image/g3fax'
                            },
                            {
                                'suffix': '.gif',
                                'media': 'image/gif'
                            },
                            {
                                'suffix': '.gl',
                                'media': 'video/gl'
                            },
                            {
                                'suffix': '.gl',
                                'media': 'video/x-gl'
                            },
                            {
                                'suffix': '.gsd',
                                'media': 'audio/x-gsm'
                            },
                            {
                                'suffix': '.gsm',
                                'media': 'audio/x-gsm'
                            },
                            {
                                'suffix': '.gsp',
                                'media': 'application/x-gsp'
                            },
                            {
                                'suffix': '.gss',
                                'media': 'application/x-gss'
                            },
                            {
                                'suffix': '.gtar',
                                'media': 'application/x-gtar'
                            },
                            {
                                'suffix': '.gz',
                                'media': 'application/x-compressed'
                            },
                            {
                                'suffix': '.gz',
                                'media': 'application/x-gzip'
                            },
                            {
                                'suffix': '.gzip',
                                'media': 'application/x-gzip'
                            },
                            {
                                'suffix': '.gzip',
                                'media': 'multipart/x-gzip'
                            },
                            {
                                'suffix': '.h',
                                'media': 'text/plain'
                            },
                            {
                                'suffix': '.h',
                                'media': 'text/x-h'
                            },
                            {
                                'suffix': '.hdf',
                                'media': 'application/x-hdf'
                            },
                            {
                                'suffix': '.help',
                                'media': 'application/x-helpfile'
                            },
                            {
                                'suffix': '.hgl',
                                'media': 'application/vnd.hp-hpgl'
                            },
                            {
                                'suffix': '.hh',
                                'media': 'text/plain'
                            },
                            {
                                'suffix': '.hh',
                                'media': 'text/x-h'
                            },
                            {
                                'suffix': '.hlb',
                                'media': 'text/x-script'
                            },
                            {
                                'suffix': '.hlp',
                                'media': 'application/hlp'
                            },
                            {
                                'suffix': '.hlp',
                                'media': 'application/x-helpfile'
                            },
                            {
                                'suffix': '.hlp',
                                'media': 'application/x-winhelp'
                            },
                            {
                                'suffix': '.hpg',
                                'media': 'application/vnd.hp-hpgl'
                            },
                            {
                                'suffix': '.hpgl',
                                'media': 'application/vnd.hp-hpgl'
                            },
                            {
                                'suffix': '.hqx',
                                'media': 'application/binhex'
                            },
                            {
                                'suffix': '.hqx',
                                'media': 'application/binhex4'
                            },
                            {
                                'suffix': '.hqx',
                                'media': 'application/mac-binhex'
                            },
                            {
                                'suffix': '.hqx',
                                'media': 'application/mac-binhex40'
                            },
                            {
                                'suffix': '.hqx',
                                'media': 'application/x-binhex40'
                            },
                            {
                                'suffix': '.hqx',
                                'media': 'application/x-mac-binhex40'
                            },
                            {
                                'suffix': '.hta',
                                'media': 'application/hta'
                            },
                            {
                                'suffix': '.htc',
                                'media': 'text/x-component'
                            },
                            {
                                'suffix': '.htm',
                                'media': 'text/html'
                            },
                            {
                                'suffix': '.html',
                                'media': 'text/html'
                            },
                            {
                                'suffix': '.htmls',
                                'media': 'text/html'
                            },
                            {
                                'suffix': '.htt',
                                'media': 'text/webviewhtml'
                            },
                            {
                                'suffix': '.htx',
                                'media': 'text/html'
                            },
                            {
                                'suffix': '.ice',
                                'media': 'x-conference/x-cooltalk'
                            },
                            {
                                'suffix': '.ico',
                                'media': 'image/x-icon'
                            },
                            {
                                'suffix': '.idc',
                                'media': 'text/plain'
                            },
                            {
                                'suffix': '.ief',
                                'media': 'image/ief'
                            },
                            {
                                'suffix': '.iefs',
                                'media': 'image/ief'
                            },
                            {
                                'suffix': '.iges',
                                'media': 'application/iges'
                            },
                            {
                                'suffix': '.iges',
                                'media': 'model/iges'
                            },
                            {
                                'suffix': '.igs',
                                'media': 'application/iges'
                            },
                            {
                                'suffix': '.igs',
                                'media': 'model/iges'
                            },
                            {
                                'suffix': '.ima',
                                'media': 'application/x-ima'
                            },
                            {
                                'suffix': '.imap',
                                'media': 'application/x-httpd-imap'
                            },
                            {
                                'suffix': '.inf',
                                'media': 'application/inf'
                            },
                            {
                                'suffix': '.ins',
                                'media': 'application/x-internett-signup'
                            },
                            {
                                'suffix': '.ip',
                                'media': 'application/x-ip2'
                            },
                            {
                                'suffix': '.isu',
                                'media': 'video/x-isvideo'
                            },
                            {
                                'suffix': '.it',
                                'media': 'audio/it'
                            },
                            {
                                'suffix': '.iv',
                                'media': 'application/x-inventor'
                            },
                            {
                                'suffix': '.ivr',
                                'media': 'i-world/i-vrml'
                            },
                            {
                                'suffix': '.ivy',
                                'media': 'application/x-livescreen'
                            },
                            {
                                'suffix': '.jam',
                                'media': 'audio/x-jam'
                            },
                            {
                                'suffix': '.jav',
                                'media': 'text/plain'
                            },
                            {
                                'suffix': '.jav',
                                'media': 'text/x-java-source'
                            },
                            {
                                'suffix': '.java',
                                'media': 'text/plain'
                            },
                            {
                                'suffix': '.java',
                                'media': 'text/x-java-source'
                            },
                            {
                                'suffix': '.jcm',
                                'media': 'application/x-java-commerce'
                            },
                            {
                                'suffix': '.jfif',
                                'media': 'image/jpeg'
                            },
                            {
                                'suffix': '.jfif',
                                'media': 'image/pjpeg'
                            },
                            {
                                'suffix': '.jfif-tbnl',
                                'media': 'image/jpeg'
                            },
                            {
                                'suffix': '.jpe',
                                'media': 'image/jpeg'
                            },
                            {
                                'suffix': '.jpe',
                                'media': 'image/pjpeg'
                            },
                            {
                                'suffix': '.jpeg',
                                'media': 'image/jpeg'
                            },
                            {
                                'suffix': '.jpeg',
                                'media': 'image/pjpeg'
                            },
                            {
                                'suffix': '.jpg',
                                'media': 'image/jpeg'
                            },
                            {
                                'suffix': '.jpg',
                                'media': 'image/pjpeg'
                            },
                            {
                                'suffix': '.jps',
                                'media': 'image/x-jps'
                            },
                            {
                                'suffix': '.js',
                                'media': 'application/x-javascript'
                            },
                            {
                                'suffix': '.js',
                                'media': 'application/javascript'
                            },
                            {
                                'suffix': '.js',
                                'media': 'application/ecmascript'
                            },
                            {
                                'suffix': '.js',
                                'media': 'text/javascript'
                            },
                            {
                                'suffix': '.js',
                                'media': 'text/ecmascript'
                            },
                            {
                                'suffix': '.jut',
                                'media': 'image/jutvision'
                            },
                            {
                                'suffix': '.kar',
                                'media': 'audio/midi'
                            },
                            {
                                'suffix': '.kar',
                                'media': 'music/x-karaoke'
                            },
                            {
                                'suffix': '.ksh',
                                'media': 'application/x-ksh'
                            },
                            {
                                'suffix': '.ksh',
                                'media': 'text/x-script.ksh'
                            },
                            {
                                'suffix': '.la',
                                'media': 'audio/nspaudio'
                            },
                            {
                                'suffix': '.la',
                                'media': 'audio/x-nspaudio'
                            },
                            {
                                'suffix': '.lam',
                                'media': 'audio/x-liveaudio'
                            },
                            {
                                'suffix': '.latex',
                                'media': 'application/x-latex'
                            },
                            {
                                'suffix': '.lha',
                                'media': 'application/lha'
                            },
                            {
                                'suffix': '.lha',
                                'media': 'application/octet-stream'
                            },
                            {
                                'suffix': '.lha',
                                'media': 'application/x-lha'
                            },
                            {
                                'suffix': '.lhx',
                                'media': 'application/octet-stream'
                            },
                            {
                                'suffix': '.list',
                                'media': 'text/plain'
                            },
                            {
                                'suffix': '.lma',
                                'media': 'audio/nspaudio'
                            },
                            {
                                'suffix': '.lma',
                                'media': 'audio/x-nspaudio'
                            },
                            {
                                'suffix': '.log',
                                'media': 'text/plain'
                            },
                            {
                                'suffix': '.lsp',
                                'media': 'application/x-lisp'
                            },
                            {
                                'suffix': '.lsp',
                                'media': 'text/x-script.lisp'
                            },
                            {
                                'suffix': '.lst',
                                'media': 'text/plain'
                            },
                            {
                                'suffix': '.lsx',
                                'media': 'text/x-la-asf'
                            },
                            {
                                'suffix': '.ltx',
                                'media': 'application/x-latex'
                            },
                            {
                                'suffix': '.lzh',
                                'media': 'application/octet-stream'
                            },
                            {
                                'suffix': '.lzh',
                                'media': 'application/x-lzh'
                            },
                            {
                                'suffix': '.lzx',
                                'media': 'application/lzx'
                            },
                            {
                                'suffix': '.lzx',
                                'media': 'application/octet-stream'
                            },
                            {
                                'suffix': '.lzx',
                                'media': 'application/x-lzx'
                            },
                            {
                                'suffix': '.m',
                                'media': 'text/plain'
                            },
                            {
                                'suffix': '.m',
                                'media': 'text/x-m'
                            },
                            {
                                'suffix': '.m1v',
                                'media': 'video/mpeg'
                            },
                            {
                                'suffix': '.m2a',
                                'media': 'audio/mpeg'
                            },
                            {
                                'suffix': '.m2v',
                                'media': 'video/mpeg'
                            },
                            {
                                'suffix': '.m3u',
                                'media': 'audio/x-mpequrl'
                            },
                            {
                                'suffix': '.man',
                                'media': 'application/x-troff-man'
                            },
                            {
                                'suffix': '.map',
                                'media': 'application/x-navimap'
                            },
                            {
                                'suffix': '.mar',
                                'media': 'text/plain'
                            },
                            {
                                'suffix': '.mbd',
                                'media': 'application/mbedlet'
                            },
                            {
                                'suffix': '.mc$',
                                'media': 'application/x-magic-cap-package-1.0'
                            },
                            {
                                'suffix': '.mcd',
                                'media': 'application/mcad'
                            },
                            {
                                'suffix': '.mcd',
                                'media': 'application/x-mathcad'
                            },
                            {
                                'suffix': '.mcf',
                                'media': 'image/vasa'
                            },
                            {
                                'suffix': '.mcf',
                                'media': 'text/mcf'
                            },
                            {
                                'suffix': '.mcp',
                                'media': 'application/netmc'
                            },
                            {
                                'suffix': '.me',
                                'media': 'application/x-troff-me'
                            },
                            {
                                'suffix': '.mht',
                                'media': 'message/rfc822'
                            },
                            {
                                'suffix': '.mhtml',
                                'media': 'message/rfc822'
                            },
                            {
                                'suffix': '.mid',
                                'media': 'application/x-midi'
                            },
                            {
                                'suffix': '.mid',
                                'media': 'audio/midi'
                            },
                            {
                                'suffix': '.mid',
                                'media': 'audio/x-mid'
                            },
                            {
                                'suffix': '.mid',
                                'media': 'audio/x-midi'
                            },
                            {
                                'suffix': '.mid',
                                'media': 'music/crescendo'
                            },
                            {
                                'suffix': '.mid',
                                'media': 'x-music/x-midi'
                            },
                            {
                                'suffix': '.midi',
                                'media': 'application/x-midi'
                            },
                            {
                                'suffix': '.midi',
                                'media': 'audio/midi'
                            },
                            {
                                'suffix': '.midi',
                                'media': 'audio/x-mid'
                            },
                            {
                                'suffix': '.midi',
                                'media': 'audio/x-midi'
                            },
                            {
                                'suffix': '.midi',
                                'media': 'music/crescendo'
                            },
                            {
                                'suffix': '.midi',
                                'media': 'x-music/x-midi'
                            },
                            {
                                'suffix': '.mif',
                                'media': 'application/x-frame'
                            },
                            {
                                'suffix': '.mif',
                                'media': 'application/x-mif'
                            },
                            {
                                'suffix': '.mime',
                                'media': 'message/rfc822'
                            },
                            {
                                'suffix': '.mime',
                                'media': 'www/mime'
                            },
                            {
                                'suffix': '.mjf',
                                'media': 'audio/x-vnd.audioexplosion.mjuicemediafile'
                            },
                            {
                                'suffix': '.mjpg',
                                'media': 'video/x-motion-jpeg'
                            },
                            {
                                'suffix': '.mm',
                                'media': 'application/base64'
                            },
                            {
                                'suffix': '.mm',
                                'media': 'application/x-meme'
                            },
                            {
                                'suffix': '.mme',
                                'media': 'application/base64'
                            },
                            {
                                'suffix': '.mod',
                                'media': 'audio/mod'
                            },
                            {
                                'suffix': '.mod',
                                'media': 'audio/x-mod'
                            },
                            {
                                'suffix': '.moov',
                                'media': 'video/quicktime'
                            },
                            {
                                'suffix': '.mov',
                                'media': 'video/quicktime'
                            },
                            {
                                'suffix': '.movie',
                                'media': 'video/x-sgi-movie'
                            },
                            {
                                'suffix': '.mp2',
                                'media': 'audio/mpeg'
                            },
                            {
                                'suffix': '.mp2',
                                'media': 'audio/x-mpeg'
                            },
                            {
                                'suffix': '.mp2',
                                'media': 'video/mpeg'
                            },
                            {
                                'suffix': '.mp2',
                                'media': 'video/x-mpeg'
                            },
                            {
                                'suffix': '.mp2',
                                'media': 'video/x-mpeq2a'
                            },
                            {
                                'suffix': '.mp3',
                                'media': 'audio/mpeg3'
                            },
                            {
                                'suffix': '.mp3',
                                'media': 'audio/x-mpeg-3'
                            },
                            {
                                'suffix': '.mp3',
                                'media': 'video/mpeg'
                            },
                            {
                                'suffix': '.mp3',
                                'media': 'video/x-mpeg'
                            },
                            {
                                'suffix': '.mpa',
                                'media': 'audio/mpeg'
                            },
                            {
                                'suffix': '.mpa',
                                'media': 'video/mpeg'
                            },
                            {
                                'suffix': '.mpc',
                                'media': 'application/x-project'
                            },
                            {
                                'suffix': '.mpe',
                                'media': 'video/mpeg'
                            },
                            {
                                'suffix': '.mpeg',
                                'media': 'video/mpeg'
                            },
                            {
                                'suffix': '.mpg',
                                'media': 'audio/mpeg'
                            },
                            {
                                'suffix': '.mpg',
                                'media': 'video/mpeg'
                            },
                            {
                                'suffix': '.mpga',
                                'media': 'audio/mpeg'
                            },
                            {
                                'suffix': '.mpp',
                                'media': 'application/vnd.ms-project'
                            },
                            {
                                'suffix': '.mpt',
                                'media': 'application/x-project'
                            },
                            {
                                'suffix': '.mpv',
                                'media': 'application/x-project'
                            },
                            {
                                'suffix': '.mpx',
                                'media': 'application/x-project'
                            },
                            {
                                'suffix': '.mrc',
                                'media': 'application/marc'
                            },
                            {
                                'suffix': '.ms',
                                'media': 'application/x-troff-ms'
                            },
                            {
                                'suffix': '.mv',
                                'media': 'video/x-sgi-movie'
                            },
                            {
                                'suffix': '.my',
                                'media': 'audio/make'
                            },
                            {
                                'suffix': '.mzz',
                                'media': 'application/x-vnd.audioexplosion.mzz'
                            },
                            {
                                'suffix': '.nap',
                                'media': 'image/naplps'
                            },
                            {
                                'suffix': '.naplps',
                                'media': 'image/naplps'
                            },
                            {
                                'suffix': '.nc',
                                'media': 'application/x-netcdf'
                            },
                            {
                                'suffix': '.ncm',
                                'media': 'application/vnd.nokia.configuration-message'
                            },
                            {
                                'suffix': '.nif',
                                'media': 'image/x-niff'
                            },
                            {
                                'suffix': '.niff',
                                'media': 'image/x-niff'
                            },
                            {
                                'suffix': '.nix',
                                'media': 'application/x-mix-transfer'
                            },
                            {
                                'suffix': '.nsc',
                                'media': 'application/x-conference'
                            },
                            {
                                'suffix': '.nvd',
                                'media': 'application/x-navidoc'
                            },
                            {
                                'suffix': '.o',
                                'media': 'application/octet-stream'
                            },
                            {
                                'suffix': '.oda',
                                'media': 'application/oda'
                            },
                            {
                                'suffix': '.omc',
                                'media': 'application/x-omc'
                            },
                            {
                                'suffix': '.omcd',
                                'media': 'application/x-omcdatamaker'
                            },
                            {
                                'suffix': '.omcr',
                                'media': 'application/x-omcregerator'
                            },
                            {
                                'suffix': '.p',
                                'media': 'text/x-pascal'
                            },
                            {
                                'suffix': '.p10',
                                'media': 'application/pkcs10'
                            },
                            {
                                'suffix': '.p10',
                                'media': 'application/x-pkcs10'
                            },
                            {
                                'suffix': '.p12',
                                'media': 'application/pkcs-12'
                            },
                            {
                                'suffix': '.p12',
                                'media': 'application/x-pkcs12'
                            },
                            {
                                'suffix': '.p7a',
                                'media': 'application/x-pkcs7-signature'
                            },
                            {
                                'suffix': '.p7c',
                                'media': 'application/pkcs7-mime'
                            },
                            {
                                'suffix': '.p7c',
                                'media': 'application/x-pkcs7-mime'
                            },
                            {
                                'suffix': '.p7m',
                                'media': 'application/pkcs7-mime'
                            },
                            {
                                'suffix': '.p7m',
                                'media': 'application/x-pkcs7-mime'
                            },
                            {
                                'suffix': '.p7r',
                                'media': 'application/x-pkcs7-certreqresp'
                            },
                            {
                                'suffix': '.p7s',
                                'media': 'application/pkcs7-signature'
                            },
                            {
                                'suffix': '.part',
                                'media': 'application/pro_eng'
                            },
                            {
                                'suffix': '.pas',
                                'media': 'text/pascal'
                            },
                            {
                                'suffix': '.pbm',
                                'media': 'image/x-portable-bitmap'
                            },
                            {
                                'suffix': '.pcl',
                                'media': 'application/vnd.hp-pcl'
                            },
                            {
                                'suffix': '.pcl',
                                'media': 'application/x-pcl'
                            },
                            {
                                'suffix': '.pct',
                                'media': 'image/x-pict'
                            },
                            {
                                'suffix': '.pcx',
                                'media': 'image/x-pcx'
                            },
                            {
                                'suffix': '.pdb',
                                'media': 'chemical/x-pdb'
                            },
                            {
                                'suffix': '.pdf',
                                'media': 'application/pdf'
                            },
                            {
                                'suffix': '.pfunk',
                                'media': 'audio/make'
                            },
                            {
                                'suffix': '.pfunk',
                                'media': 'audio/make.my.funk'
                            },
                            {
                                'suffix': '.pgm',
                                'media': 'image/x-portable-graymap'
                            },
                            {
                                'suffix': '.pgm',
                                'media': 'image/x-portable-greymap'
                            },
                            {
                                'suffix': '.pic',
                                'media': 'image/pict'
                            },
                            {
                                'suffix': '.pict',
                                'media': 'image/pict'
                            },
                            {
                                'suffix': '.pkg',
                                'media': 'application/x-newton-compatible-pkg'
                            },
                            {
                                'suffix': '.pko',
                                'media': 'application/vnd.ms-pki.pko'
                            },
                            {
                                'suffix': '.pl',
                                'media': 'text/plain'
                            },
                            {
                                'suffix': '.pl',
                                'media': 'text/x-script.perl'
                            },
                            {
                                'suffix': '.plx',
                                'media': 'application/x-pixclscript'
                            },
                            {
                                'suffix': '.pm',
                                'media': 'image/x-xpixmap'
                            },
                            {
                                'suffix': '.pm',
                                'media': 'text/x-script.perl-module'
                            },
                            {
                                'suffix': '.pm4',
                                'media': 'application/x-pagemaker'
                            },
                            {
                                'suffix': '.pm5',
                                'media': 'application/x-pagemaker'
                            },
                            {
                                'suffix': '.png',
                                'media': 'image/png'
                            },
                            {
                                'suffix': '.pnm',
                                'media': 'application/x-portable-anymap'
                            },
                            {
                                'suffix': '.pnm',
                                'media': 'image/x-portable-anymap'
                            },
                            {
                                'suffix': '.pot',
                                'media': 'application/mspowerpoint'
                            },
                            {
                                'suffix': '.pot',
                                'media': 'application/vnd.ms-powerpoint'
                            },
                            {
                                'suffix': '.pov',
                                'media': 'model/x-pov'
                            },
                            {
                                'suffix': '.ppa',
                                'media': 'application/vnd.ms-powerpoint'
                            },
                            {
                                'suffix': '.ppm',
                                'media': 'image/x-portable-pixmap'
                            },
                            {
                                'suffix': '.pps',
                                'media': 'application/mspowerpoint'
                            },
                            {
                                'suffix': '.pps',
                                'media': 'application/vnd.ms-powerpoint'
                            },
                            {
                                'suffix': '.ppt',
                                'media': 'application/mspowerpoint'
                            },
                            {
                                'suffix': '.ppt',
                                'media': 'application/powerpoint'
                            },
                            {
                                'suffix': '.ppt',
                                'media': 'application/vnd.ms-powerpoint'
                            },
                            {
                                'suffix': '.ppt',
                                'media': 'application/x-mspowerpoint'
                            },
                            {
                                'suffix': '.ppz',
                                'media': 'application/mspowerpoint'
                            },
                            {
                                'suffix': '.pre',
                                'media': 'application/x-freelance'
                            },
                            {
                                'suffix': '.prt',
                                'media': 'application/pro_eng'
                            },
                            {
                                'suffix': '.ps',
                                'media': 'application/postscript'
                            },
                            {
                                'suffix': '.psd',
                                'media': 'application/octet-stream'
                            },
                            {
                                'suffix': '.pvu',
                                'media': 'paleovu/x-pv'
                            },
                            {
                                'suffix': '.pwz',
                                'media': 'application/vnd.ms-powerpoint'
                            },
                            {
                                'suffix': '.py',
                                'media': 'text/x-script.phyton'
                            },
                            {
                                'suffix': '.pyc',
                                'media': 'application/x-bytecode.python'
                            },
                            {
                                'suffix': '.qcp',
                                'media': 'audio/vnd.qcelp'
                            },
                            {
                                'suffix': '.qd3',
                                'media': 'x-world/x-3dmf'
                            },
                            {
                                'suffix': '.qd3d',
                                'media': 'x-world/x-3dmf'
                            },
                            {
                                'suffix': '.qif',
                                'media': 'image/x-quicktime'
                            },
                            {
                                'suffix': '.qt',
                                'media': 'video/quicktime'
                            },
                            {
                                'suffix': '.qtc',
                                'media': 'video/x-qtc'
                            },
                            {
                                'suffix': '.qti',
                                'media': 'image/x-quicktime'
                            },
                            {
                                'suffix': '.qtif',
                                'media': 'image/x-quicktime'
                            },
                            {
                                'suffix': '.ra',
                                'media': 'audio/x-pn-realaudio'
                            },
                            {
                                'suffix': '.ra',
                                'media': 'audio/x-pn-realaudio-plugin'
                            },
                            {
                                'suffix': '.ra',
                                'media': 'audio/x-realaudio'
                            },
                            {
                                'suffix': '.ram',
                                'media': 'audio/x-pn-realaudio'
                            },
                            {
                                'suffix': '.ras',
                                'media': 'application/x-cmu-raster'
                            },
                            {
                                'suffix': '.ras',
                                'media': 'image/cmu-raster'
                            },
                            {
                                'suffix': '.ras',
                                'media': 'image/x-cmu-raster'
                            },
                            {
                                'suffix': '.rast',
                                'media': 'image/cmu-raster'
                            },
                            {
                                'suffix': '.rexx',
                                'media': 'text/x-script.rexx'
                            },
                            {
                                'suffix': '.rf',
                                'media': 'image/vnd.rn-realflash'
                            },
                            {
                                'suffix': '.rgb',
                                'media': 'image/x-rgb'
                            },
                            {
                                'suffix': '.rm',
                                'media': 'application/vnd.rn-realmedia'
                            },
                            {
                                'suffix': '.rm',
                                'media': 'audio/x-pn-realaudio'
                            },
                            {
                                'suffix': '.rmi',
                                'media': 'audio/mid'
                            },
                            {
                                'suffix': '.rmm',
                                'media': 'audio/x-pn-realaudio'
                            },
                            {
                                'suffix': '.rmp',
                                'media': 'audio/x-pn-realaudio'
                            },
                            {
                                'suffix': '.rmp',
                                'media': 'audio/x-pn-realaudio-plugin'
                            },
                            {
                                'suffix': '.rng',
                                'media': 'application/ringing-tones'
                            },
                            {
                                'suffix': '.rng',
                                'media': 'application/vnd.nokia.ringing-tone'
                            },
                            {
                                'suffix': '.rnx',
                                'media': 'application/vnd.rn-realplayer'
                            },
                            {
                                'suffix': '.roff',
                                'media': 'application/x-troff'
                            },
                            {
                                'suffix': '.rp',
                                'media': 'image/vnd.rn-realpix'
                            },
                            {
                                'suffix': '.rpm',
                                'media': 'audio/x-pn-realaudio-plugin'
                            },
                            {
                                'suffix': '.rt',
                                'media': 'text/richtext'
                            },
                            {
                                'suffix': '.rt',
                                'media': 'text/vnd.rn-realtext'
                            },
                            {
                                'suffix': '.rtf',
                                'media': 'application/rtf'
                            },
                            {
                                'suffix': '.rtf',
                                'media': 'application/x-rtf'
                            },
                            {
                                'suffix': '.rtf',
                                'media': 'text/richtext'
                            },
                            {
                                'suffix': '.rtx',
                                'media': 'application/rtf'
                            },
                            {
                                'suffix': '.rtx',
                                'media': 'text/richtext'
                            },
                            {
                                'suffix': '.rv',
                                'media': 'video/vnd.rn-realvideo'
                            },
                            {
                                'suffix': '.s',
                                'media': 'text/x-asm'
                            },
                            {
                                'suffix': '.s3m',
                                'media': 'audio/s3m'
                            },
                            {
                                'suffix': '.saveme',
                                'media': 'application/octet-stream'
                            },
                            {
                                'suffix': '.sbk',
                                'media': 'application/x-tbook'
                            },
                            {
                                'suffix': '.scm',
                                'media': 'application/x-lotusscreencam'
                            },
                            {
                                'suffix': '.scm',
                                'media': 'text/x-script.guile'
                            },
                            {
                                'suffix': '.scm',
                                'media': 'text/x-script.scheme'
                            },
                            {
                                'suffix': '.scm',
                                'media': 'video/x-scm'
                            },
                            {
                                'suffix': '.sdml',
                                'media': 'text/plain'
                            },
                            {
                                'suffix': '.sdp',
                                'media': 'application/sdp'
                            },
                            {
                                'suffix': '.sdp',
                                'media': 'application/x-sdp'
                            },
                            {
                                'suffix': '.sdr',
                                'media': 'application/sounder'
                            },
                            {
                                'suffix': '.sea',
                                'media': 'application/sea'
                            },
                            {
                                'suffix': '.sea',
                                'media': 'application/x-sea'
                            },
                            {
                                'suffix': '.set',
                                'media': 'application/set'
                            },
                            {
                                'suffix': '.sgm',
                                'media': 'text/sgml'
                            },
                            {
                                'suffix': '.sgm',
                                'media': 'text/x-sgml'
                            },
                            {
                                'suffix': '.sgml',
                                'media': 'text/sgml'
                            },
                            {
                                'suffix': '.sgml',
                                'media': 'text/x-sgml'
                            },
                            {
                                'suffix': '.sh',
                                'media': 'application/x-bsh'
                            },
                            {
                                'suffix': '.sh',
                                'media': 'application/x-sh'
                            },
                            {
                                'suffix': '.sh',
                                'media': 'application/x-shar'
                            },
                            {
                                'suffix': '.sh',
                                'media': 'text/x-script.sh'
                            },
                            {
                                'suffix': '.shar',
                                'media': 'application/x-bsh'
                            },
                            {
                                'suffix': '.shar',
                                'media': 'application/x-shar'
                            },
                            {
                                'suffix': '.shtml',
                                'media': 'text/html'
                            },
                            {
                                'suffix': '.shtml',
                                'media': 'text/x-server-parsed-html'
                            },
                            {
                                'suffix': '.sid',
                                'media': 'audio/x-psid'
                            },
                            {
                                'suffix': '.sit',
                                'media': 'application/x-sit'
                            },
                            {
                                'suffix': '.sit',
                                'media': 'application/x-stuffit'
                            },
                            {
                                'suffix': '.skd',
                                'media': 'application/x-koan'
                            },
                            {
                                'suffix': '.skm',
                                'media': 'application/x-koan'
                            },
                            {
                                'suffix': '.skp',
                                'media': 'application/x-koan'
                            },
                            {
                                'suffix': '.skt',
                                'media': 'application/x-koan'
                            },
                            {
                                'suffix': '.sl',
                                'media': 'application/x-seelogo'
                            },
                            {
                                'suffix': '.smi',
                                'media': 'application/smil'
                            },
                            {
                                'suffix': '.smil',
                                'media': 'application/smil'
                            },
                            {
                                'suffix': '.snd',
                                'media': 'audio/basic'
                            },
                            {
                                'suffix': '.snd',
                                'media': 'audio/x-adpcm'
                            },
                            {
                                'suffix': '.sol',
                                'media': 'application/solids'
                            },
                            {
                                'suffix': '.spc',
                                'media': 'application/x-pkcs7-certificates'
                            },
                            {
                                'suffix': '.spc',
                                'media': 'text/x-speech'
                            },
                            {
                                'suffix': '.spl',
                                'media': 'application/futuresplash'
                            },
                            {
                                'suffix': '.spr',
                                'media': 'application/x-sprite'
                            },
                            {
                                'suffix': '.sprite',
                                'media': 'application/x-sprite'
                            },
                            {
                                'suffix': '.src',
                                'media': 'application/x-wais-source'
                            },
                            {
                                'suffix': '.ssi',
                                'media': 'text/x-server-parsed-html'
                            },
                            {
                                'suffix': '.ssm',
                                'media': 'application/streamingmedia'
                            },
                            {
                                'suffix': '.sst',
                                'media': 'application/vnd.ms-pki.certstore'
                            },
                            {
                                'suffix': '.step',
                                'media': 'application/step'
                            },
                            {
                                'suffix': '.stl',
                                'media': 'application/sla'
                            },
                            {
                                'suffix': '.stl',
                                'media': 'application/vnd.ms-pki.stl'
                            },
                            {
                                'suffix': '.stl',
                                'media': 'application/x-navistyle'
                            },
                            {
                                'suffix': '.stp',
                                'media': 'application/step'
                            },
                            {
                                'suffix': '.sv4cpio',
                                'media': 'application/x-sv4cpio'
                            },
                            {
                                'suffix': '.sv4crc',
                                'media': 'application/x-sv4crc'
                            },
                            {
                                'suffix': '.svf',
                                'media': 'image/vnd.dwg'
                            },
                            {
                                'suffix': '.svf',
                                'media': 'image/x-dwg'
                            },
                            {
                                'suffix': '.svr',
                                'media': 'application/x-world'
                            },
                            {
                                'suffix': '.svr',
                                'media': 'x-world/x-svr'
                            },
                            {
                                'suffix': '.swf',
                                'media': 'application/x-shockwave-flash'
                            },
                            {
                                'suffix': '.t',
                                'media': 'application/x-troff'
                            },
                            {
                                'suffix': '.talk',
                                'media': 'text/x-speech'
                            },
                            {
                                'suffix': '.tar',
                                'media': 'application/x-tar'
                            },
                            {
                                'suffix': '.tbk',
                                'media': 'application/toolbook'
                            },
                            {
                                'suffix': '.tbk',
                                'media': 'application/x-tbook'
                            },
                            {
                                'suffix': '.tcl',
                                'media': 'application/x-tcl'
                            },
                            {
                                'suffix': '.tcl',
                                'media': 'text/x-script.tcl'
                            },
                            {
                                'suffix': '.tcsh',
                                'media': 'text/x-script.tcsh'
                            },
                            {
                                'suffix': '.tex',
                                'media': 'application/x-tex'
                            },
                            {
                                'suffix': '.texi',
                                'media': 'application/x-texinfo'
                            },
                            {
                                'suffix': '.texinfo',
                                'media': 'application/x-texinfo'
                            },
                            {
                                'suffix': '.text',
                                'media': 'application/plain'
                            },
                            {
                                'suffix': '.text',
                                'media': 'text/plain'
                            },
                            {
                                'suffix': '.tgz',
                                'media': 'application/gnutar'
                            },
                            {
                                'suffix': '.tgz',
                                'media': 'application/x-compressed'
                            },
                            {
                                'suffix': '.tif',
                                'media': 'image/tiff'
                            },
                            {
                                'suffix': '.tif',
                                'media': 'image/x-tiff'
                            },
                            {
                                'suffix': '.tiff',
                                'media': 'image/tiff'
                            },
                            {
                                'suffix': '.tiff',
                                'media': 'image/x-tiff'
                            },
                            {
                                'suffix': '.tr',
                                'media': 'application/x-troff'
                            },
                            {
                                'suffix': '.tsi',
                                'media': 'audio/tsp-audio'
                            },
                            {
                                'suffix': '.tsp',
                                'media': 'application/dsptype'
                            },
                            {
                                'suffix': '.tsp',
                                'media': 'audio/tsplayer'
                            },
                            {
                                'suffix': '.tsv',
                                'media': 'text/tab-separated-values'
                            },
                            {
                                'suffix': '.turbot',
                                'media': 'image/florian'
                            },
                            {
                                'suffix': '.txt',
                                'media': 'text/plain'
                            },
                            {
                                'suffix': '.uil',
                                'media': 'text/x-uil'
                            },
                            {
                                'suffix': '.uni',
                                'media': 'text/uri-list'
                            },
                            {
                                'suffix': '.unis',
                                'media': 'text/uri-list'
                            },
                            {
                                'suffix': '.unv',
                                'media': 'application/i-deas'
                            },
                            {
                                'suffix': '.uri',
                                'media': 'text/uri-list'
                            },
                            {
                                'suffix': '.uris',
                                'media': 'text/uri-list'
                            },
                            {
                                'suffix': '.ustar',
                                'media': 'application/x-ustar'
                            },
                            {
                                'suffix': '.ustar',
                                'media': 'multipart/x-ustar'
                            },
                            {
                                'suffix': '.uu',
                                'media': 'application/octet-stream'
                            },
                            {
                                'suffix': '.uu',
                                'media': 'text/x-uuencode'
                            },
                            {
                                'suffix': '.uue',
                                'media': 'text/x-uuencode'
                            },
                            {
                                'suffix': '.vcd',
                                'media': 'application/x-cdlink'
                            },
                            {
                                'suffix': '.vcs',
                                'media': 'text/x-vcalendar'
                            },
                            {
                                'suffix': '.vda',
                                'media': 'application/vda'
                            },
                            {
                                'suffix': '.vdo',
                                'media': 'video/vdo'
                            },
                            {
                                'suffix': '.vew',
                                'media': 'application/groupwise'
                            },
                            {
                                'suffix': '.viv',
                                'media': 'video/vivo'
                            },
                            {
                                'suffix': '.viv',
                                'media': 'video/vnd.vivo'
                            },
                            {
                                'suffix': '.vivo',
                                'media': 'video/vivo'
                            },
                            {
                                'suffix': '.vivo',
                                'media': 'video/vnd.vivo'
                            },
                            {
                                'suffix': '.vmd',
                                'media': 'application/vocaltec-media-desc'
                            },
                            {
                                'suffix': '.vmf',
                                'media': 'application/vocaltec-media-file'
                            },
                            {
                                'suffix': '.voc',
                                'media': 'audio/voc'
                            },
                            {
                                'suffix': '.voc',
                                'media': 'audio/x-voc'
                            },
                            {
                                'suffix': '.vos',
                                'media': 'video/vosaic'
                            },
                            {
                                'suffix': '.vox',
                                'media': 'audio/voxware'
                            },
                            {
                                'suffix': '.vqe',
                                'media': 'audio/x-twinvq-plugin'
                            },
                            {
                                'suffix': '.vqf',
                                'media': 'audio/x-twinvq'
                            },
                            {
                                'suffix': '.vql',
                                'media': 'audio/x-twinvq-plugin'
                            },
                            {
                                'suffix': '.vrml',
                                'media': 'application/x-vrml'
                            },
                            {
                                'suffix': '.vrml',
                                'media': 'model/vrml'
                            },
                            {
                                'suffix': '.vrml',
                                'media': 'x-world/x-vrml'
                            },
                            {
                                'suffix': '.vrt',
                                'media': 'x-world/x-vrt'
                            },
                            {
                                'suffix': '.vsd',
                                'media': 'application/x-visio'
                            },
                            {
                                'suffix': '.vst',
                                'media': 'application/x-visio'
                            },
                            {
                                'suffix': '.vsw',
                                'media': 'application/x-visio'
                            },
                            {
                                'suffix': '.w60',
                                'media': 'application/wordperfect6.0'
                            },
                            {
                                'suffix': '.w61',
                                'media': 'application/wordperfect6.1'
                            },
                            {
                                'suffix': '.w6w',
                                'media': 'application/msword'
                            },
                            {
                                'suffix': '.wav',
                                'media': 'audio/wav'
                            },
                            {
                                'suffix': '.wav',
                                'media': 'audio/x-wav'
                            },
                            {
                                'suffix': '.wb1',
                                'media': 'application/x-qpro'
                            },
                            {
                                'suffix': '.wbmp',
                                'media': 'image/vnd.wap.wbmp'
                            },
                            {
                                'suffix': '.web',
                                'media': 'application/vnd.xara'
                            },
                            {
                                'suffix': '.wiz',
                                'media': 'application/msword'
                            },
                            {
                                'suffix': '.wk1',
                                'media': 'application/x-123'
                            },
                            {
                                'suffix': '.wmf',
                                'media': 'windows/metafile'
                            },
                            {
                                'suffix': '.wml',
                                'media': 'text/vnd.wap.wml'
                            },
                            {
                                'suffix': '.wmlc',
                                'media': 'application/vnd.wap.wmlc'
                            },
                            {
                                'suffix': '.wmls',
                                'media': 'text/vnd.wap.wmlscript'
                            },
                            {
                                'suffix': '.wmlsc',
                                'media': 'application/vnd.wap.wmlscriptc'
                            },
                            {
                                'suffix': '.word',
                                'media': 'application/msword'
                            },
                            {
                                'suffix': '.wp',
                                'media': 'application/wordperfect'
                            },
                            {
                                'suffix': '.wp5',
                                'media': 'application/wordperfect'
                            },
                            {
                                'suffix': '.wp5',
                                'media': 'application/wordperfect6.0'
                            },
                            {
                                'suffix': '.wp6',
                                'media': 'application/wordperfect'
                            },
                            {
                                'suffix': '.wpd',
                                'media': 'application/wordperfect'
                            },
                            {
                                'suffix': '.wpd',
                                'media': 'application/x-wpwin'
                            },
                            {
                                'suffix': '.wq1',
                                'media': 'application/x-lotus'
                            },
                            {
                                'suffix': '.wri',
                                'media': 'application/mswrite'
                            },
                            {
                                'suffix': '.wri',
                                'media': 'application/x-wri'
                            },
                            {
                                'suffix': '.wrl',
                                'media': 'application/x-world'
                            },
                            {
                                'suffix': '.wrl',
                                'media': 'model/vrml'
                            },
                            {
                                'suffix': '.wrl',
                                'media': 'x-world/x-vrml'
                            },
                            {
                                'suffix': '.wrz',
                                'media': 'model/vrml'
                            },
                            {
                                'suffix': '.wrz',
                                'media': 'x-world/x-vrml'
                            },
                            {
                                'suffix': '.wsc',
                                'media': 'text/scriplet'
                            },
                            {
                                'suffix': '.wsrc',
                                'media': 'application/x-wais-source'
                            },
                            {
                                'suffix': '.wtk',
                                'media': 'application/x-wintalk'
                            },
                            {
                                'suffix': '.xbm',
                                'media': 'image/x-xbitmap'
                            },
                            {
                                'suffix': '.xbm',
                                'media': 'image/x-xbm'
                            },
                            {
                                'suffix': '.xbm',
                                'media': 'image/xbm'
                            },
                            {
                                'suffix': '.xdr',
                                'media': 'video/x-amt-demorun'
                            },
                            {
                                'suffix': '.xgz',
                                'media': 'xgl/drawing'
                            },
                            {
                                'suffix': '.xif',
                                'media': 'image/vnd.xiff'
                            },
                            {
                                'suffix': '.xl',
                                'media': 'application/excel'
                            },
                            {
                                'suffix': '.xla',
                                'media': 'application/excel'
                            },
                            {
                                'suffix': '.xla',
                                'media': 'application/x-excel'
                            },
                            {
                                'suffix': '.xla',
                                'media': 'application/x-msexcel'
                            },
                            {
                                'suffix': '.xlb',
                                'media': 'application/excel'
                            },
                            {
                                'suffix': '.xlb',
                                'media': 'application/vnd.ms-excel'
                            },
                            {
                                'suffix': '.xlb',
                                'media': 'application/x-excel'
                            },
                            {
                                'suffix': '.xlc',
                                'media': 'application/excel'
                            },
                            {
                                'suffix': '.xlc',
                                'media': 'application/vnd.ms-excel'
                            },
                            {
                                'suffix': '.xlc',
                                'media': 'application/x-excel'
                            },
                            {
                                'suffix': '.xld',
                                'media': 'application/excel'
                            },
                            {
                                'suffix': '.xld',
                                'media': 'application/x-excel'
                            },
                            {
                                'suffix': '.xlk',
                                'media': 'application/excel'
                            },
                            {
                                'suffix': '.xlk',
                                'media': 'application/x-excel'
                            },
                            {
                                'suffix': '.xll',
                                'media': 'application/excel'
                            },
                            {
                                'suffix': '.xll',
                                'media': 'application/vnd.ms-excel'
                            },
                            {
                                'suffix': '.xll',
                                'media': 'application/x-excel'
                            },
                            {
                                'suffix': '.xlm',
                                'media': 'application/excel'
                            },
                            {
                                'suffix': '.xlm',
                                'media': 'application/vnd.ms-excel'
                            },
                            {
                                'suffix': '.xlm',
                                'media': 'application/x-excel'
                            },
                            {
                                'suffix': '.xls',
                                'media': 'application/excel'
                            },
                            {
                                'suffix': '.xls',
                                'media': 'application/vnd.ms-excel'
                            },
                            {
                                'suffix': '.xls',
                                'media': 'application/x-excel'
                            },
                            {
                                'suffix': '.xls',
                                'media': 'application/x-msexcel'
                            },
                            {
                                'suffix': '.xlt',
                                'media': 'application/excel'
                            },
                            {
                                'suffix': '.xlt',
                                'media': 'application/x-excel'
                            },
                            {
                                'suffix': '.xlv',
                                'media': 'application/excel'
                            },
                            {
                                'suffix': '.xlv',
                                'media': 'application/x-excel'
                            },
                            {
                                'suffix': '.xlw',
                                'media': 'application/excel'
                            },
                            {
                                'suffix': '.xlw',
                                'media': 'application/vnd.ms-excel'
                            },
                            {
                                'suffix': '.xlw',
                                'media': 'application/x-excel'
                            },
                            {
                                'suffix': '.xlw',
                                'media': 'application/x-msexcel'
                            },
                            {
                                'suffix': '.xm',
                                'media': 'audio/xm'
                            },
                            {
                                'suffix': '.xml',
                                'media': 'application/xml'
                            },
                            {
                                'suffix': '.xml',
                                'media': 'text/xml'
                            },
                            {
                                'suffix': '.xmz',
                                'media': 'xgl/movie'
                            },
                            {
                                'suffix': '.xpix',
                                'media': 'application/x-vnd.ls-xpix'
                            },
                            {
                                'suffix': '.xpm',
                                'media': 'image/x-xpixmap'
                            },
                            {
                                'suffix': '.xpm',
                                'media': 'image/xpm'
                            },
                            {
                                'suffix': '.x-png',
                                'media': 'image/png'
                            },
                            {
                                'suffix': '.xsr',
                                'media': 'video/x-amt-showrun'
                            },
                            {
                                'suffix': '.xwd',
                                'media': 'image/x-xwd'
                            },
                            {
                                'suffix': '.xwd',
                                'media': 'image/x-xwindowdump'
                            },
                            {
                                'suffix': '.xyz',
                                'media': 'chemical/x-pdb'
                            },
                            {
                                'suffix': '.z',
                                'media': 'application/x-compress'
                            },
                            {
                                'suffix': '.z',
                                'media': 'application/x-compressed'
                            },
                            {
                                'suffix': '.zip',
                                'media': 'application/x-compressed'
                            },
                            {
                                'suffix': '.zip',
                                'media': 'application/x-zip-compressed'
                            },
                            {
                                'suffix': '.zip',
                                'media': 'application/zip'
                            },
                            {
                                'suffix': '.zip',
                                'media': 'multipart/x-zip'
                            },
                            {
                                'suffix': '.zoo',
                                'media': 'application/octet-stream'
                            },
                            {
                                'suffix': '.zsh',
                                'media': 'text/x-script.zsh'
                            }
                        ], file = name.split('.'), index = MIMEs._index_of_by_prop('suffix', '.' + file[file.length - 1]);
                    return index !== -1 ? MIMEs[index].media : defaultMIME;
                };
                this.download = function (url, name, type) {
                    type = type || this.getMIME(name);
                    url = $ay_requester.rest_api + url;
                    var self = this, dlDefer = $q.defer(), headers = {
                            'Accept': 'text/plain',
                            'Content-Type': type
                        };
                    $ay_requester.FILE(url, headers, true).then(function (results) {
                        var a;
                        if (!results.error) {
                            a = document.createElement('a');
                            a.setAttribute('download', name);
                            a.setAttribute('target', '_self');
                            a.href = URL.createObjectURL(results.data);
                            document.body.appendChild(a);
                            $timeout(function () {
                                a.click();
                                document.body.removeChild(a);
                            }, 100);
                        }
                        dlDefer.resolve(results);
                    });
                    return dlDefer.promise;
                };
            }
        ]
    };
});