define('modules/form-inputs/directives/input-text', [], function () {
    return {
        component: 'directive',
        name: 'ayFormInputText',
        fn: [
            '$timeout',
            function ($timeout) {
                return {
                    restrict: 'E',
                    templateUrl: 'form-inputs/views/input-text.html',
                    require: '?ngModel',
                    scope: {
                        label: '@',
                        isRequired: '@',
                        validationMsg: '@',
                        helpText: '@',
                        isDisabled: '=?',
                        isFocused: '@',
                        rows: '@',
                        max: '@',
                        min: '@',
                        tabIndex: '@?'
                    },
                    controller: [function () {
                            this.value = '';
                        }],
                    controllerAs: 'formInput',
                    bindToController: true,
                    link: function (scope, elem, attrs, ngModel) {
                        var width;
                        scope.formInput.isMulti = attrs.isMulti ? true : false;
                        scope.formInput.rows = scope.formInput.rows || 3;
                        scope.formInput.styling = { width: scope.formInput.width || '100%' };
                        scope.formInput.msgStyling = { visibility: 'hidden' };
                        scope.formInput.getInput = function () {
                            return angular.element(elem.find('.form-input-field'));
                        };
                        scope.formInput.blur = function () {
                            scope.formInput.getInput().blur();
                        };
                        scope.formInput.onChange = function (event) {
                            width = parseInt(elem.find('.form-input-ctrl').css('width').replace('px', ''), 10);
                            ngModel.$setViewValue(scope.formInput.value, event);
                        };
                        scope.formInput.send = function (action, event) {
                            scope.$parent.$eval(action, {
                                $event: event,
                                ngModelCtrl: ngModel
                            });
                        };
                        scope.formInput.onBlur = function () {
                            elem.removeClass('is-selected');
                            if (attrs.onBlur) {
                                scope.formInput.send(attrs.onBlur, { type: 'input-blur' });
                            }
                        };
                        scope.formInput.onSubmit = function () {
                            if (attrs.onBlur) {
                                $timeout(scope.formInput.blur, 100);
                            } else {
                                elem.removeClass('is-selected');
                                if (attrs.onSubmit) {
                                    scope.formInput.send(attrs.onSubmit, { type: 'input-submit' });
                                }
                            }
                        };
                        scope.formInput.setFocus = function () {
                            elem.addClass('is-selected');
                            scope.formInput.getInput().focus();
                        };
                        scope.formInput.onMouseDown = function (event) {
                            elem.addClass('is-selected');
                        };
                        scope.formInput.keydown = function (event) {
                            switch (event.keyCode) {
                            case 13:
                                scope.formInput.onSubmit();
                                break;
                            case 27:
                                if (attrs.onCancel) {
                                    scope.formInput.send(attrs.onCancel, { type: 'input-cancel' });
                                    $timeout(scope.formInput.blur, 100);
                                }
                                break;
                            }
                        };
                        ngModel.$viewChangeListeners.push(function (event) {
                            if (attrs.onChange) {
                                scope.$parent.$eval(attrs.onChange, {
                                    $event: event,
                                    ngModelCtrl: ngModel
                                });
                            }
                        });
                        function checkInputMsg(newVal) {
                            var inputMsg = elem.find('.form-input-msg');
                            function updateMsg() {
                                var msgWidth = parseInt(inputMsg.css('width').replace('px', ''), 10) + 5;
                                if (width > msgWidth) {
                                    scope.formInput.msgStyling.position = 'initial';
                                    scope.formInput.msgStyling['white-space'] = 'normal';
                                } else {
                                    scope.formInput.msgStyling['margin-bottom', '22px'];
                                }
                                scope.formInput.msgStyling.visibility = 'visible';
                            }
                            if (inputMsg[0]) {
                                scope.formInput.msgStyling.visibility = 'hidden';
                                scope.formInput.msgStyling.position = 'absolute';
                                scope.formInput.msgStyling['white-space'] = 'nowrap';
                                elem.css('margin-bottom', '0px');
                                $timeout(updateMsg, 10);
                            }
                        }
                        function focusEl(newVal, oldVal) {
                            if (scope.formInput.isFocused === 'true') {
                                scope.formInput.setFocus();
                            }
                        }
                        function init() {
                            if (attrs.overflowError && attrs.overflowError === 'true') {
                                scope.$watch('formInput.validationMsg', checkInputMsg);
                                scope.$watch('formInput.helpText', checkInputMsg);
                                checkInputMsg();
                            } else {
                                scope.formInput.msgStyling.visibility = 'visible';
                            }
                            focusEl();
                        }
                        scope.$watch('formInput.isFocused', focusEl);
                        ngModel.$render = function () {
                            scope.formInput.value = ngModel.$modelValue;
                            $timeout(init, 1);
                        };
                    }
                };
            }
        ]
    };
});