define('modules/authentication/index', [
    'modules/authentication/services/authorize-rule',
    'modules/authentication/services/me',
    'modules/authentication/services/session'
], function () {
    var def, module = angular.module('ay-authentication-mod', ['ngRoute']);
    for (var i = 0, ii = arguments.length; i < ii; i++) {
        def = arguments[i];
        if (def.hasOwnProperty('name') && def.hasOwnProperty('fn') && def.hasOwnProperty('component')) {
            module[def.component](def.name, def.fn);
        }
    }
    return module;
});