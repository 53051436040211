define('modules/rest/factories/activities', [], function () {
    'use strict';
    return {
        component: 'service',
        name: 'modelActivity',
        fn: [
            '$log',
            'srvcRequester',
            '$q',
            '$timeout',
            '$ay_requester',
            function activity_factory($log, srvcRequester, $q, $timeout, $ay_requester) {
                var activity = { media_type: 'application/vnd.amity.hub.activity+json' };
                var _ACTIVITY = function (data) {
                    var self = this;
                    self.data_type = 'activity';
                    self.created_at = null;
                    self.workspace_id = null;
                    self.media_type = null;
                    self.id = null;
                    self.account = null;
                    self.participation = null;
                    self.name = null;
                    self.properties = null;
                    self.set = function (data) {
                        if (!data) {
                            return null;
                        }
                        self.created_at = data.created_at ? new Date(data.created_at) : null;
                        self.workspace_id = data.workspace_id;
                        self.media_type = data.media_type;
                        self.id = data.id;
                        self.account = data.account;
                        self.participation = data.participation;
                        self.name = data.name;
                        self.properties = data.properties;
                        if (self.properties) {
                            self._properties = [];
                            for (var key in self.properties) {
                                self._properties.push({
                                    label: key,
                                    value: self.properties[key]
                                });
                            }
                            self._properties.sort_by('label');
                        }
                    };
                    if (data) {
                        self.set(data);
                    }
                };
                activity.set = function (results) {
                    var params = {
                        data: null,
                        data_type: 'activity',
                        cursor: results.cursor,
                        error: results.error,
                        call_info: results.call_info
                    };
                    if (!results.error) {
                        if (results.data.items && results.data.items.length) {
                            var list = [];
                            for (var i = 0; i < results.data.items.length; i++) {
                                list.push(new _ACTIVITY(results.data.items[i]));
                            }
                            params.data = list;
                        }
                    }
                    results._callback(params);
                };
                activity.getSchema = function get_entity_schema() {
                    var s = this, schemaDefer = $q.defer();
                    function setEntitySchema(results) {
                        if (!results.error) {
                            s.schema = results.data;
                            schemaDefer.resolve(s.schema);
                        } else {
                            schemaDefer.resolve(results);
                        }
                    }
                    if (s.schema) {
                        $timeout(function () {
                            schemaDefer.resolve(s.schema);
                        }, 1);
                    } else {
                        $ay_requester.GET('schema/activities').then(setEntitySchema);
                    }
                    return schemaDefer.promise;
                };
                activity.get = function (type, params) {
                    var self = this;
                    function sendCall() {
                        switch (type) {
                        case 'activity':
                            srvcRequester.get('activities', activity.set, params);
                            break;
                        case 'activity-all':
                            srvcRequester.get('activities', activity.set, params);
                            break;
                        case 'shell':
                            return params ? new _ACTIVITY(params) : new _ACTIVITY();
                        case 'activities-account':
                            params.options = {
                                f: [{
                                        field: '/account/id',
                                        op: 'EQ',
                                        value: params.account_id
                                    }],
                                s: [{
                                        field: '/created_at',
                                        descending: true
                                    }]
                            };
                            srvcRequester.get('activities', activity.set, params);
                            break;
                        case 'activities-participant':
                            params.options = {
                                f: [{
                                        field: '/participation/participant/id',
                                        op: 'EQ',
                                        value: params.participant_id
                                    }],
                                s: [{
                                        field: '/created_at',
                                        descending: true
                                    }]
                            };
                            srvcRequester.get('activities', activity.set, params);
                            break;
                        }
                    }
                    if (self.schema) {
                        sendCall();
                    } else {
                        self.getSchema().then(sendCall);
                    }
                };
                return activity;
            }
        ]
    };
});