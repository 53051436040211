define('modules/rest/factories/invoices', [], function () {
    'use strict';
    return {
        component: 'service',
        name: 'restFactoryInvoices',
        fn: [
            '$log',
            '$schemaValidator',
            '$ay_requester',
            '$q',
            '$timeout',
            '$ME',
            '$utls',
            function ($log, $schemaValidator, $ay_requester, $q, $timeout, $ME, $utls) {
                var LOCATION = 'invoices', mediaType = 'application/vnd.amity.hub.invoice+json';
                function InvoiceModel(data, schema) {
                    this.schema = schema;
                    this.id = null;
                    this.media_type = mediaType;
                    this.workspace_id = $ME.workspace.id;
                    this.account_id = null;
                    this.created_at = null;
                    this.external_ids = [];
                    this.updated_at = null;
                    this.due_date = null;
                    this.fully_paid_at = null;
                    this.number = null;
                    this.status = null;
                    this.sent = null;
                    this.void = null;
                    this.currency_code = null;
                    this.currency_rate = null;
                    this.amount = null;
                    this.balance = null;
                    this.line_items = [];
                    this.progress = {};
                    if (data) {
                        this._set(data);
                    }
                }
                InvoiceModel.prototype._set = function (data) {
                    this.id = data.id;
                    this.location = LOCATION + '/' + data.id;
                    this.media_type = data.media_type || this.media_type;
                    this.workspace_id = data.workspace_id || $ME.workspace.id;
                    this.account_id = data.account_id;
                    this.created_at = data.created_at;
                    this.external_ids = data.external_ids;
                    this.updated_at = data.updated_at;
                    this.due_date = data.due_date;
                    this.fully_paid_at = data.fully_paid_at;
                    this.number = data.number;
                    this.status = data.status;
                    this.sent = data.sent;
                    this.void = data.void;
                    this.currency_code = data.currency_code;
                    this.currency_rate = data.currency_rate;
                    this.amount = parseFloat(data.amount);
                    this.balance = parseFloat(data.balance);
                    this.line_items = data.line_items;
                    if (this.due_date && this.balance) {
                        this.progress = $utls.progress(this.due_date, this.created_at);
                    }
                };
                InvoiceModel.prototype.getEtag = function () {
                    var self = this, etagDefer = $q.defer();
                    $ay_requester.GET(self.location).then(function (results) {
                        if (!results.error) {
                            self.etag = results.data.etag;
                            results.etag = self.etag;
                        }
                        etagDefer.resolve(results);
                    });
                    return etagDefer.promise;
                };
                InvoiceModel.prototype.delete = function () {
                    var self = this, deleteDefer = $q.defer();
                    self.getEtag().then(function (results) {
                        if (!results.error) {
                            $ay_requester.DELETE(self.location, {}).then(deleteDefer.resolve);
                        } else {
                            deleteDefer.resolve(results);
                        }
                    });
                    return deleteDefer.promise;
                };
                function InvoicesFactory() {
                }
                InvoicesFactory.prototype.location = LOCATION;
                InvoicesFactory.prototype.media_type = mediaType;
                InvoicesFactory.prototype._newModel = function (data) {
                    var self = this;
                    return new InvoiceModel(data, self.schema);
                };
                InvoicesFactory.prototype._buildOptions = function (params) {
                    var options = {
                        f: [{
                                field: '/workspace_id',
                                op: 'EQ',
                                value: $ME.workspace.id
                            }],
                        s: []
                    };
                    if (params.hasOwnProperty('id')) {
                        options.f.push({
                            field: '/id',
                            op: 'EQ',
                            value: params.id
                        });
                    }
                    if (params.hasOwnProperty('accountId')) {
                        options.f.push({
                            field: '/account_id',
                            op: 'EQ',
                            value: params.accountId
                        });
                    }
                    if (params.sorts) {
                        options.s = angular.copy(params.sorts);
                    } else {
                        options.s.push({
                            field: '/created_at',
                            descending: true
                        });
                    }
                    return options;
                };
                InvoicesFactory.prototype.getSchema = function () {
                    var s = this, schemaDefer = $q.defer();
                    function setEntitySchema(results) {
                        if (!results.error) {
                            s.schema = results.data;
                            schemaDefer.resolve(s.schema);
                        } else {
                            schemaDefer.resolve(results);
                        }
                    }
                    if (s.schema) {
                        $timeout(function () {
                            schemaDefer.resolve(s.schema);
                        });
                    } else {
                        $ay_requester.GET('schema/' + LOCATION).then(setEntitySchema);
                    }
                    return schemaDefer.promise;
                };
                InvoicesFactory.prototype.get = function (params) {
                    var drainPromise, self = this, getDefer = $q.defer(), location = LOCATION, models = [];
                    function setModels(results) {
                        if (results.error) {
                            getDefer.resolve(results);
                        } else {
                            if (!results.data.hasOwnProperty('items')) {
                                results.data = self._newModel(results.data);
                                getDefer.resolve(results);
                            } else {
                                models = models.concat(results.data.items.map.apply(results.data.items, [
                                    self._newModel,
                                    self
                                ]));
                                results.data = models;
                                if (!params.drain) {
                                    getDefer.resolve(results);
                                } else {
                                    if (!results.cursor) {
                                        getDefer.resolve(results);
                                    } else {
                                        params.cursor = results.cursor;
                                        drainPromise = $ay_requester.GET(location, params);
                                        drainPromise.then(setModels);
                                    }
                                }
                            }
                        }
                    }
                    self.getSchema().then(function (results) {
                        params = params || {};
                        if (params.id) {
                            location += '/' + params.id;
                        } else {
                            params.options = self._buildOptions(params);
                            params.range = 50;
                        }
                        $ay_requester.GET(location, params).then(setModels);
                    });
                    return getDefer.promise;
                };
                return new InvoicesFactory();
            }
        ]
    };
});