define('modules/rest/services/schema-validator', [], function () {
    'use strict';
    return {
        component: 'service',
        name: '$schemaValidator',
        fn: [
            'errorDetection',
            function schema_validator_service(errorDetection) {
                function Validator() {
                    var self = this;
                    return function run_validation(_data, schema) {
                        self.result = {
                            data: {},
                            error: false,
                            error_trace: []
                        };
                        self.result.data = self.check(_data, schema, 'entity-root');
                        if (self.result.error_trace.length) {
                            self.result.error = errorDetection(1010);
                        }
                        return self.result;
                    };
                }
                Validator.prototype.error = function record_error(data, schema, type, prop_name, _isRequired) {
                    this.result.error_trace.push({
                        data: data,
                        schema: schema,
                        type: type,
                        prop_name: prop_name
                    });
                };
                Validator.prototype.check = function check(_data, schema, prop_name, _isRequired) {
                    var data, self = this;
                    function getCheck(type) {
                        if (self['check_' + type]) {
                            var schemaCheck = angular.copy(schema);
                            schemaCheck.type = type;
                            return self['check_' + type](_data, schemaCheck, prop_name, _isRequired);
                        }
                    }
                    function checkMultiTypes() {
                        for (var i = 0, ii = schema.type.length; i < ii; i++) {
                            data = getCheck(schema.type[i]);
                            if (data) {
                                self.result.error_trace = [];
                                break;
                            }
                        }
                    }
                    if (Array.isArray(schema.type)) {
                        checkMultiTypes();
                    } else {
                        data = getCheck(schema.type);
                    }
                    return data;
                };
                Validator.prototype.check_any = function check_any(_data, schema, prop_name, _isRequired) {
                    var data;
                    if (_data === null || _data === undefined) {
                        this.error(_data, schema, 'TYPE-any', prop_name);
                    } else {
                        data = _data;
                    }
                    return data;
                };
                Validator.prototype.check_number = function check_number(_data, schema, prop_name, _isRequired) {
                    var data;
                    if (!isNaN(parseFloat(_data))) {
                        if (!angular.isString(_data) || angular.isString(_data) && _data.indexOf(':') === -1) {
                            data = parseFloat(_data);
                        } else {
                            this.error(_data, schema, 'TYPE-number', prop_name);
                        }
                    }
                    if (data === undefined) {
                        this.error(_data, schema, 'TYPE-number', prop_name);
                    } else {
                        if (schema.hasOwnProperty('minimum')) {
                            if (schema.hasOwnProperty('exclusiveMinimum') && schema.exclusiveMinimum && data <= schema.minimum) {
                                this.error(_data, schema, 'number-min-ex', prop_name);
                            } else if (data < schema.minimum) {
                                this.error(_data, schema, 'number-min', prop_name);
                            }
                        }
                        if (schema.hasOwnProperty('maximum')) {
                            if (schema.hasOwnProperty('exclusiveMaximum') && schema.exclusiveMaximum && data >= schema.maximum) {
                                this.error(_data, schema, 'number-max-ex', prop_name);
                            } else if (data > schema.maximum) {
                                this.error(_data, schema, 'number-max', prop_name);
                            }
                        }
                        if (schema.hasOwnProperty('multipleOf') && schema.multipleOf > 0 && schema.multipleOf % data !== 0) {
                            this.error(_data, schema, 'number-mod', prop_name);
                        }
                    }
                    return data;
                };
                Validator.prototype.check_integer = function check_integer(_data, schema, prop_name, _isRequired) {
                    return this.check_number(_data, schema, prop_name);
                };
                Validator.prototype.check_boolean = function check_boolean(_data, schema, prop_name, _isRequired) {
                    var data;
                    if (_data === true || _data === false) {
                        data = _data;
                    } else {
                        this.error(_data, schema, 'TYPE-boolean', prop_name);
                    }
                    return data;
                };
                Validator.prototype.check_oneOf = function check_one_of(_data, schema, prop_name, _isRequired) {
                    var i, data, checked_data, one_of_valalidator;
                    for (i = 0; i < schema.options.length; i++) {
                        one_of_valalidator = new Validator();
                        checked_data = one_of_valalidator(_data, schema.options[i]);
                        if (!checked_data.error) {
                            data = checked_data.data;
                            break;
                        }
                    }
                    if (data === undefined) {
                        this.error(_data, schema, 'one-of-match', prop_name);
                    }
                    return data;
                };
                Validator.prototype.check_object = function check_object(_data, schema, prop_name, _isRequired) {
                    var i, data;
                    if (_data === null || _data === undefined) {
                        this.error(_data, schema, 'TYPE-object', prop_name);
                    } else {
                        var checked_prop, required_index, schema_keys, obj_keys = Object.keys(_data), isRequired = false;
                        if (!obj_keys.length && (schema.hasOwnProperty('default') && Object.keys(schema.default).length)) {
                            this.error(_data, schema, 'object-keys', prop_name);
                        } else {
                            data = {};
                            if (schema.hasOwnProperty('required') && schema.required.length) {
                                for (i = 0; i < schema.required.length; i++) {
                                    required_index = obj_keys.indexOf(schema.required[i]);
                                    if (required_index === -1 || _data[schema.required[i]] === null || _data[schema.required[i]] === undefined) {
                                        this.error(_data, schema, 'required-field-missing', schema.required[i]);
                                    }
                                }
                            }
                            if (schema.hasOwnProperty('properties')) {
                                var propSchema;
                                schema_keys = Object.keys(schema.properties);
                                for (i = 0; i < schema_keys.length; i++) {
                                    propSchema = schema.properties[schema_keys[i]];
                                    checked_prop = undefined;
                                    if (propSchema.hasOwnProperty('default') && (_data[schema_keys[i]] === null || _data[schema_keys[i]] === undefined)) {
                                        data[schema_keys[i]] = propSchema.default;
                                    }
                                    if (_data.hasOwnProperty(schema_keys[i]) && _data[schema_keys[i]] !== null && _data[schema_keys[i]] !== undefined) {
                                        if (schema.properties[schema_keys[i]].type === 'object' && !Object.keys(_data[schema_keys[i]]).length && schema.required.indexOf(schema_keys[i]) !== -1) {
                                            this.error(_data[schema_keys[i]], schema.properties[schema_keys[i]], 'required-field-empty', schema_keys[i]);
                                        } else if (schema.properties[schema_keys[i]].type === 'object' && Object.keys(_data[schema_keys[i]]).length || schema.properties[schema_keys[i]].type !== 'object') {
                                            isRequired = schema.required.indexOf(schema_keys[i]) !== -1;
                                            if (schema.properties[schema_keys[i]].hasOwnProperty('oneOf')) {
                                                checked_prop = this.check(_data[schema_keys[i]], {
                                                    options: schema.properties[schema_keys[i]].oneOf,
                                                    type: 'oneOf'
                                                }, schema_keys[i], isRequired);
                                            } else {
                                                checked_prop = this.check(_data[schema_keys[i]], schema.properties[schema_keys[i]], schema_keys[i], isRequired);
                                            }
                                        }
                                        if (checked_prop !== undefined && checked_prop !== null) {
                                            data[schema_keys[i]] = checked_prop;
                                        }
                                    }
                                }
                            }
                            if (schema.hasOwnProperty('additionalProperties') && schema.additionalProperties && schema.additionalProperties.hasOwnProperty('type')) {
                                var prop_type;
                                schema_keys = schema.additionalProperties.type;
                                for (i = 0; i < obj_keys.length; i++) {
                                    if (_data[obj_keys[i]] === null || _data[obj_keys[i]] === undefined) {
                                        prop_type = 'null';
                                    } else {
                                        if (Array.isArray(_data[obj_keys[i]])) {
                                            prop_type = 'array';
                                        } else {
                                            prop_type = typeof _data[obj_keys[i]];
                                        }
                                    }
                                    if (Array.isArray(schema_keys)) {
                                        if (schema_keys.indexOf(prop_type) === -1) {
                                            if (prop_name !== 'custom_properties' || prop_name === 'custom_properties' && prop_type !== 'null') {
                                                this.error(_data[obj_keys[i]], schema_keys, 'TYPE-object-additional-properties-1', prop_name);
                                            }
                                        } else {
                                            if (prop_name === 'custom_properties' && prop_type === 'array') {
                                                if (_data[obj_keys[i]].length) {
                                                    data[obj_keys[i]] = _data[obj_keys[i]];
                                                }
                                            } else {
                                                data[obj_keys[i]] = _data[obj_keys[i]];
                                            }
                                        }
                                    } else {
                                        if (typeof _data[obj_keys[i]] !== schema_keys) {
                                            this.error(_data[obj_keys[i]], schema_keys, 'TYPE-object-additional-properties-2', prop_name);
                                        } else {
                                            data[obj_keys[i]] = _data[obj_keys[i]];
                                        }
                                    }
                                }
                            }
                        }
                    }
                    return data;
                };
                Validator.prototype.check_array = function check_array(_data, schema, prop_name, _isRequired) {
                    var i, data, s = this;
                    if (!Array.isArray(_data)) {
                        this.error(_data, schema, 'TYPE-array', prop_name);
                    } else {
                        if (!schema.hasOwnProperty('items')) {
                            this.error(_data, schema, 'schema-array-items', prop_name);
                        } else {
                            var checked_data, found_index, item_schema = schema.items;
                            item_schema = item_schema.hasOwnProperty('oneOf') ? {
                                options: schema.items.oneOf,
                                type: 'oneOf'
                            } : item_schema;
                            data = [];
                            for (i = 0; i < _data.length; i++) {
                                if (_data[i] === undefined || _data[i] === null) {
                                    this.error(_data, schema, 'array-item', prop_name);
                                } else {
                                    checked_data = this.check(_data[i], item_schema, prop_name);
                                    if (checked_data !== undefined) {
                                        if (!schema.hasOwnProperty('uniqueItems') || !schema.uniqueItems) {
                                            data.push(checked_data);
                                        } else {
                                            found_index = data.indexOf(checked_data);
                                            if (found_index === -1) {
                                                data.push(checked_data);
                                            }
                                        }
                                    }
                                }
                            }
                            if (schema.hasOwnProperty('minItems') && data.length < schema.minItems) {
                                this.error(_data, schema, 'array-length-min', prop_name);
                            }
                            if (schema.hasOwnProperty('maxItems') && data.length > schema.maxItems) {
                                this.error(_data, schema, 'array-length-max', prop_name);
                            }
                        }
                    }
                    return data;
                };
                Validator.prototype['check_date-time'] = function check_datetime(_data, schema, prop_name, _isRequired) {
                    var data;
                    if (!_data || _data.constructor !== Date && !Date.parse(_data)) {
                        this.error(_data, schema, 'TYPE-date-time', prop_name);
                    } else {
                        data = _data;
                    }
                    return data;
                };
                Validator.prototype.check_email = function check_email(_data, schema, prop_name, _isRequired) {
                    var data, email_re = /\S+@\S+\.\S+/;
                    if (!_data || !email_re.test(_data)) {
                        if (_data && _data.length || _isRequired) {
                            this.error(_data, schema, 'TYPE-email', prop_name);
                        }
                    } else {
                        data = _data;
                    }
                    return data;
                };
                Validator.prototype.check_hostname = function check_hostname(_data, schema, prop_name, _isRequired) {
                    var data, domainRe = new RegExp(/^[a-z\d]([a-z\d\-]{0,61}[a-z\d])?(\.[a-z\d]([a-z\d\-]{0,61}[a-z\d])?)*$/i), charRe = new RegExp(/[^\x20-\x7E]+/g);
                    if (!_data || !domainRe.test(_data) || charRe.test(_data)) {
                        if (_data && _data.length || _isRequired) {
                            this.error(_data, schema, 'TYPE-hostname', prop_name);
                        }
                    } else {
                        data = _data;
                    }
                    return data;
                };
                Validator.prototype.check_uri = function check_hostname(_data, schema, prop_name, _isRequired) {
                    var data, uriRe = new RegExp(/\w+:(\/?\/?)[^\s]+/gi);
                    if (!_data || !uriRe.test(_data)) {
                        if (_data && _data.length || _isRequired) {
                            this.error(_data, schema, 'TYPE-hostname', prop_name);
                        }
                    } else {
                        data = _data;
                    }
                    return data;
                };
                Validator.prototype.check_string = function check_string(_data, schema, prop_name, _isRequired) {
                    var data, hasError = false;
                    if ((_data === undefined || _data === null) && _isRequired) {
                        this.error(_data, schema, 'TYPE-string', prop_name);
                    } else {
                        if (schema.hasOwnProperty('format')) {
                            if (!this['check_' + schema.format]) {
                                this.error(_data, schema, 'TYPE-format', prop_name);
                            } else {
                                data = this['check_' + schema.format](_data, schema, prop_name);
                            }
                        } else {
                            if (schema.hasOwnProperty('minLength') && _data.length < schema.minLength) {
                                hasError = true;
                                this.error(_data, schema, 'string-length-min', prop_name);
                            }
                            if (schema.hasOwnProperty('maxLength') && _data.length > schema.maxLength) {
                                hasError = true;
                                this.error(_data, schema, 'string-length-max', prop_name);
                            }
                            if (schema.hasOwnProperty('pattern')) {
                                var test = new RegExp(schema.pattern).test(_data);
                                if (!test) {
                                    hasError = true;
                                    this.error(_data, schema, 'string-regex', prop_name);
                                }
                            }
                            if (schema.hasOwnProperty('enum') && schema.enum.indexOf(_data) === -1) {
                                if (!_data.length) {
                                    _data = undefined;
                                } else if (data !== 'external_ids') {
                                    if (!/other/.test(_data) && !/email\./.test(_data) && !/alias\./.test(_data)) {
                                        hasError = true;
                                        this.error(_data, schema, 'TYPE-enum', prop_name);
                                    }
                                }
                            }
                            if (!hasError && _data && _data.length) {
                                data = _data;
                            } else if (!hasError && _isRequired) {
                                data = '';
                            }
                        }
                    }
                    return data;
                };
                function Schema_Validator() {
                }
                Schema_Validator.prototype.validate = function validate_data(data, schema) {
                    var validator = new Validator();
                    return validator(JSON.parse(JSON.stringify(data)), schema);
                };
                return new Schema_Validator();
            }
        ]
    };
});