define('modules/form-inputs/directives/editor-code', [], function () {
    return {
        component: 'directive',
        name: 'ayCodeEditor',
        fn: [
            '$sce',
            '$timeout',
            '$ME',
            '$q',
            '$log',
            function ($sce, $timeout, $ME, $q, $log) {
                return {
                    restrict: 'A',
                    priority: 500,
                    require: '?ngModel',
                    scope: {
                        options: '=editorOptions',
                        isFocused: '@'
                    },
                    templateUrl: 'form-inputs/views/editor-code.html',
                    link: function ($scope, elem, attrs, ngModel) {
                        $scope.modes = {
                            htmlJinja: 'ace/mode/html-jinja',
                            jinja: 'ace/mode/jinja',
                            python: 'ace/mode/python'
                        };
                        $scope.editor = {
                            styles: {
                                height: '440px',
                                width: '100%',
                                margin: '0'
                            }
                        };
                        $scope.menu = {
                            placeholders: {
                                label: 'Placeholders',
                                items: [{
                                        label: 'Account',
                                        items: [
                                            {
                                                value: '{{account.assignee_name}}',
                                                label: 'Assignee'
                                            },
                                            {
                                                value: '{{account.created}}',
                                                label: 'Created'
                                            },
                                            {
                                                value: '{{account.crm_owner_email}}',
                                                label: 'CRM owner email'
                                            },
                                            {
                                                value: '{{account.crm_owner_full_name}}',
                                                label: 'CRM owner full name'
                                            },
                                            {
                                                value: '{{account.currency_code}}',
                                                label: 'Currency code'
                                            },
                                            {
                                                value: '{{account.current_balance}}',
                                                label: 'Current balance'
                                            },
                                            {
                                                value: '{{account.customer_since}}',
                                                label: 'Customer since'
                                            },
                                            {
                                                value: '{{account.description}}',
                                                label: 'Description'
                                            },
                                            {
                                                value: '{{account.health}}',
                                                label: 'Health'
                                            },
                                            {
                                                value: '{{account.id}}',
                                                label: 'ID'
                                            },
                                            {
                                                value: '{{account.insights}}',
                                                label: 'Insights'
                                            },
                                            {
                                                value: '{{account.last_active}}',
                                                label: 'Last active'
                                            },
                                            {
                                                value: '{{account.last_engaged}}',
                                                label: 'Last engaged'
                                            },
                                            {
                                                value: '{{account.stage}}',
                                                label: 'Lifecycle stage'
                                            },
                                            {
                                                value: '{{account.value}}',
                                                label: $ME.workspace.account_value_metric.toUpperCase()
                                            },
                                            {
                                                value: '{{account.name}}',
                                                label: 'Name'
                                            },
                                            {
                                                value: '{{account.next_renewal}}',
                                                label: 'Next renewal'
                                            },
                                            {
                                                value: '{{account.potential_value}}',
                                                label: 'Potential ' + $ME.workspace.account_value_metric.toUpperCase()
                                            },
                                            {
                                                value: '{{account.priority}}',
                                                label: 'Priority'
                                            },
                                            {
                                                value: '{{account.risk}}',
                                                label: 'Risk'
                                            },
                                            {
                                                value: '{{account.region}}',
                                                label: 'Region'
                                            },
                                            {
                                                value: '{{account.customer_status}}',
                                                label: 'Status'
                                            },
                                            {
                                                value: '{{account.active_subscription_name}}',
                                                label: 'Subscription'
                                            },
                                            {
                                                value: '{{account.tags}}',
                                                label: 'Tags'
                                            },
                                            {
                                                value: '{{account.tier}}',
                                                label: 'Tier'
                                            },
                                            {
                                                value: '{{account.type}}',
                                                label: 'Type'
                                            },
                                            {
                                                value: '{{account.last_invoice_amount}}',
                                                label: 'Last Invoice Amount'
                                            },
                                            {
                                                value: '{{account.last_invoice_date}}',
                                                label: 'Last Invoice Date'
                                            },
                                            {
                                                value: '{{account.last_invoice_due_date}}',
                                                label: 'Last Invoice Due Date'
                                            },
                                            {
                                                value: '{{account.last_invoice_number}}',
                                                label: 'Last Invoice Number'
                                            },
                                            {
                                                value: '{{account.last_payment_amount}}',
                                                label: 'Last Payment Amount'
                                            },
                                            {
                                                value: '{{account.last_payment_date}}',
                                                label: 'Last Payment Date'
                                            },
                                            {
                                                value: '{{account.last_payment_invoice_number}}',
                                                label: 'Last Payment Invoice Number'
                                            },
                                            {
                                                value: '{{account.last_payment_number}}',
                                                label: 'Last Payment Number'
                                            }
                                        ]
                                    }],
                                onSelect: function (value) {
                                    $scope.editor.instance.insertSnippet(value);
                                }
                            }
                        };
                        $scope.menu.placeholders.items[0].items.sort_by('label');
                        function createEditor() {
                            ace.require('ace/ext/language_tools');
                            $scope.editor.instance = ace.edit('codeEditor-' + $scope.$id);
                            $scope.editor.instance.setTheme('ace/theme/textmate');
                            $scope.options.changeMode($scope.options.mode);
                            $scope.editor.instance.setOptions({
                                enableBasicAutocompletion: true,
                                enableSnippets: true,
                                enableLiveAutocompletion: false,
                                showPrintMargin: false,
                                highlightActiveLine: false
                            });
                            if (ngModel) {
                                ngModel.$formatters.push(function (value) {
                                    if (angular.isUndefined(value) || value === null) {
                                        return '';
                                    }
                                    return value;
                                });
                                ngModel.$render = function () {
                                    $scope.editor.instance.setValue(ngModel.$viewValue);
                                };
                            }
                            $scope.editor.instance.on('change', function (change, instance) {
                                var val = instance.getValue();
                                if (ngModel && val !== ngModel.$viewValue && !$scope.$$phase && !$scope.$root.$$phase) {
                                    ngModel.$setViewValue(val);
                                }
                                if ($scope.options.onChange && angular.isFunction($scope.options.onChange)) {
                                    $scope.options.onChange(val);
                                }
                            });
                            $scope.editor.instance.setValue(ngModel.$viewValue);
                            $scope.editor.instance.selection.clearSelection();
                            function setFocus() {
                                var session = $scope.editor.instance.getSession(), count = session.getLength();
                                $scope.editor.instance.focus();
                                $scope.editor.instance.gotoLine(count, session.getLine(count - 1).length);
                                $scope.editor.instance.setReadOnly(false);
                            }
                            if ($scope.isFocused) {
                                $timeout(setFocus, 1);
                            }
                        }
                        $scope.options.changeMode = function (mode) {
                            var mode = mode && $scope.modes.hasOwnProperty(mode) ? $scope.modes[mode] : $scope.modes.htmlJinja;
                            $scope.editor.instance.session.setMode(mode);
                        };
                        $scope.options.reprocess = function () {
                            ngModel.$setViewValue($scope.editor.instance.getValue());
                            return ngModel.$viewValue;
                        };
                        $scope.options.focus = function () {
                            $scope.editor.instance.focus();
                            $scope.editor.instance.selection.clearSelection();
                        };
                        function addCustomProps(item) {
                            if (item.enabled && !item.hidden) {
                                $scope.menu.placeholders.items[0].items.push({
                                    value: '{{account.custom_properties[\'' + item.id + '\']}}',
                                    label: item.display_name
                                });
                            }
                        }
                        if ($ME.is_logged_in) {
                            if ($ME.workspace.account_custom_properties.length) {
                                $ME.workspace.account_custom_properties.map(addCustomProps);
                                $scope.menu.placeholders.items[0].items.sort_by('label');
                            }
                            $timeout(createEditor);
                        }
                    }
                };
            }
        ]
    };
});