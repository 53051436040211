define('modules/form-inputs/directives/input-array', [], function () {
    return {
        component: 'directive',
        name: 'ayFormInputArray',
        fn: [
            '$timeout',
            '$log',
            function ($timeout, $log) {
                return {
                    restrict: 'E',
                    templateUrl: 'form-inputs/views/input-array.html',
                    require: '?ngModel',
                    scope: {
                        label: '@',
                        isRequired: '@',
                        validationMsg: '@',
                        helpText: '@',
                        idField: '@',
                        options: '=?',
                        isDisabled: '=?',
                        tabIndex: '@?',
                        appendTo: '@?'
                    },
                    controller: function () {
                        var self = this;
                        self.input = { value: '' };
                        self.value = [];
                        self.list = [];
                    },
                    controllerAs: 'formInput',
                    bindToController: true,
                    link: function (scope, elem, attrs, ngModel) {
                        var elemAutocomplete, elemAutocompleteMenu, appendTo = scope.formInput.appendTo ? scope.formInput.appendTo : '.modal-content', appendEl = angular.element(appendTo);
                        if (!appendEl.length) {
                            appendTo = '.app-content';
                        }
                        scope.formInput.id = 'input-array-' + scope.$id;
                        scope.formInput.ctrlId = 'form-input-ctrl-' + scope.formInput.id;
                        scope.formInput._autocompleteOptions = {
                            appendTo: appendTo,
                            position: {
                                my: 'left top',
                                at: 'left bottom',
                                collision: 'flipfit',
                                within: appendTo
                            },
                            minLength: 0,
                            autoFocus: false,
                            select: function (event, ui) {
                                if (ui && ui.item) {
                                    scope.formInput._addItem(ui.item);
                                }
                                return false;
                            },
                            open: function (event) {
                                var parentHeight, parentBox, auto = angular.element(event.target).autocomplete().data('uiAutocomplete'), autoBox = auto.element[0].getBoundingClientRect(), menu = angular.element(auto.menu.element), menuBox = menu[0].getBoundingClientRect(), parent = angular.element(menu[0].parentElement);
                                if (appendTo === '.modal-content') {
                                    parent = parent.find('.modal-content-main');
                                }
                                parentHeight = parent[0].offsetHeight;
                                parentBox = parent[0].getBoundingClientRect();
                                if (menuBox.top + menuBox.height > parentHeight) {
                                    menu.css('overflow-y', 'scroll');
                                    menu.css('overflow-x', 'hidden');
                                    menu.css('max-height', autoBox.top - parentBox.top - 20);
                                }
                            }
                        };
                        scope.formInput._filterList = function () {
                            var self = this;
                            function filterSelected(item) {
                                return scope.formInput.value._index_of_by_prop(scope.formInput.idField, item[scope.formInput.idField]) === -1;
                            }
                            function removeItems() {
                                var index;
                                for (var i = scope.formInput.value.length - 1; i > -1; i--) {
                                    index = scope.formInput.options.list._index_of_by_prop(scope.formInput.idField, scope.formInput.value[i][scope.formInput.idField]);
                                    if (index === -1) {
                                        scope.formInput.value.splice(i, 1);
                                    }
                                }
                            }
                            if (scope.formInput.options.list && scope.formInput.options.list.length) {
                                scope.formInput.list = scope.formInput.options.list.filter(filterSelected);
                                if (scope.formInput.options.onlyList === true && scope.formInput.value.length) {
                                    if (!scope.formInput.options.list.length) {
                                        scope.formInput.value = [];
                                    } else {
                                        removeItems();
                                    }
                                }
                                if (scope.formInput.options.list) {
                                    elemAutocomplete.autocomplete('option', 'source', scope.formInput.list);
                                }
                            }
                        };
                        function cyclePhase() {
                            if (!scope.$$phase && (scope.$root && !scope.$root.$$phase)) {
                                scope.$apply();
                            }
                        }
                        function notifyChange() {
                            if (attrs.onChange) {
                                scope.$parent.$eval(attrs.onChange);
                            }
                            cyclePhase();
                        }
                        function openMenu() {
                            elemAutocomplete.focus();
                            if (scope.formInput.options.list && scope.formInput.options.list.length) {
                                scope.formInput.openMenu();
                            }
                        }
                        function checkValidation() {
                            if (attrs.ayValidate) {
                                var validator;
                                if (attrs.runAlias) {
                                    validator = scope.$eval(attrs.runAlias);
                                    if (!validator) {
                                        validator = scope.$parent.$eval(attrs.runAlias);
                                    }
                                } else {
                                    validator = scope.ay_validate ? scope.ay_validate : scope.$parent.ay_validate ? scope.$parent.ay_validate : null;
                                }
                                if (!validator) {
                                    $log.error({ error: { message: 'multi picklist has validation but no validator' } });
                                } else {
                                    if (validator.run) {
                                        validator.run(elem).then(notifyChange);
                                    } else {
                                        validator.ay_validate.run(elem).then(notifyChange);
                                    }
                                }
                            }
                        }
                        scope.formInput._addItem = function (item) {
                            checkValidation();
                            if (scope.formInput.options.hasOwnProperty('itemId') && !item.hasOwnProperty(scope.formInput.options.itemId)) {
                                item[scope.formInput.options.itemId] = item.value;
                            }
                            scope.formInput.value.push(item);
                            scope.formInput.input.value = '';
                            ngModel.$setViewValue(scope.formInput.value);
                            scope.formInput._filterList();
                            elemAutocomplete.val('');
                            $timeout(openMenu);
                            notifyChange();
                        };
                        scope.formInput._update = function (item) {
                            var isActive = false;
                            if (elemAutocomplete) {
                                var instance = elemAutocomplete.autocomplete('instance');
                                if (instance && instance.menu) {
                                    isActive = instance.menu.active;
                                }
                            }
                            if (item.value && !isActive) {
                                scope.formInput._addItem(item);
                            }
                        };
                        scope.formInput.removeItem = function (index) {
                            scope.formInput.value.splice(index, 1);
                            ngModel.$setViewValue(scope.formInput.value);
                            checkValidation();
                            scope.formInput._filterList();
                        };
                        scope.formInput.openMenu = function () {
                            if (scope.formInput.options.list && scope.formInput.options.list.length) {
                                elemAutocomplete.autocomplete('search', '');
                            }
                        };
                        scope.formInput.change = function (event) {
                            if (event.type === 'blur' || event.keyCode === 13 || event.keyCode === 9) {
                                if (scope.formInput.value._index_of_by_prop(scope.formInput.idField, scope.formInput.input.value) === -1) {
                                    var index, item = scope.formInput.options && scope.formInput.options.itemDef ? angular.copy(scope.formInput.options.itemDef) : {};
                                    if (scope.formInput.list) {
                                        index = scope.formInput.list._index_of_by_prop(scope.formInput.idField, scope.formInput.input.value);
                                        if (index !== -1) {
                                            item = angular.copy(scope.formInput.list[index]);
                                        } else if (scope.formInput.options.onlyList === true && index === -1) {
                                            item = null;
                                        }
                                    }
                                    if (item) {
                                        item.value = scope.formInput.input.value;
                                        item[scope.formInput.idField] = scope.formInput.input.value;
                                        scope.formInput._update(item);
                                    }
                                }
                            }
                        };
                        ngModel.$render = function () {
                            ngModel.$modelValue = ngModel.$modelValue || [];
                            scope.formInput.value = ngModel.$modelValue;
                        };
                        ngModel.$viewChangeListeners.push(function () {
                            if (attrs.onChange) {
                                scope.$parent.$eval(attrs.onChange);
                            }
                        });
                        function init() {
                            $timeout(function () {
                                elemAutocomplete = angular.element('#' + scope.formInput.id);
                                if (scope.formInput.options.list) {
                                    elemAutocomplete.autocomplete(scope.formInput._autocompleteOptions);
                                    scope.$watchCollection('formInput.options.list', scope.formInput._filterList);
                                    scope.formInput.options.updateList = scope.formInput._filterList;
                                    elemAutocomplete.autocomplete().data('uiAutocomplete')._resizeMenu = function () {
                                        elemAutocompleteMenu = angular.element(this.menu.element);
                                        elemAutocompleteMenu.css('width', elemAutocomplete.css('width'));
                                        elemAutocompleteMenu.css('position', 'absolute');
                                    };
                                }
                            });
                        }
                        scope.$watch('formInput.options.id', init);
                    }
                };
            }
        ]
    };
});