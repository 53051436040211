'use strict';
define('modules/helpers/services/log', [], function () {
    return {
        component: 'service',
        name: '$ayLogging',
        fn: [
            '$injector',
            function ($injector) {
                var logging = {
                    error: function log_error() {
                        var details, record = true;
                        try {
                            if (arguments[0] && arguments[0].errorDetails) {
                                if (angular.isObject(arguments[0].errorDetails)) {
                                    details = JSON.parse(arguments[0].errorDetails);
                                } else {
                                    details = { message: arguments[0].errorDetails };
                                }
                                record = details && details.hasOwnProperty('loggable') ? details.loggable : true;
                            }
                            var error_details, error_msg = Error(String(arguments[0].message));
                            error_msg.stack = arguments[0].stack;
                            if (arguments[0].errorDetails) {
                                try {
                                    error_details = JSON.parse(arguments[0].errorDetails);
                                } catch (e) {
                                    error_details = arguments[0].errorDetails;
                                }
                            }
                        } catch (ex) {
                            error_msg = 'unexpected exception while hanlding error';
                            error_details = {
                                exception: ex,
                                args: arguments
                            };
                        }
                        if (record && window.Raven) {
                            Raven.captureException(error_msg, { extra: error_details });
                            return Raven.lastEventId();
                        }
                    }
                };
                return logging;
            }
        ]
    };
});