define('modules/rest/factories/formulas', [], function () {
    'use strict';
    return {
        component: 'service',
        name: 'restFactoryFormulas',
        fn: [
            '$log',
            '$ay_requester',
            '$ME',
            function view_factory($log, $ay_requester, $ME) {
                var LOCATION = 'formula';
                function FormulasFactory() {
                }
                FormulasFactory.prototype.location = LOCATION;
                FormulasFactory.prototype.testTypes = {
                    account: 'account_formula_test',
                    participant: 'participant_formula_test',
                    insight: 'insight_formula_test'
                };
                FormulasFactory.prototype.testFormula = function (type, data) {
                    var self = this;
                    return $ay_requester.POST(self.location, {
                        retrieveEntity: false,
                        data: {
                            action: self.testTypes[type],
                            workspace_id: $ME.workspace.id,
                            evaluator: data.evaluator,
                            formula: data.formula,
                            formula_parameters: data.parameters || [],
                            entity_id: data.entityId,
                            formula_result_type: data.resultType
                        }
                    });
                };
                return new FormulasFactory();
            }
        ]
    };
});