define('modules/enviroment/directives/content', [], function () {
    return {
        component: 'directive',
        name: 'ayEnviromentContent',
        fn: [function () {
                return {
                    restrict: 'A',
                    scope: {},
                    controller: [
                        '$rootScope',
                        function ($rootScope) {
                            var self = this;
                            self.template = '';
                            $rootScope.$on('platform-content-change', function (ev, data) {
                                self.template = data.viewUrl;
                            });
                        }
                    ],
                    controllerAs: 'content',
                    bindToController: true,
                    replace: true,
                    template: '<div class="enviroment-wrapper" ng-include="content.template" ng-show="!platform.loading"></div>',
                    link: function (scope, elem, attrs) {
                    }
                };
            }]
    };
});