define('modules/authentication/services/authorize-rule', [], function () {
    'use strict';
    return {
        component: 'service',
        name: '$ayAuthorizeRule',
        fn: [
            '$log',
            '$route',
            '$location',
            '$q',
            '$timeout',
            '$ME',
            'srvcConfig',
            '$ayEnviromentPackages',
            '$ay_requester',
            '$utls',
            function ($log, $route, $location, $q, $timeout, $ME, srvcConfig, $ayEnviromentPackages, $ay_requester, $utls) {
                var self = this;
                self.attachAppDef = function () {
                };
                self.checkUser = function (isAuthorizedDefer) {
                    var selectedAppName, checkDefer = $q.defer();
                    function resCheck() {
                        var isSuspended = !$ME.tenancy.suspension_date ? false : new Date() >= $ME.tenancy.suspension_date;
                        $ME.isSuspended = isSuspended;
                        checkDefer.resolve({
                            isAuthed: $ME.is_logged_in,
                            isSuspended: isSuspended
                        });
                    }
                    if ($route.current && $route.current.$$route && $route.current.$$route.originalPath) {
                        var re = /^\/csm(?:\/([^\/]+)?)?$/;
                        if (!re.test($route.current.$$route.originalPath)) {
                            selectedAppName = $route.current.$$route.originalPath.split('/')[2];
                        }
                    }
                    if ($ME.is_logged_in) {
                        $timeout(resCheck);
                    } else {
                        $ME.autoLogin().then(function () {
                            resCheck();
                        });
                    }
                    return checkDefer.promise;
                };
                self.auth = function () {
                    var isAuthorizedDefer = $q.defer();
                    if (srvcConfig.is_initialized()) {
                        self.checkUser().then(isAuthorizedDefer.resolve);
                    } else {
                        $ay_requester.CONFIG().then(function (results) {
                            if (!results.error) {
                                srvcConfig.init(results);
                            }
                            self.checkUser().then(isAuthorizedDefer.resolve);
                        });
                    }
                    return isAuthorizedDefer.promise;
                };
            }
        ]
    };
});