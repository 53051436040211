define('modules/rest/factories/notification-settings', [], function () {
    'use strict';
    return {
        component: 'service',
        name: 'modelNotificationSettings',
        fn: [
            'srvcRequester',
            '$log',
            function notification_settings_factory(srvcRequester, $log) {
                var factory = {
                    collection: 'notification_params',
                    category_types: {
                        'alert.assignment': {
                            name: 'Assignments',
                            description: 'Notify the assignee when an account, play or task is assigned to the user.'
                        },
                        'system.misconfiguration': {
                            name: 'System',
                            description: 'Notify the administrators when Amity detects a misconfiguration or system error.'
                        }
                    },
                    workspace_id: '',
                    schema: null
                };
                function notification_settings_model(data) {
                    this.etag = '';
                    this.by_category = [];
                    this.in_app_enabled = true;
                    this.email_enabled = false;
                    this.dispatch = function dispatch_model(results) {
                        results._callback({
                            updated: !results.error,
                            error: results.error,
                            etag: results.etag
                        });
                    };
                    function clean_cats(item) {
                        var t = angular.copy(item);
                        delete t.$$hashKey;
                        return t;
                    }
                    this.save = function save_model(params) {
                        var type = params.type ? params.type : 'update', data = {
                                by_category: this.by_category.map(clean_cats),
                                in_app_enabled: this.in_app_enabled,
                                email_enabled: this.email_enabled
                            };
                        if (!this.etag) {
                            srvcRequester.put(factory.collection, this.dispatch, {
                                type: type,
                                _callback: params._callback,
                                data: data,
                                id: factory.workspace_id,
                                upsert: true
                            });
                        } else {
                            srvcRequester.put(factory.collection, this.dispatch, {
                                type: type,
                                _callback: params._callback,
                                data: data,
                                id: factory.workspace_id,
                                etag: this.etag
                            });
                        }
                    };
                    this.set = function set_model(data) {
                        this.etag = data.etag ? data.etag !== '000000000000000000000000' ? data.etag : null : null;
                        this.by_category = data.by_category;
                        this.in_app_enabled = data.in_app_enabled;
                        this.email_enabled = data.email_enabled;
                    };
                    if (data) {
                        this.set(data);
                    }
                }
                factory.set = function set_notification_settings(results) {
                    var return_data = {
                        data: null,
                        call_info: results.call_info,
                        type: results.type,
                        cursor: results.cursor
                    };
                    if (!results.error) {
                        return_data.data = new notification_settings_model(results.data);
                    }
                    results._callback(return_data);
                };
                factory.get = function get_notification_settings(params) {
                    factory.workspace_id = factory.workspace_id || params.workspace_id;
                    srvcRequester.get('workspaces/' + factory.workspace_id + '/' + factory.collection, factory.set, params);
                };
                return factory;
            }
        ]
    };
});