define('modules/enviroment/services/config', ['sharedAppcues'], function (sharedAppcues) {
    'use strict';
    return {
        name: 'srvcConfig',
        component: 'service',
        fn: [
            'Phare',
            function ayConfig(Phare) {
                var self = this;
                self.pollerFq = 15000;
                self._data = {};
                self._options_initialized = {
                    ay_activity: {
                        initd: false,
                        reset: true
                    },
                    mixpanel: {
                        initd: false,
                        reset: true
                    },
                    google: {
                        initd: false,
                        reset: true
                    },
                    raven: {
                        initd: false,
                        reset: false
                    },
                    intercom: {
                        initd: false,
                        reset: true
                    },
                    appcues: { initd: false },
                    heap: {
                        initd: false,
                        reset: true
                    },
                    self: {
                        initd: false,
                        reset: true
                    }
                };
                self.is_initialized = function check_init(option) {
                    var check;
                    option = option || 'self';
                    if (self._options_initialized.hasOwnProperty(option)) {
                        check = self._options_initialized[option].initd;
                    }
                    return check;
                };
                self.reset = function reset_config() {
                    var i;
                    for (i in self._options_initialized) {
                        if (self._options_initialized[i].reset) {
                            self._options_initialized[i].initd = false;
                        }
                    }
                };
                self.init = function config_init(results) {
                    var phare_params = {};
                    self._data = results.data;
                    self.pollerFq = self._data.polling_period ? self._data.polling_period * 1000 : self.pollerFq;
                    if (self._data.ga_tracking_id && !self._options_initialized.google.initd) {
                        phare_params.google = self._data.ga_tracking_id;
                        self._options_initialized.google.initd = true;
                    }
                    if (self._data.mixpanel_token && !self._options_initialized.mixpanel.initd) {
                        phare_params.mixpanel = self._data.mixpanel_token;
                        self._options_initialized.mixpanel.initd = true;
                    }
                    if (self._data.activity_url && !self._options_initialized.ay_activity.initd) {
                        phare_params.amity = {
                            url: self._data.activity_url,
                            client_id: self._data.activity_client_id,
                            workspace_id: self._data.activity_workspace_id
                        };
                        self._options_initialized.ay_activity.initd = true;
                    }
                    Phare.init(phare_params);
                    self._options_initialized.self.initd = true;
                };
                self.set_user = function config_set_user(user) {
                    Phare.set_user(user);
                    self._user = user;
                    if (window.Intercom && self._data.intercom_app_id) {
                        window.Intercom('boot', {
                            app_id: self._data.intercom_app_id,
                            user_id: user.username,
                            name: user.full_name,
                            email: user.email
                        });
                        self._options_initialized.intercom.initd = true;
                    }
                    function appcuesLoaded() {
                        Appcues.identify(user.id, {
                            name: user.full_name,
                            email: user.email,
                            created_at: user.created_at,
                            ui_version: user.uiVersion
                        });
                    }
                    if (!self._options_initialized.appcues.initd) {
                        sharedAppcues.load(appcuesLoaded);
                    }
                };
                self.initLog = function () {
                    var ravenOptions = {};
                    if (window && window.Raven !== undefined && !self._options_initialized.raven.initd) {
                        ravenOptions = {
                            whitelistUrls: [/getamity\.com/],
                            ignoreUrls: [/code\.abtasty\.com/],
                            ignoreErrors: ['Cannot set property \'globalAnimation\' of undefined'],
                            release: self._data.release,
                            environment: self._data.environment
                        };
                        Raven.config('https://85b0483b7a254cee8c4886e7a8d52fda@app.getsentry.com/32181', ravenOptions).install();
                        self._options_initialized.raven.initd = true;
                    }
                    if (window.Raven !== undefined) {
                        Raven.setUserContext({
                            email: self._user.email,
                            id: self._user.id,
                            username: self._user.username
                        });
                        Raven.setExtraContext({
                            workspace_id: self._user.workspace_id,
                            workspace_name: self._user.workspace_name,
                            tenancy_name: self._user.tenancy_name
                        });
                    }
                };
            }
        ]
    };
});