define('modules/helpers/services/global-utilities', [
    'polyfills',
    'moment',
    'momentTimezone',
    'tinyColor',
    'numeral',
    'numeralLang'
], function (polyfills, moment, momentTimezone, tinyColor, numeral, numeralLang) {
    return {
        component: 'service',
        name: 'global_utilities',
        fn: [function () {
                var service = this;
                service.color = tinyColor;
                service.convert_color_rgb = function convert_color_rgb(color) {
                    return tinyColor(color).toRgbString();
                };
                service.convert_color_hex = function convert_color_rgb(color) {
                    return tinyColor(color).toHexString();
                };
                service.readable_color = function readable_color(color, format, size) {
                    size = size || 'small';
                    var options = {
                            includeFallbackColors: true,
                            level: 'AA',
                            size: size
                        }, standard_colors = [
                            '#555555',
                            '#1F70F1',
                            '#266DBB',
                            '#DD8516',
                            '#149E13',
                            '#CF4D3B'
                        ];
                    switch (format) {
                    case 'rgb':
                        format = 'toRgbString';
                        break;
                    default:
                        format = 'toHexString';
                        break;
                    }
                    return tinyColor.mostReadable(color, standard_colors, options)[format]();
                };
                service.change_shade = function lighten(color, lighten_by) {
                    return tinyColor(color).lighten(lighten_by).desaturate().toRgbString();
                };
                service.change_opacity = function change_opacity(_color, opacity) {
                    var self = this, color = tinyColor(_color), default_opacity = 0.5;
                    opacity = self.hasOwnProperty('opacity') && !isNaN(self.opacity) ? self.opacity : !isNaN(opacity) ? opacity : default_opacity;
                    opacity = opacity <= 1 && opacity >= 0 ? opacity : default_opacity;
                    color.setAlpha(opacity);
                    return color.toRgbString();
                };
                function Shader(base, lighten_by) {
                    var self = this;
                    self.base = base;
                    self.lighten_by = lighten_by;
                    self.spin = false;
                    return function create_shade(color) {
                        var c = tinyColor(color).lighten(self.lighten_by).desaturate(), b = c.getBrightness();
                        if (b >= 170) {
                            if (self.spin) {
                                c = tinyColor(self.base).spin(65);
                            } else {
                                c = tinyColor(self.base).complement();
                            }
                            self.base = c.toRgbString();
                            c = c.desaturate();
                            self.spin = !self.spin;
                        }
                        return c.toRgbString();
                    };
                }
                service.create_color_shades = function create_shades(base, amount, lighten_by) {
                    var i, shader, colors = [tinyColor(base).toRgbString()];
                    amount = amount || 10;
                    lighten_by = lighten_by || 8;
                    shader = new Shader(base, lighten_by);
                    for (i = 1; i < amount; i++) {
                        colors.push(shader(colors[i - 1]));
                    }
                    return colors;
                };
                service.autoColor = function (color) {
                    color = color || '#F5F5F5';
                    return {
                        'background-color': color,
                        'border-color': service.change_shade(color, -20),
                        'color': service.readable_color(color)
                    };
                };
                service.color_element = function color_element(elem, color) {
                    var styles = service.autoColor(color);
                    elem.css('background-color', styles['background-color']);
                    elem.css('border-color', styles['border-color']);
                    elem.css('color', styles['color']);
                };
                service.moment_date = function create_moment_date(d, format) {
                    if (format) {
                        return moment(d, format);
                    } else {
                        return moment(d);
                    }
                };
                service.moment_tz = function (d, tz, format) {
                    if (format) {
                        return moment(d, format).tz(tz);
                    } else {
                        return moment(d).tz(tz);
                    }
                };
                service.is_moment = function is_moment(val) {
                    return moment.isMoment(val);
                };
                service.is_same_moment = function (d1, d2) {
                    if (!d1 || !d2 || !moment.isMoment(d1) || !moment.isMoment(d2)) {
                        return null;
                    }
                    if (d1.date() != d2.date()) {
                        return false;
                    }
                    if (d1.month() != d2.month()) {
                        return false;
                    }
                    if (d1.year() != d2.year()) {
                        return false;
                    }
                    return true;
                };
                service.moment_midnight = function change_moment_to_midnight(m) {
                    m.seconds(59);
                    m.minutes(59);
                    m.hours(23);
                    return m;
                };
                service.moment_midmorning = function change_moment_to_midmorning(m) {
                    m.seconds(0);
                    m.minutes(0);
                    m.hours(0);
                    return m;
                };
                service.moment_quarter = function get_start_of_quarter(m) {
                    var q = moment(m.format());
                    q.date(1);
                    switch (m.quarter()) {
                    case 1:
                        q.month(0);
                        break;
                    case 2:
                        q.month(3);
                        break;
                    case 3:
                        q.month(6);
                        break;
                    case 4:
                        q.month(9);
                        break;
                    }
                    return q;
                };
                service.momentMoreThan = function (m, val, type) {
                    val = val || 1;
                    type = type || 'days';
                    var currentMoment = moment.isMoment(m) ? m : moment(m), testMoment = moment().subtract(val, type);
                    return currentMoment.isBefore(testMoment);
                };
                service.moment_diff = function get_time_differenc(start, end, period) {
                    var diff;
                    switch (period) {
                    default:
                        diff = 86400000;
                        break;
                    }
                    return Math.round((end.valueOf() - start.valueOf()) / diff);
                };
                service.date_months = [
                    {
                        name: 'January',
                        abbr: 'Jan',
                        value: 0,
                        display_value: '01'
                    },
                    {
                        name: 'February',
                        abbr: 'Feb',
                        value: 1,
                        display_value: '02'
                    },
                    {
                        name: 'March',
                        abbr: 'Mar',
                        value: 2,
                        display_value: '03'
                    },
                    {
                        name: 'April',
                        abbr: 'Apr',
                        value: 3,
                        display_value: '04'
                    },
                    {
                        name: 'May',
                        abbr: 'May',
                        value: 4,
                        display_value: '05'
                    },
                    {
                        name: 'June',
                        abbr: 'Jun',
                        value: 5,
                        display_value: '06'
                    },
                    {
                        name: 'July',
                        abbr: 'Jul',
                        value: 6,
                        display_value: '07'
                    },
                    {
                        name: 'August',
                        abbr: 'Aug',
                        value: 7,
                        display_value: '08'
                    },
                    {
                        name: 'September',
                        abbr: 'Sep',
                        value: 8,
                        display_value: '09'
                    },
                    {
                        name: 'October',
                        abbr: 'Oct',
                        value: 9,
                        display_value: '10'
                    },
                    {
                        name: 'November',
                        abbr: 'Nov',
                        value: 10,
                        display_value: '11'
                    },
                    {
                        name: 'December',
                        abbr: 'Dec',
                        value: 11,
                        display_value: '12'
                    }
                ];
                service.date_days = [
                    {
                        title: 'M',
                        abbr: 'Mon',
                        name: 'Monday'
                    },
                    {
                        title: 'T',
                        abbr: 'Tue',
                        name: 'Tuesday'
                    },
                    {
                        title: 'W',
                        abbr: 'Wed',
                        name: 'Wednesday'
                    },
                    {
                        title: 'T',
                        abbr: 'Thu',
                        name: 'Thursday'
                    },
                    {
                        title: 'F',
                        abbr: 'Fri',
                        name: 'Friday'
                    },
                    {
                        title: 'S',
                        abbr: 'Sat',
                        name: 'Saturday'
                    },
                    {
                        title: 'S',
                        abbr: 'Sun',
                        name: 'Sunday'
                    }
                ];
                service.number_pattern = function create_number_pattern(precision, type, sep, dec) {
                    sep = sep || ',';
                    dec = dec || '.';
                    dec = precision ? dec : '[' + dec + ']';
                    var index = 0, pat = '0' + sep + '0' + dec;
                    if (precision === null || precision === undefined || isNaN(precision)) {
                    } else {
                        while (index < precision) {
                            index++;
                            pat += '0';
                        }
                    }
                    if (type) {
                        pat += type;
                    }
                    return pat;
                };
                service.format_number = function format_number(val, pat) {
                    pat = pat || '0,0[.]0[00]';
                    return numeral(val).format(pat);
                };
                service.base64 = function base64(value) {
                    var chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-_';
                    function encoder_utob(_value) {
                        var fromCharCode = String.fromCharCode, cc;
                        if (_value.length < 2) {
                            cc = _value.charCodeAt(0);
                            return cc < 128 ? _value : cc < 2048 ? fromCharCode(192 | cc >>> 6) + fromCharCode(128 | cc & 63) : fromCharCode(224 | cc >>> 12 & 15) + fromCharCode(128 | cc >>> 6 & 63) + fromCharCode(128 | cc & 63);
                        } else {
                            cc = 65536 + (_value.charCodeAt(0) - 55296) * 1024 + (_value.charCodeAt(1) - 56320);
                            return fromCharCode(240 | cc >>> 18 & 7) + fromCharCode(128 | cc >>> 12 & 63) + fromCharCode(128 | cc >>> 6 & 63) + fromCharCode(128 | cc & 63);
                        }
                    }
                    function encoder_cb(_value) {
                        var padlen = [
                                0,
                                2,
                                1
                            ][_value.length % 3], ord = _value.charCodeAt(0) << 16 | (_value.length > 1 ? _value.charCodeAt(1) : 0) << 8 | (_value.length > 2 ? _value.charCodeAt(2) : 0), chars = [
                                chars.charAt(ord >>> 18),
                                chars.charAt(ord >>> 12 & 63),
                                padlen >= 2 ? '=' : chars.charAt(ord >>> 6 & 63),
                                padlen >= 1 ? '=' : chars.charAt(ord & 63)
                            ];
                        return chars.join('');
                    }
                    function encoder_btoa(_value) {
                        if (window.btoa) {
                            return window.btoa(_value);
                        }
                        return _value.replace(/[\s\S]{1,3}/g, encoder_cb);
                    }
                    return encoder_btoa(JSON.stringify(value).replace(/[\uD800-\uDBFF][\uDC00-\uDFFFF]|[^\x00-\x7F]/g, encoder_utob)).replace(/=/g, '');
                };
                service.jsonPointer = function (item, pointer) {
                    var resVal;
                    try {
                        resVal = eval('item.' + pointer);
                    } finally {
                        return resVal;
                    }
                };
                service.toRestPointer = function (field) {
                    function convertProp(match, submatch, offset, str) {
                        return match.replace(/(\[\")/g, '/').replace(/(\"\])/g, '');
                    }
                    function convertIndexes(match, submatch, offset, str) {
                        return match.replace(/(\[)/g, '/').replace(/(\])/g, '');
                    }
                    return '/' + field.replace(/^_/g, '').replace(/(\.)/g, '/').replace(/(\[\"\w+\"\])/g, convertProp).replace(/(\[\d+\])/g, convertIndexes);
                };
                service.textToSvg = function textToSvg(text) {
                    var t, src = '', d = {
                            w: 0,
                            h: 0
                        }, c = angular.element('<svg><text x="0" y="12" font-size="12" font-family="Roboto, sans-serif">' + text + '</text></svg>');
                    angular.element('body').append(c);
                    t = c[0].getBBox();
                    d.h = Math.round(t.height) + 8;
                    d.w = Math.round(t.width) + 14;
                    c.remove();
                    src = window.btoa('<svg width=\'' + (d.w + 2) + '\' height=\'' + (d.h + 2) + '\' xmlns=\'http://www.w3.org/2000/svg\'><rect width=\'' + d.w + '\' height=\'' + d.h + '\' x=\'1\' y=\'1\' rx=\'3\' ry=\'3\' style=\'fill:rgb(245, 245, 245);stroke:rgb(194,194,194);stroke-width:1;\'></rect><text x=\'7\' y=\'16\' font-size=\'12\' font-family=\'Roboto, sans-serif\' fill=\'rgb(85,85,85)\'>' + text + '</text></svg>');
                    return angular.element('<img src="data:image/svg+xml;base64,' + src + '" class="svg-to-text">');
                };
                service.toStringId = function (str, count) {
                    var text;
                    if (!(count > 0)) {
                        count = 24;
                    }
                    if (str) {
                        text = str.toLowerCase().substr(0, count);
                        var startOf = new RegExp(/^((?![a-z]).)*/g), toUnderscores = new RegExp(/((?![a-z0-9_]).)+/g), endOf = new RegExp(/(_+)$/g);
                        text = text.replace(startOf, '').replace(toUnderscores, '_').replace(endOf, '');
                    }
                    return text;
                };
            }]
    };
});