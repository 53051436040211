define('modules/form-inputs/directives/autocomplete', [], function () {
    return {
        component: 'directive',
        name: 'ayAutocomplete',
        fn: [
            '$document',
            '$timeout',
            '$compile',
            '$log',
            function ay_autocomplete($document, $timeout, $compile, $log) {
                return {
                    restrict: 'A',
                    require: 'ngModel',
                    scope: {
                        itemList: '=ayAutocomplete',
                        source: '=ngModel',
                        itemFormatter: '=?itemFormatter',
                        groupFormatter: '=?groupFormatter',
                        onChange: '=?onChange',
                        onSubmit: '=?onSubmit',
                        onFocus: '=?onFocus',
                        onSubmitAll: '=?onSubmitAll',
                        useParentElement: '@'
                    },
                    link: function ($scope, elem, attrs, ngModelController) {
                        var isInit = false, _windowElm = angular.element(window), widgetName = 'autocomplete', widgetElm = elem, watchers = {
                                list: null,
                                value: null,
                                cancel: function () {
                                    if (watchers.value) {
                                        watchers.value();
                                    }
                                    if (watchers.list) {
                                        watchers.list();
                                    }
                                }
                            }, timers = {
                                loader: null,
                                resize: null,
                                setup: null,
                                cancel: function () {
                                    if (!timers.setup && widgetElm && isInit) {
                                        widgetElm[widgetName]('destroy');
                                    }
                                    for (var i in timers) {
                                        if (!angular.isFunction(timers[i])) {
                                            $timeout.cancel(timers[i]);
                                        }
                                    }
                                }
                            }, inputEvents = {
                                blur: function ($event) {
                                    if (!$scope.source.value) {
                                        if ($scope.onSubmitAll) {
                                            $scope.onSubmitAll($event, $scope.source);
                                        }
                                        if (!$scope.source || $scope.source && (!$scope.source.value || $scope.source.removeOnBlur)) {
                                            $scope.source = { value: '' };
                                        }
                                        if ($scope.$parent.ay_validate) {
                                            elem.selector = true;
                                            $scope.$parent.ay_validate.run(elem);
                                        }
                                        $timeout(function () {
                                            $scope.sendChange($event);
                                        });
                                    }
                                },
                                click: function ($event) {
                                    if ($scope._widget.isReady && widgetElm) {
                                        widgetElm[widgetName]('search', '');
                                    }
                                },
                                focus: function ($event) {
                                    if ($scope._widget.isReady && widgetElm) {
                                        widgetElm[widgetName]('search', '');
                                    }
                                },
                                key: function ($event) {
                                    if ($event.keyCode === 13 && ($scope.onSubmit || $scope.onSubmitAll)) {
                                        if ($scope.onSubmit) {
                                            $scope.onSubmit($event, $scope.source);
                                        } else if ($scope.onSubmitAll) {
                                            $scope.onSubmitAll($event, $scope.source);
                                        }
                                        $scope._triggerUpdate();
                                    }
                                }
                            };
                        $scope._request_in_progress = false;
                        $scope._triggerUpdate = function () {
                            if (!$scope.$$phase && !$scope.$root.$$phase) {
                                $scope.$apply();
                            }
                        };
                        $scope._widget = {
                            menuEl: null,
                            groupBy: null,
                            isOpen: false,
                            isReady: false,
                            doSearch: function (term) {
                                return term >= (attrs.hasOwnProperty('searchMin') ? parseInt(attrs.searchMin, 10) : 1);
                            },
                            setList: function () {
                                if (widgetElm) {
                                    if (Array.isArray($scope.itemList)) {
                                        widgetElm[widgetName]('option', 'source', $scope.itemList);
                                    } else if (angular.isFunction($scope.itemList)) {
                                        widgetElm[widgetName]('option', 'source', $scope._widget.getRequest);
                                    }
                                    $scope._widget.isReady = true;
                                }
                            },
                            setHeight: function () {
                                var dim = $scope.useParentElement === 'true' ? elem[0].parentElement.getBoundingClientRect() : elem[0].getBoundingClientRect(), size = $document[0].firstChild.offsetHeight, btm = size - dim.bottom - 20;
                                if (timers.resize) {
                                    $timeout.cancel(timers.resize);
                                }
                                timers.resize = $timeout(function () {
                                    $scope._widget.menuEl.css('top', dim.bottom - 1);
                                    $scope._widget.menuEl.css('left', dim.left);
                                    $scope._widget.menuEl.css('overflow-y', 'auto');
                                    $scope._widget.menuEl.css('max-height', btm);
                                }, 5);
                            },
                            getRequest: function (request, response) {
                                function preResponse(results) {
                                    var list;
                                    $scope.loader.hide();
                                    if (!Array.isArray(results.data) || !results.data.length) {
                                        list = [{
                                                label: 'No matches found',
                                                error: true
                                            }];
                                    } else {
                                        list = Array.apply(null, new Array(results.data.length)).map(function (item, index) {
                                            item = results.data[index];
                                            var field = item.hasOwnProperty('value') ? 'value' : item.hasOwnProperty('name') ? 'name' : item.hasOwnProperty('full_name') ? 'full_name' : null;
                                            if (!field) {
                                                results._widgetData = {
                                                    item: item,
                                                    index: index
                                                };
                                                results.error = { message: 'unable to set autocomplete list item' };
                                                $log.error(results);
                                            } else {
                                                item.value = item[field];
                                            }
                                            return item;
                                        });
                                        if (!attrs.sortRes || attrs.sortRes === 'true') {
                                            if ($scope._widget.groupBy) {
                                                list.sort_by($scope._widget.groupBy, 'value');
                                            } else {
                                                list.sort_by('value');
                                            }
                                        }
                                    }
                                    response(list);
                                }
                                function closeList() {
                                    $scope.loader.hide();
                                    response([]);
                                }
                                if ($scope._widget.doSearch(request.term.length)) {
                                    $scope.loader.show();
                                    $scope.itemList(request.term, preResponse, closeList);
                                }
                            },
                            init: function () {
                                timers.setup = $timeout(function () {
                                    var dataName = (widgetName === 'autocomplete' ? 'ui' : 'custom') + angular.copy(widgetName).capitalize();
                                    if (widgetElm) {
                                        widgetElm[widgetName]($scope._options);
                                        if ($scope.itemFormatter) {
                                            widgetElm[widgetName]().data(dataName)._renderItem = function (ul, item) {
                                                return angular.element('<li class="ui-menu-item custom white"></li>').append(angular.element($scope.itemFormatter(item))).appendTo(ul);
                                            };
                                        }
                                        widgetElm[widgetName]().data(dataName)._resizeMenu = function () {
                                            var dim = $scope.useParentElement === 'true' ? elem[0].parentElement.getBoundingClientRect() : elem[0].getBoundingClientRect();
                                            $scope._widget.menuEl = angular.element(this.menu.element);
                                            if (!attrs.menuWidth) {
                                                $scope._widget.menuEl.css('width', dim.width);
                                            } else {
                                                switch (attrs.menuWidth) {
                                                case 'container':
                                                    $scope._widget.menuEl.css('width', dim.width);
                                                    break;
                                                default:
                                                    $scope._widget.menuEl.css('width', attrs.menuWidth);
                                                    break;
                                                }
                                            }
                                            $scope._widget.menuEl.css('position', 'absolute');
                                            _windowElm.on('resize.' + $scope.$index, $scope._widget.setHeight);
                                            $scope._widget.setHeight();
                                        };
                                        watchers.list = $scope.$watch('itemList', function (newVal, oldVal) {
                                            if (newVal) {
                                                $scope._widget.setList();
                                            }
                                        });
                                    }
                                    isInit = true;
                                });
                            },
                            restart: function () {
                                $scope.close();
                                timers.cancel();
                                timers.restart = $timeout(function () {
                                    if (watchers.list) {
                                        watchers.list();
                                    }
                                    $scope._widget.init();
                                }, 100);
                            }
                        };
                        $scope._options = {
                            minLength: 0,
                            autoFocus: false,
                            select: function (event, ui) {
                                if (!ui.item.error) {
                                    $scope.source = angular.copy(ui.item);
                                    $timeout(function () {
                                        $scope.sendChange(event);
                                    });
                                } else {
                                    $scope.source = { value: '' };
                                    $timeout(function () {
                                        $scope.sendChange(event);
                                    });
                                    return false;
                                }
                            },
                            open: function (event, ui) {
                                $scope._widget.isOpen = true;
                                $scope._widget.setHeight();
                            },
                            close: function (event, ui) {
                                $scope._widget.isOpen = false;
                                _windowElm.off('resize.' + $scope.$index);
                            },
                            focus: function (event, ui) {
                                var entity;
                                if (ui.item.error) {
                                    return false;
                                } else {
                                    entity = angular.copy(ui.item);
                                    entity.removeOnBlur = true;
                                    $scope.source = entity;
                                    $timeout(function () {
                                        $scope.sendChange(event);
                                    });
                                }
                            }
                        };
                        $scope.sendChange = function ($event) {
                            if ($scope.onChange && $event) {
                                $scope.onChange($event, angular.copy($scope.source));
                            }
                            $scope._triggerUpdate();
                        };
                        $scope.loader = {
                            spinner: null,
                            _cyclePhase: function () {
                                if (!$scope.$$phase && !$scope.$root.$$phase) {
                                    $scope.$apply();
                                }
                            },
                            add: function () {
                                var dim = $scope.useParentElement === 'true' ? elem[0].parentElement.getBoundingClientRect() : elem[0].getBoundingClientRect();
                                $scope.loader.spinner = $compile('<ay-loader data-is-loading="_request_in_progress" class="bdr white pad is-larger" style="position: absolute; z-index: 2000; top:' + dim.bottom + 'px; left:' + dim.left + 'px; width: ' + (dim.width - 23) + 'px; height: 60px;"></ay-loader>')($scope);
                                angular.element('body').prepend($scope.loader.spinner);
                            },
                            hide: function () {
                                $scope._request_in_progress = false;
                                if (timers.loader) {
                                    $timeout.cancel(timers.loader);
                                }
                                $scope.loader._cyclePhase();
                            },
                            show: function () {
                                if (!$scope.loader.spinner) {
                                    $scope.loader.add();
                                }
                                $scope.loader.hide();
                                timers.loader = $timeout(function () {
                                    $scope._request_in_progress = true;
                                }, 500);
                                $scope.loader._cyclePhase();
                            }
                        };
                        ngModelController.$formatters.push(function (entity) {
                            if (entity) {
                                return entity.value;
                            }
                        });
                        elem.on('blur', inputEvents.blur);
                        elem.on('focus', inputEvents.focus);
                        elem.on('click', inputEvents.click);
                        elem.on('keyup', inputEvents.key);
                        attrs.$observe('grouping', function (newVal) {
                            $scope._widget.groupBy = newVal ? newVal : false;
                            if (timers.setup) {
                                $scope._widget.restart();
                            }
                            widgetName = !$scope._widget.groupBy ? 'autocomplete' : 'ayGroupComplete_' + $scope.$id;
                            if ($scope._widget.groupBy) {
                                $.widget('custom.' + widgetName, $.ui.autocomplete, {
                                    _create: function autocomplete_create() {
                                        this._super();
                                        this.widget().menu('option', 'items', '> :not(.ui-autocomplete-category)');
                                    },
                                    _renderMenu: function autocomplete_render_menu(ul, items) {
                                        var i, li, group_el, currentGroupBy, self = this;
                                        ul.css('overflow-y', 'auto');
                                        for (i = 0; i < items.length; i++) {
                                            if (!items[i].error && items[i].hasOwnProperty($scope._widget.groupBy) && items[i][$scope._widget.groupBy] !== currentGroupBy) {
                                                currentGroupBy = items[i][$scope._widget.groupBy];
                                                group_el = angular.element('<li class="ui-autocomplete-category"></li>');
                                                if ($scope.groupFormatter) {
                                                    group_el.append(angular.element($scope.groupFormatter(items[i][$scope._widget.groupBy])));
                                                } else {
                                                    group_el.addClass('mar left txt-sm is-muted');
                                                    group_el.html(items[i][$scope._widget.groupBy]);
                                                }
                                                ul.append(group_el);
                                            }
                                            li = self._renderItemData(ul, items[i]);
                                            if (!items[i].error && items[i].hasOwnProperty($scope._widget.groupBy)) {
                                                li.attr('aria-label', items[i][$scope._widget.groupBy] + ' : ' + items[i].label);
                                            }
                                        }
                                    }
                                });
                            }
                        });
                        $scope.close = function () {
                            if ($scope._widget.isOpen && widgetElm) {
                                widgetElm[widgetName]('close');
                            }
                            $scope.loader.hide();
                        };
                        watchers.value = $scope.$watch('source', function (newVal) {
                            if (newVal && newVal.hasOwnProperty('value')) {
                                watchers.value();
                                $scope._widget.init();
                            }
                        });
                        $scope.$on('$destroy', function () {
                            timers.cancel();
                            watchers.cancel();
                            elem.off('blur', inputEvents.blur);
                            elem.off('focus', inputEvents.focus);
                            elem.off('click', inputEvents.click);
                            elem.off('keyup', inputEvents.key);
                        });
                    }
                };
            }
        ]
    };
});