define('modules/rest/factories/accounts', [], function () {
    'use strict';
    return {
        component: 'service',
        name: 'modelAccount',
        fn: [
            '$log',
            'modelParticipant',
            '$schemaValidator',
            '$q',
            '$timeout',
            '$ay_requester',
            'errorDetection',
            '$ME',
            function account_factory($log, modelParticipant, $schemaValidator, $q, $timeout, $ay_requester, errorDetection, $ME) {
                var LOCATION = 'accounts', factory = { media_type: 'application/vnd.amity.hub.account+json' };
                function Account_Model(data) {
                    var self = this;
                    self.entity_type = 'account';
                    self.addresses = [];
                    self.assigned_to_user = {};
                    self.avatar_url = null;
                    self.created_at = null;
                    self.crm_owner_email = null;
                    self.crm_owner_full_name = null;
                    self.currency_code = null;
                    self.custom_properties = null;
                    self.customer_since = null;
                    self.description = null;
                    self.email_domains = [];
                    self.etag = null;
                    self.external_ids = [];
                    self.gravatar_hash = null;
                    self.health_level = null;
                    self.id = null;
                    self.ignored = null;
                    self.insight_overrides = [];
                    self.insights = [];
                    self.lifetime_revenue = null;
                    self.location = LOCATION + '/';
                    self.linkUrl = null;
                    self.media_type = null;
                    self.name = null;
                    self.next_steps = null;
                    self.potential_value = null;
                    self.priority_level = null;
                    self.region_id = null;
                    self.risk_level = null;
                    self.roles = [];
                    self.stage_id = null;
                    self.tags = [];
                    self._tags = null;
                    self.telephones = [];
                    self.tier_id = null;
                    self.type_id = null;
                    self.urls = [];
                    self.workspace_id = null;
                    self.current_balance = null;
                    self.overdue_balance = null;
                    self.last_invoice_amount = null;
                    self.last_invoice_balance = null;
                    self.last_invoice_date = null;
                    self.last_invoice_due_date = null;
                    self.last_invoice_external_id = null;
                    self.last_invoice_number = null;
                    self.last_payment_amount = null;
                    self.last_payment_date = null;
                    self.last_payment_external_id = null;
                    self.last_payment_invoice_external_id = null;
                    self.last_payment_invoice_number = null;
                    self.last_payment_number = null;
                    self.default_payment_method = null;
                    self.payment_card_type = null;
                    self.payment_card_number = null;
                    self.payment_card_expires_at = null;
                    self.crm_owner_email = null;
                    self.crm_owner_full_name = null;
                    self.nps_comment = null;
                    self.nps_rating = null;
                    self.nps_response_date = null;
                    self.last_activity = null;
                    self.last_activity_name = null;
                    self.engagements_total = null;
                    self.last_chat_engagement = null;
                    self.last_engagement = null;
                    self.last_incoming_email_engagement = null;
                    self.last_meeting_engagement = null;
                    self.last_outgoing_email_engagement = null;
                    self.last_phone_engagement = null;
                    self.participants_total = null;
                    self.plays_active = null;
                    self.plays_overdue = null;
                    self.plays_total = null;
                    self.account_value = null;
                    self.active_subscription_name = null;
                    self.customer_status = null;
                    self.max_renewal = null;
                    self.next_renewal = null;
                    self.subscriptions_active = null;
                    self.subscriptions_total = null;
                    self.last_ticket_created_at = null;
                    self.last_ticket_display_id = null;
                    self.oldest_open_ticket_created_at = null;
                    self.oldest_open_ticket_display_id = null;
                    self.tickets_open = null;
                    self.tickets_total = null;
                    self.work_items_incomplete = null;
                    self.work_items_overdue = null;
                    self.work_items_total = null;
                    self.notes = null;
                    if (data) {
                        self.set(data);
                    }
                }
                Account_Model.prototype.set = function (data) {
                    var self = this;
                    self.addresses = data.addresses || [];
                    self.assigned_to_user = data.assigned_to_user || {};
                    self.avatar_url = data.avatar_url;
                    self.created_at = new Date(data.created_at);
                    self.currency_code = data.currency_code;
                    self.custom_properties = data.custom_properties || {};
                    self.customer_since = data.customer_since ? new Date(data.customer_since) : null;
                    self.description = data.description;
                    self.email_domains = data.email_domains || [];
                    self._email_domains = self.email_domains.length ? self.email_domains.join(', ') : '';
                    self.etag = data.etag;
                    self.external_ids = data.external_ids || [];
                    self.gravatar_hash = data.gravatar_hash;
                    self.health_level = data.health_level;
                    self.id = data.id;
                    self.ignored = data.ignored;
                    self.insight_overrides = data.insight_overrides;
                    self.insights = data.insights;
                    self.location = LOCATION + '/' + data.id;
                    self.linkUrl = '/csm/accounts/' + data.id;
                    self.media_type = data.media_type;
                    self.name = data.name;
                    self.next_steps = data.next_steps;
                    self.potential_value = data.potential_value;
                    self.priority_level = data.priority_level;
                    self.region_id = data.region_id;
                    self.risk_level = data.risk_level;
                    self.stage_id = data.stage_id;
                    self.tags = data.tags || [];
                    self._tags = self.tags.length ? self.tags.join(', ') : '';
                    self.telephones = data.telephones || [];
                    self.tier_id = data.tier_id;
                    self.type_id = data.type_id;
                    self.urls = data.urls || [];
                    self.workspace_id = data.workspace_id;
                    self.current_balance = data.current_balance;
                    self.overdue_balance = data.overdue_balance;
                    self.last_invoice_amount = data.last_invoice_amount;
                    self.last_invoice_balance = data.last_invoice_balance;
                    self.last_invoice_date = data.last_invoice_date ? new Date(data.last_invoice_date) : null;
                    self.last_invoice_due_date = data.last_invoice_due_date ? new Date(data.last_invoice_due_date) : null;
                    self.last_invoice_external_id = data.last_invoice_external_id;
                    self.last_invoice_number = data.last_invoice_number;
                    self.last_payment_amount = data.last_payment_amount;
                    self.last_payment_date = data.last_payment_date ? new Date(data.last_payment_date) : null;
                    self.last_payment_external_id = data.last_payment_external_id;
                    self.last_payment_invoice_external_id = data.last_payment_invoice_external_id;
                    self.last_payment_invoice_number = data.last_payment_invoice_number;
                    self.last_payment_number = data.last_payment_number;
                    self.default_payment_method = data.default_payment_method;
                    self.payment_card_expires_at = data.payment_card_expires_at ? new Date(data.payment_card_expires_at) : null;
                    self.payment_card_number = data.payment_card_number;
                    self.payment_card_type = data.payment_card_type;
                    self.notes = data.notes || [];
                    self.crm_owner_email = data.crm_owner_email;
                    self.crm_owner_full_name = data.crm_owner_full_name;
                    self.nps_comment = data.nps_comment;
                    self.nps_rating = data.nps_rating;
                    self.nps_response_date = data.nps_response_date;
                    self.last_activity = data.last_activity ? new Date(data.last_activity) : null;
                    self.last_activity_name = data.last_activity_name;
                    self.engagements_total = data.engagements_total;
                    self.last_chat_engagement = data.last_chat_engagement ? new Date(data.last_chat_engagement) : null;
                    self.last_engagement = data.last_engagement ? new Date(data.last_engagement) : null;
                    self.last_incoming_email_engagement = data.last_incoming_email_engagement ? new Date(data.last_incoming_email_engagement) : null;
                    self.last_meeting_engagement = data.last_meeting_engagement ? new Date(data.last_meeting_engagement) : null;
                    self.last_outgoing_email_engagement = data.last_outgoing_email_engagement ? new Date(data.last_outgoing_email_engagement) : null;
                    self.last_phone_engagement = data.last_phone_engagement ? new Date(data.last_phone_engagement) : null;
                    self.participants_total = data.participants_total;
                    self.plays_active = data.plays_active;
                    self.plays_overdue = data.plays_overdue;
                    self.plays_total = data.plays_total;
                    self.account_value = data.account_value;
                    self.active_subscription_name = data.active_subscription_name;
                    self.customer_status = data.customer_status;
                    self.lifetime_revenue = data.lifetime_revenue;
                    self.max_renewal = data.max_renewal ? new Date(data.max_renewal) : null;
                    self.next_renewal = data.next_renewal ? new Date(data.next_renewal) : null;
                    self.subscriptions_active = data.subscriptions_active;
                    self.subscriptions_total = data.subscriptions_total;
                    self.last_ticket_created_at = data.last_ticket_created_at ? new Date(data.last_ticket_created_at) : null;
                    self.last_ticket_display_id = data.last_ticket_display_id;
                    self.oldest_open_ticket_created_at = data.oldest_open_ticket_created_at ? new Date(data.oldest_open_ticket_created_at) : null;
                    self.oldest_open_ticket_display_id = data.last_ticket_display_id;
                    self.tickets_open = data.tickets_open;
                    self.tickets_total = data.tickets_total;
                    self.work_items_incomplete = data.work_items_incomplete;
                    self.work_items_overdue = data.work_items_overdue;
                    self.work_items_total = data.work_items_total;
                    if ($ME.workspace.account_roles.length) {
                        self.roles = data.roles || [];
                        self.addRoles();
                    }
                };
                Account_Model.prototype.addRoles = function addRoles() {
                    var index, self = this, roles = $ME.workspace.account_roles;
                    for (var i = 0, ii = roles.length; i < ii; i++) {
                        if (roles[i].enabled) {
                            index = self.roles._index_of_by_prop('account_role_id', roles[i].id);
                            if (index === -1) {
                                self.roles.push({
                                    user_id: '',
                                    account_role_id: roles[i].id
                                });
                            }
                        }
                    }
                    self.roles.sort_by('account_role_id');
                };
                Account_Model.prototype.remove = function () {
                    var self = this;
                    return $ay_requester.DELETE(self.location, {});
                };
                Account_Model.prototype.update = function (fields) {
                    function createTargets(item, index) {
                        var target = fields[index], change = {
                                op: target.op ? target.op : 'replace',
                                path: '/' + target.field.replace(/^_/g, '').replace(/(\[\")|(\.)/g, '/').replace(/(\"\])/g, '')
                            };
                        if ((change.op === 'replace' || change.op === 'add' || change.op === 'test') && target.value === undefined) {
                            if (target.field === 'roles') {
                                self.roles = self.removeEmptyListObjects(angular.copy(self.roles), 'user_id');
                            }
                            change.value = angular.copy(self[target.field]);
                        } else {
                            if (target.field === 'roles') {
                                change.value = self.removeEmptyListObjects(angular.copy(target.value), 'user_id');
                            } else {
                                change.value = angular.copy(target.value);
                            }
                        }
                        return change;
                    }
                    var self = this, params = {
                            fields: fields,
                            media_type: self.media_type,
                            data: Array.apply(null, new Array(fields.length)).map(createTargets)
                        };
                    self.addRoles();
                    return $ay_requester.PATCH(self.location, params);
                };
                Account_Model.prototype.removeEmptyListObjects = function (data, checkProperty) {
                    for (var i = data.length - 1; i > -1; i--) {
                        if (data[i][checkProperty] === null || data[i][checkProperty] === undefined || data[i][checkProperty].length === 0) {
                            data.splice(i, 1);
                        }
                    }
                    return data;
                };
                Account_Model.prototype._cleanUp = function () {
                    var validation_res, self = angular.copy(this);
                    function removeEmptyTags(tag, index) {
                        if (tag === '' || tag === undefined) {
                            validation_res.data.tags.splice(index, 1);
                        }
                    }
                    for (var k in self.custom_properties) {
                        if (self.custom_properties[k] === null || self.custom_properties[k] === undefined) {
                            delete self.custom_properties[k];
                        }
                    }
                    self.roles = self.removeEmptyListObjects(self.roles, 'user_id');
                    validation_res = $schemaValidator.validate(self, factory.schema);
                    if (!validation_res.error) {
                        if (validation_res.data.last_payment_date) {
                            validation_res.data.last_payment_date = new Date(validation_res.data.last_payment_date).toISOString();
                        } else {
                            delete validation_res.data.last_payment_date;
                        }
                        if (validation_res.data.last_invoice_date) {
                            validation_res.data.last_invoice_date = new Date(validation_res.data.last_invoice_date).toISOString();
                        } else {
                            delete validation_res.data.last_invoice_date;
                        }
                        if (!validation_res.data.customer_since) {
                            delete validation_res.data.customer_since;
                        }
                        if (validation_res.data.potential_value === 0 || validation_res.data.potential_value && parseFloat(validation_res.data.potential_value)) {
                            validation_res.data.potential_value = parseFloat(validation_res.data.potential_value);
                        } else {
                            delete validation_res.data.potential_value;
                        }
                        var tags = angular.copy(validation_res.data.tags);
                        if (tags) {
                            tags.forEach(removeEmptyTags);
                        }
                    }
                    self.addRoles();
                    return validation_res;
                };
                Account_Model.prototype.getEtag = function () {
                    var self = this, etagDefer = $q.defer();
                    if (self.etag) {
                        $timeout(function () {
                            etagDefer.resolve({ etag: self.etag });
                        }, 1);
                    } else {
                        $ay_requester.GET(self.location).then(function (results) {
                            if (!results.error) {
                                self.etag = results.data.etag;
                                results.etag = self.etag;
                            }
                            etagDefer.resolve(results);
                        });
                    }
                    return etagDefer.promise;
                };
                Account_Model.prototype.updateNotes = function (index) {
                    var self = this, saveDefer = $q.defer(), validation_res = self._cleanUp();
                    self.getEtag().then(function (results) {
                        if (results.error) {
                            saveDefer.resolve(results);
                        } else {
                            if (validation_res.error) {
                                saveDefer.resolve(validation_res);
                            } else {
                                if (!angular.equals(validation_res.data.notes[index].html_body, self.notes[index].html_body)) {
                                    $log.error({
                                        error: { message: 'Account Note: unexpected note change' },
                                        acccountId: self.id,
                                        noteIndex: index,
                                        noteValidated: validation_res.data.notes[index],
                                        noteLocal: angular.copy(self.notes[index])
                                    });
                                    validation_res.notes[index].html_body = self.notes[index].html_body;
                                }
                                $ay_requester.PUT(self.location, {
                                    etag: results.etag,
                                    data: validation_res.data
                                }).then(function complete_request(results) {
                                    if (!results.error) {
                                        self.set(results.data);
                                        results.data = self;
                                    }
                                    saveDefer.resolve(results);
                                });
                            }
                        }
                    });
                    return saveDefer.promise;
                };
                Account_Model.prototype.save = function () {
                    var self = this, saveDefer = $q.defer(), validation_res = self._cleanUp();
                    self.getEtag().then(function (results) {
                        function complete_request(results) {
                            if (!results.error) {
                                self.set(results.data);
                                results.data = self;
                            }
                            saveDefer.resolve(results);
                        }
                        if (results.error) {
                            saveDefer.resolve(results);
                        } else {
                            if (validation_res.error) {
                                saveDefer.resolve(validation_res);
                            } else {
                                $ay_requester.PUT(self.location, {
                                    etag: results.etag,
                                    data: validation_res.data
                                }).then(complete_request);
                            }
                        }
                    });
                    return saveDefer.promise;
                };
                Account_Model.prototype.getAttachments = function (params) {
                    var self = this, getDefer = $q.defer(), location = LOCATION + '/' + self.id + '/attachments', requestDefer = $ay_requester.GET(location, params);
                    getDefer.promise.cancel = function () {
                        requestDefer.cancel();
                    };
                    requestDefer.then(function (results) {
                        if (!results.error) {
                            results.data = results.data.items;
                        }
                        getDefer.resolve(results);
                    });
                    return getDefer.promise;
                };
                factory._buildOptions = function (params) {
                    var options = {
                        f: [],
                        s: []
                    };
                    if (params.filters) {
                        options.f = angular.copy(params.filters);
                    }
                    if (params.sorts) {
                        options.s = angular.copy(params.sorts);
                    }
                    if (params.bulk_get && params.bulk_get.length) {
                        options.f.push({
                            field: '/id',
                            op: 'IN',
                            value: params.bulk_get
                        });
                    }
                    if (params.input) {
                        options.f.push({
                            field: '/name',
                            op: 'CONTAINS',
                            value: params.input
                        });
                    }
                    if (params.onlyCustomers) {
                        options.f.push({
                            field: '/id',
                            op: 'NE',
                            value: $ME.workspace.id
                        });
                    }
                    return options;
                };
                factory.getSchema = function get_entity_schema() {
                    var s = this, schemaDefer = $q.defer();
                    function setEntitySchema(results) {
                        if (!results.error) {
                            s.schema = results.data;
                            schemaDefer.resolve(s.schema);
                        } else {
                            schemaDefer.resolve(results);
                        }
                    }
                    if (s.schema) {
                        $timeout(function () {
                            schemaDefer.resolve(s.schema);
                        }, 1);
                    } else {
                        $ay_requester.GET('schema/' + LOCATION).then(setEntitySchema);
                    }
                    return schemaDefer.promise;
                };
                factory.newModel = function (data) {
                    return new Account_Model(data);
                };
                factory.get = function (params) {
                    var getPromise, drainPromise, self = this, cancelled = false, getDefer = $q.defer(), location = LOCATION, models = [];
                    function setModels(results) {
                        if (!cancelled) {
                            if (results.error) {
                                getDefer.resolve(results);
                            } else {
                                if (!results.data.hasOwnProperty('items')) {
                                    results.data = self.newModel(results.data);
                                    getDefer.resolve(results);
                                } else {
                                    models = models.concat(results.data.items.map(self.newModel));
                                    results.data = models;
                                    if (!params.drain) {
                                        getDefer.resolve(results);
                                    } else {
                                        if (!results.cursor) {
                                            getDefer.resolve(results);
                                        } else {
                                            params.cursor = results.cursor;
                                            drainPromise = $ay_requester.GET(location, params);
                                            drainPromise.then(setModels);
                                        }
                                    }
                                }
                            }
                        }
                    }
                    self.getSchema().then(function (results) {
                        params = params || {};
                        if (!cancelled) {
                            if (params.id) {
                                location += '/' + params.id;
                            } else {
                                params.options = self._buildOptions(params);
                            }
                            getPromise = $ay_requester.GET(location, params);
                            getPromise.then(setModels);
                        }
                    });
                    getDefer.promise.cancel = function () {
                        cancelled = true;
                        if (getPromise && getPromise.hasOwnProperty('cancel')) {
                            getPromise.cancel();
                        }
                        if (drainPromise && drainPromise.hasOwnProperty('cancel')) {
                            drainPromise.cancel();
                        }
                        $log.debug('account request cancelled');
                        getDefer.resolve({
                            cancelled: true,
                            error: errorDetection('-1')
                        });
                    };
                    return getDefer.promise;
                };
                factory.create = function (data) {
                    var self = this, createDefer = $q.defer();
                    function post() {
                        data.media_type = factory.schema.description;
                        var validation_res = $schemaValidator.validate(data, factory.schema);
                        if (validation_res.error) {
                            $timeout(function () {
                                createDefer.resolve(validation_res);
                            }, 1);
                        } else {
                            $ay_requester.POST(LOCATION, { data: validation_res.data }).then(function (results) {
                                if (!results.error) {
                                    results.data = new Account_Model(results.data);
                                }
                                createDefer.resolve(results);
                            });
                        }
                    }
                    self.getSchema().then(post);
                    return createDefer.promise;
                };
                return factory;
            }
        ]
    };
});