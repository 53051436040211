define('modules/helpers/index', [
    'modules/helpers/constants/log-levels',
    'modules/helpers/services/global-utilities',
    'modules/helpers/services/log',
    'modules/helpers/services/utls'
], function () {
    var def, module = angular.module('ay-helpers-mod', []);
    module.config([
        '$provide',
        'ay_log_levels',
        function ($provide, ay_log_levels) {
            $provide.decorator('$log', [
                '$delegate',
                '$ayLogging',
                'srvcConfig',
                function ($delegate, $ayLogging, srvcConfig) {
                    var logger = {
                        _log: {
                            error: $delegate.error,
                            log: $delegate.log,
                            info: $delegate.info,
                            warn: $delegate.warn,
                            debug: $delegate.log
                        },
                        _format: function format_logs(args, type) {
                            var logs = [];
                            if (args && args[0] && args[0].stack) {
                                var err = new Error();
                                err.message = args[0].message;
                                err.stack = args[0].stack;
                                if (args.length > 1) {
                                    err.errorDetails = args[1];
                                }
                                logs = [err];
                            } else {
                                logs = args;
                            }
                            return logs;
                        },
                        _send: function send(args, level, type) {
                            var logs = logger._format(args, type);
                            if (logger.level >= ay_log_levels[level]) {
                                logger._log[level].apply(null, logs);
                            }
                            if (level === 'error') {
                                return $ayLogging[level].apply(null, logs);
                            }
                        },
                        warn: function warn_method() {
                            logger._send(arguments, 'warn');
                        },
                        info: function info_method() {
                            logger._send(arguments, 'info');
                        },
                        error: function error_method() {
                            var log_error, error_info, error_obj, error_msg, isLoggable = true;
                            try {
                                if (arguments && arguments[0] && !arguments[0].hasOwnProperty('stack') && arguments[0].error) {
                                    error_msg = arguments[0].error.message;
                                    error_obj = JSON.stringify(arguments[0]);
                                    error_info = ' additional info: ' + error_obj;
                                    if (arguments[0].error && arguments[0].error.hasOwnProperty('loggable')) {
                                        isLoggable = arguments[0].error.loggable;
                                    }
                                    if (isLoggable) {
                                        log_error = new Error(error_msg);
                                        if (log_error.stack) {
                                            log_error.stack = log_error.stack.toString().split('\n');
                                            if (error_info) {
                                                log_error.stack.splice(1, 1, error_info);
                                            } else {
                                                log_error.stack.splice(1, 1);
                                            }
                                            log_error.stack = log_error.stack.join('\n');
                                        }
                                        arguments = [
                                            log_error,
                                            error_obj
                                        ];
                                    }
                                }
                                if (isLoggable) {
                                    return logger._send(arguments, 'error');
                                }
                            } catch (exception) {
                                return logger._send(arguments, 'error');
                            }
                        },
                        log: function log_method(level) {
                            logger._send(arguments, 'log');
                        },
                        debug: function debug_method() {
                            logger._send(arguments, 'debug');
                        },
                        enter: function enter_method() {
                            logger._send(arguments, 'debug', 'Entered');
                        },
                        exit: function exit_method() {
                            logger._send(arguments, 'debug', 'Exited');
                        },
                        set_level: function set_level(level) {
                            if (angular.isString(level) && ay_log_levels.hasOwnProperty(level)) {
                                logger.level = ay_log_levels[level];
                                $ayLogging.send_logs = true;
                            } else {
                                logger.level = ay_log_levels.error;
                                $ayLogging.send_logs = false;
                            }
                        }
                    };
                    Error.stackTraceLimit = 20;
                    logger.level = ay_log_levels.error;
                    return logger;
                }
            ]);
        }
    ]);
    for (var i = 0, ii = arguments.length; i < ii; i++) {
        def = arguments[i];
        if (def.hasOwnProperty('name') && def.hasOwnProperty('fn') && def.hasOwnProperty('component')) {
            module[def.component](def.name, def.fn);
        }
    }
    return module;
});