define('modules/rest/factories/workitems', [], function () {
    'use strict';
    return {
        component: 'service',
        name: 'modelWorkitem',
        fn: [
            '$log',
            '$schemaValidator',
            '$ME',
            '$q',
            '$timeout',
            '$ay_requester',
            'restFactoryUser',
            'errorDetection',
            '$utls',
            function workitem_factory($log, $schemaValidator, $ME, $q, $timeout, $ay_requester, restFactoryUser, errorDetection, $utls) {
                var LOCATION = 'work_items', factory = {
                        schema: null,
                        media_type: 'application/vnd.amity.hub.work_item+json',
                        _userList: null
                    };
                function Workitem_Model(data) {
                    var self = this;
                    self.data_type = 'workitem';
                    self.entity_type = 'work_item';
                    self.workspace_id = null;
                    self.created_by_user = null;
                    self.assigned_to_user = null;
                    self.completed_at = null;
                    self.is_completed = false;
                    self.archived_at = null;
                    self.is_archived = false;
                    self.account = null;
                    self.title = null;
                    self.sort_key = null;
                    self.priority_level = null;
                    self.stage_id = null;
                    self.type_id = null;
                    self.id = null;
                    self.location = LOCATION + '/';
                    self.description = null;
                    self.due_when = null;
                    self.created_at = null;
                    self.etag = null;
                    self.media_type = 'application/vnd.amity.hub.work_item+json';
                    self.play = null;
                    self.original_playbook_stage_id = null;
                    self.original_playbook_id = null;
                    self.collaborator_user_ids = null;
                    self.collaboratorsLabel = '';
                    self.collaboratorsData = [];
                    self.time_spent = null;
                    self.timeSegment = {
                        day: null,
                        hour: null,
                        minute: null,
                        label: ''
                    };
                    self.tags = null;
                    self.external_ids = [];
                    self.participant = null;
                    self.flagged = null;
                    if (data) {
                        self.set(data);
                    }
                }
                Workitem_Model.prototype._timeSegment = function (val, seg) {
                    var time = {
                        val: null,
                        mod: null
                    };
                    if (val >= seg) {
                        time.val = Math.floor(val / seg);
                        time.mod = val % seg;
                    } else {
                        time.mod = val;
                    }
                    return time;
                };
                Workitem_Model.prototype.setTimeSegments = function () {
                    var tempSeg, self = this;
                    if (self.time_spent) {
                        self.timeSegment.label = '';
                        tempSeg = self._timeSegment(self.time_spent, $ME.workspace.hours_per_work_day * 60);
                        if (tempSeg.val) {
                            self.timeSegment.day = tempSeg.val;
                            self.timeSegment.label += tempSeg.val + ' day' + (tempSeg.val > 1 ? 's' : '') + (tempSeg.mod ? ', ' : ' ');
                        }
                        if (tempSeg.mod) {
                            tempSeg = self._timeSegment(tempSeg.mod, 60);
                            if (tempSeg.val) {
                                self.timeSegment.hour = tempSeg.val;
                                self.timeSegment.label += tempSeg.val + ' hour' + (tempSeg.val > 1 ? 's' : '') + (tempSeg.mod ? ', ' : ' ');
                            }
                            if (tempSeg.mod) {
                                self.timeSegment.minute = tempSeg.mod;
                                self.timeSegment.label += tempSeg.mod + ' minute' + (tempSeg.mod > 1 ? 's' : '');
                            }
                        }
                    }
                };
                Workitem_Model.prototype.setTimeSpent = function () {
                    var self = this, timeTotal = 0;
                    if (self.timeSegment.day) {
                        timeTotal += self.timeSegment.day * ($ME.workspace.hours_per_work_day * 60);
                    }
                    if (self.timeSegment.hour) {
                        timeTotal += self.timeSegment.hour * 60;
                    }
                    if (self.timeSegment.minute) {
                        timeTotal += self.timeSegment.minute;
                    }
                    self.time_spent = timeTotal ? timeTotal : null;
                };
                Workitem_Model.prototype.set = function (data) {
                    var self = this;
                    if (!data) {
                        return null;
                    }
                    function setUser(id, user) {
                        return factory._userList ? factory._userList[id] : user ? user : id;
                    }
                    function setCreator() {
                        self.created_by_user = setUser(self.created_by_user.id, self.created_by_user);
                    }
                    function setAssignee() {
                        self.assigned_to_user = setUser(self.assigned_to_user.id, self.assigned_to_user);
                    }
                    function getUser(id) {
                        return setUser(id);
                    }
                    function getLabel(user) {
                        return user ? user.full_name : '';
                    }
                    function setCollabs() {
                        if (self.collaborator_user_ids && self.collaborator_user_ids.length) {
                            self.collaboratorsData = self.collaborator_user_ids.map(getUser);
                        }
                        if (self.collaboratorsData && self.collaboratorsData.length) {
                            self.collaboratorsLabel = 'Collaborators \n' + self.collaboratorsData.map(getLabel).join('\n');
                        }
                    }
                    self.workspace_id = data.workspace_id;
                    self.created_by_user = data.created_by_user;
                    self.assigned_to_user = data.assigned_to_user;
                    self.account = data.account;
                    self.title = data.title;
                    self.sort_key = data.sort_key;
                    self.priority_level = data.priority_level;
                    self.stage_id = data.stage_id;
                    self.type_id = data.type_id;
                    self.description = data.description;
                    self.due_when = data.due_when;
                    self.created_at = data.created_at;
                    self.id = data.id;
                    self.location = LOCATION + '/' + data.id;
                    self.etag = data.etag ? data.etag : null;
                    self.media_type = data.media_type;
                    self.play = data.play;
                    self.original_playbook_stage_id = data.original_playbook_stage_id;
                    self.original_playbook_id = data.original_playbook_id;
                    self.collaborator_user_ids = data.collaborator_user_ids || [];
                    self.collaboratorsData = [];
                    self.time_spent = data.time_spent || 0;
                    self.tags = data.tags || [];
                    self.progress = $utls.progress(self.due_when, self.created_at);
                    self.external_ids = data.external_ids || [];
                    self.participant = data.participant;
                    self.flagged = data.flagged;
                    if (data.completed_at) {
                        self.completed_at = new Date(data.completed_at);
                        self.is_completed = true;
                    } else {
                        self.is_completed = false;
                        delete self.completed_at;
                    }
                    if (data.archived_at) {
                        self.archived_at = new Date(data.archived_at);
                        self.is_archived = true;
                    } else {
                        self.is_archived = false;
                        delete self.archived_at;
                    }
                    if (factory._userList !== null) {
                        if (self.created_by_user) {
                            setCreator();
                        }
                        if (self.assigned_to_user) {
                            setAssignee();
                        }
                        if (self.collaborator_user_ids.length) {
                            setCollabs();
                        }
                    }
                    self.setTimeSegments();
                };
                Workitem_Model.prototype.getEtag = function () {
                    var self = this, etagDefer = $q.defer();
                    $ay_requester.GET(self.location).then(function (results) {
                        if (!results.error) {
                            self.etag = results.data.etag;
                            results.etag = self.etag;
                        }
                        etagDefer.resolve(results);
                    });
                    return etagDefer.promise;
                };
                Workitem_Model.prototype.save = function () {
                    var self = this, data = angular.copy(self), saveDefer = $q.defer();
                    if (data.account && data.account.removeEmptyListObjects) {
                        data.account.roles = data.account.removeEmptyListObjects(data.account.roles, 'user_id');
                    }
                    var validation_res = $schemaValidator.validate(data, factory.schema);
                    self.getEtag().then(function (results) {
                        if (results.error) {
                            saveDefer.resolve(results);
                        } else {
                            if (validation_res.error) {
                                saveDefer.resolve(validation_res);
                            } else {
                                var hasLocal = !validation_res.data.description && self.description ? true : false, hasResult = validation_res.data.description && !self.description ? true : false, hasBoth = validation_res.data.description && self.description ? true : false;
                                if ((hasLocal || hasResult || hasBoth) && !angular.equals(validation_res.data.description, self.description)) {
                                    $log.error({
                                        error: { message: 'Task: unexpected description change' },
                                        taskId: self.id,
                                        descriptionValidated: validation_res.data.description,
                                        descriptionLocal: angular.copy(self.description)
                                    });
                                    validation_res.data.description = self.description;
                                }
                                $ay_requester.PUT(self.location, {
                                    etag: results.etag,
                                    data: validation_res.data
                                }).then(function complete_request(results) {
                                    if (!results.error) {
                                        self.set(results.data);
                                        results.data = self;
                                    }
                                    saveDefer.resolve(results);
                                });
                            }
                        }
                    });
                    return saveDefer.promise;
                };
                Workitem_Model.prototype.update = function (fields) {
                    function createTargets(item, index) {
                        var target = fields[index], change = {
                                op: target.op ? target.op : 'replace',
                                path: '/' + target.field.replace(/^_/g, '').replace(/(\[\")|(\.)/g, '/').replace(/(\"\])/g, '')
                            };
                        if ((change.op === 'replace' || change.op === 'add' || change.op === 'test') && target.value === undefined) {
                            change.value = s[target.field];
                        } else {
                            change.value = target.value;
                        }
                        return change;
                    }
                    var s = this, updateDefer = $q.defer(), params = {
                            fields: fields,
                            media_type: s.media_type,
                            data: Array.apply(null, new Array(fields.length)).map(createTargets)
                        };
                    $ay_requester.PATCH(s.location, params).then(function (results) {
                        if (!results.error) {
                            s.set(s);
                        }
                        updateDefer.resolve(results);
                    });
                    return updateDefer.promise;
                };
                Workitem_Model.prototype.getStage = function (stageId) {
                    var self = this, index = $ME.workspace.work_item_stages._index_of_by_prop('id', angular.isNumber(stageId) ? stageId : self.stage_id);
                    if (index !== -1) {
                        return $ME.workspace.work_item_stages[index];
                    }
                };
                Workitem_Model.prototype.changeStage = function (stageId, changes) {
                    var self = this, changeDefer = $q.defer(), curStage = self.getStage(), newStage = self.getStage(stageId);
                    changes = changes || [];
                    if (!newStage || !curStage) {
                        $timeout(function () {
                            changeDefer.resolve({ error: errorDetection(1005) });
                        }, 1);
                    } else {
                        if (curStage.completed) {
                            changes.push({
                                field: 'completed_at',
                                op: 'remove'
                            });
                            delete self.completed_at;
                        } else if (newStage.completed) {
                            changes.push({
                                field: 'completed_at',
                                op: 'add'
                            });
                            self.completed_at = new Date()._format_ISO_local();
                        }
                        self.stage_id = stageId;
                        changes.push({ field: 'stage_id' });
                        self.update(changes).then(changeDefer.resolve);
                    }
                    return changeDefer.promise;
                };
                Workitem_Model.prototype.archive = function archive_task() {
                    var self = this;
                    self.archived_at = new Date()._format_ISO_local();
                    return self.save();
                };
                Workitem_Model.prototype.restore = function restore_task() {
                    var self = this;
                    delete self.archived_at;
                    return self.save();
                };
                Workitem_Model.prototype.complete = function complete_task() {
                    var i, self = this, stages = $ME.workspace.get_valid_items('work_item_stages');
                    for (i = stages.length - 1; i > -1; i--) {
                        if (stages[i].completed) {
                            self.completed_at = new Date();
                            self.stage_id = stages[i].id;
                            break;
                        }
                    }
                    return self.save();
                };
                Workitem_Model.prototype.restart = function restart_task() {
                    var i, self = this, stages = $ME.workspace.get_valid_items('work_item_stages');
                    for (i = 0; i < stages.length; i++) {
                        if (stages[i].started && !stages[i].completed) {
                            self.stage_id = stages[i].id;
                            break;
                        }
                    }
                    delete self.completed_at;
                    return self.save();
                };
                Workitem_Model.prototype.delete_task = function delete_task() {
                    var self = this;
                    return $ay_requester.DELETE(self.location, {});
                };
                factory._buildOptions = function (params) {
                    var options = {
                        f: [],
                        s: []
                    };
                    if (params.hasOwnProperty('filters')) {
                        options.f = options.f.concat(params.filters);
                    }
                    if (params.hasOwnProperty('sorts')) {
                        options.s = options.s.concat(params.sorts);
                    } else {
                        if (!params.hasOwnProperty('is_archived')) {
                            options.s.push({
                                field: '/created_at',
                                descending: true
                            });
                        } else {
                            options.s.push({
                                field: '/archived_at',
                                descending: true
                            });
                        }
                    }
                    if (params.hasOwnProperty('account_id')) {
                        options.f.push({
                            field: '/account/id',
                            op: 'EQ',
                            value: params.account_id
                        });
                    }
                    if (params.hasOwnProperty('play_id')) {
                        options.f.push({
                            field: '/play/id',
                            op: 'EQ',
                            value: params.play_id
                        });
                    }
                    if (params.hasOwnProperty('playbook_id')) {
                        options.f.push({
                            field: '/play/playbook_id',
                            op: 'EQ',
                            value: params.playbook_id
                        });
                        options.s.push({
                            field: '/play/name',
                            descending: true
                        });
                    }
                    if (params.hasOwnProperty('person_id')) {
                        options.f.push({
                            field: '/participant/id',
                            op: 'EQ',
                            value: params.person_id
                        });
                    }
                    if (params.hasOwnProperty('in_progress')) {
                        options.f.push({
                            field: '/completed_at',
                            op: 'EXISTS',
                            value: !params.in_progress
                        });
                        if (!params.hasOwnProperty('is_archived') && !params.hasOwnProperty('include_archived')) {
                            params.is_archived = false;
                        }
                    }
                    if (params.hasOwnProperty('filter_input')) {
                        options.f.push({
                            op: 'OR',
                            value: [
                                [{
                                        field: '/title',
                                        op: 'CONTAINS',
                                        value: params.filter_input
                                    }],
                                [{
                                        field: '/account/name',
                                        op: 'CONTAINS',
                                        value: params.filter_input
                                    }]
                            ]
                        });
                    }
                    if (params.hasOwnProperty('type')) {
                        options.f.push({
                            field: '/type_id',
                            op: 'EQ',
                            value: params.type
                        });
                    }
                    if (params.include_archived) {
                        if (params.hasOwnProperty('is_archived')) {
                            options.f.push({
                                field: '/archived_at',
                                op: 'EXISTS',
                                value: params.is_archived
                            });
                        }
                    }
                    return options;
                };
                factory._refreshUserList = function () {
                    var self = this, userDefer = $q.defer();
                    function userLookup(data) {
                        if (data && data.length) {
                            self._userList = {};
                            for (var i = 0, ii = data.length; i < ii; i++) {
                                self._userList[data[i].id] = data[i];
                            }
                        }
                    }
                    if (self._userList !== null) {
                        $timeout(function () {
                            userDefer.resolve(true);
                        }, 1);
                    } else {
                        restFactoryUser.get({ drain: true }).then(function (results) {
                            if (!results.error) {
                                userLookup(results.data);
                            }
                            userDefer.resolve(results);
                        });
                    }
                    return userDefer.promise;
                };
                factory.getSchema = function get_entity_schema() {
                    var s = this, schemaDefer = $q.defer();
                    function setEntitySchema(results) {
                        if (!results.error) {
                            s.schema = results.data;
                            schemaDefer.resolve(s.schema);
                        } else {
                            schemaDefer.resolve(results);
                        }
                    }
                    if (s.schema) {
                        $timeout(function () {
                            schemaDefer.resolve(s.schema);
                        }, 1);
                    } else {
                        $ay_requester.GET('schema/' + LOCATION).then(setEntitySchema);
                    }
                    return schemaDefer.promise;
                };
                factory.newModel = function (data) {
                    return new Workitem_Model(data);
                };
                factory.get = function (params) {
                    var getPromise, drainPromise, self = this, cancelled = false, getDefer = $q.defer(), location = LOCATION, models = [];
                    function setModels(results) {
                        if (!cancelled) {
                            if (results.error) {
                                getDefer.resolve(results);
                            } else {
                                if (!results.data.hasOwnProperty('items')) {
                                    results.data = self.newModel(results.data);
                                    getDefer.resolve(results);
                                } else {
                                    models = models.concat(results.data.items.map(self.newModel));
                                    results.data = models;
                                    if (!params.drain) {
                                        getDefer.resolve(results);
                                    } else {
                                        if (!results.cursor) {
                                            getDefer.resolve(results);
                                        } else {
                                            params.cursor = results.cursor;
                                            drainPromise = $ay_requester.GET(location, params);
                                            drainPromise.then(setModels);
                                        }
                                    }
                                }
                            }
                        }
                    }
                    self.getSchema().then(function (results) {
                        params = params || {};
                        if (!cancelled) {
                            if (params.id) {
                                location += '/' + params.id;
                            } else {
                                params.options = self._buildOptions(params);
                            }
                            self._refreshUserList().then(function (results) {
                                getPromise = $ay_requester.GET(location, params);
                                getPromise.then(setModels);
                            });
                        }
                    });
                    getDefer.promise.cancel = function () {
                        cancelled = true;
                        if (getPromise && getPromise.hasOwnProperty('cancel')) {
                            getPromise.cancel();
                        }
                        if (drainPromise && drainPromise.hasOwnProperty('cancel')) {
                            drainPromise.cancel();
                        }
                        getDefer.resolve({
                            cancelled: true,
                            error: errorDetection('-1')
                        });
                    };
                    return getDefer.promise;
                };
                factory.create = function (data) {
                    var self = this, createDefer = $q.defer();
                    function post() {
                        data.media_type = factory.schema.description;
                        delete data.id;
                        delete data.created_at;
                        delete data.etag;
                        if (!data.type_id) {
                            delete data.type_id;
                        }
                        if (!data.due_when) {
                            delete data.due_when;
                        } else {
                            data.due_when = new Date(data.due_when)._format_ISO_local();
                        }
                        if (!data.archived_at) {
                            delete data.archived_at;
                        } else {
                            data.archived_at = new Date(data.archived_at)._format_ISO_local();
                        }
                        if (!data.play) {
                            delete data.play;
                        }
                        if (data.account && data.account.removeEmptyListObjects) {
                            data.account.roles = data.account.removeEmptyListObjects(data.account.roles, 'user_id');
                        }
                        var validation_res = $schemaValidator.validate(data, factory.schema);
                        if (validation_res.error) {
                            $timeout(function () {
                                createDefer.resolve(validation_res);
                            }, 1);
                        } else {
                            $ay_requester.POST(LOCATION, { data: validation_res.data }).then(function (results) {
                                if (!results.error) {
                                    results.data.isNew = true;
                                    results.data = new Workitem_Model(results.data);
                                }
                                createDefer.resolve(results);
                            });
                        }
                    }
                    self.getSchema().then(post);
                    return createDefer.promise;
                };
                factory.new = function (data) {
                    var new_defer = $q.defer();
                    factory.getSchema().then(function () {
                        var task = new Workitem_Model(data);
                        new_defer.resolve(task);
                    });
                    return new_defer.promise;
                };
                return factory;
            }
        ]
    };
});