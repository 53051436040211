define('modules/rest/factories/insight', [], function () {
    'use strict';
    return {
        component: 'service',
        name: 'modelInsight',
        fn: [
            'global_utilities',
            '$q',
            '$ay_requester',
            '$schemaValidator',
            '$log',
            '$timeout',
            function insight_factory(global_utilities, $q, $ay_requester, $schemaValidator, $log, $timeout) {
                var factory = {
                    schema: null,
                    location: '',
                    media_type: 'application/vnd.amity.hub.insight-defs+json'
                };
                function RULE_ACTION(data) {
                    var rule_action = {
                        type: '',
                        recipients: [],
                        title: '',
                        assignee: {
                            id: '',
                            type: ''
                        },
                        playbook_id: null,
                        tags: [],
                        description: '',
                        due_in: null,
                        assigned_to_user_id: '',
                        priority_level: '',
                        risk_level: '',
                        stage_id: null,
                        tier_id: null,
                        type_id: null,
                        subject: '',
                        html_body: '',
                        region_id: '',
                        sender: {
                            id: '',
                            type: ''
                        },
                        external_id: '',
                        system_type: '',
                        destination: '',
                        participants: '',
                        throttle_type: ''
                    };
                    rule_action.set = function set_action(data) {
                        if (data.id) {
                            this.id = data.id;
                        }
                        this.type = data.type ? data.type : 'add_account_tags';
                        this.recipients = data.recipients ? data.recipients : [];
                        this.title = data.title ? data.title : '';
                        this.assignee = data.assignee ? data.assignee : {
                            id: '',
                            type: ''
                        };
                        this.playbook_id = data.playbook_id;
                        this.tags = data.tags ? data.tags : [];
                        this.description = data.description ? data.description : '';
                        this.due_in = data.due_in;
                        this.assigned_to_user_id = data.assigned_to_user_id;
                        this.priority_level = data.priority_level;
                        this.risk_level = data.risk_level;
                        this.stage_id = data.stage_id;
                        this.tier_id = data.tier_id;
                        this.type_id = data.type_id;
                        this.subject = data.subject ? data.subject : '';
                        this.html_body = data.html_body ? data.html_body : '';
                        this.region_id = data.region_id;
                        this.sender = data.sender ? data.sender : {
                            id: '',
                            type: ''
                        };
                        this.external_id = data.external_id;
                        this.system_type = data.system_type;
                        this.destination = data.destination;
                        this.participants = data.participants;
                        this.update_last_engagement = data.update_last_engagement;
                        this.throttle_type = data.throttle_type;
                    };
                    if (data) {
                        rule_action.set(data);
                    }
                    return rule_action;
                }
                function RULE(data) {
                    var rule = {
                        name: '',
                        enabled: true,
                        actions: [],
                        description: '',
                        trigger: '',
                        insights_all: [],
                        insights_any: [],
                        account_filters: [],
                        trigger_entity_filters: [],
                        sort_order: 0,
                        throttle: null
                    };
                    rule.set = function set_rule(data) {
                        this.name = data.name || '';
                        this.enabled = data.hasOwnProperty('enabled') ? data.enabled : true;
                        this.actions = data.actions || [];
                        this.description = data.description || '';
                        this.trigger = data.trigger || '';
                        this.insights_all = data.insights_all || [];
                        this.insights_any = data.insights_any || [];
                        this.account_filters = data.account_filters || [];
                        this.trigger_entity_filters = data.trigger_entity_filters || [];
                        this.sort_order = data.sort_order || 0;
                        this.throttle = data.throttle;
                        if (data.hasOwnProperty('id')) {
                            this.id = data.id;
                        }
                    };
                    if (data) {
                        rule.set(data);
                    }
                    return rule;
                }
                function INSIGHT(data) {
                    var insight = {
                        id: null,
                        type: '',
                        parent_type: '',
                        child_type: '',
                        threshold: null,
                        value: null,
                        name: '',
                        enabled: false,
                        health_score: null,
                        condition_type: null,
                        level: '',
                        frecency: '',
                        ptr: '',
                        ptr_first: '',
                        ptr_second: '',
                        ptr_third: '',
                        trend: '',
                        editable: true,
                        description: '',
                        evaluator: null,
                        formula: null,
                        isFormula: false,
                        isManual: false,
                        formula_parameters: null
                    };
                    insight.set = function set_insight(data) {
                        var types = data.type ? data.type.split('.') : ['unassigned'];
                        this.id = data.id;
                        this.type = data.type;
                        this.name = data.name;
                        this.enabled = data.enabled;
                        this.threshold = data.threshold;
                        this.value = data.value;
                        this.health_score = data.health_score;
                        this.level = data.health_score && data.health_score.level !== 'highest' ? data.health_score.level : 'unassigned';
                        this.condition_type = data.condition_type;
                        this.parent_type = types[0] === 'numeric' ? 'number' : types[0];
                        this.child_type = types[1] ? types[1].replace('_', ' ') : '';
                        this.frecency = data.frecency;
                        this.ptr = data.ptr;
                        this.ptr_first = data.ptr_first;
                        this.ptr_second = data.ptr_second;
                        this.ptr_third = data.ptr_third;
                        this.trend = data.trend;
                        this.editable = data.editable;
                        this.description = data.description;
                        this.evaluator = data.evaluator;
                        this.formula = data.formula || '';
                        this.isFormula = false;
                        this.isManual = false;
                        this.formula_parameters = data.formula_parameters || [];
                        switch (this.type) {
                        case 'custom_formula':
                            this.isFormula = true;
                            break;
                        case 'manual':
                            this.isManual = true;
                            break;
                        }
                    };
                    if (data) {
                        insight.set(data);
                    }
                    return insight;
                }
                function HEALTH_SCORE(data, health_enums) {
                    var health_score = {
                        level: null,
                        color: '',
                        enabled: false,
                        name: '',
                        description: '',
                        assignments: [],
                        enum_level: -1
                    };
                    health_score.remove_insight = function remove_insight(id) {
                        var pos = this.assignments.indexOf(id), updated = false;
                        if (pos !== -1) {
                            this.assignments.splice(pos, 1);
                            updated = true;
                        } else {
                            $log.error({
                                error: {
                                    message: 'Unable to remove insight from health score:: insight not found.',
                                    data: {
                                        insight_id: id,
                                        health_score: this
                                    }
                                }
                            });
                        }
                        return updated;
                    };
                    health_score.add_insight = function add_insight(id) {
                        var pos = this.assignments.indexOf(id), updated = false;
                        if (pos === -1) {
                            this.assignments.push(id);
                            updated = true;
                        } else {
                            $log.error({
                                error: {
                                    message: 'Unable to add insight to health score:: insight already exists',
                                    data: {
                                        insight_id: id,
                                        health_score: this
                                    }
                                }
                            });
                        }
                        return updated;
                    };
                    health_score.set = function set_heath_score(data) {
                        this.level = data.level;
                        this.color = data.color;
                        this.enabled = data.enabled;
                        this.name = data.name;
                        this.description = data.description;
                        this.assignments = this.enabled && this.level !== 'highest' ? data.assignments ? data.assignments : [] : [];
                        this.enum_level = health_enums[this.level];
                    };
                    if (data) {
                        health_score.set(data);
                    }
                    return health_score;
                }
                function Insight_Model(data) {
                    var self = this;
                    self.etag = null;
                    self.workspace_id = null;
                    self.insight_defs = [];
                    self.health_defs = [];
                    self.rules = [];
                    self.media_type = null;
                    self.health_enums = {
                        highest: 0,
                        high: 1,
                        medium: 2,
                        low: 3,
                        lowest: 4
                    };
                    self.condition_defs = [
                        {
                            type: 'assignment.unassigned_account',
                            name: 'Account - Unassigned',
                            label: 'Days',
                            description: 'The account has been unassigned for {{threshold}} days.',
                            default_value: 7,
                            editable: true,
                            creatable: true,
                            valueField: 'threshold',
                            trigger: 'account',
                            isMetric: true,
                            ptrCount: 0,
                            method: 'threshold'
                        },
                        {
                            type: 'billing.current_balance',
                            name: 'Account - Current Balance',
                            label: 'Value',
                            description: 'The account\'s current balance is greater than {{threshold}}.',
                            default_value: 500,
                            editable: true,
                            creatable: true,
                            valueField: 'threshold',
                            trigger: 'account',
                            isMetric: true,
                            ptrCount: 0,
                            method: 'threshold'
                        },
                        {
                            type: 'billing.overdue_payment',
                            name: 'Account - Overdue Payment',
                            label: 'Days overdue',
                            description: 'The account\'s last invoice payment is overdue more than {{threshold}} days.',
                            default_value: 30,
                            editable: true,
                            creatable: true,
                            valueField: 'threshold',
                            trigger: 'account',
                            isMetric: true,
                            ptrCount: 0,
                            method: 'threshold'
                        },
                        {
                            type: 'risk.level',
                            name: 'Account - Risk Level',
                            label: 'Level',
                            description: 'The account risk level is {{threshold}}.',
                            default_value: 'high',
                            editable: true,
                            creatable: true,
                            valueField: 'threshold',
                            trigger: 'account',
                            isMetric: true,
                            ptrCount: 0,
                            method: 'threshold'
                        },
                        {
                            type: 'activity.days_since',
                            name: 'Activity - No Activity',
                            label: 'Days since',
                            description: 'There has been no activity in over {{threshold}} days.',
                            default_value: 14,
                            editable: true,
                            creatable: true,
                            valueField: 'threshold',
                            trigger: 'activity',
                            isMetric: true,
                            ptrCount: 0,
                            method: 'threshold'
                        },
                        {
                            type: 'engagement.days_since',
                            name: 'Engagement - No Engagement',
                            label: 'Days since',
                            description: 'The account has not been engaged in over {{threshold}} days.',
                            default_value: 60,
                            editable: true,
                            creatable: true,
                            valueField: 'threshold',
                            trigger: 'engagement',
                            isMetric: true,
                            ptrCount: 0,
                            method: 'threshold'
                        },
                        {
                            type: 'subscription.days_remaining',
                            name: 'Subscription - Expires Soon',
                            label: 'Days remaining',
                            description: 'A subscription will expire in less than {{threshold}} days.',
                            default_value: 90,
                            editable: true,
                            creatable: true,
                            valueField: 'threshold',
                            trigger: 'subscription',
                            isMetric: true,
                            ptrCount: 0,
                            method: 'threshold'
                        },
                        {
                            type: 'numeric.eq',
                            name: 'Account - Numeric Equal To',
                            label: 'Value',
                            description: 'Value is equal to {{value}}.',
                            default_value: 0,
                            editable: true,
                            creatable: false,
                            valueField: 'value',
                            ptrCount: 1,
                            trigger: 'account',
                            method: 'threshold'
                        },
                        {
                            type: 'numeric.exists',
                            name: 'Account - Numeric Exists',
                            label: 'Value',
                            description: 'Property has a value is {{threshold}}.',
                            default_value: true,
                            editable: true,
                            creatable: false,
                            valueField: 'threshold',
                            ptrCount: 1,
                            trigger: 'account',
                            method: 'threshold'
                        },
                        {
                            type: 'numeric.lt',
                            name: 'Account - Numeric Less Than',
                            label: 'Value',
                            description: 'Value is less than {{threshold}}.',
                            default_value: 0,
                            editable: true,
                            creatable: false,
                            valueField: 'threshold',
                            ptrCount: 1,
                            trigger: 'account',
                            method: 'threshold'
                        },
                        {
                            type: 'numeric.gt',
                            name: 'Account - Numeric Greater Than',
                            label: 'Value',
                            description: 'Value is greater than {{threshold}}.',
                            default_value: 0,
                            editable: true,
                            creatable: false,
                            valueField: 'threshold',
                            ptrCount: 1,
                            trigger: 'account',
                            method: 'threshold'
                        },
                        {
                            type: 'numeric.diff_eq',
                            name: 'Account - Numeric Difference Equal To',
                            label: 'Value',
                            description: 'The difference is equal to {{value}}.',
                            default_value: 0,
                            editable: true,
                            creatable: false,
                            valueField: 'value',
                            ptrCount: 2,
                            trigger: 'account',
                            method: 'difference'
                        },
                        {
                            type: 'numeric.diff_lt',
                            name: 'Account - Numeric Difference Less Than',
                            label: 'Value',
                            description: 'The difference is less than {{threshold}}.',
                            default_value: 0,
                            editable: true,
                            creatable: false,
                            valueField: 'threshold',
                            ptrCount: 2,
                            trigger: 'account',
                            method: 'difference'
                        },
                        {
                            type: 'numeric.diff_gt',
                            name: 'Account - Numeric Difference Greater Than',
                            label: 'Value',
                            description: 'The difference is greater than {{threshold}}.',
                            default_value: 0,
                            editable: true,
                            creatable: false,
                            valueField: 'threshold',
                            ptrCount: 2,
                            trigger: 'account',
                            method: 'difference'
                        },
                        {
                            type: 'numeric.ratio_eq',
                            name: 'Account - Numeric Ratio Equal To',
                            label: 'Value',
                            description: 'The ratio is equal to {{value}}.',
                            default_value: 0,
                            editable: true,
                            creatable: false,
                            valueField: 'value',
                            ptrCount: 2,
                            trigger: 'account',
                            method: 'ratio'
                        },
                        {
                            type: 'numeric.ratio_lt',
                            name: 'Account - Numeric Ratio Less Than',
                            label: 'Value',
                            description: 'The ratio is less than {{threshold}}.',
                            default_value: 0,
                            editable: true,
                            creatable: false,
                            valueField: 'threshold',
                            ptrCount: 2,
                            trigger: 'account',
                            method: 'ratio'
                        },
                        {
                            type: 'numeric.ratio_gt',
                            name: 'Account - Numeric Ratio Greater Than',
                            label: 'Value',
                            description: 'The ratio is greater than {{threshold}}.',
                            default_value: 0,
                            editable: true,
                            creatable: false,
                            valueField: 'threshold',
                            ptrCount: 2,
                            trigger: 'account',
                            method: 'ratio'
                        },
                        {
                            type: 'numeric.diff_ratio_eq',
                            name: 'Account - Numeric Difference Ratio Equal To',
                            label: 'Value',
                            description: 'The ratio of the difference is equal to {{value}}.',
                            default_value: 0,
                            editable: true,
                            creatable: false,
                            valueField: 'value',
                            ptrCount: 3,
                            trigger: 'account',
                            method: 'differenceRatio'
                        },
                        {
                            type: 'numeric.diff_ratio_lt',
                            name: 'Account - Numeric Difference Ratio Less Than',
                            label: 'Value',
                            description: 'The ratio of the difference is less than {{threshold}}.',
                            default_value: 0,
                            editable: true,
                            creatable: false,
                            valueField: 'threshold',
                            ptrCount: 3,
                            trigger: 'account',
                            method: 'differenceRatio'
                        },
                        {
                            type: 'numeric.diff_ratio_gt',
                            name: 'Account - Numeric Difference Ratio Greater',
                            label: 'Value',
                            description: 'The ratio of the difference is greater than {{threshold}}.',
                            default_value: 0,
                            editable: true,
                            creatable: false,
                            valueField: 'threshold',
                            ptrCount: 3,
                            trigger: 'account',
                            method: 'differenceRatio'
                        },
                        {
                            type: 'datetime.more_than_days_ago',
                            name: 'Account - More Than # Days Ago',
                            label: 'Days',
                            description: 'More than {{threshold}} days ago.',
                            default_value: 0,
                            editable: true,
                            creatable: false,
                            valueField: 'threshold',
                            ptrCount: 1,
                            trigger: 'account',
                            method: 'threshold'
                        },
                        {
                            type: 'datetime.next_days',
                            name: 'Account - Next # Days',
                            label: 'Days',
                            description: 'Next {{threshold}} days.',
                            default_value: 0,
                            editable: true,
                            creatable: false,
                            valueField: 'threshold',
                            ptrCount: 1,
                            trigger: 'account',
                            method: 'threshold'
                        },
                        {
                            type: 'datetime.last_days',
                            name: 'Account - Last # Days',
                            label: 'Days',
                            description: 'Last {{threshold}} days.',
                            default_value: 0,
                            editable: true,
                            creatable: false,
                            valueField: 'threshold',
                            ptrCount: 1,
                            trigger: 'account',
                            method: 'threshold'
                        },
                        {
                            type: 'datetime.diff_lt',
                            name: 'Account - Datetime Difference Less Than',
                            label: 'Days',
                            description: 'The difference is less than {{threshold}} days.',
                            default_value: 0,
                            editable: true,
                            creatable: false,
                            valueField: 'threshold',
                            ptrCount: 2,
                            trigger: 'account',
                            method: 'difference'
                        },
                        {
                            type: 'datetime.diff_gt',
                            name: 'Account - Datetime Difference Greater Than',
                            label: 'Days',
                            description: 'The difference is greater than {{threshold}} days.',
                            default_value: 0,
                            editable: true,
                            creatable: false,
                            valueField: 'threshold',
                            ptrCount: 2,
                            trigger: 'account',
                            method: 'difference'
                        },
                        {
                            type: 'datetime.exists',
                            name: 'Account - Datetime Exists',
                            label: 'Value',
                            description: 'Property has a value is {{threshold}}.',
                            default_value: true,
                            editable: true,
                            creatable: false,
                            valueField: 'threshold',
                            ptrCount: 1,
                            trigger: 'account',
                            method: 'threshold'
                        },
                        {
                            type: 'string.eq',
                            name: 'Account - String Equal To',
                            label: 'Value',
                            description: 'The value is equal to "{{value}}".',
                            default_value: '',
                            editable: true,
                            creatable: false,
                            valueField: 'value',
                            ptrCount: 1,
                            trigger: 'account',
                            method: 'threshold'
                        },
                        {
                            type: 'string.exists',
                            name: 'Account - String Exists',
                            label: 'Value',
                            description: 'Property has a value is {{threshold}}.',
                            default_value: true,
                            editable: true,
                            creatable: false,
                            valueField: 'threshold',
                            ptrCount: 1,
                            trigger: 'account',
                            method: 'threshold'
                        },
                        {
                            type: 'string.has_a_value',
                            name: 'Account - String Has A Value',
                            label: 'Value',
                            description: 'Property has a value is {{threshold}}.',
                            default_value: true,
                            editable: true,
                            creatable: false,
                            valueField: 'threshold',
                            ptrCount: 1,
                            trigger: 'account',
                            method: 'threshold'
                        },
                        {
                            type: 'string.ne',
                            name: 'Account - String Not Equal To',
                            label: 'Value',
                            description: 'The value is not equal to "{{value}}".',
                            default_value: '',
                            editable: true,
                            creatable: false,
                            valueField: 'value',
                            ptrCount: 1,
                            trigger: 'account',
                            method: 'threshold'
                        },
                        {
                            type: 'manual',
                            name: 'Manual',
                            description: 'An insight that must be applied to an account manually.',
                            editable: true,
                            creatable: true,
                            trigger: 'manual'
                        },
                        {
                            type: 'custom_formula',
                            name: 'Formula',
                            description: 'An insight that is applied based on a custom formula.',
                            editable: true,
                            creatable: true,
                            trigger: 'custom_formula'
                        }
                    ];
                    self.rule_actions = [
                        {
                            name: 'add_account_tags',
                            label: 'Add account tags'
                        },
                        {
                            name: 'create_play',
                            label: 'Add a play'
                        },
                        {
                            name: 'create_work_item',
                            label: 'Add a task'
                        },
                        {
                            name: 'email_users',
                            label: 'Email team members'
                        },
                        {
                            name: 'email_participants',
                            label: 'Email customers'
                        },
                        {
                            name: 'remove_account_tags',
                            label: 'Remove account tags'
                        },
                        {
                            name: 'notify_users',
                            label: 'Send an alert'
                        },
                        {
                            name: 'set_account_properties',
                            label: 'Set account properties'
                        },
                        {
                            name: 'set_account_tags',
                            label: 'Set account tags'
                        },
                        {
                            name: 'send_external_target',
                            label: 'Send to an external target'
                        }
                    ];
                    self.rule_triggers = [
                        {
                            type: 'new_account',
                            description: 'New account created',
                            entity_type: 'account'
                        },
                        {
                            type: 'new_subscription',
                            description: 'New subscription created',
                            entity_type: 'subscription'
                        },
                        {
                            type: 'new_ticket',
                            description: 'New ticket created',
                            entity_type: 'ticket'
                        },
                        {
                            type: 'account_assigned',
                            description: 'Account assigned',
                            entity_type: 'account'
                        },
                        {
                            type: 'account_health_changed',
                            description: 'Account health score changed',
                            entity_type: 'account'
                        },
                        {
                            type: 'account_health_declined',
                            description: 'Account health score declined',
                            entity_type: 'account'
                        },
                        {
                            type: 'account_health_improved',
                            description: 'Account health score improved',
                            entity_type: 'account'
                        },
                        {
                            type: 'account_priority_changed',
                            description: 'Account priority level changed',
                            entity_type: 'account'
                        },
                        {
                            type: 'account_priority_increased',
                            description: 'Account priority level increased',
                            entity_type: 'account'
                        },
                        {
                            type: 'account_risk_changed',
                            description: 'Account risk level changed',
                            entity_type: 'account'
                        },
                        {
                            type: 'account_risk_increased',
                            description: 'Account risk level increased',
                            entity_type: 'account'
                        },
                        {
                            type: 'account_stage_changed',
                            description: 'Account stage changed',
                            entity_type: 'account'
                        },
                        {
                            type: 'account_tier_changed',
                            description: 'Account tier changed',
                            entity_type: 'account'
                        },
                        {
                            type: 'account_type_changed',
                            description: 'Account type changed',
                            entity_type: 'account'
                        },
                        {
                            type: 'subscription_mutated',
                            description: 'Subscription changed',
                            entity_type: 'subscription'
                        }
                    ];
                    if (data) {
                        self.set(data);
                    }
                }
                Insight_Model.prototype.new_insight = function (params) {
                    params = params ? params : {};
                    var insight, condition, self = this;
                    if (!params.hasOwnProperty('id')) {
                        params.id = self.insight_defs._generate_id('id');
                    }
                    condition = self.get_condition_type(params.type);
                    params.editable = condition ? condition.editable : false;
                    insight = new INSIGHT(params);
                    return insight;
                };
                Insight_Model.prototype.get_insight = function (insight_id, return_field) {
                    var self = this, pos = self.insight_defs._index_of_by_prop('id', insight_id);
                    return pos !== -1 ? return_field ? self.insight_defs[pos].hasOwnProperty(return_field) ? self.insight_defs[pos][return_field] : self.insight_defs[pos].name : self.insight_defs[pos] : null;
                };
                Insight_Model.prototype.get_condition_type = function (type) {
                    var self = this, pos = self.condition_defs._index_of_by_prop('type', type);
                    return pos !== -1 ? self.condition_defs[pos] : null;
                };
                Insight_Model.prototype.get_health_score = function (id, return_field, id_field) {
                    id_field = id_field || 'level';
                    var self = this, pos = self.health_defs._index_of_by_prop(id_field, id);
                    return pos !== -1 ? return_field ? self.health_defs[pos].hasOwnProperty(return_field) ? self.health_defs[pos][return_field] : self.health_defs[pos].name : self.health_defs[pos] : null;
                };
                Insight_Model.prototype.get_insight_health_score = function (insight_id, return_field) {
                    var self = this, pos = -1, health_score = null;
                    for (var i = 0; i < self.health_defs.length; i++) {
                        pos = self.health_defs[i].assignments.indexOf(insight_id);
                        if (pos !== -1) {
                            health_score = self.health_defs[i];
                            break;
                        }
                    }
                    return return_field && health_score ? health_score.hasOwnProperty(return_field) ? health_score[return_field] : health_score.name : health_score;
                };
                Insight_Model.prototype.new_rule = function (params) {
                    return new RULE(params);
                };
                Insight_Model.prototype.get_rule = function (pos) {
                    var rule_found = null;
                    if (pos === -1 || pos === null || pos === undefined) {
                        $log.warn('Unable to get insight rule:', { pos: pos });
                    } else {
                        rule_found = this.rules[pos];
                    }
                    return rule_found;
                };
                Insight_Model.prototype.remove_rule = function (name) {
                    var self = this, pos = self.rules._index_of_by_prop('name', name), updated = false;
                    if (pos !== -1) {
                        self.rules.splice(pos, 1);
                        updated = true;
                    } else {
                        $log.error({
                            error: {
                                message: 'Unable to remove insight rule:: rule not found.',
                                data: { name: name }
                            }
                        });
                    }
                    return updated;
                };
                Insight_Model.prototype.get_list_items = function (list_name, sort_by_field, enabled) {
                    var self = this, items = [], i;
                    enabled = enabled !== undefined && enabled !== null ? enabled : true;
                    if (self.hasOwnProperty(list_name) && Array.isArray(self[list_name])) {
                        for (i = 0; i < self[list_name].length; i++) {
                            if (self[list_name][i].enabled === enabled) {
                                items.push(self[list_name][i]);
                            }
                        }
                        if (items.length && sort_by_field) {
                            items.sort_by(sort_by_field);
                        }
                    }
                    return items;
                };
                Insight_Model.prototype.get_insights = function (enabled) {
                    var self = this;
                    return self.get_list_items('insight_defs', 'name', enabled);
                };
                Insight_Model.prototype.get_health_levels = function (enabled, sort_by_field) {
                    sort_by_field = sort_by_field || 'name';
                    var self = this;
                    return self.get_list_items('health_defs', sort_by_field, enabled);
                };
                Insight_Model.prototype.calc_score = function (insights) {
                    var score, self = this;
                    function get_weight(insight_id) {
                        var health_score = self.get_insight_health_score(insight_id);
                        return health_score ? health_score.enum_level : null;
                    }
                    if (insights.length) {
                        score = Math.max.apply(null, insights.map(get_weight));
                        score = self.get_health_score(score, null, 'enum_level');
                    }
                    return score;
                };
                Insight_Model.prototype.set = function (data) {
                    var i = 0, self = this;
                    self.etag = data.etag;
                    self.health_defs = [];
                    self.insight_defs = [];
                    self.rules = [];
                    self.media_type = data.media_type || factory.media_type;
                    if (data.health_defs && data.health_defs.length) {
                        for (i = 0; i < data.health_defs.length; i++) {
                            self.health_defs.push(new HEALTH_SCORE(data.health_defs[i], self.health_enums));
                        }
                    }
                    if (data.insight_defs && data.insight_defs.length) {
                        for (i = 0; i < data.insight_defs.length; i++) {
                            var new_insight_obj = self.new_insight(data.insight_defs[i]), health_score = self.get_insight_health_score(data.insight_defs[i].id);
                            new_insight_obj.condition_type = self.get_condition_type(data.insight_defs[i].type);
                            new_insight_obj.health_score = health_score && health_score.enabled ? health_score : null;
                            new_insight_obj.level = new_insight_obj.health_score ? new_insight_obj.health_score.level : 'unassigned';
                            self.insight_defs.push(new_insight_obj);
                        }
                    }
                    if (data.rules && data.rules.length) {
                        for (i = 0; i < data.rules.length; i++) {
                            data.rules[i].sort_order = i;
                            self.rules.push(self.new_rule(data.rules[i]));
                        }
                    }
                };
                Insight_Model.prototype.update = function (fields) {
                    var patchPromise, self = this, updateDefer = $q.defer(), params = {
                            fields: fields,
                            media_type: self.media_type,
                            data: Array.apply(null, new Array(fields.length)).map(createTargets)
                        };
                    function createTargets(item, index) {
                        var validation, schema, target = fields[index], change = {
                                op: target.op ? target.op : 'replace',
                                path: global_utilities.toRestPointer(target.field)
                            };
                        if (change.op === 'move') {
                            change.from = global_utilities.toRestPointer(target.from);
                        } else {
                            if (target.schemaField) {
                                schema = global_utilities.jsonPointer(factory.schema.properties, target.schemaField);
                            }
                            if (target.hasOwnProperty('value')) {
                                switch (schema.type) {
                                case 'array':
                                    validation = $schemaValidator.validate([target.value], schema);
                                    change.value = validation.data[0];
                                    break;
                                default:
                                    validation = $schemaValidator.validate(target.value, schema);
                                    change.value = validation.data;
                                    break;
                                }
                            } else {
                                if (change.op === 'replace' || change.op === 'add' || change.op === 'test') {
                                    if (!schema) {
                                        change.value = global_utilities.jsonPointer(self, target.field);
                                    } else {
                                        validation = $schemaValidator.validate([target.value], schema);
                                        change.value = validation.data;
                                    }
                                }
                            }
                        }
                        return change;
                    }
                    patchPromise = $ay_requester.PATCH(self.location, params);
                    patchPromise.then(function (results) {
                        if (results.error) {
                            updateDefer.resolve(results);
                        } else {
                            $ay_requester.GET(self.location).then(function (getResults) {
                                if (!getResults.error) {
                                    self.set(getResults.data);
                                    getResults.data = self;
                                }
                                updateDefer.resolve(getResults);
                            });
                        }
                    });
                    return updateDefer.promise;
                };
                Insight_Model.prototype.save = function (params) {
                    var self = this, saveDefer = $q.defer(), validRes = $schemaValidator.validate(self, factory.schema), options = { data: validRes.data };
                    if (validRes.error) {
                        $timeout(function () {
                            options.update = false;
                            options.call_info = params;
                            options.error = validRes.error;
                            saveDefer.resolve(options);
                        });
                    } else {
                        if (!self.etag) {
                            options.upsert = true;
                        } else {
                            options.etag = self.etag;
                        }
                        $ay_requester.PUT(self.location, options).then(function (results) {
                            if (!results.error) {
                                self.set(results.data);
                                results.data = self;
                            }
                            saveDefer.resolve(results);
                        });
                    }
                    return saveDefer.promise;
                };
                factory.getSchema = function get_entity_schema() {
                    var s = this, schemaDefer = $q.defer();
                    function setEntitySchema(results) {
                        if (!results.error) {
                            s.schema = results.data;
                            schemaDefer.resolve(s.schema);
                        } else {
                            schemaDefer.resolve(results);
                        }
                    }
                    if (s.schema) {
                        $timeout(function () {
                            schemaDefer.resolve(s.schema);
                        }, 1);
                    } else {
                        $ay_requester.GET('schema/workspaces/insight').then(setEntitySchema);
                    }
                    return schemaDefer.promise;
                };
                factory.set = function handler_set(results) {
                    if (!results || !results._callback) {
                        return null;
                    }
                    var params = {
                        data: null,
                        type: results.type
                    };
                    if (!results.error) {
                        var insight = new Insight_Model(results.data);
                        insight.location = 'workspaces/' + results.workspaceId + '/insights';
                        factory.location = 'workspaces/' + results.workspaceId + '/insights';
                        params.data = insight;
                    }
                    results._callback(params);
                };
                factory.get = function (params) {
                    var self = this, model_defer = $q.defer();
                    self.location = 'workspaces/' + params.id + '/insight';
                    function get_insight() {
                        $ay_requester.GET(self.location).then(function (results) {
                            if (!results.error) {
                                results.data = new Insight_Model(results.data);
                                results.data.location = self.location;
                            }
                            model_defer.resolve(results);
                        });
                    }
                    if (factory.schema) {
                        get_insight();
                    } else {
                        self.getSchema().then(get_insight);
                    }
                    return model_defer.promise;
                };
                return factory;
            }
        ]
    };
});