define('modules/rest/factories/tickets', [], function () {
    'use strict';
    return {
        component: 'service',
        name: 'modelTicket',
        fn: [
            '$q',
            '$timeout',
            '$ay_requester',
            function ticket_factory($q, $timeout, $ay_requester) {
                var LOCATION = 'tickets', media_type = 'application/vnd.amity.hub.ticket+json';
                function TicketModel(data) {
                    var self = this;
                    self.data_type = 'ticket';
                    self.entity_type = 'ticket';
                    self.id = null;
                    self.etag = null;
                    self.created_at = null;
                    self.workspace_id = null;
                    self.display_id = null;
                    self.account = {};
                    self.external_ids = [];
                    self.updated_at = null;
                    self.external_agent_url = null;
                    self.status = null;
                    self.type = null;
                    self.priority = null;
                    self.channel = null;
                    self.subject = null;
                    self.description = null;
                    self.requester = null;
                    self.assignee = null;
                    self.tags = [];
                    self.resolved_at = null;
                    self.resolved = null;
                    self.resolution_calendar_time = null;
                    self.media_type = null;
                    self.satisfaction_comment = null;
                    self.satisfaction_rating = null;
                    self.satisfaction_rating_scale = null;
                    self.resolutionTime = null;
                    self.tagString = null;
                    self.rating = [];
                    if (data) {
                        self.set(data);
                    }
                }
                TicketModel.prototype.set = function (data) {
                    var self = this;
                    self.id = data.id;
                    self.location = LOCATION + '/' + data.id;
                    self.etag = data.etag;
                    self.created_at = data.created_at ? new Date(data.created_at)._format_ISO_local() : null;
                    self.updated_at = data.updated_at ? new Date(data.updated_at)._format_ISO_local() : null;
                    self.resolved_at = data.resolved_at ? new Date(data.resolved_at)._format_ISO_local() : null;
                    self.resolved = data.resolved || false;
                    self.resolution_calendar_time = data.resolution_calendar_time;
                    self.workspace_id = data.workspace_id;
                    self.display_id = data.display_id;
                    self.account = data.account ? data.account : {};
                    self.external_ids = data.external_ids ? data.external_ids : [];
                    self.external_agent_url = data.external_agent_url;
                    self.status = data.status;
                    self.type = data.type;
                    self.priority = data.priority;
                    self.channel = data.channel;
                    self.subject = data.subject;
                    self.description = data.description;
                    self.requester = data.requester;
                    self.assignee = data.assignee;
                    self.tags = data.tags ? data.tags : [];
                    self.media_type = data.media_type;
                    self.satisfaction_comment = data.satisfaction_comment;
                    self.satisfaction_rating = data.satisfaction_rating;
                    self.satisfaction_rating_scale = data.satisfaction_rating_scale;
                    if (self.tags.length) {
                        self.tagString = self.tags.join(', ');
                    }
                    if (self.resolved_at) {
                        var calTimeHrs = Math.floor(self.resolution_calendar_time / 60), calTimeMins = self.resolution_calendar_time % 60;
                        self.resolutionTime = calTimeHrs + ' hrs';
                        if (calTimeMins) {
                            self.resolutionTime += ' and ' + calTimeMins + 'mins';
                        }
                    }
                    if (self.satisfaction_rating_scale) {
                        self.rating = [];
                        if (self.satisfaction_rating_scale === 2) {
                            self.rating.push(self.satisfaction_rating === 1 ? 'far fa-frown' : 'far fa-smile');
                        } else {
                            for (var i = 0; i < self.satisfaction_rating_scale; i++) {
                                self.rating.push(i < self.satisfaction_rating ? 'fa-star' : 'far fa-star');
                            }
                        }
                    }
                };
                TicketModel.prototype.remove = function () {
                    var self = this;
                    return $ay_requester.DELETE('tickets/' + self.id, {});
                };
                TicketModel.prototype.update = function (fields) {
                    function createTargets(item, index) {
                        var target = fields[index], change = {
                                op: target.op ? target.op : 'replace',
                                path: '/' + target.field.replace(/^_/g, '').replace(/(\[\")|(\.)/g, '/').replace(/(\"\])/g, '')
                            };
                        if ((change.op === 'replace' || change.op === 'add' || change.op === 'test') && target.value === undefined) {
                            change.value = s[target.field];
                        } else {
                            change.value = target.value;
                        }
                        return change;
                    }
                    var s = this, updateDefer = $q.defer(), params = {
                            fields: fields,
                            media_type: s.media_type,
                            data: Array.apply(null, new Array(fields.length)).map(createTargets)
                        };
                    $ay_requester.PATCH(s.location, params).then(function (results) {
                        if (!results.error) {
                            s.set(s);
                        }
                        updateDefer.resolve(results);
                    });
                    return updateDefer.promise;
                };
                function TicketFactory() {
                }
                TicketFactory.prototype.media_type = media_type;
                TicketFactory.prototype.location = LOCATION;
                TicketFactory.prototype.newModel = function (data) {
                    var self = this;
                    return new TicketModel(data, self.schema);
                };
                TicketFactory.prototype._buildOptions = function build_options(_options) {
                    var options = {
                        f: [],
                        s: []
                    };
                    if (_options.hasOwnProperty('filters') && _options.filters && _options.filters.length) {
                        options.f = options.f.concat(_options.filters);
                    }
                    if (_options.account_id) {
                        options.f.push({
                            field: '/account/id',
                            op: 'EQ',
                            value: _options.account_id
                        });
                    }
                    if (_options.participant_id) {
                        options.f.push({
                            field: '/requester/participant/id',
                            op: 'EQ',
                            value: _options.participant_id
                        });
                    }
                    if (_options.hasOwnProperty('open') && _options.open !== null && _options.open !== undefined) {
                        options.f.push({
                            field: '/resolved',
                            op: 'EQ',
                            value: _options.open
                        });
                    }
                    if (_options.hasOwnProperty('date_before')) {
                        options.f.push({
                            field: '/created_at',
                            op: 'LE',
                            value: _options.date_before
                        });
                    }
                    if (_options.hasOwnProperty('date_after')) {
                        options.f.push({
                            field: '/created_at',
                            op: 'GE',
                            value: _options.date_after
                        });
                    }
                    if (_options.sorts) {
                        options.s = angular.copy(_options.sorts);
                    } else {
                        options.s.push({
                            field: '/created_at',
                            descending: _options.hasOwnProperty('desc') ? _options.desc : true
                        });
                    }
                    return options;
                };
                TicketFactory.prototype.getEtag = function () {
                    var self = this, etagDefer = $q.defer();
                    $ay_requester.GET(self.location).then(function (results) {
                        if (!results.error) {
                            self.etag = results.data.etag;
                            results.etag = self.etag;
                        }
                        etagDefer.resolve(results);
                    });
                    return etagDefer.promise;
                };
                TicketFactory.prototype.getSchema = function get_entity_schema() {
                    var s = this, schemaDefer = $q.defer();
                    function setEntitySchema(results) {
                        if (!results.error) {
                            s.schema = results.data;
                            schemaDefer.resolve(s.schema);
                        } else {
                            schemaDefer.resolve(results);
                        }
                    }
                    if (s.schema) {
                        $timeout(function () {
                            schemaDefer.resolve(s.schema);
                        }, 1);
                    } else {
                        $ay_requester.GET('schema/tickets').then(setEntitySchema);
                    }
                    return schemaDefer.promise;
                };
                TicketFactory.prototype.get = function (params) {
                    var drainPromise, self = this, getDefer = $q.defer(), location = LOCATION, models = [];
                    function setModels(results) {
                        if (results.error) {
                            getDefer.resolve(results);
                        } else {
                            if (!results.data.hasOwnProperty('items')) {
                                results.data = self.newModel(results.data);
                                getDefer.resolve(results);
                            } else {
                                models = models.concat(results.data.items.map(function (data) {
                                    return self.newModel(data);
                                }));
                                results.data = models;
                                if (!params.drain) {
                                    getDefer.resolve(results);
                                } else {
                                    if (!results.cursor) {
                                        getDefer.resolve(results);
                                    } else {
                                        params.cursor = results.cursor;
                                        drainPromise = $ay_requester.GET(location, params);
                                        drainPromise.then(setModels);
                                    }
                                }
                            }
                        }
                    }
                    self.getSchema().then(function (results) {
                        params = params || {};
                        if (params.id) {
                            location += '/' + params.id;
                        } else {
                            params.options = self._buildOptions(params);
                        }
                        $ay_requester.GET(location, params).then(setModels);
                    });
                    return getDefer.promise;
                };
                return new TicketFactory();
            }
        ]
    };
});