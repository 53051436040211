define('modules/rest/factories/user', [], function () {
    'use strict';
    return {
        component: 'service',
        name: 'restFactoryUser',
        fn: [
            '$log',
            'errorDetection',
            '$ay_requester',
            '$schemaValidator',
            '$q',
            '$timeout',
            '$utls',
            function ($log, errorDetection, $ay_requester, $schemaValidator, $q, $timeout, $utls) {
                var LOCATION = 'users', factory = {
                        media_type: 'application/vnd.amity.hub.user+json',
                        cache: {
                            users: [],
                            usersDict: {},
                            apiIds: []
                        }
                    };
                function User_Model(data) {
                    this.etag = null;
                    this.media_type = null;
                    this.id = null;
                    this.tenancy_id = null;
                    this.role_ids = null;
                    this.perms = null;
                    this.created_at = null;
                    this.last_login = null;
                    this.login_history = null;
                    this.username = null;
                    this.full_name = null;
                    this.first_name = null;
                    this.last_name = null;
                    this.email = null;
                    this.timezone = null;
                    this.locale = null;
                    this.title = null;
                    this.phone1 = null;
                    this.phone2 = null;
                    this.enabled = false;
                    this.avatar_url = null;
                    this.gravatar_hash = null;
                    this.linkedin_url = null;
                    this.twitter_username = null;
                    this.calendar_url = null;
                    this.other_url = null;
                    if (data) {
                        this.set(data);
                    }
                }
                User_Model.prototype.set = function (data) {
                    this.etag = data.etag;
                    this.media_type = data.media_type || factory.media_type;
                    this.id = data.id;
                    this.tenancy_id = data.tenancy_id;
                    this.role_ids = data.role_ids || [];
                    this.perms = data.perms || [];
                    this.created_at = new Date(data.created_at);
                    this.last_login = data.last_login ? new Date(data.last_login) : null;
                    this.login_history = data.login_history || [];
                    this.username = data.username;
                    this.full_name = data.full_name;
                    this.first_name = data.first_name;
                    this.last_name = data.last_name;
                    this.email = data.email;
                    this.timezone = data.timezone;
                    this.locale = data.locale;
                    this.title = data.title;
                    this.phone1 = data.phone1;
                    this.phone2 = data.phone2;
                    this.avatar_url = data.avatar_url;
                    this.gravatar_hash = data.gravatar_hash;
                    this.linkedin_url = data.linkedin_url;
                    this.twitter_username = data.twitter_username;
                    this.calendar_url = data.calendar_url;
                    this.other_url = data.other_url;
                    if (this.perms.indexOf('CorePermission.Login') !== -1) {
                        this.enabled = true;
                    }
                    this.location = LOCATION + '/' + data.id;
                };
                User_Model.prototype.getEtag = function () {
                    var self = this, etagDefer = $q.defer();
                    if (self.etag) {
                        $timeout(function () {
                            etagDefer.resolve({ etag: self.etag });
                        }, 1);
                    } else {
                        $ay_requester.GET(self.location).then(function (results) {
                            if (!results.error) {
                                self.etag = results.data.etag;
                                results.etag = self.etag;
                            }
                            etagDefer.resolve(results);
                        });
                    }
                    return etagDefer.promise;
                };
                User_Model.prototype.save = function (params) {
                    var self = this, saveDefer = $q.defer(), validationRes = $schemaValidator.validate(self, factory.schema);
                    self.getEtag().then(function (results) {
                        if (results.error) {
                            saveDefer.resolve(results);
                        } else {
                            if (validationRes.error) {
                                saveDefer.resolve(validationRes);
                            } else {
                                $ay_requester.PUT(self.location, {
                                    etag: results.etag,
                                    data: validationRes.data
                                }).then(function (results) {
                                    if (!results.error) {
                                        self.set(results.data);
                                        factory.updateCache(self);
                                        results.data = self;
                                    }
                                    saveDefer.resolve(results);
                                });
                            }
                        }
                    });
                    return saveDefer.promise;
                };
                User_Model.prototype.update = function (fields) {
                    function createTargets(item, index) {
                        var target = fields[index], change = {
                                op: target.op ? target.op : 'replace',
                                path: $utls.toRestPointer(target.field)
                            };
                        if (change.op === 'replace' || change.op === 'add' || change.op === 'test') {
                            change.value = $utls.jsonPointer(s, target.field);
                        }
                        return change;
                    }
                    var s = this, updateDefer = $q.defer(), params = {
                            fields: fields,
                            media_type: s.media_type,
                            data: Array.apply(null, new Array(fields.length)).map(createTargets)
                        };
                    $ay_requester.PATCH(s.location, params).then(function (results) {
                        s.set(s);
                        factory.updateCache(s);
                        updateDefer.resolve(results);
                    });
                    return updateDefer.promise;
                };
                User_Model.prototype.toggleActivation = function () {
                    var self = this, sendUpdate = false, changeUpdates = {}, perm = 'CorePermission.Login', index = self.perms.indexOf(perm);
                    if (self.enabled) {
                        if (index === -1) {
                            sendUpdate = true;
                            changeUpdates.op = 'add';
                            changeUpdates.field = 'perms';
                            self.perms.push(perm);
                        }
                    } else {
                        if (index !== -1) {
                            sendUpdate = true;
                            changeUpdates.op = 'remove';
                            changeUpdates.field = 'perms[' + index + ']';
                            self.perms.splice(index, 1);
                        }
                    }
                    if (sendUpdate) {
                        return self.update([changeUpdates]);
                    } else {
                        var updateDefer = $q.defer();
                        $timeout(function () {
                            updateDefer.resolve({});
                        }, 1);
                        return updateDefer.promise;
                    }
                };
                User_Model.prototype.sendEmail = function (type) {
                    return $ay_requester.SEND_EMAIL({
                        type: type,
                        id: this.id
                    });
                };
                User_Model.prototype.getCredentials = function () {
                    var self = this, getDefer = $q.defer();
                    $ay_requester.POST(self.location, {
                        retrieveEntity: false,
                        data: {
                            action: 'GetCredentials',
                            workspace_id: self.workspace_id
                        }
                    }).then(function (results) {
                        if (!results.error) {
                            self.credentials = results.data.credentials;
                            results.data = self;
                        }
                        getDefer.resolve(results);
                    });
                    return getDefer.promise;
                };
                User_Model.prototype.connectCredential = function (credentialUrl, completionUrl, authUserId) {
                    return $ay_requester.POST(credentialUrl, {
                        data: {
                            action: 'connect',
                            completion_url: completionUrl,
                            external_resource_id: authUserId
                        },
                        retrieveEntity: false
                    });
                };
                User_Model.prototype.deauthorizeCredential = function (credentialId) {
                    var self = this;
                    return $ay_requester.POST(self.location, {
                        data: {
                            action: 'InvalidateCredential',
                            credential_id: credentialId
                        },
                        retrieveEntity: false
                    });
                };
                factory.newModel = function (data) {
                    var self = this;
                    data = data || {};
                    if (!data.tenancy_id) {
                        data.tenancy_id = self.tenancy_id;
                    }
                    return new User_Model(data);
                };
                factory._buildOptions = function (params) {
                    var self = this, tenancy_id = params && params.hasOwnProperty('tenancy_id') ? params.tenancy_id : self.tenancy_id, options = {
                            s: [],
                            f: [
                                {
                                    field: '/perms',
                                    op: 'NE',
                                    value: 'CorePermission.ApiLogin'
                                },
                                {
                                    field: '/tenancy_id',
                                    op: 'EQ',
                                    value: tenancy_id
                                }
                            ]
                        };
                    if (!params || !params.full_name) {
                        if (params && params.sorts && params.sorts.length) {
                            options.s = params.sorts;
                        } else {
                            options.s.push({
                                field: '/full_name',
                                descending: false
                            });
                        }
                    }
                    if (params) {
                        if (params.full_name) {
                            options.f.push({
                                field: '/full_name',
                                op: 'CONTAINS',
                                value: params.full_name
                            });
                            options.s.push({
                                field: '/full_name',
                                descending: false
                            });
                        }
                    }
                    return options;
                };
                factory.getSchema = function () {
                    var s = this, schemaDefer = $q.defer();
                    function setEntitySchema(results) {
                        if (!results.error) {
                            s.schema = results.data;
                            schemaDefer.resolve(s.schema);
                        } else {
                            schemaDefer.resolve(results);
                        }
                    }
                    if (s.schema) {
                        $timeout(function () {
                            schemaDefer.resolve(s.schema);
                        });
                    } else {
                        $ay_requester.GET('schema/' + LOCATION).then(setEntitySchema);
                    }
                    return schemaDefer.promise;
                };
                factory.updateCache = function (user) {
                    var self = this, index = self.cache.users._index_of_by_prop('id', user.id);
                    if (index === -1) {
                        self.cache.users.push(user);
                    } else {
                        self.cache.users.splice(index, 1, user);
                    }
                    self.cache.usersDict[user.id] = user;
                };
                factory.createModel = function (data) {
                    var self = this, user = new User_Model(data);
                    factory.updateCache(user);
                    return user;
                };
                factory.get = function (params) {
                    params = params || {};
                    var setPromise, self = this, getDefer = $q.defer(), location = angular.copy(LOCATION), models = !params.isDictionary ? [] : {};
                    function setModels(requestRes) {
                        var setDefer = $q.defer(), models = [];
                        function resolveModels(modelRes) {
                            $timeout(function () {
                                setDefer.resolve(modelRes);
                            });
                        }
                        function mergeModels(mergeRes) {
                            setModels(mergeRes).then(function (mergeSet) {
                                if (mergeSet.error) {
                                    resolveModels(mergeSet);
                                } else {
                                    requestRes.data = requestRes.data.concat(mergeSet.data);
                                    resolveModels(requestRes);
                                }
                            });
                        }
                        if (requestRes.error) {
                            resolveModels(requestRes);
                        } else {
                            if (!requestRes.data.hasOwnProperty('items')) {
                                requestRes.data = self.createModel(requestRes.data);
                                resolveModels(requestRes);
                            } else {
                                if (params.tenancy_id) {
                                    models = models.concat(requestRes.data.items.map(self.newModel));
                                } else {
                                    models = models.concat(requestRes.data.items.map(self.createModel));
                                }
                                requestRes.data = models;
                                if (!params.drain || params.drain && !requestRes.cursor) {
                                    resolveModels(requestRes);
                                } else {
                                    params.cursor = requestRes.cursor;
                                    $ay_requester.GET(location, params).then(mergeModels);
                                }
                            }
                        }
                        return setDefer.promise;
                    }
                    function makeRequest(schemaRes) {
                        function returnCache() {
                            if (!params.isDictionary) {
                                getDefer.resolve({ data: self.cache.users });
                            } else {
                                getDefer.resolve({ data: self.cache.usersDict });
                            }
                        }
                        function setRequest(requestRes) {
                            setModels(requestRes).then(function (setRes) {
                                getDefer.resolve(setRes);
                            });
                        }
                        if (self.cache.users.length && !params.refresh && !params.id && !params.tenancy_id) {
                            returnCache();
                        } else {
                            if (params.id) {
                                location += '/' + params.id;
                            } else {
                                params.options = self._buildOptions(params);
                            }
                            $ay_requester.GET(location, params).then(setRequest);
                        }
                    }
                    self.getSchema().then(makeRequest);
                    return getDefer.promise;
                };
                factory.getApis = function () {
                    var self = this, getDefer = $q.defer(), location = angular.copy(LOCATION), tenancy_id = params && params.hasOwnProperty('tenancy_id') ? params.tenancy_id : self.tenancy_id, models = [], params = {
                            options: {
                                s: [],
                                f: [
                                    {
                                        field: '/perms',
                                        op: 'EQ',
                                        value: 'CorePermission.ApiLogin'
                                    },
                                    {
                                        field: '/tenancy_id',
                                        op: 'EQ',
                                        value: tenancy_id
                                    }
                                ]
                            }
                        };
                    function setModels(results) {
                        if (!results.error) {
                            models = models.concat(results.data.items);
                            results.data = models;
                        }
                        if (!results.cursor) {
                            for (var i = 0, ii = models.length; i < ii; i++) {
                                self.cache.apiIds.push(models[i].id);
                            }
                            getDefer.resolve(results);
                        } else {
                            params.cursor = results.cursor;
                            $ay_requester.GET(location, params).then(setModels);
                        }
                    }
                    $ay_requester.GET(location, params).then(setModels);
                    return getDefer.promise;
                };
                factory.create = function (data) {
                    var self = this, createDefer = $q.defer();
                    function post() {
                        data.media_type = factory.schema.description;
                        delete data.created_at;
                        var validationRes = $schemaValidator.validate(data, factory.schema);
                        if (validationRes.error) {
                            $timeout(function () {
                                createDefer.resolve(validationRes);
                            }, 1);
                        } else {
                            $ay_requester.POST(LOCATION, { data: validationRes.data }).then(function (results) {
                                if (!results.error) {
                                    results.data = new User_Model(results.data);
                                    factory.updateCache(results.data);
                                }
                                createDefer.resolve(results);
                            });
                        }
                    }
                    self.getSchema().then(post);
                    return createDefer.promise;
                };
                factory.resetUserPassword = function (username) {
                    return $ay_requester.resetUserPassword(username);
                };
                factory.getApiExcludeFilter = function () {
                    var self = this;
                    return {
                        field: '/id',
                        op: 'NIN',
                        value: self.cache.apiIds
                    };
                };
                return factory;
            }
        ]
    };
});