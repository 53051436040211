define('modules/rest/factories/search', [], function () {
    'use strict';
    return {
        component: 'service',
        name: 'restFactorySearch',
        fn: [
            '$log',
            '$ay_requester',
            '$ME',
            function ($log, $ay_requester, $ME) {
                var LOCATION = 'search';
                function SearchFactory() {
                }
                SearchFactory.prototype.location = LOCATION;
                SearchFactory.prototype.search = function (query) {
                    var self = this;
                    query = query || {};
                    query.retrieveEntity = false;
                    query.data = {
                        limit: query.limit || 5,
                        action: 'search',
                        entity_type: query.type || 'account',
                        query: query.terms
                    };
                    if (query.hasOwnProperty('account_id')) {
                        query.data.account_id = query.account_id;
                    }
                    if (query.hasOwnProperty('participant_id')) {
                        query.data.participant_id = query.participant_id;
                    }
                    return $ay_requester.POST('workspaces/' + $ME.workspace.id + '/' + self.location, query);
                };
                return new SearchFactory();
            }
        ]
    };
});