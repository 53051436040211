define('modules/rest/factories/roles', [], function () {
    'use strict';
    return {
        component: 'factory',
        name: 'modelRoleFactory',
        fn: [
            '$log',
            '$q',
            '$ay_requester',
            '$timeout',
            function model_role_factory($log, $q, $ay_requester, $timeout) {
                function Role_Model() {
                }
                Role_Model.prototype.schema = null;
                Role_Model.prototype._property_names = [];
                Role_Model.prototype._set = function set_data(data) {
                    var i, prop, self = this;
                    for (i = 0; i < self._property_names.length; i++) {
                        prop = self._property_names[i];
                        self[prop] = data[prop];
                        if (self.schema.properties[prop].hasOwnProperty('format') && self.schema.properties[prop].format === 'date-time' && self[prop]) {
                            self[prop] = new Date(self[prop]);
                        }
                    }
                    if (data.name === 'Administrator') {
                        self.isAdmin = true;
                    }
                    if (data.name === 'Super Administrator') {
                        self.isAdmin = true;
                        self.isSuper = true;
                    }
                    if (data.name.indexOf('API') !== -1) {
                        self.isApi = true;
                    }
                    return self;
                };
                function Role_Factory() {
                }
                Role_Factory.prototype._location = 'roles';
                Role_Factory.prototype.schema = null;
                Role_Factory.prototype._build_options = function build_options(params) {
                    var options = {
                        f: [],
                        s: []
                    };
                    if (params.hasOwnProperty('tenancy_id')) {
                        options.f.push({
                            field: '/tenancy_id',
                            op: 'EQ',
                            value: params.tenancy_id
                        });
                    }
                    if (options.f.length || options.s.length) {
                        params.options = options;
                    }
                };
                Role_Factory.prototype.getSchema = function get_entity_schema() {
                    var s = this, schemaDefer = $q.defer();
                    function setEntitySchema(results) {
                        if (!results.error) {
                            var role = new Role_Model();
                            s.schema = results.data;
                            Role_Model.prototype.schema = results.data;
                            Role_Model.prototype._property_names = Object.keys(results.data.properties);
                            schemaDefer.resolve(s.schema);
                        } else {
                            schemaDefer.resolve(results);
                        }
                    }
                    if (s.schema) {
                        $timeout(function () {
                            schemaDefer.resolve(s.schema);
                        }, 1);
                    } else {
                        $ay_requester.GET('schema/' + s._location).then(setEntitySchema);
                    }
                    return schemaDefer.promise;
                };
                Role_Factory.prototype.make = function make_role_model(data) {
                    return new Role_Model()._set(data);
                };
                Role_Factory.prototype._send_request = function send_request(defer, params) {
                    var self = this, location = self._location;
                    if (params.hasOwnProperty('id')) {
                        location += '/' + params.id;
                    } else {
                        params = self._build_options(params);
                    }
                    $ay_requester.GET(location, params).then(function (data_results) {
                        if (!data_results.error) {
                            if (data_results.data.hasOwnProperty('items') && data_results.data.items.length) {
                                data_results.data = data_results.data.items.map(self.make);
                            } else {
                                data_results.data = self.make(data_results.data);
                                data_results.data.etag = data_results.etag;
                            }
                        }
                        defer.resolve(data_results);
                    });
                };
                Role_Factory.prototype.GET = function get_roles(params) {
                    var self = this, model_defer = $q.defer();
                    function sendCall() {
                        self._send_request(model_defer, params);
                    }
                    if (self.schema) {
                        sendCall();
                    } else {
                        self.getSchema().then(sendCall);
                    }
                    return model_defer.promise;
                };
                return new Role_Factory();
            }
        ]
    };
});