(function () {
    var language = {
        delimiters: {
            thousands: ' ',
            decimal: ','
        },
        abbreviations: {
            thousand: 'k',
            million: ' mln',
            billion: ' mld',
            trillion: ' bln'
        },
        ordinal: function (number) {
            var remainder = number % 100;
            return number !== 0 && remainder <= 1 || remainder === 8 || remainder >= 20 ? 'ste' : 'de';
        },
        currency: { symbol: '\u20AC ' }
    };
    if (typeof module !== 'undefined' && module.exports) {
        module.exports = language;
    }
    if (typeof window !== 'undefined' && this.numeral && this.numeral.language) {
        this.numeral.language('be-nl', language);
    }
}());
(function () {
    var language = {
        delimiters: {
            thousands: ',',
            decimal: '.'
        },
        abbreviations: {
            thousand: '千',
            million: '百万',
            billion: '十亿',
            trillion: '兆'
        },
        ordinal: function (number) {
            return '.';
        },
        currency: { symbol: '\xA5' }
    };
    if (typeof module !== 'undefined' && module.exports) {
        module.exports = language;
    }
    if (typeof window !== 'undefined' && this.numeral && this.numeral.language) {
        this.numeral.language('chs', language);
    }
}());
(function () {
    var language = {
        delimiters: {
            thousands: ' ',
            decimal: ','
        },
        abbreviations: {
            thousand: 'tis.',
            million: 'mil.',
            billion: 'b',
            trillion: 't'
        },
        ordinal: function () {
            return '.';
        },
        currency: { symbol: 'Kč' }
    };
    if (typeof module !== 'undefined' && module.exports) {
        module.exports = language;
    }
    if (typeof window !== 'undefined' && this.numeral && this.numeral.language) {
        this.numeral.language('cs', language);
    }
}());
(function () {
    var language = {
        delimiters: {
            thousands: '.',
            decimal: ','
        },
        abbreviations: {
            thousand: 'k',
            million: 'mio',
            billion: 'mia',
            trillion: 'b'
        },
        ordinal: function (number) {
            return '.';
        },
        currency: { symbol: 'DKK' }
    };
    if (typeof module !== 'undefined' && module.exports) {
        module.exports = language;
    }
    if (typeof window !== 'undefined' && this.numeral && this.numeral.language) {
        this.numeral.language('da-dk', language);
    }
}());
(function () {
    var language = {
        delimiters: {
            thousands: ' ',
            decimal: ','
        },
        abbreviations: {
            thousand: 'k',
            million: 'm',
            billion: 'b',
            trillion: 't'
        },
        ordinal: function (number) {
            return '.';
        },
        currency: { symbol: 'CHF' }
    };
    if (typeof module !== 'undefined' && module.exports) {
        module.exports = language;
    }
    if (typeof window !== 'undefined' && this.numeral && this.numeral.language) {
        this.numeral.language('de-ch', language);
    }
}());
(function () {
    var language = {
        delimiters: {
            thousands: ' ',
            decimal: ','
        },
        abbreviations: {
            thousand: 'k',
            million: 'm',
            billion: 'b',
            trillion: 't'
        },
        ordinal: function (number) {
            return '.';
        },
        currency: { symbol: '\u20AC' }
    };
    if (typeof module !== 'undefined' && module.exports) {
        module.exports = language;
    }
    if (typeof window !== 'undefined' && this.numeral && this.numeral.language) {
        this.numeral.language('de', language);
    }
}());
(function () {
    var language = {
        delimiters: {
            thousands: ',',
            decimal: '.'
        },
        abbreviations: {
            thousand: 'k',
            million: 'm',
            billion: 'b',
            trillion: 't'
        },
        ordinal: function (number) {
            var b = number % 10;
            return ~~(number % 100 / 10) === 1 ? 'th' : b === 1 ? 'st' : b === 2 ? 'nd' : b === 3 ? 'rd' : 'th';
        },
        currency: { symbol: '\xA3' }
    };
    if (typeof module !== 'undefined' && module.exports) {
        module.exports = language;
    }
    if (typeof window !== 'undefined' && this.numeral && this.numeral.language) {
        this.numeral.language('en-gb', language);
    }
}());
(function () {
    var language = {
        delimiters: {
            thousands: '.',
            decimal: ','
        },
        abbreviations: {
            thousand: 'k',
            million: 'mm',
            billion: 'b',
            trillion: 't'
        },
        ordinal: function (number) {
            var b = number % 10;
            return b === 1 || b === 3 ? 'er' : b === 2 ? 'do' : b === 7 || b === 0 ? 'mo' : b === 8 ? 'vo' : b === 9 ? 'no' : 'to';
        },
        currency: { symbol: '\u20AC' }
    };
    if (typeof module !== 'undefined' && module.exports) {
        module.exports = language;
    }
    if (typeof window !== 'undefined' && this.numeral && this.numeral.language) {
        this.numeral.language('es', language);
    }
}());
(function () {
    var language = {
        delimiters: {
            thousands: '.',
            decimal: ','
        },
        abbreviations: {
            thousand: 'k',
            million: 'mm',
            billion: 'b',
            trillion: 't'
        },
        ordinal: function (number) {
            var b = number % 10;
            return b === 1 || b === 3 ? 'er' : b === 2 ? 'do' : b === 7 || b === 0 ? 'mo' : b === 8 ? 'vo' : b === 9 ? 'no' : 'to';
        },
        currency: { symbol: '$' }
    };
    if (typeof module !== 'undefined' && module.exports) {
        module.exports = language;
    }
    if (typeof window !== 'undefined' && this.numeral && this.numeral.language) {
        this.numeral.language('es', language);
    }
}());
(function () {
    var language = {
        delimiters: {
            thousands: ' ',
            decimal: ','
        },
        abbreviations: {
            thousand: ' tuh',
            million: ' mln',
            billion: ' mld',
            trillion: ' trl'
        },
        ordinal: function (number) {
            return '.';
        },
        currency: { symbol: '\u20AC' }
    };
    if (typeof module !== 'undefined' && module.exports) {
        module.exports = language;
    }
    if (typeof window !== 'undefined' && this.numeral && this.numeral.language) {
        this.numeral.language('et', language);
    }
}());
(function () {
    var language = {
        delimiters: {
            thousands: ' ',
            decimal: ','
        },
        abbreviations: {
            thousand: 'k',
            million: 'M',
            billion: 'G',
            trillion: 'T'
        },
        ordinal: function (number) {
            return '.';
        },
        currency: { symbol: '\u20AC' }
    };
    if (typeof module !== 'undefined' && module.exports) {
        module.exports = language;
    }
    if (typeof window !== 'undefined' && this.numeral && this.numeral.language) {
        this.numeral.language('fi', language);
    }
}());
(function () {
    var language = {
        delimiters: {
            thousands: ' ',
            decimal: ','
        },
        abbreviations: {
            thousand: 'k',
            million: 'M',
            billion: 'G',
            trillion: 'T'
        },
        ordinal: function (number) {
            return number === 1 ? 'er' : 'e';
        },
        currency: { symbol: '$' }
    };
    if (typeof module !== 'undefined' && module.exports) {
        module.exports = language;
    }
    if (typeof window !== 'undefined' && this.numeral && this.numeral.language) {
        this.numeral.language('fr-CA', language);
    }
}());
(function () {
    var language = {
        delimiters: {
            thousands: '\'',
            decimal: '.'
        },
        abbreviations: {
            thousand: 'k',
            million: 'm',
            billion: 'b',
            trillion: 't'
        },
        ordinal: function (number) {
            return number === 1 ? 'er' : 'e';
        },
        currency: { symbol: 'CHF' }
    };
    if (typeof module !== 'undefined' && module.exports) {
        module.exports = language;
    }
    if (typeof window !== 'undefined' && this.numeral && this.numeral.language) {
        this.numeral.language('fr-ch', language);
    }
}());
(function () {
    var language = {
        delimiters: {
            thousands: ' ',
            decimal: ','
        },
        abbreviations: {
            thousand: 'k',
            million: 'm',
            billion: 'b',
            trillion: 't'
        },
        ordinal: function (number) {
            return number === 1 ? 'er' : 'e';
        },
        currency: { symbol: '\u20AC' }
    };
    if (typeof module !== 'undefined' && module.exports) {
        module.exports = language;
    }
    if (typeof window !== 'undefined' && this.numeral && this.numeral.language) {
        this.numeral.language('fr', language);
    }
}());
(function () {
    var language = {
        delimiters: {
            thousands: ' ',
            decimal: ','
        },
        abbreviations: {
            thousand: 'E',
            million: 'M',
            billion: 'Mrd',
            trillion: 'T'
        },
        ordinal: function (number) {
            return '.';
        },
        currency: { symbol: ' Ft' }
    };
    if (typeof module !== 'undefined' && module.exports) {
        module.exports = language;
    }
    if (typeof window !== 'undefined' && this.numeral && this.numeral.language) {
        this.numeral.language('hu', language);
    }
}());
(function () {
    var language = {
        delimiters: {
            thousands: '.',
            decimal: ','
        },
        abbreviations: {
            thousand: 'mila',
            million: 'mil',
            billion: 'b',
            trillion: 't'
        },
        ordinal: function (number) {
            return 'º';
        },
        currency: { symbol: '\u20AC' }
    };
    if (typeof module !== 'undefined' && module.exports) {
        module.exports = language;
    }
    if (typeof window !== 'undefined' && this.numeral && this.numeral.language) {
        this.numeral.language('it', language);
    }
}());
(function () {
    var language = {
        delimiters: {
            thousands: ',',
            decimal: '.'
        },
        abbreviations: {
            thousand: '千',
            million: '百万',
            billion: '十億',
            trillion: '兆'
        },
        ordinal: function (number) {
            return '.';
        },
        currency: { symbol: '\xA5' }
    };
    if (typeof module !== 'undefined' && module.exports) {
        module.exports = language;
    }
    if (typeof window !== 'undefined' && this.numeral && this.numeral.language) {
        this.numeral.language('ja', language);
    }
}());
(function () {
    var language = {
        delimiters: {
            thousands: '.',
            decimal: ','
        },
        abbreviations: {
            thousand: 'k',
            million: 'mln',
            billion: 'mrd',
            trillion: 'bln'
        },
        ordinal: function (number) {
            var remainder = number % 100;
            return number !== 0 && remainder <= 1 || remainder === 8 || remainder >= 20 ? 'ste' : 'de';
        },
        currency: { symbol: '\u20AC ' }
    };
    if (typeof module !== 'undefined' && module.exports) {
        module.exports = language;
    }
    if (typeof window !== 'undefined' && this.numeral && this.numeral.language) {
        this.numeral.language('nl-nl', language);
    }
}());
(function () {
    var language = {
        delimiters: {
            thousands: ' ',
            decimal: ','
        },
        abbreviations: {
            thousand: 'tys.',
            million: 'mln',
            billion: 'mld',
            trillion: 'bln'
        },
        ordinal: function (number) {
            return '.';
        },
        currency: { symbol: 'PLN' }
    };
    if (typeof module !== 'undefined' && module.exports) {
        module.exports = language;
    }
    if (typeof window !== 'undefined' && this.numeral && this.numeral.language) {
        this.numeral.language('pl', language);
    }
}());
(function () {
    var language = {
        delimiters: {
            thousands: '.',
            decimal: ','
        },
        abbreviations: {
            thousand: 'mil',
            million: 'milhões',
            billion: 'b',
            trillion: 't'
        },
        ordinal: function (number) {
            return 'º';
        },
        currency: { symbol: 'R$' }
    };
    if (typeof module !== 'undefined' && module.exports) {
        module.exports = language;
    }
    if (typeof window !== 'undefined' && this.numeral && this.numeral.language) {
        this.numeral.language('pt-br', language);
    }
}());
(function () {
    var language = {
        delimiters: {
            thousands: ' ',
            decimal: ','
        },
        abbreviations: {
            thousand: 'k',
            million: 'm',
            billion: 'b',
            trillion: 't'
        },
        ordinal: function (number) {
            return 'º';
        },
        currency: { symbol: '\u20AC' }
    };
    if (typeof module !== 'undefined' && module.exports) {
        module.exports = language;
    }
    if (typeof window !== 'undefined' && this.numeral && this.numeral.language) {
        this.numeral.language('pt-pt', language);
    }
}());
(function () {
    var language = {
        delimiters: {
            thousands: ' ',
            decimal: ','
        },
        abbreviations: {
            thousand: 'тыс.',
            million: 'млн',
            billion: 'b',
            trillion: 't'
        },
        ordinal: function () {
            return '.';
        },
        currency: { symbol: '\u20B4' }
    };
    if (typeof module !== 'undefined' && module.exports) {
        module.exports = language;
    }
    if (typeof window !== 'undefined' && this.numeral && this.numeral.language) {
        this.numeral.language('ru-UA', language);
    }
}());
(function () {
    var language = {
        delimiters: {
            thousands: ' ',
            decimal: ','
        },
        abbreviations: {
            thousand: 'тыс.',
            million: 'млн',
            billion: 'b',
            trillion: 't'
        },
        ordinal: function () {
            return '.';
        },
        currency: { symbol: 'руб.' }
    };
    if (typeof module !== 'undefined' && module.exports) {
        module.exports = language;
    }
    if (typeof window !== 'undefined' && this.numeral && this.numeral.language) {
        this.numeral.language('ru', language);
    }
}());
(function () {
    var language = {
        delimiters: {
            thousands: ' ',
            decimal: ','
        },
        abbreviations: {
            thousand: 'tis.',
            million: 'mil.',
            billion: 'b',
            trillion: 't'
        },
        ordinal: function () {
            return '.';
        },
        currency: { symbol: '\u20AC' }
    };
    if (typeof module !== 'undefined' && module.exports) {
        module.exports = language;
    }
    if (typeof window !== 'undefined' && this.numeral && this.numeral.language) {
        this.numeral.language('sk', language);
    }
}());
(function () {
    var language = {
        delimiters: {
            thousands: ',',
            decimal: '.'
        },
        abbreviations: {
            thousand: 'พัน',
            million: 'ล้าน',
            billion: 'พันล้าน',
            trillion: 'ล้านล้าน'
        },
        ordinal: function (number) {
            return '.';
        },
        currency: { symbol: '\u0E3F' }
    };
    if (typeof module !== 'undefined' && module.exports) {
        module.exports = language;
    }
    if (typeof window !== 'undefined' && this.numeral && this.numeral.language) {
        this.numeral.language('th', language);
    }
}());
(function () {
    var suffixes = {
            1: '\'inci',
            5: '\'inci',
            8: '\'inci',
            70: '\'inci',
            80: '\'inci',
            2: '\'nci',
            7: '\'nci',
            20: '\'nci',
            50: '\'nci',
            3: '\'üncü',
            4: '\'üncü',
            100: '\'üncü',
            6: '\'ncı',
            9: '\'uncu',
            10: '\'uncu',
            30: '\'uncu',
            60: '\'ıncı',
            90: '\'ıncı'
        }, language = {
            delimiters: {
                thousands: '.',
                decimal: ','
            },
            abbreviations: {
                thousand: 'bin',
                million: 'milyon',
                billion: 'milyar',
                trillion: 'trilyon'
            },
            ordinal: function (number) {
                if (number === 0) {
                    return '\'ıncı';
                }
                var a = number % 10, b = number % 100 - a, c = number >= 100 ? 100 : null;
                return suffixes[a] || suffixes[b] || suffixes[c];
            },
            currency: { symbol: '\u20BA' }
        };
    if (typeof module !== 'undefined' && module.exports) {
        module.exports = language;
    }
    if (typeof window !== 'undefined' && this.numeral && this.numeral.language) {
        this.numeral.language('tr', language);
    }
}());
(function () {
    var language = {
        delimiters: {
            thousands: ' ',
            decimal: ','
        },
        abbreviations: {
            thousand: 'тис.',
            million: 'млн',
            billion: 'млрд',
            trillion: 'блн'
        },
        ordinal: function () {
            return '';
        },
        currency: { symbol: '\u20B4' }
    };
    if (typeof module !== 'undefined' && module.exports) {
        module.exports = language;
    }
    if (typeof window !== 'undefined' && this.numeral && this.numeral.language) {
        this.numeral.language('uk-UA', language);
    }
}());
define('numeralLang', [], function () {
    return;
});