define('modules/rest/factories/preferences', [], function () {
    'use strict';
    return {
        component: 'service',
        name: 'modelPreferenceFactory',
        fn: [
            '$log',
            '$q',
            '$timeout',
            '$ay_requester',
            'errorDetection',
            function ($log, $q, $timeout, $ay_requester, errorDetection) {
                var LOCATION = '', factory = { media_type: 'application/vnd.amity.hub.preference+json' };
                function App_State(data) {
                    var self = this;
                    self.app_name = null;
                    self.query_state = null;
                    self._listeners = [];
                    if (data) {
                        self.set(data);
                    }
                    return self;
                }
                App_State.prototype.set = function (data) {
                    var self = this;
                    self.app_name = data.app_name;
                    self.query_state = data.query_state || [];
                };
                App_State.prototype._triggerListeners = function (type, name, value) {
                    var self = this, ln = self._listeners.length;
                    if (ln) {
                        for (var i = 0; i < ln; i++) {
                            if (!self._listeners[i].options) {
                                self._listeners[i].listener.notify({
                                    type: type,
                                    name: name,
                                    value: value
                                });
                            } else if (!self._listeners[i].options.type || !self._listeners[i].options.name) {
                                self._listeners[i].listener.notify({
                                    type: type,
                                    name: name,
                                    value: value
                                });
                            } else if (self._listeners[i].options.type && !self._listeners[i].options.name && self._listeners[i].options.type === type) {
                                self._listeners[i].listener.notify({
                                    type: type,
                                    name: name,
                                    value: value
                                });
                            } else if (!self._listeners[i].options.type && self._listeners[i].options.name && self._listeners[i].options.name === name) {
                                self._listeners[i].listener.notify({
                                    type: type,
                                    name: name,
                                    value: value
                                });
                            } else if (self._listeners[i].options.type && self._listeners[i].options.name && self._listeners[i].options.type === type && self._listeners[i].options.name === name) {
                                self._listeners[i].listener.notify({
                                    type: type,
                                    name: name,
                                    value: value
                                });
                            }
                        }
                    }
                };
                App_State.prototype.state = function (name, value) {
                    var self = this;
                    if (!name) {
                        return self;
                    } else {
                        var index = self.query_state._index_of_by_prop('query_name', name);
                        if (value !== undefined) {
                            if (index === -1) {
                                index = self.query_state.length;
                                self.query_state.push({
                                    query_name: name,
                                    value: value
                                });
                            }
                            self.query_state[index].value = value;
                            self._triggerListeners('modify', name, value);
                        }
                        return index !== -1 ? self.query_state[index].value : undefined;
                    }
                };
                App_State.prototype.listen = function (scope, fn, options) {
                    var self = this, listenDefer = $q.defer(), id = self._listeners._generate_id();
                    listenDefer.promise.then(null, null, fn);
                    self._listeners.push({
                        id: id,
                        options: options,
                        listener: listenDefer
                    });
                    scope.$on('$destroy', function () {
                        var index = self._listeners._index_of_by_prop('id', id);
                        self._listeners.splice(index, 1);
                    });
                };
                App_State.prototype.removeState = function (name) {
                    var self = this, index = self.query_state._index_of_by_prop('query_name', name);
                    if (index !== -1) {
                        self.query_state.splice(index, 1);
                    }
                };
                function App_Preference(data) {
                    var self = this;
                    self.list = [];
                    if (data) {
                        self.set(data);
                    }
                }
                App_Preference.prototype.create = function (data) {
                    var self = this, state = new App_State(data);
                    self.list.push(state);
                };
                App_Preference.prototype.set = function (data) {
                    var self = this;
                    for (var i = 0, ii = data.length; i < ii; i++) {
                        self.create(data[i]);
                    }
                };
                App_Preference.prototype.get = function (name) {
                    var self = this, index = self.list._index_of_by_prop('app_name', name);
                    if (index === -1) {
                        index = self.list.length;
                        self.create({ app_name: name });
                    }
                    return self.list[index];
                };
                App_Preference.prototype.replace = function (name, data) {
                    var self = this, app = self.get(name);
                    app.query_state = data;
                    return app;
                };
                function Preference_Model(data) {
                    var self = this;
                    self.saveQueue = [];
                    if (data) {
                        self.set(data);
                    }
                }
                Preference_Model.prototype.set = function (data) {
                    var self = this;
                    self.version = data.version;
                    self.data = data.data;
                    self.created_at = data.created_at;
                    self.etag = data.etag;
                    self.media_type = data.media_type;
                    if (self.data.hasOwnProperty('apps') && Array.isArray(self.data.apps)) {
                        self.apps = new App_Preference(self.data.apps);
                    } else {
                        self.apps = new App_Preference();
                    }
                };
                Preference_Model.prototype._formatData = function () {
                    var self = this;
                    function cleanApps(app) {
                        return {
                            app_name: app.app_name,
                            query_state: app.query_state
                        };
                    }
                    return {
                        version: self.version,
                        created_at: self.created_at,
                        media_type: self.media_type,
                        data: { apps: self.apps.list.map(cleanApps) }
                    };
                };
                Preference_Model.prototype.save = function () {
                    var self = this, params = {
                            media_type: self.media_type,
                            fields: [{ field: 'data.apps' }],
                            data: [{
                                    op: 'replace',
                                    path: '/data/apps',
                                    value: self._formatData().data.apps
                                }]
                        };
                    return $ay_requester.PATCH(LOCATION, params);
                };
                factory.config = function (options) {
                    var self = this;
                    self.workspace_id = options.workspace_id;
                    self.user_id = options.user_id;
                    LOCATION = 'workspaces/' + self.workspace_id + '/preferences/' + self.user_id;
                };
                factory.get = function () {
                    var self = this, modelDefer = $q.defer();
                    function completeRequest(results) {
                        if (!results.error) {
                            results.data = new Preference_Model(results.data);
                        }
                        modelDefer.resolve(results);
                    }
                    $ay_requester.GET(LOCATION).then(function (results) {
                        if (results.status !== 404) {
                            completeRequest(results);
                        } else {
                            var newPref = {
                                retrieveEntity: true,
                                data: {
                                    media_type: self.media_type,
                                    version: 1,
                                    data: { apps: [] }
                                }
                            };
                            $ay_requester.PUT(LOCATION, newPref).then(completeRequest);
                        }
                    });
                    return modelDefer.promise;
                };
                factory._mock = function (data) {
                    return new Preference_Model(data);
                };
                return factory;
            }
        ]
    };
});