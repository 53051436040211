define('applications/password-reset/controllers/main', [], function () {
    'use strict';
    return {
        name: 'ctrlPasswdReset',
        component: 'controller',
        fn: [
            '$scope',
            'restFactoryUser',
            function ($scope, restFactoryUser) {
                $scope.title = 'Forgot your password?';
                $scope.message = '';
                $scope.username = '';
                $scope.error = false;
                $scope.reset_started = false;
                $scope.reset_confirmed = false;
                function confirm_reset(results) {
                    $scope.reset_started = false;
                    if (results.error) {
                        if (results.data) {
                            switch (results.data.exception) {
                            case 'lilikoi.exceptions.EntityNotFoundError':
                                $scope.message = 'That username was not found.';
                                break;
                            }
                        }
                        $scope.error = true;
                    } else {
                        $scope.title = 'Password reset email sent.';
                        $scope.reset_confirmed = true;
                    }
                }
                $scope.submit = function reset_user_password() {
                    $scope.reset_started = true;
                    $scope.username = $scope.username.trim();
                    if ($scope.username) {
                        restFactoryUser.resetUserPassword($scope.username).then(confirm_reset);
                    } else {
                        $scope.message = 'Username must not be blank.';
                        $scope.error = true;
                    }
                };
                $scope.clear_message = function clear_message() {
                    $scope.message = '';
                    $scope.error = false;
                };
            }
        ]
    };
});