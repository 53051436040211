define('modules/rest/factories/rules', [], function () {
    'use strict';
    return {
        component: 'service',
        name: 'restFactoryRules',
        fn: [
            '$log',
            '$schemaValidator',
            '$ay_requester',
            '$q',
            '$timeout',
            '$ME',
            '$sce',
            'srvcMappingFormatters',
            '$rootScope',
            '$compile',
            function ($log, $schemaValidator, $ay_requester, $q, $timeout, $ME, $sce, srvcMappingFormatters, $rootScope, $compile) {
                var LOCATION = 'rules';
                var media_type = 'application/vnd.amity.hub.rule+json';
                function RulesModel(data, schema) {
                    this.schema = schema;
                    this.id = null;
                    this.media_type = media_type;
                    this.workspace_id = $ME.workspace.id;
                    this.created_at = null;
                    this.updated_at = null;
                    this.name = '';
                    this.description = '';
                    this.enabled = null;
                    this.trigger = null;
                    this.property_criteria = null;
                    this.entity_type = null;
                    this.recurrence = null;
                    this.conditions = null;
                    this.actions = null;
                    this.created_by_user_id = null;
                    this.updated_by_user_id = null;
                    if (data) {
                        this._set(data);
                    }
                }
                RulesModel.prototype._set = function (data) {
                    var self = this;
                    self.etag = data.etag;
                    self.id = data.id;
                    self.location = LOCATION + '/' + data.id;
                    self.media_type = data.media_type || self.media_type;
                    self.workspace_id = data.workspace_id || $ME.workspace.id;
                    self.created_at = data.created_at;
                    self.updated_at = data.updated_at;
                    self.name = data.name;
                    self.description = data.description;
                    self.enabled = data.enabled;
                    self.trigger = data.trigger;
                    self.property_criteria = data.property_criteria;
                    self.entity_type = data.entity_type;
                    self.recurrence = data.recurrence;
                    self.conditions = data.conditions;
                    self.actions = data.actions;
                    self.created_by_user_id = data.created_by_user_id;
                    self.updated_by_user_id = data.updated_by_user_id;
                };
                RulesModel.prototype.getEtag = function () {
                    var self = this, etagDefer = $q.defer();
                    if (self.etag) {
                        $timeout(function () {
                            etagDefer.resolve({ etag: self.etag });
                        }, 1);
                    } else {
                        $ay_requester.GET(self.location).then(function (results) {
                            if (!results.error) {
                                self.etag = results.data.etag;
                                results.etag = self.etag;
                            }
                            etagDefer.resolve(results);
                        });
                    }
                    return etagDefer.promise;
                };
                RulesModel.prototype.save = function () {
                    var self = this, saveDefer = $q.defer(), validationRes = $schemaValidator.validate(angular.copy(self), self.schema);
                    self.getEtag().then(function (results) {
                        if (results.error) {
                            saveDefer.resolve(results);
                        } else {
                            if (validationRes.error) {
                                saveDefer.resolve(validationRes);
                            } else {
                                $ay_requester.PUT(self.location, {
                                    etag: results.etag,
                                    data: validationRes.data
                                }).then(function completeRequest(results) {
                                    if (!results.error) {
                                        self._set(results.data);
                                        results.data = self;
                                    }
                                    saveDefer.resolve(results);
                                });
                            }
                        }
                    });
                    return saveDefer.promise;
                };
                RulesModel.prototype.remove = function () {
                    var self = this;
                    return $ay_requester.DELETE(self.location, {});
                };
                RulesModel.prototype.run = function (filters) {
                    var self = this, runData = {
                            data: {
                                action: 'run_manual_trigger_rule',
                                target_filter: filters
                            },
                            retrieveEntity: false
                        };
                    return $ay_requester.POST(self.location, runData);
                };
                function RulesFactory() {
                }
                RulesFactory.prototype.location = LOCATION;
                RulesFactory.prototype.media_type = media_type;
                RulesFactory.prototype.formatActionDescription = function formatActionDescription(action, entity, users, externalTargets, map) {
                    function getAssignee() {
                        var assigneeName, index;
                        if (action.data.assignee.type === 'assignee') {
                            assigneeName = 'the ' + entity + ' assignee';
                        } else {
                            index = users._index_of_by_prop('id', action.data.assignee.user_id);
                            if (index !== -1) {
                                assigneeName = users[index].full_name;
                            }
                        }
                        return assigneeName;
                    }
                    function getPlaybook() {
                        var playName, index = $ME.workspace.playbooks._index_of_by_prop('id', action.data.playbook_id);
                        if (index !== -1) {
                            playName = $ME.workspace.playbooks[index].name;
                        }
                        return playName;
                    }
                    function getTarget() {
                        var targetName, index = externalTargets._index_of_by_prop('id', action.data.external_target_id);
                        if (index !== -1) {
                            targetName = externalTargets[index].name;
                        }
                        return targetName;
                    }
                    function getField() {
                        var fieldMap, index = map._index_of_by_prop('field', action.data.property_ptr);
                        if (index !== -1) {
                            fieldMap = map[index];
                        }
                        return fieldMap;
                    }
                    function setMockEntity(mock, paths) {
                        var prop = paths.splice(0, 1)[0];
                        if (paths.length === 0) {
                            mock[prop] = action.data.value;
                        } else {
                            mock[prop] = {};
                            setMockEntity(mock, paths);
                        }
                        return mock;
                    }
                    function setDescription(description) {
                        action.type.description = $sce.trustAsHtml('<span class="nav is-hoz is-vertical size100"><i class="' + action.type.icon + ' mar right"></i>' + description + '</span>');
                    }
                    function setFieldDescription(map) {
                        var el, link_fn, scope;
                        if (map) {
                            var mockEntity = {};
                            if (map.propDef && map.propDef.isCustom) {
                                mockEntity.custom_properties = {};
                                mockEntity.custom_properties[map.propDef.id] = action.data.value;
                            } else {
                                var paths = action.data.property_ptr.split('/');
                                paths.splice(0, 1);
                                setMockEntity(mockEntity, paths);
                            }
                            delete map.propDef.avatarFields;
                            delete map.propDef.avatarValues;
                            if (map.propDef.style === 'link') {
                                map.propDef.style = 'single';
                            }
                            el = angular.element('<span ay-entity-prop="map" data-entity-type="' + entity + '"></span>'), link_fn = $compile(el);
                            scope = $rootScope.$new();
                            srvcMappingFormatters.properties.entity(mockEntity, map);
                            scope.map = map;
                            link_fn(scope);
                        }
                        $timeout(function () {
                            setDescription('Set the property <span class="is-bold mar top-o btm-o">' + map.label + '</span> to <span class="is-bold mar top-o btm-o">' + el[0].outerHTML + '</span>');
                        }, 100);
                    }
                    switch (action.type.name) {
                    case 'set_tags':
                        action.type.description = 'Set the <span class="is-bold mar top-o btm-o">' + action.data.tags.join(', ') + '</span>' + (action.data.tags.length > 1 ? ' tags' : ' tag') + ' on the ' + entity;
                        break;
                    case 'add_tags':
                        action.type.description = 'Add the <span class="is-bold mar top-o btm-o">' + action.data.tags.join(', ') + '</span>' + (action.data.tags.length > 1 ? ' tags' : ' tag') + ' to the ' + entity;
                        break;
                    case 'remove_tags':
                        action.type.description = 'Remove the <span class="is-bold mar top-o btm-o">' + action.data.tags.join(', ') + '</span>' + (action.data.tags.length > 1 ? ' tags' : ' tag') + ' from the ' + entity;
                        break;
                    case 'create_play':
                        var play = getPlaybook(), assignee = getAssignee();
                        if (play && assignee) {
                            action.type.description = 'Add a play in the <span class="is-bold">' + play + '</span> playbook and assign it to <span class="is-bold mar top-o btm-o">' + assignee + '</span>';
                        }
                        break;
                    case 'create_note':
                        action.type.description = 'Add a note titled <span class="is-bold mar top-o btm-o">' + action.data.title + '</span> to the ' + entity;
                        break;
                    case 'create_work_item':
                        var assignee = getAssignee();
                        action.type.description = 'Add a task titled <span class="is-bold mar top-o btm-o">' + action.data.title + '</span> and assign it to <span class="is-bold mar top-o btm-o">' + assignee + '</span>';
                        break;
                    case 'send_external_target':
                        var target = getTarget();
                        action.type.description = 'Send data to the <span class="is-bold mar top-o btm-o">' + target + '</span> external target';
                        break;
                    case 'set_property':
                        var field = getField();
                        setFieldDescription(field);
                        break;
                    case 'clear_property':
                        var field = getField();
                        action.type.description = 'Clear the property <span class="is-bold mar top-o btm-o">' + field.label + '</span>';
                        break;
                    }
                    setDescription('<span class="nav is-hoz is-vertical">' + action.type.description + '</span>');
                    return action;
                };
                RulesFactory.prototype.actionTypes = [
                    {
                        name: 'set_tags',
                        label: 'Set tags',
                        icon: 'fa-tags'
                    },
                    {
                        name: 'add_tags',
                        label: 'Add tags',
                        icon: 'fa-tags'
                    },
                    {
                        name: 'remove_tags',
                        label: 'Remove tags',
                        icon: 'fa-tags'
                    },
                    {
                        name: 'create_play',
                        label: 'Add a play',
                        icon: 'fa-route'
                    },
                    {
                        name: 'create_work_item',
                        label: 'Add a task',
                        icon: 'fa-tasks'
                    },
                    {
                        name: 'send_external_target',
                        label: 'Send to an external target',
                        icon: 'fa-external-link-square-alt'
                    },
                    {
                        name: 'create_note',
                        label: 'Add a note',
                        icon: 'fa-file-alt'
                    },
                    {
                        name: 'set_property',
                        label: 'Set property',
                        icon: 'fa-edit'
                    },
                    {
                        name: 'clear_property',
                        label: 'Clear property',
                        icon: 'fa-edit'
                    }
                ];
                RulesFactory.prototype._newModel = function (data) {
                    var self = this;
                    return new RulesModel(data, self.schema);
                };
                RulesFactory.prototype._buildOptions = function (params) {
                    var options = {
                            f: [{
                                    field: '/workspace_id',
                                    op: 'EQ',
                                    value: $ME.workspace.id
                                }],
                            s: [{
                                    field: '/name',
                                    descending: false
                                }]
                        }, manualRuleTriggers = ['manual_immediate'];
                    if (params.hasOwnProperty('id')) {
                        options.f.push({
                            field: '/id',
                            op: 'EQ',
                            value: params.id
                        });
                    }
                    if (params.hasOwnProperty('created_by_user_id')) {
                        options.f.push({
                            field: '/created_by_user_id',
                            op: 'EQ',
                            value: params.created_by_user_id
                        });
                    }
                    if (params.hasOwnProperty('updated_by_user_id')) {
                        options.f.push({
                            field: '/updated_by_user_id',
                            op: 'EQ',
                            value: params.updated_by_user_id
                        });
                    }
                    if (params.hasOwnProperty('isManual')) {
                        var manualOp = params.isManual ? 'IN' : 'NIN';
                        options.f.push({
                            field: '/trigger/trigger_type',
                            op: manualOp,
                            value: manualRuleTriggers
                        });
                    } else {
                        options.f.push({
                            field: '/trigger/trigger_type',
                            op: 'NIN',
                            value: manualRuleTriggers
                        });
                    }
                    return options;
                };
                RulesFactory.prototype.getSchema = function () {
                    var s = this, schemaDefer = $q.defer();
                    function setEntitySchema(results) {
                        if (!results.error) {
                            s.schema = results.data;
                            schemaDefer.resolve(s.schema);
                        } else {
                            schemaDefer.resolve(results);
                        }
                    }
                    if (s.schema) {
                        $timeout(function () {
                            schemaDefer.resolve(s.schema);
                        });
                    } else {
                        $ay_requester.GET('schema/' + LOCATION).then(setEntitySchema);
                    }
                    return schemaDefer.promise;
                };
                RulesFactory.prototype.get = function (params) {
                    var drainPromise, self = this, getDefer = $q.defer(), location = LOCATION, models = [];
                    function setModels(results) {
                        if (results.error) {
                            getDefer.resolve(results);
                        } else {
                            if (!results.data.hasOwnProperty('items')) {
                                results.data = self._newModel(results.data);
                                getDefer.resolve(results);
                            } else {
                                models = models.concat(results.data.items.map.apply(results.data.items, [
                                    self._newModel,
                                    self
                                ]));
                                results.data = models;
                                if (!params.drain) {
                                    getDefer.resolve(results);
                                } else {
                                    if (!results.cursor) {
                                        getDefer.resolve(results);
                                    } else {
                                        params.cursor = results.cursor;
                                        drainPromise = $ay_requester.GET(location, params);
                                        drainPromise.then(setModels);
                                    }
                                }
                            }
                        }
                    }
                    self.getSchema().then(function (results) {
                        params = params || {};
                        if (params.id) {
                            location += '/' + params.id;
                        } else {
                            params.options = self._buildOptions(params);
                            params.range = 25;
                        }
                        $ay_requester.GET(location, params).then(setModels);
                    });
                    return getDefer.promise;
                };
                RulesFactory.prototype.create = function (data) {
                    var self = this, createDefer = $q.defer();
                    function post() {
                        data.media_type = self.schema.description;
                        data.workspace_id = $ME.workspace.id;
                        var validationRes = $schemaValidator.validate(data, self.schema);
                        if (validationRes.error) {
                            $timeout(function () {
                                createDefer.resolve(validationRes);
                            }, 1);
                        } else {
                            $ay_requester.POST(LOCATION, { data: validationRes.data }).then(function (results) {
                                if (!results.error) {
                                    results.data = self._newModel(results.data);
                                }
                                createDefer.resolve(results);
                            });
                        }
                    }
                    self.getSchema().then(post);
                    return createDefer.promise;
                };
                return new RulesFactory();
            }
        ]
    };
});