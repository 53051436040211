define('modules/rest/factories/rule-action-jobs', [], function () {
    'use strict';
    return {
        component: 'service',
        name: 'restFactoryRuleActionJobs',
        fn: [
            '$log',
            '$schemaValidator',
            '$ay_requester',
            '$q',
            '$timeout',
            '$ME',
            function ($log, $schemaValidator, $ay_requester, $q, $timeout, $ME) {
                var LOCATION = 'rule_action_jobs';
                function RuleActionJobModel(data, schema) {
                    this.schema = schema;
                    this.id = null;
                    this.media_type = 'application/vnd.amity.hub.rule_action_job+json';
                    this.workspace_id = $ME.workspace.id;
                    this.created_at = null;
                    this.scheduled_at = null;
                    this.finished_at = null;
                    this.external_target_id = null;
                    this.status = null;
                    this.target_data = null;
                    this.message_log = [];
                    this.retry_offsets = [];
                    if (data) {
                        this._set(data);
                    }
                }
                RuleActionJobModel.prototype._set = function (data) {
                    this.id = data.id;
                    this.location = LOCATION + '/' + data.id;
                    this.media_type = data.media_type || this.media_type;
                    this.workspace_id = data.workspace_id || $ME.workspace.id;
                    this.created_at = data.created_at;
                    this.finished_at = data.finished_at;
                    this.scheduled_at = data.scheduled_at;
                    this.external_target_id = data.external_target_id;
                    this.status = data.status;
                    this.target_data = data.target_data;
                    this.message_log = data.message_log.reverse() || [];
                    this.retry_offsets = data.retry_offsets || [];
                };
                function RuleActionJobsFactory() {
                }
                RuleActionJobsFactory.prototype.location = LOCATION;
                RuleActionJobsFactory.prototype._newModel = function (data) {
                    var self = this;
                    return new RuleActionJobModel(data, self.schema);
                };
                RuleActionJobsFactory.prototype._buildOptions = function (params) {
                    var options = {
                        f: [{
                                field: '/workspace_id',
                                op: 'EQ',
                                value: $ME.workspace.id
                            }],
                        s: [{
                                field: '/created_at',
                                descending: true
                            }]
                    };
                    if (params.hasOwnProperty('id')) {
                        options.f.push({
                            field: '/id',
                            op: 'EQ',
                            value: params.id
                        });
                    }
                    if (params.hasOwnProperty('externalTargetId')) {
                        options.f.push({
                            field: '/external_target_id',
                            op: 'EQ',
                            value: params.externalTargetId
                        });
                    }
                    if (params.hasOwnProperty('status')) {
                        options.f.push({
                            field: '/status',
                            op: 'EQ',
                            value: params.status
                        });
                    }
                    return options;
                };
                RuleActionJobsFactory.prototype.getSchema = function () {
                    var s = this, schemaDefer = $q.defer();
                    function setEntitySchema(results) {
                        if (!results.error) {
                            s.schema = results.data;
                            schemaDefer.resolve(s.schema);
                        } else {
                            schemaDefer.resolve(results);
                        }
                    }
                    if (s.schema) {
                        $timeout(function () {
                            schemaDefer.resolve(s.schema);
                        });
                    } else {
                        $ay_requester.GET('schema/' + LOCATION).then(setEntitySchema);
                    }
                    return schemaDefer.promise;
                };
                RuleActionJobsFactory.prototype.get = function (params) {
                    var drainPromise, self = this, getDefer = $q.defer(), location = LOCATION, models = [];
                    function setModels(results) {
                        if (results.error) {
                            getDefer.resolve(results);
                        } else {
                            if (!results.data.hasOwnProperty('items')) {
                                results.data = self._newModel(results.data);
                                getDefer.resolve(results);
                            } else {
                                models = models.concat(results.data.items.map.apply(results.data.items, [
                                    self._newModel,
                                    self
                                ]));
                                results.data = models;
                                if (!params.drain) {
                                    getDefer.resolve(results);
                                } else {
                                    if (!results.cursor) {
                                        getDefer.resolve(results);
                                    } else {
                                        params.cursor = results.cursor;
                                        drainPromise = $ay_requester.GET(location, params);
                                        drainPromise.then(setModels);
                                    }
                                }
                            }
                        }
                    }
                    self.getSchema().then(function (results) {
                        params = params || {};
                        if (params.id) {
                            location += '/' + params.id;
                        } else {
                            params.options = self._buildOptions(params);
                            params.range = 25;
                        }
                        $ay_requester.GET(location, params).then(setModels);
                    });
                    return getDefer.promise;
                };
                return new RuleActionJobsFactory();
            }
        ]
    };
});