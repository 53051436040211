define('modules/enviroment/services/phare', [
    'exports',
    'ayActivities'
], function (exports, ayActivities) {
    'use strict';
    var ext_libs = {
        _copy: function copy(obj) {
            var c;
            if (obj) {
                c = JSON.parse(JSON.stringify(obj));
            }
            return c;
        }
    };
    ext_libs.google = {
        enabled: false,
        init: function google_init(token) {
            if (window._gaq && token) {
                window._gaq.push([
                    '_setAccount',
                    token
                ]);
                window._gaq.push([
                    '_setDomainName',
                    'none'
                ]);
                this.enabled = true;
            }
        },
        reset: function google_reset() {
            this.enabled = false;
        },
        track: function google_track(params) {
            if (this.enabled) {
                window._gaq.push(['_trackPageview']);
            }
        }
    };
    ext_libs.mixpanel = {
        enabled: false,
        user: {},
        init: function mixpanel_init(token) {
            if (window.mixpanel && token) {
                window.mixpanel.init(token);
                this.enabled = true;
            }
        },
        set_user: function mixpanel_set_user(params) {
            this.user = ext_libs._copy(params);
            if (this.enabled && this.user) {
                window.mixpanel.identify(this.user.username);
                window.mixpanel.people.set({
                    '$username': this.user.username,
                    '$name': this.user.full_name,
                    '$email': this.user.email,
                    'Tenancy': this.user.tenancy_name,
                    'Workspace': this.user.workspace_name
                });
            }
        },
        reset: function mixpanel_reset() {
            this.enabled = false;
            this.user = {};
        },
        track: function mixpanel_track(params) {
            var cp = ext_libs._copy(params);
            if (this.enabled && cp) {
                window.mixpanel.track(cp.event, cp.data, function () {
                });
            }
        }
    };
    ext_libs.amity = {
        enabled: false,
        user: {},
        init: function amity_init(data) {
            if (data.url) {
                AMITY_ACTIVITIES._set_base_url(data.url);
            }
            if (data.client_id && data.workspace_id) {
                AMITY_ACTIVITIES.load(data.client_id, data.workspace_id);
                this.enabled = true;
            }
        },
        set_user: function amity_set_user(params) {
            this.user = ext_libs._copy(params);
            if (this.enabled && this.user) {
                AMITY_ACTIVITIES.identify(this.user.id, {
                    first_name: this.user.first_name,
                    last_name: this.user.last_name,
                    email: this.user.email
                });
                AMITY_ACTIVITIES.group(this.user.workspace_id, { name: this.user.workspace_name });
            }
        },
        reset: function amity_reset() {
            this.enabled = false;
            this.user = {};
            this.data = {};
        },
        track: function amity_track(params) {
            var cp = ext_libs._copy(params);
            if (this.enabled && cp) {
                params.data = params.data ? params.data : {};
                if (this.user.impersonator_id) {
                    params.data.impersonator_id = this.user.impersonator_id;
                }
                AMITY_ACTIVITIES.track(params.event, params.data);
            }
        }
    };
    return {
        component: 'service',
        name: 'Phare',
        fn: [function () {
                return {
                    _ext_libs: ext_libs,
                    _action: function (action, params) {
                        var self = this;
                        for (var option in self._ext_libs) {
                            if (self._ext_libs[option].hasOwnProperty(action)) {
                                self._ext_libs[option][action](params);
                            }
                        }
                    },
                    init: function phare_init(params) {
                        var self = this;
                        for (var option in params) {
                            if (self._ext_libs.hasOwnProperty(option)) {
                                self._ext_libs[option].init(params[option]);
                            }
                        }
                    },
                    set_user: function phare_set_user(params) {
                        this._action('set_user', params);
                    },
                    track: function phare_track(params) {
                        this._action('track', params);
                    },
                    reset: function phare_reset() {
                        this._action('reset');
                    }
                };
            }]
    };
});