define('polyfills', ['moment'], function (moment) {
    String.prototype.capitalize = function capitalize() {
        return this.charAt(0).toUpperCase() + this.slice(1);
    };
    String.prototype._to_array = function string_to_array(delimiter, trim) {
        delimiter = delimiter || ',';
        trim = trim !== null && trim !== undefined ? trim : true;
        var i, val, list = [], values = this.split(delimiter);
        for (i = 0; i < values.length; i++) {
            if (!trim) {
                list.push(values[i]);
            } else {
                val = values[i].trim();
                if (val) {
                    list.push(val);
                }
            }
        }
        return list;
    };
    var origParse = Date.parse, numericKeys = [
            1,
            4,
            5,
            6,
            7,
            10,
            11
        ];
    Date.parse = function parse(date) {
        var timestamp, struct, minutesOffset = 0;
        if (struct = /^(\d{4}|[+\-]\d{6})(?:-(\d{2})(?:-(\d{2}))?)?(?:T(\d{2}):(\d{2})(?::(\d{2})(?:\.(\d{3}))?)?(?:(Z)|([+\-])(\d{2})(?::(\d{2}))?)?)?$/.exec(date)) {
            for (var i = 0, k; k = numericKeys[i]; ++i) {
                struct[k] = +struct[k] || 0;
            }
            struct[2] = (+struct[2] || 1) - 1;
            struct[3] = +struct[3] || 1;
            if (struct[8] !== 'Z' && struct[9] !== undef) {
                minutesOffset = struct[10] * 60 + struct[11];
                if (struct[9] === '+') {
                    minutesOffset = 0 - minutesOffset;
                }
            }
            timestamp = Date.UTC(struct[1], struct[2], struct[3], struct[4], struct[5] + minutesOffset, struct[6], struct[7]);
        } else {
            timestamp = origParse ? origParse(date) : NaN;
        }
        return timestamp;
    };
    Date.prototype.toJSON = function toJSON(key) {
        function f(n) {
            return n < 10 ? '0' + n : n;
        }
        function f3(n) {
            return n < 10 ? '00' + n : n < 100 ? '0' + n : n;
        }
        return this.getUTCFullYear() + '-' + f(this.getUTCMonth() + 1) + '-' + f(this.getUTCDate()) + 'T' + f(this.getUTCHours()) + ':' + f(this.getUTCMinutes()) + ':' + f(this.getUTCSeconds()) + '.' + f3(this.getUTCMilliseconds()) + 'Z';
    };
    Date.prototype._change_day = function change_day(days) {
        var time, one_day = 86400000;
        days = days === null ? 1 : days;
        time = days * one_day;
        return new Date(new Date(this.getTime() + time).setHours(24, 0, 0, 0));
    };
    Date.prototype._is_same = function is_same(other_date) {
        if (!other_date) {
            return null;
        }
        if (this.getDate() != other_date.getDate()) {
            return false;
        }
        if (this.getMonth() != other_date.getMonth()) {
            return false;
        }
        if (this.getFullYear() != other_date.getFullYear()) {
            return false;
        }
        return true;
    };
    Date.prototype._to_midnight = function to_midnight() {
        this.setHours(0, 0, 0, 0);
        return this;
    };
    Date.prototype._format_ISO_local = function format_ISO_local() {
        var self = this, local_offset = this.getTimezoneOffset() / 60, utc_date = new Date(Date.UTC(self.getFullYear(), self.getMonth(), self.getDate(), self.getHours() + local_offset, self.getMinutes(), self.getSeconds(), self.getMilliseconds()));
        return utc_date.toISOString();
    };
    Date.prototype._format_ISO_midnight = function format_ISO_midnight(params) {
        var utc_date = new Date(this);
        if (!params) {
            utc_date._to_midnight();
        } else {
            if (params.days) {
                utc_date.setTime(self.getTime() + 86400000 * params.days);
            }
            if (params.offset_name) {
                utc_date = moment(utc_date.toISOString()).tz(params.offset_name);
                utc_date.hours(0);
                utc_date.minutes(0);
                utc_date.seconds(0);
                utc_date.milliseconds(0);
            }
        }
        utc_date = utc_date.toISOString();
        return utc_date;
    };
    Array.prototype.sort_by = function sort_by(prop) {
        var i, props = [];
        for (i = 0; i < arguments.length; i++) {
            if (arguments[i]) {
                switch (typeof arguments[i]) {
                case 'function':
                    props.push(arguments[i]);
                    break;
                case 'string':
                    props.push(arguments[i].split('.'));
                    break;
                }
            }
        }
        function find_prop_val(item, lookup) {
            var t = lookup.slice(), c = t.splice(0, 1)[0], r = -1;
            if (item.hasOwnProperty(c)) {
                if (t.length) {
                    r = find_prop_val(item[c], t);
                } else {
                    r = item[c];
                }
            }
            return r;
        }
        function variant_sort(props) {
            return function (a, b) {
                var i, val_a, val_b, r;
                for (i = 0; i < props.length; i++) {
                    if (Array.isArray(props[i])) {
                        val_a = find_prop_val(a, props[i]);
                        val_b = find_prop_val(b, props[i]);
                    } else if (typeof props[i] === 'function') {
                        val_a = props[i](a);
                        val_b = props[i](b);
                    }
                    if (val_a === null || val_a === undefined) {
                        r = 1;
                        break;
                    }
                    if (val_b === null || val_b === undefined) {
                        r = 0;
                        break;
                    }
                    val_a = typeof val_a === 'string' ? val_a.toLowerCase() : val_a;
                    val_b = typeof val_b === 'string' ? val_b.toLowerCase() : val_b;
                    if (val_a < val_b) {
                        r = -1;
                        break;
                    }
                    if (val_a > val_b) {
                        r = 1;
                        break;
                    }
                    r = 0;
                }
                return r;
            };
        }
        if (props.length) {
            return this.sort(variant_sort(props));
        } else {
            return this;
        }
    };
    Array.prototype._index_of_by_prop = function index_of_by_prop(prop, val, delimiter) {
        if (prop === null || prop === undefined) {
            return -1;
        }
        var self = this, prop_found = false, props = delimiter ? prop.split(delimiter) : prop.split('.'), first_prop = props.splice(0, 1)[0];
        function find_prop(obj, f_prop, props_l) {
            var find_res = false;
            if (obj !== undefined) {
                if (Array.isArray(obj)) {
                    if (obj.length) {
                        if (typeof obj[0] !== 'object') {
                            if (obj.indexOf(f_prop) != -1) {
                                find_res = true;
                            }
                        } else {
                            var index = obj._index_of_by_prop(f_prop, val);
                            if (index !== -1) {
                                find_res = true;
                            }
                        }
                    }
                } else {
                    if (obj.hasOwnProperty(f_prop)) {
                        if (props_l && props_l.length) {
                            var temp_props = props_l.slice();
                            find_res = find_prop(obj[f_prop], temp_props.splice(0, 1)[0], temp_props);
                        } else {
                            if (obj[f_prop] == val) {
                                find_res = true;
                            }
                        }
                    }
                }
            }
            return find_res;
        }
        for (var i = 0; i < this.length; i++) {
            prop_found = find_prop(this[i], first_prop, props);
            if (prop_found) {
                return i;
            }
        }
        return -1;
    };
    Array.prototype._index_of_list_item = function index_of_list_item(item, match_props) {
        var i, j, prop, list = this, index = -1;
        for (i = 0; i < list.length; i++) {
            if (angular.equals(list[i], item)) {
                index = i;
                break;
            } else if (match_props && match_props.length) {
                for (j = 0; j < match_props.length; j++) {
                    prop = match_props[j];
                    if (angular.equals(list[i][prop], item[prop])) {
                        index = i;
                        break;
                    }
                }
            }
        }
        return index;
    };
    Array.prototype._generate_id = function generate_id(prop, type, sep) {
        var pos, new_id, temp, _length = this.length;
        type = type ? type : 'number';
        prop = prop ? prop : 'id';
        sep = sep ? sep : '.';
        function generate() {
            var id = this.slice().sort_by(prop).reverse()[0][prop] || 0;
            switch (type) {
            case 'number':
                id = parseInt(id, 10);
                id = id + 1;
                break;
            case 'string':
                temp = id.split(sep);
                temp[temp.length - 1] = parseInt(temp[temp.length - 1], 10) + 1;
                id = temp.join(sep);
                break;
            }
            return id;
        }
        if (_length) {
            new_id = generate.apply(this);
            pos = this._index_of_by_prop(prop, new_id);
            if (pos !== -1) {
                new_id = -1;
            }
        } else {
            new_id = 1;
        }
        return new_id;
    };
    Array.prototype._uniqueList = function () {
        var list = this;
        for (var i = list.length - 1; i > -1; i--) {
            var index = list.indexOf(list[i]);
            if (index !== i) {
                list.splice(i, 1);
            }
        }
    };
});