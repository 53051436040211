define('modules/form-inputs/directives/input-array-menu', [], function () {
    return {
        component: 'directive',
        name: 'ayFormInputArrayMenu',
        fn: [
            '$timeout',
            function ($timeout) {
                return {
                    restrict: 'E',
                    template: '<div ay-dropmenu="formMenu.templateUrl" data-external-items="formMenu.externals" data-match-width="{{formMenu.matchWidth}}" data-on-close="formMenu.close();" class="size100"></div>',
                    require: '?ngModel',
                    replace: true,
                    scope: {
                        options: '=?',
                        matchWidth: '@?'
                    },
                    controller: function () {
                        var self = this;
                        self.input = { display_name: '' };
                        self.value = [];
                        self.list = [];
                        self.templateUrl = 'form-inputs/views/input-array-menu.html';
                        self.showGroups = false;
                    },
                    controllerAs: 'formMenu',
                    bindToController: true,
                    link: function (scope, elem, attrs, ngModel) {
                        var initWatch;
                        function isEnabled(item) {
                            return item.enabled;
                        }
                        function listSetupNumber() {
                            var ln = ngModel.$modelValue.length;
                            scope.formMenu.list = angular.copy(scope.formMenu.options.items).map(function (val) {
                                var item = {
                                    label: val,
                                    value: val,
                                    isSelected: false
                                };
                                if (ln && ngModel.$modelValue.indexOf(val) !== -1) {
                                    item.isSelected = true;
                                }
                                if (item.isSelected) {
                                    scope.formMenu.value.push(val);
                                }
                                return item;
                            });
                        }
                        function listSetupString() {
                            var ln = ngModel.$modelValue.length;
                            scope.formMenu.options.itemValue = scope.formMenu.options.itemValue || 'value';
                            if (scope.formMenu.options.items[0].hasOwnProperty('groupLabel')) {
                                scope.formMenu.options.items.sort_by('groupLabel', 'display_name');
                                scope.formMenu.showGroups = true;
                            }
                            scope.formMenu.list = angular.copy(scope.formMenu.options.items).filter(isEnabled).map(function (item) {
                                item.isSelected = ln && ngModel.$modelValue._index_of_by_prop(scope.formMenu.options.itemValue, item[scope.formMenu.options.itemValue]) !== -1;
                                if (item.isSelected) {
                                    scope.formMenu.value.push(item);
                                }
                                return item;
                            });
                        }
                        scope.formMenu.listSetup = function () {
                            ngModel.$modelValue = ngModel.$modelValue || [];
                            scope.formMenu.value = [];
                            switch (scope.formMenu.options.type) {
                            case 'multi_number_picklist':
                                listSetupNumber();
                                break;
                            case 'multi_string_picklist':
                            case 'picklist':
                            case 'dynamic_picklist':
                                listSetupString();
                                break;
                            }
                            if (initWatch) {
                                initWatch();
                            }
                            scope.formMenu.options.dropmenu = scope.dropmenu;
                        };
                        scope.formMenu.close = function () {
                            scope.formMenu.input = {
                                display_name: '',
                                value: ''
                            };
                        };
                        function sendChange(event) {
                            function process() {
                                if (attrs.onChange) {
                                    scope.$parent.$eval(attrs.onChange, { $event: event });
                                }
                            }
                            $timeout(process);
                        }
                        scope.formMenu.itemSelected = function (item) {
                            function numberSelected() {
                                if (item.isSelected) {
                                    scope.formMenu.value.push(item.value);
                                } else {
                                    var index = scope.formMenu.value.indexOf(item.value);
                                    if (index !== -1) {
                                        scope.formMenu.value.splice(index, 1);
                                    }
                                }
                            }
                            function stringSelected() {
                                if (item.isSelected) {
                                    scope.formMenu.value.push(item);
                                } else {
                                    var index = scope.formMenu.value._index_of_by_prop(scope.formMenu.options.itemValue, item[scope.formMenu.options.itemValue]);
                                    if (index !== -1) {
                                        scope.formMenu.value.splice(index, 1);
                                    }
                                }
                            }
                            switch (scope.formMenu.options.type) {
                            case 'multi_number_picklist':
                                numberSelected();
                                break;
                            case 'picklist':
                            case 'dynamic_picklist':
                            case 'multi_string_picklist':
                                stringSelected();
                                break;
                            }
                            ngModel.$setViewValue(scope.formMenu.value);
                            sendChange({
                                type: 'item-selected',
                                item: item
                            });
                        };
                        ngModel.$render = function () {
                            if (scope.formMenu.options) {
                                scope.formMenu.listSetup();
                            } else {
                                initWatch = scope.$watch('formMenu.options', scope.formMenu.listSetup);
                            }
                        };
                        attrs.$observe('ctlrId', function (newVal) {
                            scope.formMenu.externals = newVal ? ['#' + attrs.ctlrId] : null;
                        });
                        scope.$on('$destroy', function () {
                            elem.remove();
                        });
                    }
                };
            }
        ]
    };
});