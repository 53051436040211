define('modules/rest/index', [
    'modules/rest/services/error-detection',
    'modules/rest/services/connection',
    'modules/rest/services/schema-validator',
    'modules/rest/services/auto-download',
    'modules/rest/services/requester',
    'modules/rest/services/requester-old',
    'modules/rest/factories/accounts',
    'modules/rest/factories/activities',
    'modules/rest/factories/apps',
    'modules/rest/factories/engagements',
    'modules/rest/factories/insight',
    'modules/rest/factories/integrations',
    'modules/rest/factories/marketing',
    'modules/rest/factories/newsfeed',
    'modules/rest/factories/notifications',
    'modules/rest/factories/notification-settings',
    'modules/rest/factories/participant',
    'modules/rest/factories/play',
    'modules/rest/factories/preferences',
    'modules/rest/factories/roles',
    'modules/rest/factories/subscription',
    'modules/rest/factories/tenancy',
    'modules/rest/factories/tickets',
    'modules/rest/factories/user',
    'modules/rest/factories/views',
    'modules/rest/factories/workitems',
    'modules/rest/factories/workspace',
    'modules/rest/factories/workspace-goals',
    'modules/rest/factories/notes',
    'modules/rest/factories/email-template',
    'modules/rest/factories/custom-reports',
    'modules/rest/factories/bulk-jobs',
    'modules/rest/factories/formulas',
    'modules/rest/factories/external-targets',
    'modules/rest/factories/rule-action-jobs',
    'modules/rest/factories/invoices',
    'modules/rest/factories/layouts',
    'modules/rest/factories/rules',
    'modules/rest/factories/rule-events',
    'modules/rest/factories/search'
], function () {
    var def, module = angular.module('ay-rest-mod', []);
    for (var i = 0, ii = arguments.length; i < ii; i++) {
        def = arguments[i];
        if (def.hasOwnProperty('name') && def.hasOwnProperty('fn') && def.hasOwnProperty('component')) {
            module[def.component](def.name, def.fn);
        }
    }
    return module;
});