define('modules/form-inputs/directives/input-array-string', [], function () {
    return {
        component: 'directive',
        name: 'ayFormInputArrayString',
        fn: [
            '$log',
            '$timeout',
            function ($log, $timeout) {
                return {
                    restrict: 'E',
                    templateUrl: 'form-inputs/views/input-array-string.html',
                    require: '?ngModel',
                    scope: {
                        label: '@',
                        isRequired: '@',
                        validationMsg: '@',
                        helpText: '@',
                        options: '=?',
                        isDisabled: '=?',
                        tabIndex: '@?',
                        appendTo: '@?'
                    },
                    controller: function () {
                        var self = this;
                        self.value = [];
                        self.list = [];
                    },
                    controllerAs: 'formInput',
                    bindToController: true,
                    link: function (scope, elem, attrs, ngModel) {
                        scope.formInput.id = 'input-array-' + scope.$id;
                        scope.formInput.ctrlId = 'form-input-ctrl-' + scope.formInput.id;
                        function cyclePhase() {
                            if (!scope.$$phase && (scope.$root && !scope.$root.$$phase)) {
                                scope.$apply();
                            }
                        }
                        function notifyChange() {
                            if (attrs.onChange) {
                                scope.$parent.$eval(attrs.onChange);
                            }
                            cyclePhase();
                        }
                        scope.formInput._sendChange = function () {
                            if (attrs.ayValidate) {
                                var validator;
                                if (attrs.runAlias) {
                                    validator = scope.$eval(attrs.runAlias);
                                    if (!validator) {
                                        validator = scope.$parent.$eval(attrs.runAlias);
                                    }
                                } else {
                                    validator = scope.ay_validate ? scope.ay_validate : scope.$parent.ay_validate ? scope.$parent.ay_validate : null;
                                }
                                if (!validator) {
                                    $log.error({ error: { message: 'multi picklist has validation but no validator' } });
                                } else {
                                    if (validator.run) {
                                        validator.run(elem).then(notifyChange);
                                    } else {
                                        validator.ay_validate.run(elem).then(notifyChange);
                                    }
                                }
                            }
                            notifyChange();
                        };
                        scope.formInput.update = function (item, event) {
                            ngModel.$setViewValue(scope.formInput.value);
                            scope.formInput._sendChange();
                        };
                        scope.formInput.removeItem = function (index) {
                            scope.formInput.value.splice(index, 1);
                            scope.formInput.update();
                            scope.$$childHead.formMenu.listSetup();
                        };
                        scope.formInput.openMenu = function (event) {
                            scope.$$childHead.dropmenu.open();
                        };
                        ngModel.$render = function () {
                            ngModel.$modelValue = ngModel.$modelValue || [];
                            scope.formInput.value = ngModel.$modelValue;
                        };
                    }
                };
            }
        ]
    };
});