define('modules/enviroment/services/core', [], function () {
    return {
        component: 'service',
        name: '$ayEnviromentCore',
        fn: [function () {
                function Detect_Mobile() {
                }
                Detect_Mobile.prototype.Android = function () {
                    return navigator.userAgent.match(/Android/i);
                };
                Detect_Mobile.prototype.BlackBerry = function () {
                    return navigator.userAgent.match(/BlackBerry/i);
                };
                Detect_Mobile.prototype.iOS = function () {
                    return navigator.userAgent.match(/iPhone|iPad|iPod/i);
                };
                Detect_Mobile.prototype.Opera = function () {
                    return navigator.userAgent.match(/Opera Mini/i);
                };
                Detect_Mobile.prototype.Windows = function () {
                    return navigator.userAgent.match(/IEMobile/i);
                };
                Detect_Mobile.prototype.any = function () {
                    var self = this;
                    return self.Android() || self.BlackBerry() || self.iOS() || self.Opera() || self.Windows();
                };
                function Enviroment_Core_Service() {
                }
                Enviroment_Core_Service.prototype._mobileDetect = new Detect_Mobile();
                Enviroment_Core_Service.prototype.isMobile = function () {
                    return this._mobileDetect.any();
                };
                Enviroment_Core_Service.prototype.isDev = function () {
                    return window.location.hostname === 'localhost';
                };
                Enviroment_Core_Service.prototype.screenDetails = function () {
                    var screen = window.screen, details = {
                            width: screen.availWidth,
                            height: screen.availHeight,
                            orientation: screen.orientation.type.match(/portrait/i) ? 'portrait' : screen.orientation.type.match(/landscape/i) ? 'landscape' : null
                        };
                    return details;
                };
                return new Enviroment_Core_Service();
            }]
    };
});