define('ayActivities', [], function () {
    (function (window) {
        'use strict';
        var _b64chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-_';
        var _base_url = 'https://app.getamity.com/rest/v1/activities';
        var _library_name = 'activities.js';
        var _library_version = '1.1';
        var Person = {
            person_id: null,
            person_traits: {},
            _expected_traits: [
                'participant_id_source',
                'created_at',
                'full_name',
                'first_name',
                'last_name',
                'email',
                'avatar_url',
                'gender',
                'birthday',
                'address',
                'telephone',
                'url',
                'description',
                'salutation'
            ],
            id: function (personId) {
                if (personId) {
                    this.person_id = personId;
                }
                return this.person_id;
            },
            traits: function (personTraits) {
                var trait, i;
                if (personTraits) {
                    for (trait in personTraits) {
                        if (personTraits.hasOwnProperty(trait)) {
                            for (i = 0; i < this._expected_traits.length; i = i + 1) {
                                if (trait === this._expected_traits[i] && personTraits[trait] !== null) {
                                    if (trait.toLowerCase() === 'birthday' || trait.toLowerCase() === 'created_at') {
                                        this.person_traits[trait] = new Date(personTraits[trait]).toISOString();
                                    } else {
                                        this.person_traits[trait] = personTraits[trait];
                                    }
                                }
                            }
                        }
                    }
                }
                return this.person_traits;
            }
        };
        var Group = {
            group_id: null,
            group_traits: {},
            _expected_traits: [
                'account_id_source',
                'created_at',
                'name',
                'avatar_url',
                'description',
                'address',
                'telephone',
                'url',
                'participant_id',
                'participant_id_source'
            ],
            id: function (groupId) {
                if (groupId) {
                    this.group_id = groupId;
                }
                return this.group_id;
            },
            traits: function (groupTraits) {
                var trait, i;
                if (groupTraits) {
                    for (trait in groupTraits) {
                        if (groupTraits.hasOwnProperty(trait)) {
                            for (i = 0; i < this._expected_traits.length; i = i + 1) {
                                if (trait.toLowerCase() === 'created_at') {
                                    this.group_traits[trait] = new Date(groupTraits[trait]).toISOString();
                                } else {
                                    this.group_traits[trait] = groupTraits[trait];
                                }
                            }
                        }
                    }
                }
                return this.group_traits;
            }
        };
        var amity_activities = {
            client_id: null,
            workspace_id: null,
            _person: Person,
            _group: Group,
            _expected_track_traits: [
                'name',
                'properties',
                'participant_id',
                'participant_id_source',
                'account_id',
                'account_id_source',
                'created_at'
            ],
            urls: {
                identify: _base_url + '/identify_participant/',
                group: _base_url + '/identify_account/',
                track: _base_url + '/record/'
            },
            callback: null,
            _cb_utob: function (value) {
                var fromCharCode = String.fromCharCode, cc;
                if (value.length < 2) {
                    cc = value.charCodeAt(0);
                    return cc < 128 ? value : cc < 2048 ? fromCharCode(192 | cc >>> 6) + fromCharCode(128 | cc & 63) : fromCharCode(224 | cc >>> 12 & 15) + fromCharCode(128 | cc >>> 6 & 63) + fromCharCode(128 | cc & 63);
                } else {
                    cc = 65536 + (value.charCodeAt(0) - 55296) * 1024 + (value.charCodeAt(1) - 56320);
                    return fromCharCode(240 | cc >>> 18 & 7) + fromCharCode(128 | cc >>> 12 & 63) + fromCharCode(128 | cc >>> 6 & 63) + fromCharCode(128 | cc & 63);
                }
            },
            _cb_encode: function (value) {
                var padlen = [
                        0,
                        2,
                        1
                    ][value.length % 3], ord = value.charCodeAt(0) << 16 | (value.length > 1 ? value.charCodeAt(1) : 0) << 8 | (value.length > 2 ? value.charCodeAt(2) : 0), chars = [
                        _b64chars.charAt(ord >>> 18),
                        _b64chars.charAt(ord >>> 12 & 63),
                        padlen >= 2 ? '=' : _b64chars.charAt(ord >>> 6 & 63),
                        padlen >= 1 ? '=' : _b64chars.charAt(ord & 63)
                    ];
                return chars.join('');
            },
            _btoa: function (value) {
                if (window.btoa) {
                    return window.btoa(value);
                }
                return value.replace(/[\s\S]{1,3}/g, this._cb_encode);
            },
            _encodeURI: function (value) {
                return this._btoa(value.replace(/[\uD800-\uDBFF][\uDC00-\uDFFFF]|[^\x00-\x7F]/g, this._cb_utob)).replace(/=/g, '');
            },
            _merge: function (object1, object2) {
                var returnObj = {};
                var prop;
                for (prop in object1) {
                    if (object1.hasOwnProperty(prop)) {
                        returnObj[prop] = object1[prop];
                    }
                }
                for (prop in object2) {
                    if (object2.hasOwnProperty(prop)) {
                        returnObj[prop] = object2[prop];
                    }
                }
                return returnObj;
            },
            _clean: function (toClean) {
                var prop, i, _toReturn = { properties: {} }, allowedProperty = false;
                for (prop in toClean) {
                    if (toClean.hasOwnProperty(prop)) {
                        for (i = 0; i < this._expected_track_traits.length; i = i + 1) {
                            if (prop === this._expected_track_traits[i]) {
                                allowedProperty = true;
                            }
                        }
                        if (allowedProperty) {
                            if (prop.toLowerCase() === 'created_at') {
                                _toReturn[prop] = new Date(toClean[prop]).toISOString();
                            } else {
                                _toReturn[prop] = toClean[prop];
                            }
                        } else {
                            _toReturn.properties[prop] = toClean[prop];
                        }
                        allowedProperty = false;
                    }
                }
                for (prop in _toReturn) {
                    if (_toReturn.hasOwnProperty(prop) && _toReturn[prop] === null) {
                        delete _toReturn[prop];
                    }
                }
                if (Object.keys(_toReturn.properties).length === 0) {
                    delete _toReturn.properties;
                }
                return _toReturn;
            },
            identify: function (person_id, traits, options, fn) {
                traits = traits || {};
                if (typeof options === 'function') {
                    fn = options;
                    options = null;
                }
                if (typeof person_id === 'object') {
                    traits = person_id;
                    person_id = this._person.id();
                }
                this._person.id(person_id);
                this._person.traits(traits);
                this.send('identify', traits, fn);
            },
            group: function (account_id, traits, options, fn) {
                traits = traits || {};
                if (typeof options === 'function') {
                    fn = options;
                    options = null;
                }
                if (typeof account_id === 'object') {
                    traits = account_id;
                    account_id = this._group.id();
                }
                this._group.id(account_id);
                if (this._person.id()) {
                    traits.participant_id = this._person.id();
                    traits.participant_id_source = this._person.traits().participant_id_source;
                }
                this._group.traits(traits);
                this.send('group', traits, fn);
            },
            track: function (event, properties, options, fn) {
                if (typeof properties === 'function') {
                    fn = properties;
                    options = null;
                    properties = null;
                }
                if (typeof options === 'function') {
                    fn = options;
                    options = null;
                }
                if (typeof event === 'object') {
                    properties = event;
                    event = null;
                }
                properties = properties || {};
                if (event !== null) {
                    properties.name = event;
                } else {
                    if (properties.event && !properties.name) {
                        properties.name = properties.event;
                        delete properties.event;
                    }
                }
                if (!properties.participant_id) {
                    properties.participant_id = this._person.id();
                }
                if (!properties.participant_id_source) {
                    properties.participant_id_source = this._person.traits().participant_id_source;
                }
                if (!properties.account_id) {
                    properties.account_id = this._group.id();
                }
                if (!properties.account_id_source) {
                    properties.account_id_source = this._group.traits().account_id_source;
                }
                var clean = this._clean(properties);
                this.send('track', clean, fn);
            },
            send: function (action, traits, fn) {
                var callback = fn || null;
                if (!this.client_id || !this.workspace_id) {
                    console.error('Client ID and Workspace ID are required.  Call the load function');
                } else {
                    var localTraits = {}, baseTraits = {
                            workspace_id: this.workspace_id,
                            buster: new Date().toJSON(),
                            library_name: _library_name,
                            library_version: _library_version
                        };
                    switch (action) {
                    case 'identify':
                        localTraits = this._person.traits();
                        localTraits.participant_id = this._person.id();
                        break;
                    case 'group':
                        localTraits = this._group.traits();
                        localTraits.account_id = this._group.id();
                        break;
                    case 'track':
                        localTraits = traits;
                        break;
                    }
                    var url = this.urls[action] + this.client_id + '?' + this._encodeURI(JSON.stringify(this._merge(baseTraits, localTraits))), img = document.createElement('img');
                    img.src = url;
                    document.body.appendChild(img);
                    setTimeout(function () {
                        document.body.removeChild(img);
                    }, 500);
                    if (callback !== null) {
                        setTimeout(callback, 500);
                    }
                }
            },
            load: function (client_id, workspace_id) {
                this.client_id = client_id;
                this.workspace_id = workspace_id;
                if (this.client_id === null || this.workspace_id === null) {
                    console.error('Client ID and Amity Workspace ID are required.');
                }
            },
            _set_base_url: function (new_url) {
                this.urls = {
                    identify: new_url + '/identify_participant/',
                    group: new_url + '/identify_account/',
                    track: new_url + '/record/'
                };
            },
            reset: function () {
                this.client_id = null;
                this.workspace_id = null;
            },
            useEuProduction: function () {
                this._set_base_url('https://app.eu.getamity.com/rest/v1/activities');
            },
            useSandbox: function () {
                this._set_base_url('https://sandbox.getamity.com/rest/v1/activities');
            }
        };
        amity_activities.identifyParticipant = amity_activities.identify;
        amity_activities.identifyAccount = amity_activities.group;
        amity_activities.record = amity_activities.track;
        window.AMITY_ACTIVITIES = amity_activities;
        if (window._aaq && window._aaq.length === 2) {
            window.AMITY_ACTIVITIES.load(_aaq[0], _aaq[1]);
        }
        return amity_activities;
    }(window));
    return;
});