define('applications/login/controllers/login', [], function () {
    'use strict';
    return {
        name: 'ctrlLogin',
        component: 'controller',
        fn: [
            '$scope',
            '$location',
            '$timeout',
            '$ME',
            '$ayEnviromentPackages',
            'Phare',
            '$ayEnviromentCore',
            function ($scope, $location, $timeout, $ME, $ayEnviromentPackages, Phare, $ayEnviromentCore) {
                var self = this;
                self.username = '';
                self.password = '';
                self.error_msg = '';
                self.started = false;
                self.isLoading = $ayEnviromentPackages.isLoading || false;
                self.isMobile = $ayEnviromentCore.isMobile();
                self.lockout = {
                    isLocked: false,
                    retryCnt: 0,
                    counter: function () {
                        function timer() {
                            $timeout(function () {
                                self.lockout.retryCnt--;
                                self.error_msg = 'Too many failed login attempts. Please must wait ' + self.lockout.retryCnt + ' seconds before you can try again.';
                                self.lockout.counter();
                            }, 1000);
                        }
                        if (self.lockout.retryCnt > 0) {
                            self.lockout.isLocked = true;
                            timer();
                        } else {
                            self.lockout.isLocked = false;
                            self.error_msg = '';
                        }
                    }
                };
                self.submit = function submit() {
                    var is_valid = true;
                    self.error_msg = '';
                    self.username = self.username.trim();
                    self.started = true;
                    if (!self.username) {
                        is_valid = false;
                        self.error_msg = 'Username is required to login';
                    }
                    if (!self.password) {
                        is_valid = false;
                        if (self.error_msg) {
                            self.error_msg += '\n';
                        }
                        self.error_msg += 'Password is required to login';
                    }
                    function confirmLogin(results) {
                        if (results.error) {
                            switch (results.error.category) {
                            case 'server':
                                self.error_msg = 'An error occurred while trying to establish user settings.';
                                self.password = '';
                                break;
                            case 'client':
                                if (results.retryAfter) {
                                    self.error_msg = 'Too many failed login attempts. Please must wait ' + results.retryAfter + ' seconds before you can try again.';
                                    self.lockout.retryCnt = results.retryAfter;
                                    self.lockout.counter();
                                } else {
                                    self.error_msg = 'The username or password you provided does not match our records.';
                                }
                                self.password = '';
                                break;
                            default:
                                self.error_msg = 'An unexpected error occurred.<br/><br />If you cannot login to Amity, contact <a href=\'https://support.getamity.com/\' class=\'is-light red accent s-700\' target=\'_BLANK\'>Amity Support</a>.';
                                break;
                            }
                            self.started = false;
                        } else {
                            var userPreference = $ME.preferences.apps.get('user'), appVersion = userPreference.state('version');
                            if (!appVersion && !self.isMobile) {
                                appVersion = 'v2';
                            } else if (self.isMobile) {
                                appVersion = 'v2';
                            }
                            self.isLoading = true;
                            $ayEnviromentPackages.loadVersion(appVersion).then(function () {
                                self.isLoading = false;
                                self.started = false;
                            });
                        }
                    }
                    if (is_valid && !self.lockout.isLocked) {
                        self.started = true;
                        $ME.login(self.username, self.password).then(confirmLogin);
                    } else {
                        self.started = false;
                    }
                };
                self.clear_error = function clear_error() {
                    self.error_msg = '';
                };
            }
        ]
    };
});