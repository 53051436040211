define('modules/rest/factories/tenancy', [], function () {
    'use strict';
    return {
        component: 'service',
        name: 'restFactoryTenancy',
        fn: [
            '$log',
            '$schemaValidator',
            '$ay_requester',
            '$q',
            '$timeout',
            '$utls',
            'errorDetection',
            function view_factory($log, $schemaValidator, $ay_requester, $q, $timeout, $utls, errorDetection) {
                var LOCATION = 'tenancies', factory = { schema: null };
                function Tenancy_Model(data) {
                    this.etag = null;
                    this.media_type = 'application/vnd.amity.hub.tenancy+json';
                    this.id = null;
                    this.created_at = null;
                    this.name = null;
                    this.description = null;
                    this.entitlements = [];
                    this.grants = [];
                    this.suspension_date = null;
                    this.billing = null;
                    if (data) {
                        this._set(data);
                    }
                }
                Tenancy_Model.prototype._set = function (data) {
                    this.etag = data.etag;
                    this.media_type = data.media_type || this.media_type;
                    this.id = data.id;
                    this.location = LOCATION + '/' + data.id;
                    this.created_at = new Date(data.created_at);
                    this.name = data.name;
                    this.description = data.description;
                    this.entitlements = data.entitlements || [];
                    this.grants = data.grants || [];
                    this.loginPermIndex = this.entitlements._index_of_by_prop('perm', 'CorePermission.Login');
                    this.suspension_date = data.suspension_date ? new Date(data.suspension_date) : null;
                    this.billing = data.billing;
                };
                Tenancy_Model.prototype.save = function () {
                    var self = this, saveDefer = $q.defer(), validation_res = $schemaValidator.validate(angular.copy(self), factory.schema);
                    if (validation_res.error) {
                        $timeout(function () {
                            saveDefer.resolve(validation_res);
                        });
                    } else {
                        $ay_requester.PUT(self.location, {
                            etag: self.etag,
                            data: validation_res.data
                        }).then(function (results) {
                            if (!results.error) {
                                self._set(results.data);
                                results.data = self;
                            }
                            saveDefer.resolve(results);
                        });
                    }
                    return saveDefer.promise;
                };
                Tenancy_Model.prototype.refresh = function () {
                    var self = this, refreshDefer = $q.defer();
                    $ay_requester.GET(self.location, {}).then(function (results) {
                        if (!results.error) {
                            self._set(results.data);
                        }
                        refreshDefer.resolve(results);
                    });
                    return refreshDefer.promise;
                };
                Tenancy_Model.prototype.update = function (fields) {
                    function createTargets(item, index) {
                        var target = fields[index], change = {
                                op: target.op ? target.op : 'replace',
                                path: $utls.toRestPointer(target.field)
                            };
                        if (change.op === 'replace' || change.op === 'add' || change.op === 'test') {
                            change.value = $utls.jsonPointer(s, target.field);
                        }
                        return change;
                    }
                    var s = this, params = {
                            fields: fields,
                            media_type: s.media_type,
                            data: Array.apply(null, new Array(fields.length)).map(createTargets)
                        };
                    return $ay_requester.PATCH(s.location, params);
                };
                Tenancy_Model.prototype.toggleActivation = function (userId, activate) {
                    var self = this, sendUpdate = false, index = self.entitlements[self.loginPermIndex].assigned_user_ids.indexOf(userId);
                    if (activate) {
                        if (index === -1) {
                            sendUpdate = true;
                            self.entitlements[self.loginPermIndex].assigned_user_ids.push(userId);
                        }
                    } else {
                        if (index !== -1) {
                            sendUpdate = true;
                            self.entitlements[self.loginPermIndex].assigned_user_ids.splice(index, 1);
                        }
                    }
                    if (sendUpdate) {
                        return self.save();
                    } else {
                        var updateDefer = $q.defer();
                        $timeout(function () {
                            updateDefer.resolve({});
                        }, 1);
                        return updateDefer.promise;
                    }
                };
                Tenancy_Model.prototype.updatePayment = function (token) {
                    var self = this;
                    return $ay_requester.POST(self.location, {
                        data: {
                            action: 'update_credit_card',
                            stripe_token: token
                        },
                        retrieveEntity: false
                    });
                };
                factory._buildOptions = function (params) {
                    var options = {
                        f: [],
                        s: [{
                                'field': '/name',
                                'descending': false
                            }]
                    };
                    if (params) {
                        if (params.filters) {
                            options.f = angular.copy(params.filters);
                        }
                        if (params.sorts) {
                            options.s = options.s.concat(angular.copy(params.sorts));
                        }
                        if (params.terms) {
                            options.f.push({
                                field: '/name',
                                op: 'CONTAINS',
                                value: params.terms
                            });
                        }
                    }
                    return options;
                };
                factory.newModel = function (data) {
                    return new Tenancy_Model(data);
                };
                factory.getSchema = function () {
                    var s = this, schemaDefer = $q.defer();
                    function setEntitySchema(results) {
                        if (!results.error) {
                            s.schema = results.data;
                            schemaDefer.resolve(s.schema);
                        } else {
                            schemaDefer.resolve(results);
                        }
                    }
                    if (s.schema) {
                        $timeout(function () {
                            schemaDefer.resolve(s.schema);
                        }, 1);
                    } else {
                        $ay_requester.GET('schema/' + LOCATION).then(setEntitySchema);
                    }
                    return schemaDefer.promise;
                };
                factory.get = function (params) {
                    var getPromise, drainPromise, self = this, location = LOCATION, cancelled = false, getDefer = $q.defer(), models = [];
                    function setModels(results) {
                        if (!cancelled) {
                            if (results.error) {
                                getDefer.resolve(results);
                            } else {
                                if (!results.data.hasOwnProperty('items')) {
                                    results.data = new Tenancy_Model(results.data);
                                    getDefer.resolve(results);
                                } else {
                                    models = models.concat(results.data.items.map(function (data) {
                                        return new Tenancy_Model(data);
                                    }));
                                    results.data = models;
                                    if (!params.drain) {
                                        getDefer.resolve(results);
                                    } else {
                                        if (!results.cursor) {
                                            getDefer.resolve(results);
                                        } else {
                                            params.cursor = results.cursor;
                                            drainPromise = $ay_requester.GET(location, params);
                                            drainPromise.then(setModels);
                                        }
                                    }
                                }
                            }
                        }
                    }
                    self.getSchema().then(function (results) {
                        params = params || {};
                        if (!cancelled) {
                            if (params.id) {
                                location += '/' + params.id;
                            } else {
                                params.options = self._buildOptions(params);
                            }
                            getPromise = $ay_requester.GET(location, params);
                            getPromise.then(setModels);
                        }
                    });
                    getDefer.promise.cancel = function () {
                        cancelled = true;
                        if (getPromise && getPromise.hasOwnProperty('cancel')) {
                            getPromise.cancel();
                        }
                        if (drainPromise && drainPromise.hasOwnProperty('cancel')) {
                            drainPromise.cancel();
                        }
                        getDefer.resolve({
                            cancelled: true,
                            error: errorDetection('-1')
                        });
                    };
                    return getDefer.promise;
                };
                return factory;
            }
        ]
    };
});