define('modules/form-inputs/directives/input-readonly', [], function () {
    return {
        component: 'directive',
        name: 'ayFormInputReadonly',
        fn: [function () {
                return {
                    restrict: 'E',
                    templateUrl: 'form-inputs/views/input-readonly.html',
                    require: '?ngModel',
                    scope: {
                        label: '@',
                        helpText: '@',
                        type: '@'
                    },
                    controller: [
                        '$attrs',
                        function ($attrs) {
                            var self = this;
                            self.value = '';
                            self.isNotEmpty = false;
                            function updateValue(val) {
                                self.value = val;
                            }
                            if ($attrs.output) {
                                $attrs.$observe('output', updateValue);
                            }
                        }
                    ],
                    controllerAs: 'formInput',
                    bindToController: true,
                    link: function (scope, elem, attrs, ngModel) {
                        scope.formInput.isMulti = attrs.isMulti ? true : false;
                        function bindModel() {
                            scope.formInput.value = ngModel.$modelValue;
                            if (scope.formInput.value) {
                                scope.formInput.isNotEmpty = true;
                            }
                        }
                        if (ngModel) {
                            ngModel.$render = bindModel;
                        }
                    }
                };
            }]
    };
});