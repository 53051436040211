define('modules/rest/factories/views', [], function () {
    'use strict';
    return {
        component: 'service',
        name: 'restFactoryViews',
        fn: [
            '$log',
            'errorDetection',
            '$schemaValidator',
            '$ay_requester',
            '$q',
            '$timeout',
            '$ME',
            '$utls',
            function view_factory($log, errorDetection, $schemaValidator, $ay_requester, $q, $timeout, $ME, $utls) {
                var LOCATION = 'views', media_type = 'application/vnd.amity.hub.view+json';
                function Model_View(_data, schema) {
                    this.schema = schema;
                    this.id = null;
                    this.media_type = media_type;
                    this.etag = null;
                    this.workspace_id = $ME.workspace.id;
                    this.created_at = null;
                    this.entity_media_type = null;
                    this.owner_user_id = null;
                    this.name = null;
                    this.description = null;
                    this.filters = null;
                    this.sorts = null;
                    this.limit = null;
                    this.location = LOCATION + '/';
                    this.columns = null;
                    this.columnsJson = null;
                    this.is_default = null;
                    this.isShared = null;
                    this.group = null;
                    this.custom_heading = null;
                    this.set = function set_data(data) {
                        this.id = data.id;
                        this.location = LOCATION + '/' + data.id;
                        this.media_type = data.media_type || media_type;
                        this.etag = data.etag;
                        this.workspace_id = data.workspace_id || $ME.workspace.id;
                        this.created_at = data.created_at;
                        this.entity_media_type = data.entity_media_type;
                        this.owner_user_id = data.owner_user_id;
                        this.name = data.name;
                        this.description = data.description;
                        this.filters = data.filters || [];
                        this.sorts = data.sorts || [];
                        this.limit = data.limit;
                        this.columns = data.columns || [];
                        this.is_default = data.is_default;
                        this.isShared = !data.owner_user_id;
                        this.group = this.isShared ? 'Shared' : 'Private';
                        this.custom_heading = data.custom_heading;
                        this.setColumnPointers();
                    };
                    this.save = function save_view() {
                        var self = this, save_defer = $q.defer(), validation_res = $schemaValidator.validate(self, self.schema);
                        function complete_request(results) {
                            if (!results.error) {
                                self.set(validation_res.data);
                                self.etag = results.data.etag;
                                results.data = self;
                            }
                            save_defer.resolve(results);
                        }
                        function save_data() {
                            $ay_requester.PUT(self.location, {
                                etag: self.etag,
                                data: validation_res.data
                            }).then(complete_request);
                        }
                        function update_etag(results) {
                            if (!results.error) {
                                self.set(results.data);
                                save_data();
                            }
                        }
                        if (validation_res.error) {
                            $timeout(function () {
                                complete_request(validation_res);
                            }, 1);
                        } else {
                            if (self.etag) {
                                save_data();
                            } else {
                                $ay_requester.GET(self.location).then(update_etag);
                            }
                        }
                        return save_defer.promise;
                    };
                    this.remove = function delete_view() {
                        var self = this;
                        return $ay_requester.DELETE(self.location, {});
                    };
                    if (_data) {
                        this.set(_data);
                    }
                }
                Model_View.prototype.setColumnPointers = function () {
                    function columnsToJson(field) {
                        return $utls.toJsonPointer(field);
                    }
                    this.columnsJson = this.columns ? this.columns.map(columnsToJson) : [];
                };
                function Rest_Factory_Views() {
                }
                Rest_Factory_Views.prototype.location = LOCATION;
                Rest_Factory_Views.prototype.entities = {
                    account: {
                        mediaType: 'application/vnd.amity.hub.account+json',
                        hasColumns: true,
                        defaultProp: 'name',
                        hasFav: true
                    },
                    play: {
                        mediaType: 'application/vnd.amity.hub.play+json',
                        hasColumns: false
                    },
                    participant: {
                        mediaType: 'application/vnd.amity.hub.participant+json',
                        hasColumns: true,
                        defaultProp: 'full_name',
                        hasFav: true
                    },
                    newsfeed: {
                        mediaType: 'application/vnd.amity.hub.newsfeed+json',
                        hasColumns: false
                    },
                    work_item: {
                        mediaType: 'application/vnd.amity.hub.work_item+json',
                        hasColumns: false
                    }
                };
                Rest_Factory_Views.prototype.new_model = function new_model(data) {
                    var self = this;
                    return new Model_View(data, self.schema);
                };
                Rest_Factory_Views.prototype.create = function create_model(data) {
                    var self = this, create_defer = $q.defer();
                    data.workspace_id = $ME.workspace.id;
                    data.media_type = media_type;
                    self.getSchema().then(function () {
                        var validation_res = $schemaValidator.validate(data, self.schema);
                        if (validation_res.error) {
                            $timeout(function () {
                                create_defer.resolve(validation_res);
                            }, 1);
                        } else {
                            $ay_requester.POST(self.location, { data: validation_res.data }).then(function (results) {
                                if (!results.error) {
                                    results.data = self.new_model(results.data);
                                }
                                create_defer.resolve(results);
                            });
                        }
                    });
                    return create_defer.promise;
                };
                Rest_Factory_Views.prototype._buildOptions = function (params) {
                    var self = this, workspace_filter = {
                            field: '/workspace_id',
                            op: 'EQ',
                            value: $ME.workspace.id
                        }, entity_filter = {
                            field: '/entity_media_type',
                            op: 'EQ',
                            value: ''
                        }, user_filter = {
                            field: '/owner_user_id',
                            op: 'EQ',
                            value: $ME.data.id
                        }, shared_filter = {
                            field: '/owner_user_id',
                            op: 'EXISTS',
                            value: false
                        }, options = {
                            f: [],
                            s: []
                        };
                    if (params.entity_type) {
                        entity_filter.value = self.entities[params.entity_type].mediaType;
                        options.f = [{
                                op: 'OR',
                                value: [
                                    [
                                        workspace_filter,
                                        entity_filter,
                                        user_filter
                                    ],
                                    [
                                        workspace_filter,
                                        entity_filter,
                                        shared_filter
                                    ]
                                ]
                            }];
                    } else {
                        options.f = [{
                                op: 'OR',
                                value: [
                                    [
                                        workspace_filter,
                                        user_filter
                                    ],
                                    [
                                        workspace_filter,
                                        shared_filter
                                    ]
                                ]
                            }];
                    }
                    return options;
                };
                Rest_Factory_Views.prototype.getSchema = function get_entity_schema() {
                    var s = this, schemaDefer = $q.defer();
                    function setEntitySchema(results) {
                        if (!results.error) {
                            s.schema = results.data;
                            schemaDefer.resolve(s.schema);
                        } else {
                            schemaDefer.resolve(results);
                        }
                    }
                    if (s.schema) {
                        $timeout(function () {
                            schemaDefer.resolve(s.schema);
                        }, 1);
                    } else {
                        $ay_requester.GET('schema/' + LOCATION).then(setEntitySchema);
                    }
                    return schemaDefer.promise;
                };
                Rest_Factory_Views.prototype.get = function (params) {
                    var getPromise, drainPromise, self = this, cancelled = false, getDefer = $q.defer(), location = LOCATION, models = [];
                    function setModels(results) {
                        if (!cancelled) {
                            if (results.error) {
                                getDefer.resolve(results);
                            } else {
                                if (!results.data.hasOwnProperty('items')) {
                                    results.data = self.new_model(results.data);
                                    getDefer.resolve(results);
                                } else {
                                    models = models.concat(results.data.items.map(function (data) {
                                        return self.new_model(data);
                                    }));
                                    results.data = models;
                                    if (!params.drain) {
                                        getDefer.resolve(results);
                                    } else {
                                        if (!results.cursor) {
                                            getDefer.resolve(results);
                                        } else {
                                            params.cursor = results.cursor;
                                            drainPromise = $ay_requester.GET(location, params);
                                            drainPromise.then(setModels);
                                        }
                                    }
                                }
                            }
                        }
                    }
                    self.getSchema().then(function (results) {
                        params = params || {};
                        if (!cancelled) {
                            if (params.id) {
                                location += '/' + params.id;
                            } else {
                                params.drain = params.hasOwnProperty('drain') ? params.drain : true;
                                params.options = self._buildOptions(params);
                            }
                            getPromise = $ay_requester.GET(location, params);
                            getPromise.then(setModels);
                        }
                    });
                    getDefer.promise.cancel = function () {
                        cancelled = true;
                        if (getPromise && getPromise.hasOwnProperty('cancel')) {
                            getPromise.cancel();
                        }
                        if (drainPromise && drainPromise.hasOwnProperty('cancel')) {
                            drainPromise.cancel();
                        }
                        getDefer.resolve({
                            cancelled: true,
                            error: errorDetection('-1')
                        });
                    };
                    return getDefer.promise;
                };
                return new Rest_Factory_Views();
            }
        ]
    };
});