define('modules/rest/factories/subscription', [], function () {
    'use strict';
    return {
        component: 'service',
        name: 'modelSubscription',
        fn: [
            '$log',
            '$schemaValidator',
            '$ay_requester',
            '$q',
            '$timeout',
            '$ME',
            'errorDetection',
            function subscriptio_factory($log, $schemaValidator, $ay_requester, $q, $timeout, $ME, errorDetection) {
                var LOCATION = 'subscriptions', factory = {
                        schema: null,
                        media_type: 'application/vnd.amity.hub.subscription+json'
                    };
                function Subscription_Model(data, schema) {
                    var self = this;
                    self.location = LOCATION + '/';
                    self.schema = schema;
                    self.entity_type = 'subscription';
                    self.account = null;
                    self.media_type = null;
                    self.billing_period = null;
                    self.created_at = null;
                    self.currency_code = null;
                    self.disposition = null;
                    self.etag = null;
                    self.expired_at = null;
                    self.external_ids = null;
                    self.id = null;
                    self.name = null;
                    self.notes = null;
                    self.one_time_amount = null;
                    self.prior = null;
                    self.quantity = null;
                    self.recurring_amount = null;
                    self.activated_at = null;
                    self.workspace_id = $ME.workspace.id;
                    self.cancelled_at = null;
                    self.accepted_at = null;
                    self.auto_renew = null;
                    self.components = null;
                    if (data) {
                        self.set(data);
                    }
                }
                Subscription_Model.prototype.set = function (data) {
                    var self = this;
                    self.location = LOCATION + '/' + data.id;
                    self.account = data.account;
                    if (self.account.removeEmptyListObjects) {
                        self.account.roles = self.account.removeEmptyListObjects(self.account.roles, 'user_id');
                    }
                    self.billing_period = data.billing_period;
                    self.media_type = data.media_type;
                    self.created_at = data.created_at;
                    self.currency_code = data.currency_code;
                    self.disposition = data.disposition;
                    self.etag = data.etag;
                    self.external_ids = data.external_ids;
                    self.id = data.id;
                    self.name = data.name;
                    self.notes = data.notes;
                    self.one_time_amount = data.one_time_amount;
                    self.prior = data.prior || {};
                    self.quantity = data.quantity;
                    self.recurring_amount = data.recurring_amount;
                    self.activated_at = new Date(data.activated_at);
                    self.expired_at = data.expired_at ? new Date(data.expired_at) : null;
                    self.cancelled_at = data.cancelled_at ? new Date(data.cancelled_at) : null;
                    self.accepted_at = data.accepted_at ? new Date(data.accepted_at) : null;
                    self.auto_renew = data.auto_renew;
                    self.components = data.components || [];
                    self.components.sort_by('name');
                    self.setStatus();
                };
                Subscription_Model.prototype.setStatus = function () {
                    var self = this, curTime = new Date().valueOf();
                    self.status = self.disposition ? self.disposition : '';
                    if (!self.status) {
                        if (curTime >= self.accepted_at.valueOf() && curTime <= self.activated_at.valueOf()) {
                            self.status = 'Accepted';
                        } else if (curTime < self.accepted_at.valueOf()) {
                            self.status = 'Pending';
                        } else {
                            self.status = 'Active';
                        }
                    }
                };
                Subscription_Model.prototype.getEtag = function () {
                    var self = this, etagDefer = $q.defer();
                    if (self.etag) {
                        $timeout(function () {
                            etagDefer.resolve({ etag: self.etag });
                        }, 1);
                    } else {
                        $ay_requester.GET(self.location).then(function (results) {
                            if (!results.error) {
                                self.etag = results.data.etag;
                                results.etag = self.etag;
                            }
                            etagDefer.resolve(results);
                        });
                    }
                    return etagDefer.promise;
                };
                Subscription_Model.prototype.save = function () {
                    var self = this, saveDefer = $q.defer(), validation_res = $schemaValidator.validate(angular.copy(self), factory.schema);
                    self.getEtag().then(function (results) {
                        if (results.error) {
                            saveDefer.resolve(results);
                        } else {
                            if (validation_res.error) {
                                saveDefer.resolve(validation_res);
                            } else {
                                $ay_requester.PUT(self.location, {
                                    etag: results.etag,
                                    data: validation_res.data
                                }).then(function complete_request(results) {
                                    if (!results.error) {
                                        self.set(results.data);
                                        results.data = self;
                                    }
                                    saveDefer.resolve(results);
                                });
                            }
                        }
                    });
                    return saveDefer.promise;
                };
                Subscription_Model.prototype.update = function (fields) {
                    function createTargets(item, index) {
                        var target = fields[index], change = {
                                op: target.op ? target.op : 'replace',
                                path: '/' + target.field.replace(/^_/g, '').replace(/(\[\")|(\.)/g, '/').replace(/(\"\])/g, '')
                            };
                        if ((change.op === 'replace' || change.op === 'add' || change.op === 'test') && target.value === undefined) {
                            change.value = s[target.field];
                        } else {
                            change.value = target.value;
                        }
                        return change;
                    }
                    var s = this, params = {
                            fields: fields,
                            media_type: s.media_type,
                            data: Array.apply(null, new Array(fields.length)).map(createTargets)
                        };
                    return $ay_requester.PATCH(s.location, params);
                };
                Subscription_Model.prototype.delete = function () {
                    var self = this, deleteDefer = $q.defer();
                    self.getEtag().then(function (results) {
                        if (results.error) {
                            deleteDefer.resolve(results);
                        } else {
                            $ay_requester.DELETE(self.location, {}).then(deleteDefer.resolve);
                        }
                    });
                    return deleteDefer.promise;
                };
                factory.new_model = function (data) {
                    return new Subscription_Model(data, this.schema);
                };
                factory._build_query = function (params) {
                    var options = {
                        f: [{
                                field: '/workspace_id',
                                op: 'EQ',
                                value: $ME.workspace.id
                            }],
                        s: []
                    };
                    if (params.hasOwnProperty('filters')) {
                        options.f = options.f.concat(params.filters);
                        if (!params.hasOwnProperty('priors')) {
                            var dispositionIndex = options.f._index_of_by_prop('field', '/disposition');
                            if (dispositionIndex !== -1) {
                                if (!options.f[dispositionIndex].hasOwnProperty('value') || options.f[dispositionIndex].value === null || options.f[dispositionIndex].value === undefined) {
                                    options.f[dispositionIndex].op = 'HAS_A_VALUE';
                                    options.f[dispositionIndex].value = false;
                                }
                            }
                        }
                    }
                    if (params.hasOwnProperty('priors') && params.priors) {
                        options.f.push({
                            field: '/disposition',
                            op: 'IN',
                            value: [
                                'amended',
                                'renewed'
                            ]
                        });
                    }
                    if (params.hasOwnProperty('account_id')) {
                        options.f.push({
                            field: '/account/id',
                            op: 'EQ',
                            value: params.account_id
                        });
                    }
                    if (params.sorts) {
                        options.s = angular.copy(params.sorts);
                    } else {
                        options.s.push({
                            field: '/expired_at',
                            descending: true
                        });
                    }
                    return options;
                };
                factory.getSchema = function get_entity_schema() {
                    var s = this, schemaDefer = $q.defer();
                    function setEntitySchema(results) {
                        if (!results.error) {
                            s.schema = results.data;
                            schemaDefer.resolve(s.schema);
                        } else {
                            schemaDefer.resolve(results);
                        }
                    }
                    if (s.schema) {
                        $timeout(function () {
                            schemaDefer.resolve(s.schema);
                        }, 1);
                    } else {
                        $ay_requester.GET('schema/' + LOCATION).then(setEntitySchema);
                    }
                    return schemaDefer.promise;
                };
                factory.get = function (params) {
                    var location, getPromise, drainPromise, self = this, cancelled = false, getDefer = $q.defer(), models = [];
                    params = params || {};
                    function setModels(results) {
                        if (!cancelled) {
                            if (!results.error && results.data) {
                                if (results.data.hasOwnProperty('items')) {
                                    models = results.data.items.map(self.new_model, self);
                                    results.data = models;
                                    if (!params.drain) {
                                        getDefer.resolve(results);
                                    } else {
                                        if (!results.cusor) {
                                            getDefer.resolve(results);
                                        } else {
                                            params.cursor = results.cursor;
                                            drainPromise = $ay_requester.GET(location, params);
                                            drainPromise.then(setModels);
                                        }
                                    }
                                } else {
                                    results.data = self.new_model(results.data);
                                    getDefer.resolve(results);
                                }
                            } else {
                                getDefer.resolve(results);
                            }
                        }
                    }
                    self.getSchema().then(function (schema_res) {
                        if (!cancelled) {
                            if (schema_res.error) {
                                getDefer.resolve(schema_res);
                            } else {
                                if (params.id) {
                                    location = LOCATION + '/' + params.id;
                                } else {
                                    location = LOCATION;
                                    params.options = self._build_query(params);
                                }
                                getPromise = $ay_requester.GET(location, params);
                                getPromise.then(setModels);
                            }
                        }
                    });
                    getDefer.promise.cancel = function () {
                        cancelled = true;
                        if (getPromise && getPromise.hasOwnProperty('cancel')) {
                            getPromise.cancel();
                        }
                        if (drainPromise && drainPromise.hasOwnProperty('cancel')) {
                            drainPromise.cancel();
                        }
                        getDefer.resolve({
                            cancelled: true,
                            error: errorDetection(0)
                        });
                    };
                    return getDefer.promise;
                };
                factory.create = function (data) {
                    var validation_res, self = this, create_defer = $q.defer();
                    self.getSchema().then(function (schema_res) {
                        if (schema_res.error) {
                            create_defer.resolve(schema_res);
                        } else {
                            data.media_type = self.schema.description;
                            validation_res = $schemaValidator.validate(data, self.schema);
                            if (validation_res.error) {
                                create_defer.resolve(validation_res);
                            } else {
                                $ay_requester.POST(LOCATION, { data: validation_res.data }).then(function (complete_res) {
                                    if (!complete_res.error) {
                                        complete_res.data = self.new_model(complete_res.data);
                                    }
                                    create_defer.resolve(complete_res);
                                });
                            }
                        }
                    });
                    return create_defer.promise;
                };
                return factory;
            }
        ]
    };
});