define('modules/form-inputs/directives/entity-filter', [], function () {
    return {
        component: 'directive',
        name: 'ayFormEntityFilter',
        fn: [
            'srvcMappingFields',
            '$timeout',
            '$log',
            '$q',
            function (srvcMappingFields, $timeout, $log, $q) {
                return {
                    restrict: 'A',
                    priority: 500,
                    scope: {
                        item: '=ayFormEntityFilter',
                        fieldMap: '=fieldMap',
                        on_remove: '=onRemove',
                        on_replace: '=onReplace',
                        parent_list: '=parentList',
                        validator_parent: '=validatorParent',
                        onChange: '&onChange',
                        isDisabled: '=isDisabled'
                    },
                    templateUrl: 'form-inputs/views/entity-filter.html',
                    link: function ($scope, elem, attrs) {
                        $scope.has_remove = attrs.onRemove;
                        $scope.has_replace = attrs.onReplace;
                        $scope.input_types = srvcMappingFields.input_types;
                        $scope.fields = angular.copy($scope.fieldMap).sort_by('label');
                        $scope.minItems = attrs.minItems && !isNaN(parseInt(attrs.minItems, 10)) ? parseInt(attrs.minItems, 10) : 0;
                        var cp, index = $scope.fields._index_of_by_prop('field', $scope.item.map.field);
                        $scope.item.map = $scope.fields[index];
                        $scope.item.validate = function () {
                            var validationPromises = [], validationDefer = $q.defer(), entityValues = elem.find('.entity-filter-value'), validator = $scope.validator_parent ? $scope.validator_parent : $scope.ay_validate;
                            function resolveValidation(invalid) {
                                invalid = invalid === undefined ? true : invalid;
                                validationDefer.resolve(invalid);
                            }
                            function validationComplete(results) {
                                var invalid = results.indexOf(true) !== -1;
                                resolveValidation(invalid);
                            }
                            for (var i = 0, ii = entityValues.length; i < ii; i++) {
                                validationPromises.push(validator.run(entityValues[i]));
                            }
                            if (!validationPromises.length) {
                                $timeout(resolveValidation, 1);
                            } else {
                                $q.all(validationPromises).then(validationComplete);
                            }
                            return validationDefer.promise;
                        };
                        if ($scope.validator_parent) {
                            $scope.ay_validate = $scope.validator_parent;
                        }
                        $scope.validators = {
                            multiPicklist: function (vEl, vAttrs, vNgModelCtrl) {
                                var errMsg;
                                if (!vNgModelCtrl.$viewValue.length) {
                                    errMsg = 'This field is required.';
                                }
                                return errMsg;
                            },
                            item_list_string: function (vEl, vAttrs, vNgModelCtrl) {
                                var msg;
                                if (!vNgModelCtrl.$viewValue || !vNgModelCtrl.$viewValue.length) {
                                    msg = 'This field is required.';
                                }
                                return msg;
                            }
                        };
                        $scope.changes = {
                            item_list_string: function (item) {
                                item.filter.value = !item.value.length ? [] : item.value.map(function (val) {
                                    return val[item.map.value_id_field];
                                });
                            }
                        };
                        function isDefined(value) {
                            return value !== null && value !== undefined;
                        }
                        function sendChange() {
                            if ($scope.onChange) {
                                $scope.onChange();
                            }
                        }
                        $scope.remove = function () {
                            if ($scope.has_remove) {
                                $scope.on_remove($scope.$parent.$index, $scope.parent_list);
                            }
                        };
                        $scope.replace = function () {
                            if ($scope.has_replace) {
                                if (!$scope.on_replace) {
                                    $log.error({
                                        error: {
                                            message: 'Entity Filter missing replace function (v2)',
                                            data: { item: $scope.item }
                                        }
                                    });
                                } else {
                                    $scope.on_replace($scope.$parent.$index, $scope.parent_list);
                                    $timeout(function () {
                                        $scope.item_map_changed($scope.item);
                                    });
                                }
                            } else {
                                $scope.remove();
                            }
                        };
                        $scope.item_input_value_changed = function item_filter_value_changed(item, update) {
                            if (isDefined(item.value)) {
                                if (item.value.fn) {
                                    item.value.fn(item.value.input_value, item, update);
                                } else if (isDefined(item.value.input_value)) {
                                    item.filter.value = item.value.input_value;
                                } else {
                                    item.filter.value = item.value;
                                }
                            } else {
                                item.filter.value = item.value;
                            }
                        };
                        $scope.setDefaultValue = function (item) {
                            var valueDefault;
                            if (item.map.hasOwnProperty('value_default') && isDefined(item.map.value_default)) {
                                valueDefault = item.map.value_default;
                            }
                            if (isDefined(item.value) && item.value.hasOwnProperty('value_default') && isDefined(item.value.value_default)) {
                                valueDefault = item.value.value_default;
                            }
                            if (item.op.hasOwnProperty('valueDefault') && isDefined(item.op.valueDefault)) {
                                item.filter.value = angular.copy(item.op.valueDefault);
                            } else {
                                if (isDefined(item.value) && item.value.fn) {
                                    item.value.input_value = angular.copy(valueDefault);
                                } else {
                                    if (valueDefault !== null && valueDefault !== undefined) {
                                        item.filter.value = angular.copy(valueDefault);
                                    } else if (item.map.hasOwnProperty('values') && item.map.hasOwnProperty('value_field')) {
                                        item.filter.value = $scope.item.value[item.map.value_field];
                                    } else {
                                        item.filter.value = angular.copy($scope.item.value);
                                    }
                                }
                            }
                        };
                        $scope.item_value_changed = function item_value_changed(item, update) {
                            function valueList(itemValue) {
                                return itemValue[item.map.propDef.itemId];
                            }
                            if (isDefined(item.value) && item.value.hasOwnProperty('input_type')) {
                                if (item.value.hasOwnProperty('value_default')) {
                                    $scope.setDefaultValue(item);
                                }
                                $scope.item_input_value_changed(item, update);
                            } else if (item.map.value_field && isDefined(item.value)) {
                                if (item.value.fn) {
                                    item.value.fn(item.value, item);
                                } else if (!isDefined(item.value.value) && item.value.hasOwnProperty(item.map.value_id_field)) {
                                    delete item.filter.fn;
                                    item.filter.value = item.value[item.map.value_id_field];
                                } else if (isDefined(item.value.value)) {
                                    item.filter.value = item.value.value;
                                } else if (Array.isArray(item.value)) {
                                    switch ($scope.item.map.input_type) {
                                    case $scope.input_types.multi_number_picklist:
                                        item.filter.value = item.value;
                                        break;
                                    default:
                                        item.filter.value = item.value.map(valueList);
                                        break;
                                    }
                                } else {
                                    item.filter.value = item.value;
                                }
                            } else {
                                item.filter.value = item.value;
                            }
                            sendChange();
                        };
                        $scope.item_op_changed = function item_op_changed(item, update) {
                            var index, values = item.op.hasOwnProperty('values') ? item.op.values : item.map.hasOwnProperty('values') ? item.map.values : null;
                            item.filter.op = item.op.op;
                            delete item.filter.fn;
                            if (!item.op.valueInput && (update || item.op.values || !item.op.values && item.map.values && item.map.values._index_of_by_prop(item.map.value_field, item.filter.value) === -1)) {
                                if (!values) {
                                    item.filter.value = undefined;
                                    item.value = '';
                                } else {
                                    if ($scope.item.map.input_type !== $scope.input_types.multi_string_picklist && $scope.item.map.input_type !== $scope.input_types.multi_number_picklist && $scope.item.op.input_type !== $scope.input_types.multi_string_picklist && $scope.item.op.input_type !== $scope.input_types.item_list_string) {
                                        index = values._index_of_by_prop('enabled', true);
                                        item.value = values[index];
                                        $scope.setDefaultValue(item);
                                    } else if ($scope.item.op.values && $scope.item.op.values.length) {
                                        item.value = $scope.item.op.values[0];
                                    } else {
                                        item.value = [];
                                    }
                                }
                                $scope.item_value_changed(item, update);
                            } else if (item.op.valueInput) {
                                item.filter.value = undefined;
                                if (update && !isDefined(item.value)) {
                                    $scope.item_value_changed(item, update);
                                } else {
                                    if ($scope.item.map.input_type !== $scope.input_types.multi_string_picklist && $scope.item.map.input_type !== $scope.input_types.multi_number_picklist && $scope.item.op.input_type !== $scope.input_types.multi_number_picklist && $scope.item.op.input_type !== $scope.input_types.item_list_string) {
                                        if (item.op.valueInput !== $scope.input_types.picklist) {
                                            $scope.setDefaultValue(item);
                                            item.value = item.filter.value;
                                            $scope.item_value_changed(item, update);
                                        }
                                    } else {
                                        item.value = [];
                                    }
                                }
                            } else {
                                if ($scope.item.op.input_type === $scope.input_types.multi_string_picklist || $scope.item.op.input_type === $scope.input_types.item_list_string) {
                                    item.value = [];
                                } else if ($scope.item.op.input_type === $scope.input_types.string) {
                                    item.value = '';
                                } else {
                                    $scope.setDefaultValue(item);
                                }
                            }
                            sendChange();
                        };
                        function checkPropertyType() {
                            switch ($scope.item.map.input_type) {
                            case $scope.input_types.date:
                                $scope.item.map.propDef.editOptions.select_time = false;
                                if ($scope.item.map.propDef.editOptions.force_redraw) {
                                    $scope.item.map.propDef.editOptions.force_redraw();
                                }
                                break;
                            }
                        }
                        $scope.item_map_changed = function item_map_changed(item) {
                            delete item.filter.fn;
                            item.filter.field = item.map.field;
                            item.op = item.map.operators[0];
                            $scope.item_op_changed(item, true);
                            checkPropertyType();
                            sendChange();
                        };
                        if (!$scope.item.op) {
                            $scope.item_map_changed($scope.item);
                        } else {
                            index = $scope.item.map.operators._index_of_by_prop('op', $scope.item.filter.op);
                            $scope.item.op = $scope.item.map.operators[index];
                            cp = angular.copy($scope.item.value);
                            checkPropertyType();
                            if ($scope.item.op.hasOwnProperty('values')) {
                                index = $scope.item.op.values._index_of_by_prop('value', cp.value);
                                $scope.item.value = $scope.item.op.values[index];
                            } else if ($scope.item.map.input_type !== $scope.input_types.multi_string_picklist && $scope.item.map.input_type !== $scope.input_types.multi_number_picklist && $scope.item.op.input_type !== $scope.input_types.multi_string_picklist && $scope.item.op.input_type !== $scope.input_types.item_list_string) {
                                if ($scope.item.map.hasOwnProperty('values') && !$scope.item.op.valueInput) {
                                    index = $scope.item.map.values._index_of_by_prop($scope.item.map.value_id_field, cp[$scope.item.map.value_id_field]);
                                    $scope.item.value = $scope.item.map.values[index];
                                }
                            }
                            if (cp.hasOwnProperty('input_value')) {
                                $scope.item.value.input_value = cp.input_value;
                            }
                        }
                    }
                };
            }
        ]
    };
});