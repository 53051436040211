define('modules/rest/factories/play', [], function () {
    'use strict';
    return {
        component: 'service',
        name: 'modelPlay',
        fn: [
            '$utls',
            'Phare',
            '$log',
            '$schemaValidator',
            '$ME',
            '$q',
            '$timeout',
            'modelWorkitem',
            '$ay_requester',
            'errorDetection',
            'restFactoryUser',
            function play_factory($utls, Phare, $log, $schemaValidator, $ME, $q, $timeout, modelWorkitem, $ay_requester, errorDetection, restFactoryUser) {
                var LOCATION = 'plays', factory = { media_type: 'application/vnd.amity.hub.play+json' };
                function Play_Model(data, userList) {
                    var self = this;
                    self.data_type = 'play';
                    self.entity_type = 'play';
                    self.id = null;
                    self.location = LOCATION + '/';
                    self.etag = null;
                    self.workspace_id = null;
                    self.name = null;
                    self.created_at = null;
                    self.media_type = null;
                    self.playbook_id = null;
                    self.playbook_stage_id = null;
                    self.account = null;
                    self.created_by_user = null;
                    self.assigned_to_user = null;
                    self.stage_entered_at = null;
                    self.stage_exit_deadline = null;
                    self.deadline = null;
                    self.success = null;
                    self.closed_at = null;
                    self.notes = null;
                    self.urls = null;
                    self.tags = null;
                    self.progress = {};
                    self.tasks = [];
                    self.ongoing_tasks = [];
                    self.completed_tasks = [];
                    self.external_ids = [];
                    self.custom_properties = null;
                    self.flagged = null;
                    self.work_items_incomplete = null;
                    self.work_items_total = null;
                    self.notes = null;
                    if (data) {
                        self.set(data, userList);
                    }
                }
                Play_Model.prototype.getEtag = function () {
                    var self = this, etagDefer = $q.defer();
                    $ay_requester.GET(self.location).then(function (results) {
                        if (!results.error) {
                            self.etag = results.data.etag;
                            results.etag = self.etag;
                        }
                        etagDefer.resolve(results);
                    });
                    return etagDefer.promise;
                };
                Play_Model.prototype.getTaskData = function () {
                    var self = this;
                    return self.getEtag();
                };
                Play_Model.prototype.delete = function delete_play() {
                    var self = this, deleteDefer = $q.defer();
                    self.getEtag().then(function (results) {
                        if (!results.error) {
                            $ay_requester.DELETE(self.location, {}).then(deleteDefer.resolve);
                        } else {
                            deleteDefer.resolve(results);
                        }
                    });
                    return deleteDefer.promise;
                };
                Play_Model.prototype._cleanUp = function () {
                    var i, validation_res, self = angular.copy(this);
                    for (i in self.custom_properties) {
                        if (self.custom_properties[i] === null || self.custom_properties[i] === undefined) {
                            delete self.custom_properties[i];
                        }
                    }
                    if (self.account && self.account.removeEmptyListObjects) {
                        self.account.roles = self.account.removeEmptyListObjects(self.account.roles, 'user_id');
                    }
                    validation_res = $schemaValidator.validate(self, factory.schema);
                    return validation_res;
                };
                Play_Model.prototype.updateNotes = function (index) {
                    var self = this, saveDefer = $q.defer(), validation_res = self._cleanUp();
                    self.getEtag().then(function (results) {
                        if (results.error) {
                            saveDefer.resolve(results);
                        } else {
                            if (validation_res.error) {
                                saveDefer.resolve(validation_res);
                            } else {
                                if (!angular.equals(validation_res.data.notes[index].html_body, self.notes[index].html_body)) {
                                    $log.error({
                                        error: { message: 'Account Note: unexpected note change' },
                                        acccountId: self.id,
                                        noteIndex: index,
                                        noteValidated: validation_res.data.notes[index],
                                        noteLocal: angular.copy(self.notes[index])
                                    });
                                    validation_res.notes[index].html_body = self.notes[index].html_body;
                                }
                                $ay_requester.PUT(self.location, {
                                    etag: results.etag,
                                    data: validation_res.data
                                }).then(function complete_request(results) {
                                    if (!results.error) {
                                        self.set(results.data);
                                        results.data = self;
                                    }
                                    saveDefer.resolve(results);
                                });
                            }
                        }
                    });
                    return saveDefer.promise;
                };
                Play_Model.prototype.save = function () {
                    var self = this, saveDefer = $q.defer(), validation_res = self._cleanUp();
                    self.getEtag().then(function (results) {
                        if (results.error) {
                            saveDefer.resolve(results);
                        } else {
                            if (validation_res.error) {
                                saveDefer.resolve(validation_res);
                            } else {
                                $ay_requester.PUT(self.location, {
                                    etag: results.etag,
                                    data: validation_res.data
                                }).then(function complete_request(results) {
                                    if (!results.error) {
                                        self.set(results.data);
                                        results.data = self;
                                    }
                                    saveDefer.resolve(results);
                                });
                            }
                        }
                    });
                    return saveDefer.promise;
                };
                Play_Model.prototype.update = function (fields) {
                    function createTargets(item, index) {
                        var target = fields[index], change = {
                                op: target.op ? target.op : 'replace',
                                path: '/' + target.field.replace(/^_/g, '').replace(/(\[\")|(\.)/g, '/').replace(/(\"\])/g, '')
                            };
                        if ((change.op === 'replace' || change.op === 'add' || change.op === 'test') && target.value === undefined) {
                            change.value = s[target.field];
                        } else {
                            change.value = target.value;
                        }
                        return change;
                    }
                    var s = this, params = {
                            fields: fields,
                            media_type: s.media_type,
                            data: Array.apply(null, new Array(fields.length)).map(createTargets),
                            retrieveEntity: true
                        };
                    return $ay_requester.PATCH(s.location, params);
                };
                Play_Model.prototype.getStage = function (stageId) {
                    var self = this, playbookIndex = $ME.workspace.playbooks._index_of_by_prop('id', self.playbook_id), stageIndex = $ME.workspace.playbooks[playbookIndex].stages._index_of_by_prop('id', angular.isNumber(stageId) ? stageId : self.playbook_stage_id);
                    if (stageIndex !== -1) {
                        return $ME.workspace.playbooks[playbookIndex].stages[stageIndex];
                    }
                };
                Play_Model.prototype.changeStage = function (stageId) {
                    var self = this, changeDefer = $q.defer(), curStage = self.getStage(), newStage = self.getStage(stageId);
                    if (!newStage || !curStage) {
                        $timeout(function () {
                            changeDefer.resolve({ error: errorDetection(1005) });
                        });
                    } else {
                        if (curStage.completed) {
                            delete self.completed_at;
                        } else if (newStage.completed) {
                            self.completed_at = new Date()._format_ISO_local();
                        }
                        self.playbook_stage_id = stageId;
                        self.stage_entered_at = new Date()._format_ISO_local();
                        self.save().then(function (results) {
                            if (!results.error) {
                                self.set(results.data);
                                results.data = self;
                            }
                            changeDefer.resolve(results);
                        });
                    }
                    return changeDefer.promise;
                };
                Play_Model.prototype.set_outcome = function (outcome) {
                    var self = this, outcomeDefer = $q.defer();
                    self.getEtag().then(function (results) {
                        if (results.error) {
                            outcomeDefer.resolve(results);
                        } else {
                            if (outcome === null || outcome === undefined) {
                                delete self.success;
                                delete self.closed_at;
                            } else {
                                self.success = outcome;
                                self.closed_at = new Date();
                                Phare.track({ event: 'Play outcome: ' + (outcome ? 'Success' : 'Failure') });
                            }
                            self.save().then(outcomeDefer.resolve);
                        }
                    });
                    return outcomeDefer.promise;
                };
                Play_Model.prototype.set = function (data, userList) {
                    var self = this;
                    self.id = data.id;
                    self.location = LOCATION + '/' + data.id;
                    self.etag = data.etag;
                    self.workspace_id = data.workspace_id;
                    self.name = data.name;
                    self.created_at = data.created_at ? new Date(data.created_at) : null;
                    self.media_type = factory.schema ? factory.schema.description : data.media_type;
                    self.playbook_id = data.playbook_id;
                    self.playbook_stage_id = data.playbook_stage_id;
                    self.account = data.account;
                    self.created_by_user = !userList || !data.created_by_user ? data.created_by_user : userList[data.created_by_user.id];
                    self.assigned_to_user = !userList || !data.assigned_to_user ? data.assigned_to_user : userList[data.assigned_to_user.id];
                    self.stage_entered_at = data.stage_entered_at ? new Date(data.stage_entered_at) : null;
                    self.stage_exit_deadline = data.stage_exit_deadline ? new Date(data.stage_exit_deadline) : null;
                    self.deadline = data.deadline ? new Date(data.deadline) : null;
                    self.success = data.success;
                    self.closed_at = data.closed_at ? new Date(data.closed_at) : null;
                    self.urls = data.urls ? data.urls : [];
                    self.tags = data.tags ? data.tags : [];
                    self.tasks = data.tasks ? data.tasks : [];
                    self.external_ids = data.external_ids || [];
                    self.custom_properties = data.custom_properties || {};
                    self.flagged = data.flagged || false;
                    self.work_items_incomplete = data.work_items_incomplete;
                    self.work_items_total = data.work_items_total;
                    self.notes = data.notes || [];
                    switch (self.success) {
                    case true:
                        self._state = 'success';
                        break;
                    case false:
                        self._state = 'fail';
                        break;
                    default:
                        self._state = 'progress';
                        break;
                    }
                    self.progress = {
                        lifetime: $utls.progress(self.deadline, self.created_at),
                        stage: $utls.progress(self.stage_exit_deadline, self.stage_entered_at)
                    };
                };
                Play_Model.prototype.organize_tasks = function organize_tasks() {
                    var i, task, self = this;
                    self.ongoing_tasks = [];
                    self.completed_tasks = [];
                    for (i = 0; i < self.tasks.length; i++) {
                        task = self.tasks[i];
                        if (task.is_completed) {
                            self.completed_tasks.push(task);
                        } else {
                            self.ongoing_tasks.push(task);
                        }
                    }
                };
                Play_Model.prototype.add_task = function (task) {
                    var self = this;
                    self.tasks.push(task);
                    self.organize_tasks();
                };
                Play_Model.prototype.remove_task = function (task) {
                    var self = this, index = self.tasks._index_of_by_prop('id', task.id);
                    if (index !== -1) {
                        self.tasks.splice(index, 1);
                    }
                };
                Play_Model.prototype.get_tasks = function get_play_tasks() {
                    var self = this, cancelled = false, getRequest = $q.defer(), searchRequest = modelWorkitem.get({
                            play_id: self.id,
                            sorts: [{
                                    field: '/created_at',
                                    descending: false
                                }],
                            drain: true
                        });
                    getRequest.promise.cancel = function () {
                        searchRequest.cancel();
                        cancelled = true;
                    };
                    searchRequest.then(function (results) {
                        if (!results.error) {
                            if (results.data && results.data.length) {
                                self.tasks = results.data;
                                self.organize_tasks();
                            }
                        }
                        getRequest.resolve(results);
                    });
                    return getRequest.promise;
                };
                factory._buildOptions = function (params) {
                    var i, options = {
                            f: [],
                            s: []
                        };
                    if (params.hasOwnProperty('filters') && params.filters && params.filters.length) {
                        options.f = angular.copy(params.filters);
                    }
                    if (params.hasOwnProperty('sorts') && params.sorts && params.sorts.length) {
                        options.s = angular.copy(params.sorts);
                    } else {
                        options.s.push({
                            field: '/created_at',
                            descending: false
                        });
                    }
                    if (params.hasOwnProperty('playbook_id')) {
                        options.f.push({
                            field: '/playbook_id',
                            op: 'EQ',
                            value: params.playbook_id
                        });
                    }
                    if (params.hasOwnProperty('account_id')) {
                        options.f.push({
                            field: '/account/id',
                            op: 'EQ',
                            value: params.account_id
                        });
                    }
                    if (params.hasOwnProperty('account_ids')) {
                        options.f.push({
                            field: '/account/id',
                            op: 'IN',
                            value: params.account_ids
                        });
                    }
                    if (params.hasOwnProperty('success_state')) {
                        if (params.success_state !== 'all') {
                            options.f.push({
                                field: '/success',
                                op: 'EQ',
                                value: params.success_state
                            });
                        }
                    } else {
                        var outcomeIndex = options.f._index_of_by_prop('field', '/success');
                        var closedIndex = options.f._index_of_by_prop('field', '/closed_at');
                        if (outcomeIndex === -1) {
                            if (closedIndex === -1) {
                                options.f.push({
                                    field: '/closed_at',
                                    op: 'EXISTS',
                                    value: false
                                });
                            }
                        } else {
                            if (!options.f[outcomeIndex].hasOwnProperty('value') || options.f[outcomeIndex].value === null || options.f[outcomeIndex].value === undefined) {
                                options.f[outcomeIndex].op = 'HAS_A_VALUE';
                                options.f[outcomeIndex].value = false;
                            }
                        }
                    }
                    if (params.hasOwnProperty('terms')) {
                        options.f.push({
                            op: 'OR',
                            value: [
                                [{
                                        field: '/name',
                                        op: 'CONTAINS',
                                        value: params.terms
                                    }],
                                [{
                                        field: '/account/name',
                                        op: 'CONTAINS',
                                        value: params.terms
                                    }]
                            ]
                        });
                    }
                    return options;
                };
                factory.getSchema = function () {
                    var s = this, schemaDefer = $q.defer();
                    function setEntitySchema(results) {
                        if (!results.error) {
                            s.schema = results.data;
                            schemaDefer.resolve(s.schema);
                        } else {
                            schemaDefer.resolve(results);
                        }
                    }
                    if (s.schema) {
                        $timeout(function () {
                            schemaDefer.resolve(s.schema);
                        }, 1);
                    } else {
                        $ay_requester.GET('schema/' + LOCATION).then(setEntitySchema);
                    }
                    return schemaDefer.promise;
                };
                factory.newModel = function (data) {
                    return new Play_Model(data, self._userList);
                };
                factory.get = function (params) {
                    var getPromise, drainPromise, self = this, cancelled = false, getDefer = $q.defer(), location = LOCATION, models = [];
                    function setModels(results) {
                        if (!cancelled) {
                            if (results.error) {
                                getDefer.resolve(results);
                            } else {
                                restFactoryUser.get({
                                    drain: true,
                                    isDictionary: true
                                }).then(function (userResults) {
                                    if (userResults.error) {
                                        getDefer.resolve(results);
                                    } else {
                                        self._userList = userResults.data;
                                        if (!results.data.hasOwnProperty('items')) {
                                            results.data = self.newModel(results.data);
                                            getDefer.resolve(results);
                                        } else {
                                            models = models.concat(results.data.items.map.apply(results.data.items, [self.newModel]));
                                            results.data = models;
                                            if (!params.drain) {
                                                getDefer.resolve(results);
                                            } else {
                                                if (!results.cursor) {
                                                    getDefer.resolve(results);
                                                } else {
                                                    params.cursor = results.cursor;
                                                    drainPromise = $ay_requester.GET(location, params);
                                                    drainPromise.then(setModels);
                                                }
                                            }
                                        }
                                    }
                                });
                            }
                        }
                    }
                    self.getSchema().then(function (results) {
                        params = params || {};
                        if (!cancelled) {
                            if (params.id) {
                                location += '/' + params.id;
                            } else {
                                params.options = self._buildOptions(params);
                            }
                            getPromise = $ay_requester.GET(location, params);
                            getPromise.then(setModels);
                        }
                    });
                    getDefer.promise.cancel = function () {
                        cancelled = true;
                        if (getPromise && getPromise.hasOwnProperty('cancel')) {
                            getPromise.cancel();
                        }
                        if (drainPromise && drainPromise.hasOwnProperty('cancel')) {
                            drainPromise.cancel();
                        }
                        getDefer.resolve({
                            cancelled: true,
                            error: errorDetection('-1')
                        });
                    };
                    return getDefer.promise;
                };
                factory.create = function (data) {
                    var self = this, createDefer = $q.defer();
                    function post() {
                        data.media_type = factory.schema.description;
                        data.stage_entered_at = data.stage_entered_at ? new Date(data.stage_entered_at).toISOString() : new Date().toISOString();
                        if (data.account && data.account.removeEmptyListObjects) {
                            data.account.roles = data.account.removeEmptyListObjects(data.account.roles, 'user_id');
                        }
                        var validation_res = $schemaValidator.validate(data, factory.schema);
                        if (validation_res.error) {
                            $timeout(function () {
                                createDefer.resolve(validation_res);
                            }, 1);
                        } else {
                            $ay_requester.POST(LOCATION, { data: validation_res.data }).then(function (results) {
                                if (!results.error) {
                                    results.data = new Play_Model(results.data);
                                }
                                createDefer.resolve(results);
                            });
                        }
                    }
                    self.getSchema().then(post);
                    return createDefer.promise;
                };
                factory.create_shell = function create_shell(data) {
                    return new Play_Model(data);
                };
                return factory;
            }
        ]
    };
});