define('modules/rest/factories/engagements', [], function () {
    'use strict';
    return {
        component: 'service',
        name: 'modelEngagement',
        fn: [
            '$ay_requester',
            '$schemaValidator',
            '$log',
            '$q',
            '$timeout',
            '$ME',
            'errorDetection',
            function engagement_factory($ay_requester, $schemaValidator, $log, $q, $timeout, $ME, errorDetection) {
                var LOCATION = 'engagements', media_type = 'application/vnd.amity.hub.engagement+json', method_type_re = /^(?=.*\bincoming|outgoing)/, method_type_email = /.email\b/, emailStatusTypes = {
                        'bounced': '',
                        'processed': '',
                        'delivered': '',
                        'not_delivered': '',
                        'not_processed': ''
                    };
                function get_method_icon(method) {
                    switch (method) {
                    case 'incoming.email':
                    case 'outgoing.email':
                        return 'fa-envelope';
                    case 'phone':
                        return 'fa-phone';
                    case 'meeting':
                        return 'fa-users';
                    case 'incoming.chat':
                    case 'outgoing.chat':
                        return 'fa-comment';
                    default:
                        return 'fa-comments';
                    }
                }
                function mapParticipant(item) {
                    return item.participant;
                }
                function getTeam(list) {
                    var team = list.filter(function (item) {
                        return item.account_id === $ME.workspace.id;
                    });
                    if (team.length) {
                        team = team.map(mapParticipant);
                    }
                    return team;
                }
                function getPeople(list) {
                    var people = list.filter(function (item) {
                        return item.account_id !== $ME.workspace.id;
                    });
                    if (people.length) {
                        people = people.map(mapParticipant);
                    }
                    return people;
                }
                function summarizeText(text, max_size) {
                    var summary;
                    if (text.length > max_size) {
                        summary = text.substr(0, max_size - 3) + '...';
                    } else {
                        summary = text;
                    }
                    return summary;
                }
                function EngagementModel(data, schema) {
                    var self = this;
                    self.schema = schema;
                    self.data_type = 'engagement';
                    self.entity_type = 'engagement';
                    self.id = null;
                    self.location = LOCATION + '/';
                    self.created_at = null;
                    self.workspace_id = null;
                    self.etag = null;
                    self.media_type = 'application/vnd.amity.hub.engagement+json';
                    self.account = null;
                    self.method = null;
                    self.method_icon = null;
                    self.topic_ids = [];
                    self.notes = null;
                    self.participants = [];
                    self.subject = null;
                    self.text_body = null;
                    self._text_body_summary = null;
                    self.update_last_engagement = null;
                    self.closed_at = null;
                    self.engaged_at = null;
                    self.updates = null;
                    self.external_ids = null;
                    self.sent_by_amity = null;
                    if (data) {
                        self.set(data);
                    }
                }
                EngagementModel.prototype.set = function (data) {
                    var self = this;
                    self.id = data.id;
                    self.location = LOCATION + '/' + data.id;
                    self.created_at = data.created_at ? new Date(data.created_at) : null;
                    self.workspace_id = data.workspace_id;
                    self.account = data.account;
                    self.method = data.method;
                    self.method_icon = get_method_icon(self.method);
                    self.notes = data.notes;
                    self.topic_ids = data.topic_ids ? data.topic_ids : [];
                    self.etag = data.etag;
                    self.media_type = data.media_type ? data.media_type : self.media_type;
                    self.participants = data.participants ? data.participants : [];
                    self.update_last_engagement = data.hasOwnProperty('update_last_engagement') ? data.update_last_engagement : true;
                    self.closed_at = data.closed_at ? new Date(data.closed_at) : null;
                    self.engaged_at = data.engaged_at ? new Date(data.engaged_at) : null;
                    self.updates = data.updates || [];
                    self.external_ids = data.external_ids;
                    self._team = getTeam(self.participants);
                    self._people = getPeople(self.participants);
                    if (method_type_re.test(data.method)) {
                        self.subject = data.subject;
                        self.text_body = data.text_body;
                        if (data.text_body) {
                            self._text_body_summary = summarizeText(data.text_body, 100);
                        }
                        if (data.updates && data.updates.length) {
                            self._text_body_summary = summarizeText(data.updates[data.updates.length - 1], 100);
                        }
                        self.sent_by_amity = data.sent_by_amity;
                        if (self.sent_by_amity) {
                            self.email_status = data.email_status || 'not_processed';
                            self.email_status_reason = data.email_status_reason;
                            self.email_open_count = data.email_open_count;
                            self.email_click_count = data.email_click_count;
                            self.emailMetrics = {
                                processLevel: 0,
                                isProcessed: false,
                                isSent: false,
                                isDelivered: false,
                                isOpened: false,
                                isClicked: false,
                                status: ''
                            };
                            switch (self.email_status) {
                            case 'processed':
                                self.emailMetrics.processLevel = 1;
                                self.emailMetrics.isProcessed = true;
                                self.emailMetrics.isSent = true;
                                self.emailMetrics.status = 'delivered';
                                break;
                            case 'delivered':
                                self.emailMetrics.processLevel = 2;
                                self.emailMetrics.isProcessed = true;
                                self.emailMetrics.isSent = true;
                                self.emailMetrics.isDelivered = true;
                                self.emailMetrics.status = 'delivered';
                                break;
                            case 'not_delivered':
                                self.emailMetrics.processLevel = 2;
                                self.emailMetrics.isProcessed = true;
                                self.emailMetrics.isSent = true;
                                self.emailMetrics.isDelivered = false;
                                self.emailMetrics.status = 'not delivered';
                                break;
                            case 'not_processed':
                                self.emailMetrics.processLevel = 1;
                                self.emailMetrics.isSent = false;
                                self.emailMetrics.isDelivered = false;
                                self.emailMetrics.status = 'processed';
                                break;
                            default:
                                self.emailMetrics.status = self.email_status;
                                break;
                            }
                            if (self.email_open_count) {
                                self.emailMetrics.processLevel = 3;
                                self.emailMetrics.isOpened = true;
                            }
                            if (self.email_click_count) {
                                self.emailMetrics.processLevel = 4;
                                self.emailMetrics.isClicked = true;
                            }
                        }
                    } else {
                        delete self.subject;
                        delete self.text_body;
                        if (data.notes) {
                            self._text_body_summary = summarizeText(data.notes, 100);
                        } else {
                            delete self._text_body_summary;
                        }
                    }
                };
                EngagementModel.prototype.getEtag = function () {
                    var self = this, etagDefer = $q.defer();
                    if (self.etag) {
                        $timeout(function () {
                            etagDefer.resolve({ etag: self.etag });
                        }, 1);
                    } else {
                        $ay_requester.GET(self.location).then(function (results) {
                            if (!results.error) {
                                self.etag = results.data.etag;
                                results.etag = self.etag;
                            }
                            etagDefer.resolve(results);
                        });
                    }
                    return etagDefer.promise;
                };
                EngagementModel.prototype.save = function () {
                    var self = this, saveDefer = $q.defer(), validation_res = $schemaValidator.validate(angular.copy(self), self.schema);
                    self.getEtag().then(function (results) {
                        if (results.error) {
                            saveDefer.resolve(results);
                        } else {
                            if (validation_res.error) {
                                saveDefer.resolve(validation_res);
                            } else {
                                $ay_requester.PUT(self.location, {
                                    etag: results.etag,
                                    data: validation_res.data
                                }).then(function complete_request(results) {
                                    if (!results.error) {
                                        self.set(results.data);
                                        results.data = self;
                                    }
                                    saveDefer.resolve(results);
                                });
                            }
                        }
                    });
                    return saveDefer.promise;
                };
                EngagementModel.prototype.update = function (fields) {
                    function createTargets(item, index) {
                        var target = fields[index], change = {
                                op: target.op ? target.op : 'replace',
                                path: '/' + target.field.replace(/^_/g, '').replace(/(\[\")|(\.)/g, '/').replace(/(\"\])/g, '')
                            };
                        if ((change.op === 'replace' || change.op === 'add' || change.op === 'test') && target.value === undefined) {
                            change.value = s[target.field];
                        } else {
                            change.value = target.value;
                        }
                        return change;
                    }
                    var s = this, params = {
                            fields: fields,
                            media_type: s.media_type,
                            data: Array.apply(null, new Array(fields.length)).map(createTargets)
                        };
                    return $ay_requester.PATCH(s.location, params);
                };
                EngagementModel.prototype.remove = function () {
                    var self = this;
                    return $ay_requester.DELETE(self.location, {});
                };
                function EngagementFactory() {
                }
                EngagementFactory.prototype.media_type = media_type;
                EngagementFactory.prototype.location = LOCATION;
                EngagementFactory.prototype.method_type_re = method_type_re;
                EngagementFactory.prototype.method_type_email = method_type_email;
                EngagementFactory.prototype.emailStatusTypes = emailStatusTypes;
                EngagementFactory.prototype.newModel = function (data) {
                    var self = this;
                    return new EngagementModel(data, self.schema);
                };
                EngagementFactory.prototype._buildOptions = function build_options(params) {
                    var workspace_filter = {
                            field: '/workspace_id',
                            op: 'EQ',
                            value: $ME.workspace.id
                        }, account_filter = {
                            field: '/account/id',
                            op: 'EQ',
                            value: ''
                        }, person_filter = {
                            field: '/participants/participant/id',
                            op: 'EQ',
                            value: ''
                        }, method_filter = {
                            field: '/method',
                            op: 'EQ',
                            value: ''
                        }, options = {
                            f: [],
                            s: []
                        }, methodItem;
                    if (params.hasOwnProperty('filters')) {
                        var methodIndex;
                        if (params.filters.length) {
                            options.f = angular.copy(params.filters);
                        }
                        methodIndex = options.f._index_of_by_prop('field', '/method');
                        if (methodIndex !== -1) {
                            methodItem = options.f.splice(methodIndex, 1)[0];
                            if (Array.isArray(methodItem.value)) {
                                if (methodItem.value.indexOf('incoming.email') !== -1 || methodItem.value.indexOf('outgoing.email') !== -1) {
                                    params.method = 'email';
                                } else if (methodItem.value.indexOf('incoming.chat') !== -1 || methodItem.value.indexOf('outgoing.chat') !== -1) {
                                    params.method = 'chat';
                                }
                            } else {
                                params.method = methodItem.value;
                            }
                        }
                    } else if (params.method) {
                        methodItem = angular.copy(method_filter);
                        switch (params.method) {
                        case 'email':
                            methodItem.value = [
                                'incoming.email',
                                'outgoing.email'
                            ];
                            methodItem.op = 'IN';
                            break;
                        case 'chat':
                            methodItem.value = [
                                'incoming.chat',
                                'outgoing.chat'
                            ];
                            methodItem.op = 'IN';
                            break;
                        default:
                            methodItem.value = params.method;
                            break;
                        }
                    }
                    options.f.push(workspace_filter);
                    if (params.account_id) {
                        account_filter.value = params.account_id;
                        options.f.push(account_filter);
                    }
                    if (params.participant_id) {
                        person_filter.value = params.participant_id;
                        options.f.push(person_filter);
                    }
                    if (params.method) {
                        switch (params.method) {
                        case 'email':
                        case 'chat':
                            method_filter.value = methodItem.value;
                            method_filter.op = methodItem.op;
                            options.f.push(method_filter);
                            break;
                        default:
                            method_filter.value = params.method;
                            method_filter.op = methodItem.op;
                            options.f.push(method_filter);
                            break;
                        }
                    }
                    if (params.sorts) {
                        options.s = angular.copy(params.sorts);
                    } else {
                        options.s.push({
                            field: '/created_at',
                            descending: true
                        });
                    }
                    return options;
                };
                EngagementFactory.prototype.getSchema = function get_entity_schema() {
                    var s = this, schemaDefer = $q.defer();
                    function setEntitySchema(results) {
                        if (!results.error) {
                            s.schema = results.data;
                            schemaDefer.resolve(s.schema);
                        } else {
                            schemaDefer.resolve(results);
                        }
                    }
                    if (s.schema) {
                        $timeout(function () {
                            schemaDefer.resolve(s.schema);
                        }, 1);
                    } else {
                        $ay_requester.GET('schema/engagements').then(setEntitySchema);
                    }
                    return schemaDefer.promise;
                };
                EngagementFactory.prototype.get = function (params) {
                    var drainPromise, self = this, getDefer = $q.defer(), location = LOCATION, models = [];
                    function setModels(results) {
                        if (results.error) {
                            getDefer.resolve(results);
                        } else {
                            if (!results.data.hasOwnProperty('items')) {
                                results.data = self.newModel(results.data);
                                getDefer.resolve(results);
                            } else {
                                models = models.concat(results.data.items.map(function (data) {
                                    return self.newModel(data);
                                }));
                                results.data = models;
                                if (!params.drain) {
                                    getDefer.resolve(results);
                                } else {
                                    if (!results.cursor) {
                                        getDefer.resolve(results);
                                    } else {
                                        params.cursor = results.cursor;
                                        drainPromise = $ay_requester.GET(location, params);
                                        drainPromise.then(setModels);
                                    }
                                }
                            }
                        }
                    }
                    self.getSchema().then(function (results) {
                        params = params || {};
                        if (params.id) {
                            location += '/' + params.id;
                        } else {
                            params.options = self._buildOptions(params);
                        }
                        $ay_requester.GET(location, params).then(setModels);
                    });
                    return getDefer.promise;
                };
                EngagementFactory.prototype.create = function (data) {
                    var self = this, idList = [], errorList = [], createDefer = $q.defer();
                    function post(item) {
                        item.media_type = self.schema.description;
                        var postDefer = $q.defer(), validation_res = $schemaValidator.validate(item, self.schema);
                        if (validation_res.error) {
                            $timeout(function () {
                                errorList.push(validation_res);
                                postDefer.resolve(validation_res);
                            }, 1);
                        } else {
                            $ay_requester.POST(LOCATION, { data: validation_res.data }).then(function (results) {
                                if (!results.error) {
                                    results.data = self.newModel(results.data);
                                    idList.push(results.data.id);
                                } else {
                                    errorList.push(results);
                                }
                                postDefer.resolve(results);
                            });
                        }
                        return postDefer.promise;
                    }
                    self.getSchema().then(function () {
                        var postPromises = [];
                        if (data.items) {
                            postPromises = data.items.map(post);
                        } else {
                            postPromises = [post(data)];
                        }
                        $q.all(postPromises).then(function (results) {
                            if (data.items) {
                                if (errorList.length) {
                                    var errorData = {
                                        created: false,
                                        error: {
                                            message: 'Engagements not created',
                                            data: {
                                                engagements: errorList,
                                                request: results
                                            }
                                        }
                                    };
                                    createDefer.resolve(errorData);
                                    $log.error(errorData);
                                } else {
                                    createDefer.resolve({
                                        created: true,
                                        id_list: idList
                                    });
                                }
                            } else {
                                if (results[0].error) {
                                    results[0].created = false;
                                } else {
                                    results[0].created = true;
                                }
                                createDefer.resolve(results[0]);
                            }
                        });
                    });
                    return createDefer.promise;
                };
                return new EngagementFactory();
            }
        ]
    };
});