define('modules/rest/factories/layouts', [], function () {
    'use strict';
    return {
        component: 'service',
        name: 'restFactoryLayouts',
        fn: [
            '$log',
            '$schemaValidator',
            '$ay_requester',
            '$q',
            '$timeout',
            '$ME',
            '$utls',
            function view_factory($log, $schemaValidator, $ay_requester, $q, $timeout, $ME, $utls) {
                var LOCATION = 'layouts';
                function LayoutModel(data, schema) {
                    this.schema = schema;
                    this.etag = null;
                    this.id = null;
                    this.media_type = 'application/vnd.amity.hub.layouts+json';
                    this.workspace_id = $ME.workspace.id;
                    this.created_at = null;
                    this.layout_type = null;
                    this.name = null;
                    this.description = null;
                    this.created_by_user_id = null;
                    this.widgets = null;
                    if (data) {
                        this._set(data);
                    }
                }
                LayoutModel.prototype._set = function (data) {
                    this.id = data.id;
                    this.etag = data.etag;
                    this.location = LOCATION + '/' + data.id;
                    this.media_type = data.media_type || this.media_type;
                    this.workspace_id = data.workspace_id || $ME.workspace.id;
                    this.created_at = data.created_at;
                    this.layout_type = data.layout_type || 'page';
                    this.name = data.name;
                    this.description = data.description;
                    this.created_by_user_id = data.created_by_user_id || $ME.data.id;
                    this.widgets = data.widgets || [];
                    switch (this.layout_type) {
                    case 'entity_grid':
                        this._setGrid(data);
                        break;
                    }
                };
                LayoutModel.prototype._setGrid = function (data) {
                    this.entity_type = data.entity_type;
                    this.title = data.title;
                    this.filters = data.filters || [];
                    this.sort = data.sort || [];
                    this.columns = data.columns || [];
                };
                LayoutModel.prototype.getEtag = function () {
                    var self = this, etagDefer = $q.defer();
                    function confirmEtag(results) {
                        if (!results.error) {
                            self.etag = results.data.etag;
                            results.etag = self.etag;
                        }
                        etagDefer.resolve(results);
                    }
                    function returnEtag() {
                        etagDefer.resolve({ etag: self.etag });
                    }
                    if (self.etag) {
                        $timeout(returnEtag, 1);
                    } else {
                        $ay_requester.GET(self.location).then(confirmEtag);
                    }
                    return etagDefer.promise;
                };
                LayoutModel.prototype.save = function () {
                    var self = this, saveDefer = $q.defer(), validation = $schemaValidator.validate(angular.copy(self), self.schema);
                    function completeRequest(results) {
                        if (!results.error) {
                            self._set(results.data);
                            results.data = self;
                        }
                        saveDefer.resolve(results);
                    }
                    self.getEtag().then(function (results) {
                        if (results.error) {
                            saveDefer.resolve(results);
                        } else {
                            if (validation.error) {
                                saveDefer.resolve(validation);
                            } else {
                                $ay_requester.PUT(self.location, {
                                    etag: results.etag,
                                    data: validation.data
                                }).then(completeRequest);
                            }
                        }
                    });
                    return saveDefer.promise;
                };
                LayoutModel.prototype.update = function (fields) {
                    function createTargets(item, index) {
                        var target = fields[index], change = {
                                op: target.op ? target.op : 'replace',
                                path: $utls.toRestPointer(target.field)
                            };
                        if (change.op === 'replace' || change.op === 'add' || change.op === 'test') {
                            change.value = $utls.jsonPointer(self, target.field);
                        }
                        return change;
                    }
                    var self = this, params = {
                            fields: fields,
                            media_type: self.media_type,
                            data: Array.apply(null, new Array(fields.length)).map(createTargets)
                        };
                    return $ay_requester.PATCH(self.location, params);
                };
                LayoutModel.prototype.remove = function () {
                    var self = this;
                    return $ay_requester.DELETE(self.location, {});
                };
                function LayoutsFactory() {
                }
                LayoutsFactory.prototype.location = LOCATION;
                LayoutsFactory.prototype.newModel = function (data) {
                    var self = this;
                    return new LayoutModel(data, self.schema);
                };
                LayoutsFactory.prototype._buildOptions = function (params) {
                    var options = {
                        f: [{
                                field: '/workspace_id',
                                op: 'EQ',
                                value: $ME.workspace.id
                            }],
                        s: [{
                                field: '/name',
                                descending: false
                            }]
                    };
                    if (params.hasOwnProperty('id')) {
                        options.f.push({
                            field: '/id',
                            op: 'EQ',
                            value: params.id
                        });
                    }
                    return options;
                };
                LayoutsFactory.prototype.getSchema = function () {
                    var s = this, schemaDefer = $q.defer();
                    function setEntitySchema(results) {
                        if (!results.error) {
                            s.schema = results.data;
                            schemaDefer.resolve(s.schema);
                        } else {
                            schemaDefer.resolve(results);
                        }
                    }
                    if (s.schema) {
                        $timeout(function () {
                            schemaDefer.resolve(s.schema);
                        });
                    } else {
                        $ay_requester.GET('schema/' + LOCATION).then(setEntitySchema);
                    }
                    return schemaDefer.promise;
                };
                LayoutsFactory.prototype.get = function (params) {
                    var drainPromise, self = this, getDefer = $q.defer(), location = LOCATION, models = [];
                    function setModels(results) {
                        if (results.error) {
                            getDefer.resolve(results);
                        } else {
                            if (!results.data.hasOwnProperty('items')) {
                                results.data = self.newModel(results.data);
                                getDefer.resolve(results);
                            } else {
                                models = models.concat(results.data.items.map(function (data) {
                                    return self.newModel(data);
                                }));
                                results.data = models;
                                if (!params.drain) {
                                    getDefer.resolve(results);
                                } else {
                                    if (!results.cursor) {
                                        getDefer.resolve(results);
                                    } else {
                                        params.cursor = results.cursor;
                                        drainPromise = $ay_requester.GET(location, params);
                                        drainPromise.then(setModels);
                                    }
                                }
                            }
                        }
                    }
                    self.getSchema().then(function (results) {
                        params = params || {};
                        if (params.id) {
                            location += '/' + params.id;
                        } else {
                            params.options = self._buildOptions(params);
                        }
                        $ay_requester.GET(location, params).then(setModels);
                    });
                    return getDefer.promise;
                };
                LayoutsFactory.prototype.create = function (data) {
                    var self = this, createDefer = $q.defer();
                    function post() {
                        var validation = $schemaValidator.validate(data, self.schema);
                        function returnResults(results) {
                            if (!results.error) {
                                results.data = self.newModel(results.data);
                            }
                            createDefer.resolve(results);
                        }
                        function rejectRequest() {
                            createDefer.resolve(validation);
                        }
                        if (validation.error) {
                            $timeout(rejectRequest, 1);
                        } else {
                            $ay_requester.POST(self.location, { data: validation.data }).then(returnResults);
                        }
                    }
                    if (self.schema) {
                        post();
                    } else {
                        self.getSchema().then(post);
                    }
                    return createDefer.promise;
                };
                return new LayoutsFactory();
            }
        ]
    };
});