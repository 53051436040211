define('modules/enviroment/controllers/platform', [], function () {
    'use strict';
    return {
        name: 'ctrlEnviromentPlatform',
        component: 'controller',
        fn: [
            '$rootScope',
            '$scope',
            '$location',
            '$route',
            '$ME',
            '$timeout',
            'ayContextToolsAppData',
            function ($rootScope, $scope, $location, $route, $ME, $timeout, ayContextToolsAppData) {
                var self = this;
                self.title = 'Amity';
                self.loading = false;
                $rootScope.current_account = {};
                $rootScope.current_person = {};
                $rootScope.current_play = {};
                $rootScope.base_path = '/csm/';
                function badRoute(errorObj) {
                    var errorResponse = { '403': '/csm/forbidden' };
                    if (errorResponse[errorObj.code]) {
                        $location.path(errorResponse[errorObj.code]);
                        self.loading = false;
                    } else {
                        $location.path('/csm/not-found');
                    }
                }
                function watchAccount(params) {
                    var watcher = $scope.$watch('$root.current_account.name', function (new_val) {
                        if (new_val) {
                            watcher();
                            setBrowserTitle(params);
                        }
                    });
                }
                function watchPerson(params) {
                    var watcher = $scope.$watch('$root.current_person.full_name', function (new_val) {
                        if (new_val) {
                            watcher();
                            setBrowserTitle(params);
                        }
                    });
                }
                function watchPlay(params) {
                    var watcher = $scope.$watch('$root.current_play.name', function (new_val) {
                        if (new_val) {
                            watcher();
                            setBrowserTitle(params);
                        }
                    });
                }
                function setBrowserTitle(params) {
                    var section, title = '', app = ayContextToolsAppData.app ? ayContextToolsAppData.app.name : '', app_name = ayContextToolsAppData.app ? ayContextToolsAppData.app.tile.title : '';
                    switch (app) {
                    case 'accounts':
                        if (params.id) {
                            if ($rootScope.current_account.name) {
                                section = params.section || 'Dashboard';
                                title = $rootScope.current_account.name + ' - ' + section.capitalize();
                            } else {
                                watchAccount(params);
                            }
                        } else {
                            title = app_name;
                        }
                        break;
                    case 'people':
                        if (params.id) {
                            if ($rootScope.current_person.full_name) {
                                section = params.section || 'Contact Info';
                                title = $rootScope.current_person.full_name + ' - ' + section.capitalize();
                            } else {
                                watchPerson(params);
                            }
                        } else {
                            title = app_name;
                        }
                        break;
                    case 'playbooks':
                        if (params.id) {
                            if ($rootScope.current_play.name) {
                                title = $rootScope.current_play.name + ' - ' + $rootScope.current_play.account.name;
                            } else {
                                watchPlay(params);
                            }
                        } else {
                            title = app_name;
                        }
                        break;
                    default:
                        title = app_name;
                        if (params.sub_app) {
                            title += ' - ' + params.sub_app.split('-').map(function (itm) {
                                return itm.capitalize();
                            }).join(' ');
                        }
                        break;
                    }
                    if (title) {
                        title += ' - Amity';
                    } else {
                        title = 'Amity';
                    }
                    self.title = title;
                }
                $rootScope.$on('app-loading', function (event, data, calledFrom) {
                    $timeout(function () {
                        self.loading = data;
                    });
                });
                $rootScope.$on('$routeChangeStart', function (event, route_to, route_from) {
                    $rootScope.current_account = {};
                    $rootScope.current_play = {};
                    $rootScope.current_person = {};
                });
                $rootScope.$on('$routeChangeSuccess', function (event, routeTo, routeFrom) {
                    if (routeTo) {
                        setBrowserTitle(routeTo.params);
                    }
                });
                $rootScope.$on('$locationChangeSuccess', function () {
                    if (window.Appcues) {
                        window.Appcues.page();
                        window.Appcues.start();
                    }
                });
                $rootScope.$on('$routeChangeError', function (current, route, route2, error) {
                    self.loading = false;
                });
            }
        ]
    };
});