define('modules/form-inputs/directives/input-select', [], function () {
    return {
        component: 'directive',
        name: 'ayFormInputSelect',
        fn: [
            '$timeout',
            function ($timeout) {
                return {
                    restrict: 'E',
                    templateUrl: 'form-inputs/views/input-select.html',
                    transclude: true,
                    scope: {
                        label: '@',
                        helpText: '@',
                        isDisabled: '=?',
                        isRequired: '@'
                    },
                    controller: [function () {
                        }],
                    controllerAs: 'formInput',
                    bindToController: true,
                    link: function (scope, elem, attrs) {
                        var select, isObserving = false, activeClass = 'ng-not-empty';
                        function updateValidation(val) {
                            scope.formInput.validationMsg = val;
                        }
                        function getModelValue() {
                            var data = select.data();
                            if (!isObserving && data.$ngModelController && data.$ngModelController.$$attr) {
                                data.$ngModelController.$$attr.$observe('validationMsg', updateValidation);
                                isObserving = true;
                            }
                            return !(data.$selectController.$isEmptyOptionSelected() || data.$selectController.$isUnknownOptionSelected());
                        }
                        function change(event) {
                            var value = getModelValue();
                            if (value) {
                                scope.formInput.inputClass = activeClass;
                            } else if (event) {
                                switch (event.type) {
                                case 'blur':
                                    scope.formInput.inputClass = '';
                                    break;
                                case 'focus':
                                    scope.formInput.inputClass = activeClass;
                                    break;
                                default:
                                    var modelValue = select.data().$ngModelController.$modelValue;
                                    if (event === modelValue) {
                                        scope.formInput.inputClass = activeClass;
                                    } else {
                                        scope.formInput.inputClass = '';
                                    }
                                    break;
                                }
                            } else {
                                scope.formInput.inputClass = '';
                            }
                        }
                        function blur() {
                            select.on('blur', change);
                        }
                        function init() {
                            select = angular.element(elem.find('select'));
                            if (select[0]) {
                                select.on('focus', change);
                                select.scope().$watch(select.attr('ng-model'), change);
                                $timeout(blur, 100);
                            } else {
                                $timeout(init, 10);
                            }
                        }
                        init();
                    }
                };
            }
        ]
    };
});