define('modules/rest/factories/custom-reports', [], function () {
    'use strict';
    return {
        component: 'service',
        name: 'restFactoryCustomReports',
        fn: [
            '$log',
            '$schemaValidator',
            '$ay_requester',
            '$q',
            '$timeout',
            '$ME',
            function view_factory($log, $schemaValidator, $ay_requester, $q, $timeout, $ME) {
                var LOCATION = 'custom_reports', reportScopeTypes = {
                        workspace: 'workspace',
                        account: 'account'
                    };
                function Model_Custom_Report(data, schema) {
                    this.schema = schema;
                    this.id = null;
                    this.media_type = 'application/vnd.amity.hub.custom_report+json';
                    this.workspace_id = $ME.workspace.id;
                    this.created_at = null;
                    this.name = null;
                    this.location = LOCATION + '/';
                    this.enabled = null;
                    this.url = null;
                    this.description = null;
                    this.type = null;
                    this.image_url = null;
                    this.scope = null;
                    this.default_period = null;
                    if (data) {
                        this._set(data);
                    }
                }
                Model_Custom_Report.prototype._set = function (data) {
                    this.id = data.id;
                    this.location = LOCATION + '/' + data.id;
                    this.media_type = data.media_type || this.media_type;
                    this.workspace_id = data.workspace_id || $ME.workspace.id;
                    this.created_at = data.created_at;
                    this.name = data.name;
                    this.enabled = data.enabled;
                    this.url = data.url;
                    this.description = data.description;
                    this.type = data.type;
                    this.image_url = data.image_url;
                    this.scope = data.scope;
                    this.default_period = data.default_period;
                };
                Model_Custom_Report.prototype.getHtml = function () {
                    var self = this;
                    return $ay_requester.JSONP(self.url);
                };
                Model_Custom_Report.prototype.sign = function (options) {
                    var params, self = this;
                    if (options) {
                        params = { options: options };
                    }
                    return $ay_requester.GET(self.location + '/sign', params);
                };
                function Rest_Factory_Custom_Reports() {
                    this._getSchemaCnt = 0;
                }
                Rest_Factory_Custom_Reports.prototype.location = LOCATION;
                Rest_Factory_Custom_Reports.prototype._new_model = function (data) {
                    var self = this;
                    return new Model_Custom_Report(data, self.schema);
                };
                Rest_Factory_Custom_Reports.prototype._build_query = function (params) {
                    var self = this, workspace_filter = {
                            field: '/workspace_id',
                            op: 'EQ',
                            value: $ME.workspace.id
                        }, options = {
                            f: [workspace_filter],
                            s: []
                        };
                    if (params.hasOwnProperty('id')) {
                        options.f.push({
                            field: '/id',
                            op: 'EQ',
                            value: params.id
                        });
                    }
                    if (params.hasOwnProperty('scope') && reportScopeTypes.hasOwnProperty(params.scope)) {
                        options.f.push({
                            field: '/scope',
                            op: 'EQ',
                            value: reportScopeTypes[params.scope]
                        });
                    }
                    if (params.sorts) {
                        options.s = angular.copy(params.sorts);
                    }
                    return options;
                };
                Rest_Factory_Custom_Reports.prototype.get = function get_model(params) {
                    var self = this, get_defer = $q.defer(), location = self.location;
                    params = params || {};
                    if (params.id) {
                        location += '/' + params.id;
                    } else {
                        params.options = self._build_query(params);
                    }
                    function load_results(results) {
                        if (!results.error) {
                            if (results.data.hasOwnProperty('items')) {
                                results.data = results.data.items.map(self._new_model, self);
                            } else {
                                results.data = self._new_model(results.data);
                            }
                        }
                        get_defer.resolve(results);
                    }
                    $ay_requester.GET(location, params).then(load_results);
                    return get_defer.promise;
                };
                return new Rest_Factory_Custom_Reports();
            }
        ]
    };
});