define('modules/rest/factories/marketing', [], function () {
    'use strict';
    return {
        component: 'service',
        name: 'factoryMarketing',
        fn: [
            '$log',
            '$ay_requester',
            '$q',
            '$timeout',
            '$ME',
            function ($log, $ay_requester, $q, $timeout, $ME) {
                function Marketing_Factory() {
                    this.timestamps = {};
                }
                Marketing_Factory.prototype.new_model = {
                    workspace: function (data) {
                        return data;
                    },
                    participant: function (data) {
                        function format_systems(system) {
                            if (system.hasOwnProperty('conversion_stats')) {
                                if (system.conversion_stats.hasOwnProperty('first_event_date')) {
                                    system.conversion_stats.first_event_date = new Date(system.conversion_stats.first_event_date);
                                }
                                if (system.conversion_stats.hasOwnProperty('recent_event_date')) {
                                    system.conversion_stats.recent_event_date = new Date(system.conversion_stats.recent_event_date);
                                }
                            }
                            return system;
                        }
                        function format_emails(email) {
                            if (email.hasOwnProperty('systems') && email.systems.length) {
                                email.systems = email.systems.map(format_systems);
                            }
                            return email;
                        }
                        if (data.hasOwnProperty('emails') && data.emails.length) {
                            data.emails = data.emails.map(format_emails);
                        }
                        return data;
                    }
                };
                Marketing_Factory.prototype._location = {
                    workspace: function () {
                        return 'workspaces/' + $ME.workspace.id + '/marketing_systems';
                    },
                    participant: function (params) {
                        return 'participants/' + params.id + '/marketing';
                    }
                };
                Marketing_Factory.prototype.get = function (params) {
                    params.type = params.type || 'participant';
                    var self = this, location = self._location[params.type](params), get_defer = $q.defer(), check = new Date().getTime(), cache_limit = 60000 * 10;
                    $ME.marketing = $ME.marketing || {};
                    if (!self.timestamps[location] || check - self.timestamps[location] >= cache_limit) {
                        $ay_requester.GET(location, params).then(function (results) {
                            if (results.error) {
                                $ME.marketing[location] = null;
                                get_defer.resolve(results);
                            } else {
                                $ME.marketing[location] = self.new_model[params.type](results.data);
                                self.timestamps[location] = new Date().getTime();
                                get_defer.resolve({ data: $ME.marketing[location] });
                            }
                        });
                    } else {
                        $timeout(function () {
                            get_defer.resolve({ data: $ME.marketing[location] });
                        }, 1);
                    }
                    return get_defer.promise;
                };
                return new Marketing_Factory();
            }
        ]
    };
});