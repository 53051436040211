define('sharedApp', [
    'sharedModuleLocators',
    'sharedApplicationLocators'
], function (modules, apps) {
    var app, appName = 'amity-app-shared', depList = [
            'ngRoute',
            'ngCookies'
        ];
    depList = modules(depList);
    depList = apps(depList);
    app = angular.module(appName, depList);
    app.config([
        '$locationProvider',
        '$qProvider',
        function ($locationProvider, $qProvider) {
            $locationProvider.html5Mode(true);
            $qProvider.errorOnUnhandledRejections(false);
        }
    ]);
    return app;
});