define('modules/rest/factories/notifications', [], function () {
    'use strict';
    return {
        component: 'service',
        name: 'modelNotification',
        fn: [
            'srvcRequester',
            '$schemaValidator',
            '$log',
            '$q',
            '$timeout',
            '$ME',
            '$ay_requester',
            function notification_factory(srvcRequester, $schemaValidator, $log, $q, $timeout, $ME, $ay_requester) {
                var factory = {
                        media_type: 'application/vnd.amity.hub.notification+json',
                        collection: 'notifications',
                        types: {
                            alerts: ['alert'],
                            notifications: ['system'],
                            general: []
                        },
                        schema: null,
                        _observers: []
                    }, general_call_types = [
                        'general',
                        'general-count'
                    ];
                factory.types.general = factory.types.alerts.concat(factory.types.notifications);
                function NOTIFICATION_MODEL(data) {
                    this.data_type = 'notifications';
                    this.etag = '';
                    this.workspace_id = '';
                    this.id = '';
                    this.title = '';
                    this.created_at = null;
                    this.recipents = [];
                    this.unread_recipients = [];
                    this.type = '';
                    this.priority_level = '';
                    this.account = null;
                    this.ticket = null;
                    this.subscription = null;
                    this.engagement = null;
                    this.work_item = null;
                    this.play = null;
                    this.media_type = '';
                    this._in_progress = false;
                    this._current_request = '';
                    if (data) {
                        this.set(data);
                    }
                }
                NOTIFICATION_MODEL.prototype.mark = function (_callback, option) {
                    var self = this;
                    if (!self._in_progress) {
                        self._in_progress = true;
                        factory.mark(self.id, _callback, option, self);
                    }
                };
                NOTIFICATION_MODEL.prototype.toggleStatus = function (read) {
                    var self = this, markDefer = $q.defer(), params = {
                            data: {
                                action: 'mark',
                                read: read === null || read === undefined ? self.isUnread : read
                            }
                        };
                    $ay_requester.POST(self._location, params).then(function (results) {
                        if (!results.error) {
                            self.isUnread = !params.data.read;
                            if (!read) {
                                self.unread_recipients.push($ME.data.id);
                            } else {
                                var index = self.unread_recipients.indexOf($ME.data.id);
                                if (index !== -1) {
                                    self.unread_recipients.splice(index, 1);
                                }
                            }
                            results.data = self;
                        }
                        markDefer.resolve(results);
                    });
                    return markDefer.promise;
                };
                NOTIFICATION_MODEL.prototype.set = function (data) {
                    var self = this;
                    self.etag = data.etag ? data.etag : '';
                    self.workspace_id = data.workspace_id ? data.workspace_id : '';
                    self.id = data.id ? data.id : '';
                    self._location = factory.collection + '/' + self.id;
                    self.title = data.title ? data.title : '';
                    self.created_at = data.created_at ? new Date(data.created_at) : null;
                    self.recipents = data.recipents ? data.recipents : [];
                    self.unread_recipients = data.unread_recipients ? data.unread_recipients : [];
                    self.isUnread = self.unread_recipients.indexOf($ME.data.id) !== -1 ? true : false;
                    self.type = data.type ? data.type : '';
                    self.priority_level = data.priority_level ? data.priority_level : '';
                    self.account = data.account ? data.account : null;
                    self.ticket = data.ticket ? data.ticket : null;
                    self.subscription = data.subscription ? data.subscription : null;
                    self.engagement = data.engagement ? data.engagement : null;
                    self.work_item = data.work_item ? data.work_item : null;
                    self.play = data.play ? data.play : null;
                    self.media_type = data.media_type ? data.media_type : '';
                };
                factory._dispatch = function notifications_dispatch(results) {
                    switch (results.type) {
                    case 'notification-marked':
                        results.call_info.params.model._current_request = '';
                        results.call_info.params.model._in_progress = false;
                        results._callback({
                            updated: !results.error,
                            mark: results.call_info.params.data.read,
                            id: results.call_info.params.id,
                            data: results.call_info.params.model,
                            error: results.error
                        });
                        break;
                    case 'notification-create':
                        var location = results.headers('Location');
                        results._callback({
                            created: true,
                            location: location,
                            error: results.error
                        });
                        break;
                    default:
                        results._callback(results);
                        break;
                    }
                };
                factory.mark = function notifications_mark(id, _callback, option, model) {
                    var params = {
                        id: id,
                        _callback: _callback,
                        type: 'notification-marked',
                        data: {
                            action: 'mark',
                            read: option || option === null || option === undefined ? true : false
                        },
                        model: model
                    };
                    model._current_request = 'mark';
                    srvcRequester.post(factory.collection + '/' + id, factory._dispatch, params);
                };
                factory.set = function notifications_set(results) {
                    var return_data = {
                        data: [],
                        call_info: results.call_info,
                        type: results.type,
                        cursor: results.cursor,
                        error: results.error
                    };
                    if (!results.error && results.data) {
                        if (results.data.items) {
                            var list = [], i = 0;
                            if (results.type === 'general-count') {
                                return_data.data = results.data.items.length;
                            } else {
                                for (i = 0; i < results.data.items.length; i++) {
                                    list.push(new NOTIFICATION_MODEL(results.data.items[i]));
                                }
                                return_data.data = list;
                            }
                        } else if (!results.data.hasOwnProperty('items') && results.data.id) {
                            return_data.data = new NOTIFICATION_MODEL(results.data);
                        }
                    }
                    results._callback(return_data);
                };
                factory._build_query = function notifications_query(params) {
                    var options = {
                            f: [],
                            s: []
                        }, errors = [];
                    if (params.type) {
                        switch (params.type) {
                        case 'general-new':
                            if (!params.user_id) {
                                errors.push({
                                    msg: 'Notification error: user_id required.',
                                    data: params
                                });
                            }
                            options.f.push({
                                field: '/type',
                                op: 'IN',
                                value: factory.types.general
                            });
                            options.f.push({
                                field: '/recipients',
                                op: 'EQ',
                                value: params.user_id
                            });
                            break;
                        case 'account-alerts':
                            if (!params.account_id) {
                                errors.push({
                                    msg: 'Notification error: account_id required.',
                                    data: params
                                });
                            }
                            options.f.push({
                                field: '/type',
                                op: 'IN',
                                value: factory.types.alerts
                            });
                            options.f.push({
                                field: '/account/id',
                                op: 'EQ',
                                value: params.account_id
                            });
                            options.s.push({
                                field: '/created_at',
                                descending: true
                            });
                            break;
                        case 'auto-check':
                            if (!params.user_id) {
                                errors.push({
                                    msg: 'Notification error: user_id required.',
                                    data: params
                                });
                            }
                            options.f.push({
                                field: '/type',
                                op: 'IN',
                                value: factory.types.general
                            });
                            options.f.push({
                                field: '/recipients',
                                op: 'EQ',
                                value: params.user_id
                            });
                            options.s.push({
                                field: '/created_at',
                                descending: false
                            });
                            break;
                        default:
                            if (general_call_types.indexOf(params.type) !== -1) {
                                if (!params.user_id) {
                                    errors.push({
                                        msg: 'Notification error: user_id required.',
                                        data: params
                                    });
                                }
                                options.f.push({
                                    field: '/type',
                                    op: 'IN',
                                    value: factory.types.general
                                });
                                options.f.push({
                                    field: '/recipients',
                                    op: 'EQ',
                                    value: params.user_id
                                });
                                options.s.push({
                                    field: '/created_at',
                                    descending: true
                                });
                            }
                            break;
                        }
                    }
                    if (params.unread_user_id) {
                        options.f.push({
                            field: '/unread_recipients',
                            op: 'EQ',
                            value: params.unread_user_id
                        });
                    }
                    if (params.created_after) {
                        options.f.push({
                            field: '/created_at',
                            op: 'GT',
                            value: params.created_after
                        });
                    }
                    if (params.date_after) {
                        options.f.push({
                            field: '/created_at',
                            op: 'GT',
                            value: params.date_after
                        });
                    }
                    if (params.created_before || params.date_before) {
                        options.f.push({
                            field: '/created_at',
                            op: 'LE',
                            value: params.hasOwnProperty('created_before') ? params.created_before : params.date_before
                        });
                    }
                    if (errors.length) {
                        $log.error({
                            error: {
                                message: 'Unable to build notification query.',
                                data: errors
                            }
                        });
                    }
                    return errors.length ? false : options;
                };
                factory.getSchema = function get_entity_schema() {
                    var s = this, schemaDefer = $q.defer();
                    function setEntitySchema(results) {
                        if (!results.error) {
                            s.schema = results.data;
                            schemaDefer.resolve(s.schema);
                        } else {
                            schemaDefer.resolve(results);
                        }
                    }
                    if (s.schema) {
                        $timeout(function () {
                            schemaDefer.resolve(s.schema);
                        }, 1);
                    } else {
                        $ay_requester.GET('schema/' + factory.collection).then(setEntitySchema);
                    }
                    return schemaDefer.promise;
                };
                factory.get = function notifications_get(params) {
                    var self = this;
                    function sendCall() {
                        if (params.type) {
                            params.options = factory._build_query(params);
                            if (params.options) {
                                srvcRequester.get(factory.collection, factory.set, params);
                            }
                        } else if (params.id) {
                            srvcRequester.get(factory.collection, factory.set, params);
                        }
                    }
                    if (factory.schema) {
                        sendCall();
                    } else {
                        self.getSchema().then(sendCall);
                    }
                };
                factory.create_model = function notifications_create(data, _callback) {
                    data.title = data.title.trim();
                    if (!data.workspace_id) {
                        _callback({ created: false });
                        $log.log('Unable to create notification:: Missing workspace_id', {
                            data: data,
                            _callback: _callback
                        });
                        return null;
                    }
                    if (!data.title) {
                        _callback({ created: false });
                        $log.log('Unable to create notification:: Missing title', {
                            data: data,
                            _callback: _callback
                        });
                        return null;
                    }
                    srvcRequester.post('notifications', factory._dispatch, {
                        data: data,
                        _callback: _callback,
                        type: 'notification-create'
                    });
                };
                factory.new = function notifications_new(data) {
                    return new NOTIFICATION_MODEL(data);
                };
                return factory;
            }
        ]
    };
});