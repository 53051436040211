require([
    'sharedApp',
    'sharedTemplates'
], function (app) {
    angular.element(document).ready(function () {
        window.appInjector = angular.bootstrap(document, [app.name]);
    });
});
define('index', [
    'sharedApp',
    'sharedTemplates'
], function () {
    return;
});