define('modules/rest/factories/apps', [], function () {
    'use strict';
    return {
        component: 'service',
        name: 'modelApps',
        fn: [
            '$q',
            '$ay_requester',
            '$log',
            function apps_factory($q, $ay_requester, $log) {
                var apps = {};
                function APP(data) {
                    this.data_type = 'application';
                    this.name = null;
                    this.tile = null;
                    this.order = null;
                    this.sidebarUrl = null;
                    this.preferenceName = null;
                    this.entityMediaType = null;
                    this.baseEntity = null;
                    this.url = null;
                    if (data) {
                        this.set(data);
                    }
                }
                APP.prototype.set = function (data) {
                    this.name = data.type;
                };
                APP.prototype.load = function (def) {
                    this.tile = def.tile;
                    this.order = def.order;
                    this.sidebarUrl = def.sidebarUrl;
                    this.preferenceName = def.preferenceName;
                    this.entityMediaType = def.entityMediaType;
                    this.url = def.url;
                    this.baseEntity = def.baseEntity;
                };
                function APPDEF(data) {
                    this.data_type = 'application-definition';
                    this.apps = [];
                    this.id = null;
                    this.etag = null;
                    this.workspace_id = null;
                    this.immutability_constraints = [];
                    this._get_immutability_props = function get_immutability_props(entity, type) {
                        var i, constraints = [], self = this;
                        for (i = 0; i < self.immutability_constraints.length; i++) {
                            if (self.immutability_constraints[i].entity_type === entity.media_type && entity.external_ids._index_of_by_prop('source', self.immutability_constraints[i].external_id_source) !== -1) {
                                switch (type) {
                                case 'entity':
                                    constraints = constraints.concat(self.immutability_constraints[i].constrained_properties);
                                    break;
                                case 'source':
                                    constraints.push(self.immutability_constraints[i].external_id_source);
                                    break;
                                case 'trait':
                                    constraints = constraints.concat(self.immutability_constraints[i].constrained_related_entity_types);
                                    break;
                                }
                            }
                        }
                        return constraints;
                    };
                    this.is_field_editable = function is_entity_editable(prop, entity) {
                        var constraints = [], editable = false, self = this;
                        if (prop && entity) {
                            if (!self.immutability_constraints.length) {
                                editable = true;
                            } else {
                                constraints = self._get_immutability_props(entity, 'entity');
                                if (!constraints.length || constraints.indexOf(prop) === -1) {
                                    editable = true;
                                }
                            }
                        }
                        return editable;
                    };
                    this.is_entity_synced = function is_entity_synced(entity) {
                        var self = this, synced = entity ? self._get_immutability_props(entity, 'source').length ? true : false : false;
                        return synced;
                    };
                    this.is_trait_editable = function is_trait_editable(entity, trait_type) {
                        var constraints = [], editable = false, self = this;
                        if (entity && trait_type) {
                            if (!self.immutability_constraints.length) {
                                editable = true;
                            } else {
                                constraints = self._get_immutability_props(entity, 'trait');
                                if (!constraints.length || constraints.indexOf(trait_type) === -1) {
                                    editable = true;
                                }
                            }
                        }
                        return editable;
                    };
                    this.is_group_editable = function (entityType, integrationId, groupFields) {
                        var constraint, i, ii, index, editList = [], editable = true, self = this;
                        for (i = 0, ii = self.immutability_constraints.length; i < ii; i++) {
                            if (self.immutability_constraints[i].external_id_source === integrationId && self.immutability_constraints[i].entity_type === entityType) {
                                constraint = self.immutability_constraints[i];
                            }
                        }
                        ii = constraint.constrained_properties.length;
                        if (constraint && ii) {
                            editList = Array.apply(null, new Array(ii)).map(function (item, index) {
                                return groupFields.indexOf(constraint.constrained_properties[index]) !== -1;
                            });
                            editable = editList.indexOf(false) !== -1;
                        }
                        return editable;
                    };
                    this.set = function (data) {
                        var self = this;
                        self.id = data.id;
                        self.etag = data.etag;
                        if (data.apps && data.apps.length) {
                            for (var i = 0; i < data.apps.length; i++) {
                                var _app = new APP(data.apps[i]);
                                self.apps.push(_app);
                            }
                        }
                        if (data.immutability_constraints && data.immutability_constraints.length) {
                            self.immutability_constraints = data.immutability_constraints;
                        }
                    };
                    this.set(data);
                }
                APPDEF.prototype.addApp = function (data, def) {
                    var _app = new APP(data);
                    _app.load(def);
                    this.apps.push(_app);
                };
                apps.set = function (results) {
                    if (results.error) {
                        $log.warn('Error Retrieving Apps');
                        results._callback();
                    } else {
                        var _appdef = new APPDEF(results.data);
                        _appdef.workspace_id = results.call_info.params.id;
                        results._callback({
                            data: _appdef,
                            type: results.type,
                            error: results.error
                        });
                    }
                };
                apps.make = function make_model(data) {
                    return new APPDEF(data);
                };
                apps.GET = function get_user_data(params) {
                    var self = this, location = 'workspaces/' + params.id + '/apps', model_defer = $q.defer();
                    $ay_requester.GET(location).then(function (results) {
                        if (!results.error) {
                            results.data = self.make(results.data);
                        }
                        model_defer.resolve(results);
                    });
                    return model_defer.promise;
                };
                return apps;
            }
        ]
    };
});