define('modules/rest/factories/bulk-jobs', [], function () {
    'use strict';
    return {
        component: 'service',
        name: 'restFactoryBulkJobs',
        fn: [
            '$log',
            '$schemaValidator',
            '$ay_requester',
            '$q',
            '$timeout',
            '$ME',
            function view_factory($log, $schemaValidator, $ay_requester, $q, $timeout, $ME) {
                var LOCATION = 'bulk_data_jobs';
                function BulkJobModel(data, schema) {
                    this.schema = schema;
                    this.id = null;
                    this.media_type = 'application/vnd.amity.hub.bulk_data_job+json';
                    this.workspace_id = $ME.workspace.id;
                    this.created_at = null;
                    this.content_type = null;
                    this.entity_target = null;
                    this.errors = null;
                    this.file_id = null;
                    this.finished_at = null;
                    this.message = null;
                    this.name = null;
                    this.scheduled_at = null;
                    this.started_at = null;
                    this.status = null;
                    this.type = null;
                    this.records_processed = null;
                    this.records_failed = null;
                    this.records_succeeded = null;
                    this.outcome = null;
                    this.labels = {
                        entity: '',
                        status: '',
                        type: ''
                    };
                    if (data) {
                        this._set(data);
                    }
                }
                BulkJobModel.prototype._set = function (data) {
                    this.id = data.id;
                    this.location = LOCATION + '/' + data.id;
                    this.media_type = data.media_type || this.media_type;
                    this.workspace_id = data.workspace_id || $ME.workspace.id;
                    this.created_at = data.created_at;
                    this.content_type = data.content_type;
                    this.entity_target = data.entity_target;
                    this.errors = data.errors;
                    this.file_id = data.file_id ? JSON.parse(data.file_id) : {};
                    this.finished_at = data.finished_at;
                    this.message = data.message;
                    this.name = data.name;
                    this.scheduled_at = data.scheduled_at;
                    this.started_at = data.started_at;
                    this.status = data.status;
                    this.type = data.type;
                    this.records_processed = data.records_processed || 0;
                    this.records_failed = data.records_failed || 0;
                    this.labels = {
                        entity: '',
                        status: '',
                        type: ''
                    };
                    this.outcome = {};
                    switch (this.entity_target) {
                    case 'participant':
                        this.labels.entity = 'People';
                        break;
                    case 'activity':
                        this.labels.entity = 'Activities';
                        break;
                    case 'account':
                        this.labels.entity = 'Accounts';
                        break;
                    }
                    switch (this.status) {
                    case 'created':
                        this.labels.status = 'Created';
                        break;
                    case 'failed':
                        this.labels.status = 'Failed';
                        break;
                    case 'finished':
                        this.labels.status = 'Finished';
                        break;
                    case 'in_progress':
                        this.labels.status = 'In Progress';
                        break;
                    case 'scheduled':
                        this.labels.status = 'Scheduled';
                        break;
                    }
                    switch (this.type) {
                    case 'load.create':
                        this.labels.type = 'Load Create';
                        break;
                    case 'load_dry_run.create':
                        this.labels.type = 'Load Create (Dry Run)';
                        break;
                    case 'load.update':
                        this.labels.type = 'Load Update';
                        break;
                    case 'load_dry_run.update':
                        this.labels.type = 'Load Update (Dry Run)';
                        break;
                    case 'load.upsert':
                        this.labels.type = 'Load Upsert';
                        break;
                    case 'load_dry_run.upsert':
                        this.labels.type = 'Load Upsert (Dry Run)';
                        break;
                    case 'load.delete':
                        this.labels.type = 'Load Delete';
                        break;
                    case 'load_dry_run.delete':
                        this.labels.type = 'Load Delete (Dry Run)';
                        break;
                    }
                    if (this.records_failed) {
                        if (this.records_failed < this.records_processed) {
                            this.outcome = {
                                type: 'warn',
                                style: 'yellow s-100'
                            };
                        } else {
                            this.outcome = {
                                type: 'error',
                                style: 'red s-100'
                            };
                        }
                    } else {
                        this.outcome = {
                            type: 'success',
                            style: 'white'
                        };
                    }
                };
                function BulkJobsFactory() {
                }
                BulkJobsFactory.prototype.location = LOCATION;
                BulkJobsFactory.prototype._newModel = function (data) {
                    var self = this;
                    return new BulkJobModel(data, self.schema);
                };
                BulkJobsFactory.prototype._buildOptions = function (params) {
                    var options = {
                        f: [{
                                field: '/workspace_id',
                                op: 'EQ',
                                value: $ME.workspace.id
                            }],
                        s: []
                    };
                    if (params.hasOwnProperty('id')) {
                        options.f.push({
                            field: '/id',
                            op: 'EQ',
                            value: params.id
                        });
                    }
                    if (params.sorts) {
                        options.s = angular.copy(params.sorts);
                    } else {
                        options.s.push({
                            field: '/created_at',
                            descending: true
                        });
                    }
                    return options;
                };
                BulkJobsFactory.prototype.getSchema = function () {
                    var s = this, schemaDefer = $q.defer();
                    function setEntitySchema(results) {
                        if (!results.error) {
                            s.schema = results.data;
                            schemaDefer.resolve(s.schema);
                        } else {
                            schemaDefer.resolve(results);
                        }
                    }
                    if (s.schema) {
                        $timeout(function () {
                            schemaDefer.resolve(s.schema);
                        });
                    } else {
                        $ay_requester.GET('schema/' + LOCATION).then(setEntitySchema);
                    }
                    return schemaDefer.promise;
                };
                BulkJobsFactory.prototype.get = function (params) {
                    var drainPromise, self = this, getDefer = $q.defer(), location = LOCATION, models = [];
                    function setModels(results) {
                        if (results.error) {
                            getDefer.resolve(results);
                        } else {
                            if (!results.data.hasOwnProperty('items')) {
                                results.data = new BulkJobModel(results.data);
                                getDefer.resolve(results);
                            } else {
                                models = models.concat(results.data.items.map(function (data) {
                                    return new BulkJobModel(data);
                                }));
                                results.data = models;
                                if (!params.drain) {
                                    getDefer.resolve(results);
                                } else {
                                    if (!results.cursor) {
                                        getDefer.resolve(results);
                                    } else {
                                        params.cursor = results.cursor;
                                        drainPromise = $ay_requester.GET(location, params);
                                        drainPromise.then(setModels);
                                    }
                                }
                            }
                        }
                    }
                    self.getSchema().then(function (results) {
                        params = params || {};
                        if (params.id) {
                            location += '/' + params.id;
                        } else {
                            params.options = self._buildOptions(params);
                            params.range = 25;
                        }
                        $ay_requester.GET(location, params).then(setModels);
                    });
                    return getDefer.promise;
                };
                return new BulkJobsFactory();
            }
        ]
    };
});