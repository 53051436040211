define('modules/form-inputs/directives/icon-picker', [], function () {
    'use strict';
    return {
        name: 'ayIconPicker',
        component: 'directive',
        fn: [
            '$rootScope',
            '$log',
            '$utls',
            '$compile',
            '$timeout',
            '$document',
            function ($rootScope, $log, $utls, $compile, $timeout, $document) {
                return {
                    restrict: 'A',
                    replace: true,
                    scope: { source: '=ayIconPicker' },
                    link: function ($scope, elem, attrs) {
                        var linkFn, picker = angular.element('<section class="amity-picker white shadow-outline hide w-300-max"><div class="nav is-hoz"><div ng-repeat="icon in iconList track by icon.value" class="pad is-larger action" ng-click="changes.selected(icon)"><i class="{{icon.value}}"></i></div></div><div class="footer is-dead"><div></div><div class="is-dark nav is-hoz is-vertical"><div class="hover-action" ng-click="changes.clear()">Clear</div></div></div></section>'), parent_element = $document.find('body');
                        $scope.iconList = [
                            {
                                label: '',
                                value: 'fa-address-card'
                            },
                            {
                                label: '',
                                value: 'fa-address-book'
                            },
                            {
                                label: '',
                                value: 'fa-ambulance'
                            },
                            {
                                label: '',
                                value: 'fa-anchor'
                            },
                            {
                                label: '',
                                value: 'fa-bank'
                            },
                            {
                                label: '',
                                value: 'fa-book'
                            },
                            {
                                label: '',
                                value: 'fa-bookmark'
                            },
                            {
                                label: '',
                                value: 'fa-briefcase'
                            },
                            {
                                label: '',
                                value: 'fa-bolt'
                            },
                            {
                                label: '',
                                value: 'fa-black-tie'
                            },
                            {
                                label: '',
                                value: 'fa-bell'
                            },
                            {
                                label: '',
                                value: 'fa-calendar-check-o'
                            },
                            {
                                label: '',
                                value: 'fa-comments'
                            },
                            {
                                label: '',
                                value: 'fa-credit-card'
                            },
                            {
                                label: '',
                                value: 'fa-diamond'
                            },
                            {
                                label: '',
                                value: 'fa-flag'
                            },
                            {
                                label: '',
                                value: 'fa-flask'
                            },
                            {
                                label: '',
                                value: 'fa-heart'
                            },
                            {
                                label: '',
                                value: 'fa-leaf'
                            },
                            {
                                label: '',
                                value: 'fa-life-ring'
                            },
                            {
                                label: '',
                                value: 'fa-rocket'
                            },
                            {
                                label: '',
                                value: 'fa-star'
                            }
                        ];
                        $scope.changes = {
                            selected: function (icon) {
                                $scope.source = icon.value;
                            },
                            clear: function () {
                                $scope.source = '';
                            }
                        };
                        function closePicker(event) {
                            var locator = event.toElement ? 'toElement' : 'relatedTarget';
                            if (elem[0] !== event[locator] && !elem[0].contains(event[locator]) && picker[0] !== event[locator] && !picker[0].contains(event[locator])) {
                                picker.addClass('hide');
                            }
                        }
                        elem.addClass('ay-icon-selector bdr is-dead is-round is-center');
                        elem.bind('mouseout', closePicker);
                        picker.bind('mouseout', closePicker);
                        parent_element.append(picker);
                        linkFn = $compile(picker);
                        linkFn($scope);
                        elem.bind('mouseover', function (event) {
                            picker.removeClass('hide');
                            var scroll_offset, content_bounds, top, elem_bounds = elem[0].getBoundingClientRect(), picker_bounds = picker[0].getBoundingClientRect();
                            scroll_offset = parent_element[0].scrollTop;
                            content_bounds = parent_element[0].getBoundingClientRect();
                            picker.css('left', elem_bounds.right - content_bounds.left - 1);
                            top = scroll_offset + elem_bounds.top + (elem_bounds.height - picker_bounds.height) / 2 - content_bounds.top + 50;
                            if (top + picker_bounds.height + 20 > content_bounds.height) {
                                top = content_bounds.height - 20 - picker_bounds.height;
                            }
                            picker.css('top', top);
                        });
                        $scope.$on('$destroy', function (event) {
                            picker.remove();
                        });
                    }
                };
            }
        ]
    };
});