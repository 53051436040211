define('modules/rest/factories/email-template', [], function () {
    'use strict';
    return {
        component: 'service',
        name: 'factoryEmailTemplate',
        fn: [
            '$log',
            '$schemaValidator',
            '$ay_requester',
            '$q',
            '$timeout',
            '$ME',
            function factory($log, $schemaValidator, $ay_requester, $q, $timeout, $ME) {
                var LOCATION = 'email_templates', factory = {};
                function Model_Email_Template(_data, schema) {
                    this.schema = schema;
                    this.location = LOCATION + '/';
                    this.etag = null;
                    this.media_type = schema ? schema.description : 'application/vnd.amity.hub.' + LOCATION + '+json';
                    this.workspace_id = $ME.workspace.id;
                    this.created_at = null;
                    this.id = null;
                    this.description = null;
                    this.name = null;
                    this.subject = null;
                    this.html_body = null;
                    this.internal_users = null;
                    if (_data) {
                        this.set(_data);
                    }
                }
                Model_Email_Template.prototype.set = function (data) {
                    this.id = data.id;
                    this.location = LOCATION + '/' + data.id;
                    this.etag = data.etag;
                    this.media_type = data.media_type || this.media_type;
                    this.workspace_id = data.workspace_id || $ME.workspace.id;
                    this.created_at = data.created_at;
                    this.id = data.id;
                    this.description = data.description;
                    this.name = data.name;
                    this.subject = data.subject;
                    this.html_body = data.html_body;
                    this.internal_users = data.internal_users;
                };
                Model_Email_Template.prototype.remove = function () {
                    var self = this;
                    return $ay_requester.DELETE(self.location, {});
                };
                Model_Email_Template.prototype.clone = function () {
                    var self = this, copy = angular.copy(self);
                    delete copy.created_at;
                    delete copy.id;
                    delete copy.etag;
                    copy.name += ' copy';
                    return copy;
                };
                Model_Email_Template.prototype.getEtag = function () {
                    var self = this, etagDefer = $q.defer();
                    if (self.etag) {
                        $timeout(function () {
                            etagDefer.resolve({ etag: self.etag });
                        }, 1);
                    } else {
                        $ay_requester.GET(self.location).then(function (results) {
                            if (!results.error) {
                                self.etag = results.data.etag;
                                results.etag = self.etag;
                            }
                            etagDefer.resolve(results);
                        });
                    }
                    return etagDefer.promise;
                };
                Model_Email_Template.prototype.save = function () {
                    var self = this, saveDefer = $q.defer(), validation_res = $schemaValidator.validate(angular.copy(self), factory.schema);
                    self.getEtag().then(function (results) {
                        if (results.error) {
                            saveDefer.resolve(results);
                        } else {
                            if (validation_res.error) {
                                saveDefer.resolve(validation_res);
                            } else {
                                $ay_requester.PUT(self.location, {
                                    etag: results.etag,
                                    data: validation_res.data
                                }).then(function complete_request(results) {
                                    if (!results.error) {
                                        self.set(results.data);
                                        results.data = self;
                                    }
                                    saveDefer.resolve(results);
                                });
                            }
                        }
                    });
                    return saveDefer.promise;
                };
                Model_Email_Template.prototype.sendTest = function (testData) {
                    var self = this;
                    testData.workspace_id = self.workspace_id;
                    testData.subject = self.subject;
                    testData.html_body = self.html_body;
                    testData.action = self.internal_users ? 'send_user_test' : 'send_participant_test';
                    return $ay_requester.POST('email', {
                        data: testData,
                        retrieveEntity: false
                    });
                };
                factory.location = LOCATION;
                factory.new_model = function new_model(data) {
                    var self = this;
                    return new Model_Email_Template(data, self.schema);
                };
                factory.getSchema = function get_entity_schema() {
                    var s = this, schemaDefer = $q.defer();
                    function setEntitySchema(results) {
                        if (!results.error) {
                            s.schema = results.data;
                            schemaDefer.resolve(s.schema);
                        } else {
                            schemaDefer.resolve(results);
                        }
                    }
                    if (s.schema) {
                        $timeout(function () {
                            schemaDefer.resolve(s.schema);
                        }, 1);
                    } else {
                        $ay_requester.GET('schema/' + LOCATION).then(setEntitySchema);
                    }
                    return schemaDefer.promise;
                };
                factory.create = function create_model(data) {
                    var self = this, create_defer = $q.defer();
                    function post() {
                        var validation_res = $schemaValidator.validate(data, self.schema);
                        if (validation_res.error) {
                            $timeout(function () {
                                create_defer.resolve(validation_res);
                            }, 1);
                        } else {
                            $ay_requester.POST(self.location, { data: validation_res.data }).then(function (results) {
                                if (!results.error) {
                                    results.data = self.new_model(results.data);
                                }
                                create_defer.resolve(results);
                            });
                        }
                    }
                    if (self.schema) {
                        post();
                    } else {
                        self.getSchema().then(post);
                    }
                    return create_defer.promise;
                };
                factory._build_query = function build_query(params) {
                    var self = this, workspace_filter = {
                            field: '/workspace_id',
                            op: 'EQ',
                            value: $ME.workspace.id
                        }, options = {
                            f: [],
                            s: []
                        };
                    if (params.hasOwnProperty('filters')) {
                        options.f = angular.copy(params.filters);
                    }
                    options.f.push(workspace_filter);
                    if (params.hasOwnProperty('terms')) {
                        options.f.push({
                            op: 'OR',
                            value: [
                                [{
                                        'field': '/name',
                                        op: 'CONTAINS',
                                        value: params.terms
                                    }],
                                [{
                                        'field': '/subject',
                                        op: 'CONTAINS',
                                        value: params.terms
                                    }]
                            ]
                        });
                    }
                    if (params.hasOwnProperty('isInternal')) {
                        if (params.isInternal) {
                            options.f.push({
                                'field': '/internal_users',
                                op: 'EQ',
                                value: params.isInternal
                            });
                        } else {
                            options.f.push({
                                op: 'OR',
                                value: [
                                    [{
                                            'field': '/internal_users',
                                            op: 'EQ',
                                            value: params.isInternal
                                        }],
                                    [{
                                            'field': '/internal_users',
                                            op: 'EXISTS',
                                            value: false
                                        }]
                                ]
                            });
                        }
                    }
                    if (params.hasOwnProperty('sorts')) {
                        options.s = angular.copy(params.sorts);
                    } else {
                        options.s = [{
                                field: '/name',
                                descending: false
                            }];
                    }
                    return options;
                };
                factory.get = function get_model(params) {
                    var self = this, get_defer = $q.defer(), location = self.location;
                    params = params || {};
                    if (params.id) {
                        location += '/' + params.id;
                    } else {
                        params.options = self._build_query(params);
                    }
                    function load_results(results) {
                        if (!results.error) {
                            if (results.data.hasOwnProperty('items')) {
                                results.data = results.data.items.map(self.new_model, self);
                            } else {
                                results.data = self.new_model(results.data);
                            }
                        }
                        get_defer.resolve(results);
                    }
                    function send_query(results) {
                        if (!results || !results.error) {
                            $ay_requester.GET(location, params).then(load_results);
                        } else {
                            get_defer.resolve(results);
                        }
                    }
                    if (self.schema) {
                        send_query();
                    } else {
                        self.getSchema().then(send_query);
                    }
                    return get_defer.promise;
                };
                return factory;
            }
        ]
    };
});