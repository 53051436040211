define('modules/rest/factories/workspace-goals', [], function () {
    'use strict';
    return {
        component: 'service',
        name: 'modelWorkspaceGoals',
        fn: [
            '$q',
            '$ay_requester',
            '$schemaValidator',
            '$log',
            '$ME',
            function workspace_goals_factory($q, $ay_requester, $schemaValidator, $log, $ME) {
                function rest_location() {
                    return 'workspaces/' + $ME.workspace.id + '/goals';
                }
                function Model_Workspace_Goal(data, schema) {
                    this.etag = '';
                    this.created_at = '';
                    this.media_type = '';
                    this.account_lifecycle_stage_targets = [];
                    this.schema = schema;
                    if (data) {
                        this.set(data);
                    }
                }
                Model_Workspace_Goal.prototype.set = function (data) {
                    var self = this;
                    function create_stages() {
                        var i, stage;
                        for (i = 0; i < $ME.workspace.account_stages.length; i++) {
                            stage = $ME.workspace.account_stages[i];
                            if (stage.enabled) {
                                self.create_stage_target({
                                    id: stage.id,
                                    name: stage.name
                                });
                            }
                        }
                    }
                    function check_stages() {
                        var i, index, stage;
                        for (i = 0; i < $ME.workspace.account_stages.length; i++) {
                            stage = $ME.workspace.account_stages[i];
                            index = self.account_lifecycle_stage_targets._index_of_by_prop('id', stage.id);
                            if (!stage.enabled && index !== -1) {
                                self.remove_stage_target(index);
                            } else if (stage.enabled) {
                                if (index === -1) {
                                    self.create_stage_target({
                                        id: stage.id,
                                        name: stage.name
                                    });
                                } else {
                                    self.account_lifecycle_stage_targets[index].name = stage.name;
                                }
                            }
                        }
                    }
                    self.etag = data && data.hasOwnProperty('etag') ? data.etag : null;
                    self.created_at = data && data.hasOwnProperty('created_at') ? new Date(data.created_at) : null;
                    self.media_type = data && data.hasOwnProperty('media_type') ? data.media_type : null;
                    self.account_lifecycle_stage_targets = [];
                    if (data && data.hasOwnProperty('account_lifecycle_stage_targets')) {
                        data.account_lifecycle_stage_targets.map(self.create_stage_target, self);
                    }
                    if (!self.account_lifecycle_stage_targets.length) {
                        create_stages();
                    } else {
                        check_stages();
                    }
                    return self;
                };
                Model_Workspace_Goal.prototype.save = function save_workspace_goal() {
                    var self = this, save_defer = $q.defer(), data = angular.copy(self), location = rest_location();
                    function remove_name(item) {
                        var temp = angular.copy(item);
                        delete temp.name;
                        delete temp.$$hashKey;
                        delete temp.$isValid;
                        return temp;
                    }
                    data.account_lifecycle_stage_targets = data.account_lifecycle_stage_targets.map(remove_name);
                    delete data.etag;
                    delete data.schema;
                    $ay_requester.PUT(location, {
                        data: data,
                        etag: self.etag
                    }).then(function confirm_save(results) {
                        if (!results.error) {
                            self.etag = results.data.etag;
                            results.data = self;
                        }
                        save_defer.resolve(results);
                    });
                    return save_defer.promise;
                };
                Model_Workspace_Goal.prototype.create_stage_target = function create_stage_target(data) {
                    var target = {
                        id: data && data.hasOwnProperty('id') ? data.id : 0,
                        account_count: data && data.hasOwnProperty('account_count') ? data.account_count : 0,
                        account_value: data && data.hasOwnProperty('account_value') ? data.account_value : 0,
                        name: data && data.hasOwnProperty('name') ? data.name : ''
                    };
                    this.account_lifecycle_stage_targets.push(target);
                    return this.account_lifecycle_stage_targets[this.account_lifecycle_stage_targets.length - 1];
                };
                Model_Workspace_Goal.prototype.remove_stage_target = function remove_stage_target(index) {
                    if (angular.isNumber(index) && index !== -1) {
                        this.account_lifecycle_stage_targets.splice(index, 1);
                    }
                    return this.account_lifecycle_stage_targets;
                };
                function Rest_Factory_Workspace_Goal() {
                    this.schema = null;
                }
                Rest_Factory_Workspace_Goal.prototype.new_model = function new_workspace_goal_model(data) {
                    var self = this;
                    return new Model_Workspace_Goal(data, self.schema);
                };
                Rest_Factory_Workspace_Goal.prototype.get = function get_workspace_goal(options) {
                    var self = this, get_defer = $q.defer(), location = rest_location();
                    function set_results(results) {
                        if (!results.error) {
                            if (results.data.hasOwnProperty('items')) {
                                results.data = results.data.items.map(self.new_model, self);
                            } else {
                                results.data = self.new_model(results.data);
                            }
                        }
                        get_defer.resolve(results);
                    }
                    function load_results(results) {
                        if (!results.error) {
                            set_results(results);
                        } else {
                            switch (results.status) {
                            case 404:
                                $ay_requester.PUT(location, {
                                    upsert: true,
                                    data: {
                                        media_type: 'application/vnd.amity.hub.goals+json',
                                        account_lifecycle_stage_targets: []
                                    }
                                }).then(function (create_results) {
                                    set_results(create_results);
                                });
                                break;
                            default:
                                get_defer.resolve(results);
                                break;
                            }
                        }
                    }
                    $ay_requester.GET(location, {}).then(load_results);
                    return get_defer.promise;
                };
                Rest_Factory_Workspace_Goal.prototype.create = function create_workspace_goal() {
                    var self = this, create_defer = $q.defer();
                    return create_defer.promise;
                };
                return new Rest_Factory_Workspace_Goal();
            }
        ]
    };
});