define('modules/enviroment/index', [
    'modules/enviroment/values/app-data',
    'modules/enviroment/services/core',
    'modules/enviroment/services/packages',
    'modules/enviroment/services/config',
    'modules/enviroment/services/phare',
    'modules/enviroment/controllers/platform',
    'modules/enviroment/directives/content',
    'modules/enviroment/directives/loader'
], function () {
    var def, module = angular.module('ay-enviroment-mod', []);
    for (var i = 0, ii = arguments.length; i < ii; i++) {
        def = arguments[i];
        if (def.hasOwnProperty('name') && def.hasOwnProperty('fn') && def.hasOwnProperty('component')) {
            module[def.component](def.name, def.fn);
        }
    }
    return module;
});