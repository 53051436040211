define('modules/form-inputs/directives/input-datetime', [], function () {
    return {
        component: 'directive',
        name: 'ayFormInputDatetime',
        fn: [
            '$timeout',
            function ($timeout) {
                return {
                    restrict: 'E',
                    templateUrl: 'form-inputs/views/input-datetime.html',
                    require: '?ngModel',
                    scope: {
                        label: '@',
                        isRequired: '@',
                        validationMsg: '@',
                        helpText: '@',
                        options: '=',
                        isDisabled: '=?',
                        isFocused: '@',
                        tabIndex: '@?'
                    },
                    controller: function () {
                        this.value = '';
                        this.viewValue = '';
                    },
                    controllerAs: 'formInput',
                    bindToController: true,
                    link: function (scope, elem, attrs, ngModel) {
                        var width;
                        scope.formInput.id = scope.$id + '-datepicker';
                        scope.formInput.isRequired = scope.formInput.isRequired === 'true' ? true : false;
                        scope.formInput.styling = { width: scope.formInput.width || '100%' };
                        scope.formInput.msgStyling = { visibility: 'hidden' };
                        scope.formInput.updateView = function (value) {
                            scope.formInput.viewValue = value;
                        };
                        scope.formInput.setValue = function () {
                            if (scope.formInput.options.valueOverride) {
                                scope.formInput.value = scope.formInput.options.valueOverride(angular.copy(ngModel.$modelValue));
                            } else {
                                scope.formInput.value = angular.copy(ngModel.$modelValue);
                            }
                        };
                        scope.formInput.onChange = function (event) {
                            width = parseInt(elem.find('.form-input-ctrl').css('width').replace('px', ''), 10);
                            if (event === 'selected') {
                                if (scope.formInput.options.clearOverride && !scope.formInput.value) {
                                    ngModel.$setViewValue(scope.formInput.options.clearOverride(), event);
                                    scope.formInput.setValue();
                                } else {
                                    ngModel.$setViewValue(scope.formInput.value, event);
                                }
                            }
                        };
                        scope.formInput.setFocus = function () {
                            elem.addClass('is-selected');
                        };
                        scope.formInput.onBlur = function (event) {
                            elem.removeClass('is-selected');
                        };
                        scope.formInput.onMouseDown = function (event) {
                            scope.formInput.setFocus();
                        };
                        ngModel.$render = function () {
                            scope.formInput.setValue();
                            scope.formInput.updateView(ngModel.$modelValue);
                        };
                        ngModel.$viewChangeListeners.push(function () {
                            if (attrs.onChange) {
                                scope.$parent.$eval(attrs.onChange);
                            }
                            scope.formInput.updateView(ngModel.$modelValue);
                        });
                        function checkInputMsg(newVal) {
                            var inputMsg = elem.find('.form-input-msg');
                            function updateMsg() {
                                var msgWidth = parseInt(inputMsg.css('width').replace('px', ''), 10) + 5;
                                if (width > msgWidth) {
                                    scope.formInput.msgStyling.position = 'initial';
                                    scope.formInput.msgStyling['white-space'] = 'normal';
                                } else {
                                    scope.formInput.msgStyling['margin-bottom', '22px'];
                                }
                                scope.formInput.msgStyling.visibility = 'visible';
                            }
                            if (inputMsg[0]) {
                                scope.formInput.msgStyling.visibility = 'hidden';
                                scope.formInput.msgStyling.position = 'absolute';
                                scope.formInput.msgStyling['white-space'] = 'nowrap';
                                elem.css('margin-bottom', '0px');
                                $timeout(updateMsg, 10);
                            } else {
                                scope.formInput.msgStyling.visibility = 'visible';
                            }
                        }
                        function onClose(event) {
                            elem.removeClass('is-selected');
                            if (scope.onClose) {
                                scope.onClose(event);
                            }
                            if (attrs.onClose) {
                                scope.$parent.$eval(attrs.onClose, {
                                    $event: event,
                                    ngModelCtrl: ngModel
                                });
                            }
                        }
                        function init() {
                            if (attrs.overflowError && attrs.overflowError === 'true') {
                                scope.$watch('formInput.validationMsg', checkInputMsg);
                                scope.$watch('formInput.helpText', checkInputMsg);
                                checkInputMsg();
                            } else {
                                scope.formInput.msgStyling.visibility = 'visible';
                            }
                            if (scope.formInput.options.on_close) {
                                scope.onClose = angular.copy(scope.formInput.options.on_close);
                            }
                            scope.formInput.options.on_close = onClose;
                            if (scope.formInput.isFocused === 'true') {
                                angular.element(elem.find('.form-input-field')).focus();
                            }
                        }
                        $timeout(init, 1);
                    }
                };
            }
        ]
    };
});