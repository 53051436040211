define('modules/form-inputs/index', [
    'modules/form-inputs/directives/list',
    'modules/form-inputs/directives/editor',
    'modules/form-inputs/directives/editor-code',
    'modules/form-inputs/directives/autocomplete',
    'modules/form-inputs/directives/entity-filter',
    'modules/form-inputs/directives/entity-filter-readonly',
    'modules/form-inputs/directives/pills',
    'modules/form-inputs/directives/color-picker',
    'modules/form-inputs/directives/icon-picker',
    'modules/form-inputs/directives/input-text',
    'modules/form-inputs/directives/input-readonly',
    'modules/form-inputs/directives/input-checkbox',
    'modules/form-inputs/directives/input-radio',
    'modules/form-inputs/directives/input-number',
    'modules/form-inputs/directives/input-select',
    'modules/form-inputs/directives/input-datetime',
    'modules/form-inputs/directives/input-array',
    'modules/form-inputs/directives/input-array-string',
    'modules/form-inputs/directives/input-array-number',
    'modules/form-inputs/directives/input-array-menu',
    'modules/form-inputs/directives/input-login',
    'modules/form-inputs/directives/input-password',
    'modules/form-inputs/directives/input-autocomplete',
    'modules/form-inputs/directives/menu'
], function () {
    var def, module = angular.module('ay-form-inputs-mod', []);
    for (var i = 0, ii = arguments.length; i < ii; i++) {
        def = arguments[i];
        if (def.hasOwnProperty('name') && def.hasOwnProperty('fn') && def.hasOwnProperty('component')) {
            module[def.component](def.name, def.fn);
        }
    }
    return module;
});