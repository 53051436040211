define('modules/authentication/services/session', [], function () {
    'use strict';
    return {
        component: 'service',
        name: '$ay_session',
        fn: [
            '$log',
            '$http',
            '$q',
            '$timeout',
            '$cookies',
            'errorDetection',
            '$ay_connection',
            '$ayEnviromentCore',
            '$rootScope',
            function authentication_session_service($log, $http, $q, $timeout, $cookies, errorDetection, $ay_connection, $ayEnviromentCore, $rootScope) {
                var COOKIE_NAME = 'amity_csm';
                function Token() {
                    var isDev = $ayEnviromentCore.isDev(), details = {
                            _enabled: true,
                            access: '',
                            refresh: '',
                            type: '',
                            expiry: 0,
                            timer: null,
                            retry_delay: 10000,
                            retry_cnt: 0,
                            ms_second: 1000,
                            remove_values: {
                                expires: new Date('Thu, 01 Jan 1970 00:00:00 GMT'),
                                secure: !isDev
                            }
                        };
                    details.ms_year = 365 * 24 * 60 * 60 * details.ms_second;
                    this.is_initialized = function () {
                        return details.access && details.refresh && details.type && details.expiry && (details.timer && details.timer.$$state.status !== 2);
                    };
                    this._toggle_status = function () {
                        details._enabled = !details._enabled;
                    };
                    this._cancel = function () {
                        details.retry_cnt = 0;
                        if (details.timer) {
                            $timeout.cancel(details.timer);
                        }
                    };
                    this._refresh = function refresh_token() {
                        var token = this, refresh_defer = $q.defer(), payload = {
                                method: 'POST',
                                url: '/oauth/access_token',
                                data: {
                                    grant_type: 'refresh_token',
                                    refresh_token: details.refresh
                                }
                            };
                        function update_token(response) {
                            var reachable = true, return_obj = {
                                    data: response.data,
                                    status: response.status,
                                    headers: response.headers,
                                    error: errorDetection(status)
                                };
                            if (!return_obj.error) {
                                return_obj.data = token.SET(return_obj.data, return_obj).GET();
                                refresh_defer.resolve(return_obj);
                            } else if (return_obj.error.status === 400) {
                                refresh_defer.resolve(return_obj);
                            } else {
                                details.retry_cnt++;
                                if (return_obj.error.status !== 0) {
                                    reachable = false;
                                }
                                if (details.retry_cnt < 10) {
                                    if (details._enabled) {
                                        details.timer = $timeout(function () {
                                            token._refresh();
                                        }, details.retry_delay);
                                    }
                                } else {
                                    refresh_defer.resolve(return_obj);
                                }
                            }
                            $ay_connection.update_status(reachable);
                        }
                        function call_rest() {
                            $http(payload).then(update_token, update_token);
                        }
                        if ($ay_connection.online) {
                            call_rest();
                        }
                        return refresh_defer.promise;
                    };
                    this.DELETE = function () {
                        this._cancel();
                        details.access = '';
                        details.refresh = '';
                        details.type = '';
                        details.expiry = 0;
                        var delete_defer = $q.defer();
                        $cookies.putObject(COOKIE_NAME, { r: '' }, details.remove_values);
                        $cookies.remove(COOKIE_NAME);
                        $timeout(function () {
                            delete_defer.resolve({ error: $cookies.getObject(COOKIE_NAME) });
                        }, 1);
                        return delete_defer.promise;
                    };
                    this.SET = function (data, request) {
                        var token = this;
                        if (!data) {
                            var error = errorDetection(1006);
                            if (request) {
                                error.request = request;
                            }
                            $log.error({ error: error });
                        } else {
                            details.access = data.access_token;
                            details.expiry = data.expires_in ? data.expires_in * details.ms_second * 0.8 : 0;
                            details.refresh = data.refresh_token;
                            details.type = data.token_type;
                            if (details.expiry) {
                                token._cancel();
                                if (details._enabled) {
                                    details.timer = $timeout(function () {
                                        token._refresh();
                                    }, details.expiry);
                                }
                                var data = { r: details.refresh }, options = {
                                        expires: new Date(new Date().getTime() + details.ms_year),
                                        secure: !isDev
                                    };
                                $cookies.remove(COOKIE_NAME);
                                $cookies.putObject(COOKIE_NAME, data, options);
                            }
                            if (!data.isImpersonate) {
                                $rootScope.$broadcast('token-refreshed');
                            }
                        }
                        return token;
                    };
                    this.GET = function () {
                        return { Authorization: details.type + ' ' + details.access };
                    };
                    this._renew = function () {
                        this._cancel();
                        return this._refresh();
                    };
                }
                function Session_Service() {
                }
                Session_Service.prototype._token = new Token();
                Session_Service.prototype.isSuspended = false;
                Session_Service.prototype.CREATE = function create_session(data, isImpersonate) {
                    var self = this, results = {
                            error: false,
                            data: self._token
                        }, token_defer = $q.defer();
                    function refreshToken(data) {
                        if (data.online) {
                            self._token._refresh();
                        }
                    }
                    if (data) {
                        self._token = self._token.SET(data);
                        $timeout(function () {
                            token_defer.resolve(results);
                        }, 1);
                    } else {
                        data = $cookies.getObject(COOKIE_NAME);
                        if (!data) {
                            $timeout(function () {
                                token_defer.resolve(results);
                            }, 1);
                        } else {
                            $cookies.putObject(COOKIE_NAME, { r: '' }, self.remove_values);
                            $cookies.remove(COOKIE_NAME);
                            self._token.SET({
                                refresh_token: data.r,
                                expires_in: 0,
                                isImpersonate: isImpersonate
                            });
                            self._token._renew().then(function (renew_results) {
                                results.error = renew_results.error;
                                token_defer.resolve(results);
                            });
                            $ay_connection.listener(refreshToken);
                        }
                    }
                    return token_defer.promise;
                };
                Session_Service.prototype.GET = function get_session() {
                    return this._token.GET();
                };
                Session_Service.prototype.DELETE = function delete_session() {
                    return this._token.DELETE();
                };
                Session_Service.prototype.is_initialized = function is_session_initialized() {
                    return this._token.is_initialized();
                };
                Session_Service.prototype.renew = function renew_session() {
                    return this._token._renew();
                };
                return new Session_Service();
            }
        ]
    };
});