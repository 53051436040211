define('modules/form-inputs/directives/list', [], function () {
    return {
        component: 'directive',
        name: 'ayFormControlsList',
        fn: [
            'srvcMappingFields',
            '$schemaValidator',
            '$q',
            '$parse',
            '$timeout',
            '$filter',
            '$utls',
            '$ayTimelineContext',
            function (srvcMappingFields, $schemaValidator, $q, $parse, $timeout, $filter, $utls, $ayTimelineContext) {
                return {
                    restrict: 'A',
                    templateUrl: 'form-inputs/views/list.html',
                    scope: { options: '=ayFormControlsList' },
                    link: function ($scope, elem, attrs) {
                        var currency_symbol = $filter('currency_symbol');
                        $scope.options.hasItemActions = $scope.options.hasOwnProperty('hasItemActions') ? $scope.options.hasItemActions : false;
                        $scope.currency_symbol = currency_symbol();
                        $scope.list_values = [];
                        $scope.input_types = srvcMappingFields.input_types;
                        $scope.selected = {
                            search: '',
                            filter: {},
                            sort: {
                                prop: '',
                                desc: true
                            },
                            content: '',
                            context: ''
                        };
                        $scope.boolean_groups = {};
                        $scope.primary_col = null;
                        $scope.basic_filters = [
                            {
                                id: 1,
                                label: 'Enabled',
                                fn: function enabled_filter(item) {
                                    return item && item.enabled;
                                }
                            },
                            {
                                id: 2,
                                label: 'Disabled',
                                fn: function disabled_filter(item) {
                                    return item && !item.enabled;
                                }
                            }
                        ];
                        $scope.changes = {
                            search: function (searchText) {
                                var option;
                                function textFilter() {
                                    var filteredList = [];
                                    for (var i = 0, ii = $scope.list_values.length; i < ii; i++) {
                                        option = $scope.list_values[i];
                                        if (option.hasOwnProperty($scope.options.searchField) && option[$scope.options.searchField].search(new RegExp(searchText, 'gi')) !== -1) {
                                            filteredList.push(option);
                                        }
                                    }
                                    $scope.list_values = filteredList;
                                }
                                $scope.filter_list($scope.selected.filter);
                                if (searchText) {
                                    textFilter();
                                }
                            },
                            sort: function (prop) {
                                if (prop) {
                                    if ($scope.selected.sort.prop === prop) {
                                        $scope.selected.sort.desc = !$scope.selected.sort.desc;
                                        $scope.selected.sort.styling = $scope.selected.sort.desc ? 'fa-caret-down' : 'fa-caret-up';
                                    } else {
                                        $scope.selected.sort.desc = false;
                                        $scope.selected.sort.styling = 'fa-caret-up';
                                    }
                                    $scope.selected.sort.prop = prop;
                                    $scope.list_values.sort_by(prop);
                                    if ($scope.selected.sort.desc) {
                                        $scope.list_values.reverse();
                                    }
                                }
                            }
                        };
                        $scope.invalid_item = {
                            unique: {},
                            custom: {}
                        };
                        $scope.basic_validators = {
                            _check_list: function check_list_errors(msg, list, field, item, item_scope, val) {
                                var index;
                                if (msg) {
                                    if (!list[field] || list[field].indexOf(item[field]) === -1) {
                                        list[field] = list[field] || [];
                                        list[field].push(item_scope.$eval($scope.item_id_field));
                                    }
                                } else {
                                    if (list[field] && list[field].length) {
                                        index = list[field].indexOf(item_scope.$eval($scope.item_id_field));
                                        if (index !== -1) {
                                            list[field].splice(index, 1);
                                            $scope.options.validate_list.ay_validate.run();
                                        }
                                    }
                                }
                            },
                            is_required: function is_required(item, list, col_def, option, item_scope, val) {
                                var msg;
                                switch (col_def.type) {
                                case $scope.input_types.string:
                                    if (val) {
                                        val = val.trim();
                                    }
                                    if (!val) {
                                        msg = col_def.label + ' is required';
                                    }
                                    break;
                                }
                                return msg;
                            },
                            is_unique: function is_unique(item, list, col_def, option, item_scope, val) {
                                var msg, id = item_scope.$eval($scope.item_id_field), values = $scope.options.values.slice(), lookupIndex = $scope.options.values._index_of_by_prop($scope.options.map.value_id_field, id), foundIndex = -1, temp_item = values.splice(lookupIndex, 1)[0], val_getter = $parse(col_def.field);
                                for (var i = 0, ii = values.length; i < ii; i++) {
                                    if (lookupIndex !== i && val_getter({ item: values[i] }) === val) {
                                        foundIndex = i;
                                        break;
                                    }
                                }
                                if (foundIndex !== -1) {
                                    msg = col_def.label + ' must be unique';
                                }
                                return msg;
                            },
                            is_one_enabled: function is_one_enabled(item, list, col_def, option, item_scope, val) {
                                var i, msg, is_valid = false, getter = $parse(col_def.field);
                                if (val) {
                                    is_valid = true;
                                } else {
                                    for (i = 0; i < list.length; i++) {
                                        if (getter({ item: list[i] })) {
                                            is_valid = true;
                                            break;
                                        }
                                    }
                                }
                                if (!is_valid) {
                                    msg = 'At least one item must be ' + col_def.label;
                                }
                                return msg;
                            },
                            is_email: function (item, list, col_def, option, item_scope, val) {
                                var msg;
                                if (!/\S+@\S+\.\S+/.test(val)) {
                                    msg = col_def.label + ' is invalid';
                                }
                                return msg;
                            }
                        };
                        function change_pos(ogArr, itemEl) {
                            var updateIndex, adjacentIndex, adjacentItemData, displayOrder = angular.element(itemEl.parentElement).children(), ogIndex = ogArr._index_of_by_prop($scope.options.map.value_id_field, itemEl[0].dataset.entityId), itemData = ogArr.splice(ogIndex, 1)[0];
                            for (var i = 0, ii = displayOrder.length; i < ii; i++) {
                                if (displayOrder[i].dataset.entityId === itemEl[0].dataset.entityId) {
                                    updateIndex = i;
                                    if (i) {
                                        adjacentIndex = ogArr._index_of_by_prop($scope.options.map.value_id_field, displayOrder[i - 1].dataset.entityId);
                                        adjacentItemData = ogArr[adjacentIndex];
                                        updateIndex = adjacentIndex + 1;
                                    }
                                    ogArr.splice(updateIndex, 0, itemData);
                                    break;
                                }
                            }
                            if ($scope.options.on_sort) {
                                $scope.options.on_sort(itemData, updateIndex, ogIndex, adjacentItemData);
                            }
                            return ogArr;
                        }
                        $scope.filter_list = function filter_list(filter) {
                            $scope.selected.filter = filter;
                            $scope.list_values = $scope.options.values.filter(filter.fn);
                            if ($scope.options.onFilterChange) {
                                $scope.options.onFilterChange(filter);
                            }
                        };
                        $scope.remove = function remove_item(index) {
                            var getter, val, r_index, removed_item = $scope.list_values.splice(index, 1)[0];
                            if ($scope.selected.filter.id) {
                                getter = $parse($scope.options.map.value_id_field);
                                val = getter(removed_item);
                                r_index = $scope.options.values._index_of_by_prop($scope.options.map.value_id_field, val);
                                $scope.options.values.splice(r_index, 1);
                            }
                        };
                        $scope.boolean_group_change = function boolean_group_change(item, col) {
                            var i, temp, val, id_getter = $parse($scope.options.map.value_id_field), id_setter = id_getter.assign, val_getter = $parse(col.field), val_setter = val_getter.assign;
                            for (i = 0; i < $scope.options.values.length; i++) {
                                temp = $scope.options.values[i];
                                val = val_getter({ item: temp });
                                if (val && id_getter(temp) !== id_getter(item)) {
                                    val_setter({ item: temp }, false);
                                    break;
                                }
                            }
                            val_setter({ item: item }, true);
                            if (col.hasOwnProperty('onChange')) {
                                col.onChange(item, col);
                            }
                        };
                        $scope.assignChange = function (item, col, modelVal) {
                            var valGetter = $parse(col.field), valSetter = valGetter.assign;
                            if (col.type !== srvcMappingFields.input_types.boolean_group) {
                                valSetter({ item: item }, modelVal);
                            }
                        };
                        function getRowDefs(rowIndex) {
                            var id, validtorContext, inputEl, rowDefs = [];
                            for (var i = 0, ii = $scope.options.col_defs.length; i < ii; i++) {
                                var id = $scope.options.field + '-list-item-item.' + $scope.options.col_defs[i].field.split('.')[1] + '-' + rowIndex, inputEl = angular.element('[data-input-id="' + id + '"]');
                                if (inputEl[0]) {
                                    validtorContext = $scope.options.validate_list.ay_validate.get_validator(inputEl);
                                    if (validtorContext) {
                                        rowDefs.push(validtorContext);
                                    }
                                }
                            }
                            return rowDefs;
                        }
                        $scope.validate_list_item = function validate_list_item(input, attrs, ngController, tipElm, childScope, modVal, viewVal) {
                            var i, valid_msg, item_scope = this, item = item_scope.$eval('item'), col_def = item_scope.$eval('col');
                            if (ngController.$dirty || item.is_new) {
                                $scope.assignChange(item, col_def, ngController.$viewValue);
                                if (col_def.is_required) {
                                    valid_msg = $scope.basic_validators.is_required(item, $scope.options.values, col_def, $scope.options, item_scope, modVal);
                                }
                                if (col_def.is_email) {
                                    valid_msg = $scope.basic_validators.is_email(item, $scope.options.values, col_def, $scope.options, item_scope, modVal);
                                }
                                if (!valid_msg && col_def.is_unique) {
                                    valid_msg = $scope.basic_validators.is_unique(item, $scope.options.values, col_def, $scope.options, item_scope, modVal);
                                }
                                if (!valid_msg && col_def.is_one_enabled) {
                                    valid_msg = $scope.basic_validators.is_one_enabled(item, $scope.options.values, col_def, $scope.options, item_scope, modVal);
                                }
                                if (!valid_msg && col_def.validators) {
                                    for (i = 0; i < col_def.validators.length; i++) {
                                        valid_msg = col_def.validators[i](item, $scope.options.values, col_def, $scope.options, item_scope);
                                        if (valid_msg) {
                                            $scope.invalid_item.custom[col_def.field] = true;
                                            break;
                                        }
                                    }
                                    if (!valid_msg && $scope.invalid_item.custom[col_def.field]) {
                                        $scope.invalid_item.custom[col_def.field] = false;
                                        $scope.options.validate_list.ay_validate.run();
                                    }
                                }
                                item.$isValid = !valid_msg;
                                if (item.$isValid && col_def.hasOwnProperty('onUpdate')) {
                                    var rowIndex = input[0].id.split('.')[1].split('-')[1];
                                    col_def.onUpdate(item, col_def, item_scope, getRowDefs(rowIndex));
                                }
                            }
                            return valid_msg;
                        };
                        $scope.sendChange = function (item, col) {
                            if (col.hasOwnProperty('onChange') && (!item.hasOwnProperty('$isValid') || item.$isValid)) {
                                col.onChange(item, col);
                            }
                        };
                        $scope.set_boolean_group_item = function set_boolean_group_item(col) {
                            var index = $scope.options.values._index_of_by_prop(col.field.replace('item.', ''), true);
                            return $scope.options.values[index];
                        };
                        $scope.colorStyle = function (clr) {
                            var style = {};
                            if (clr) {
                                style = $utls.color(clr).paintStyle();
                            }
                            return style;
                        };
                        function find_primary_input() {
                            var id, new_el;
                            id = $scope.id_name + '-list-item-' + $scope.primary_col.field + '-' + ($scope.options.values.length - 1);
                            function focus_on_input() {
                                $timeout(function () {
                                    new_el = angular.element('input[data-input-id="' + id + '"]');
                                    if (!new_el.length) {
                                        focus_on_input();
                                    } else {
                                        new_el.focus();
                                    }
                                }, 350);
                            }
                            focus_on_input();
                        }
                        $scope._clearState = function () {
                            $scope.selected.context = '';
                        };
                        $scope._updater = function (results) {
                            if (!results.error) {
                                var index = $scope.options.values._index_of_by_prop('id', results.data.id);
                                if (index !== -1) {
                                    if (!results.is_deleted) {
                                        $scope.options.values.splice(index, 1, results.data);
                                    } else {
                                        $scope.options.values.splice(index, 1);
                                    }
                                    $scope.filter_list($scope.selected.filter);
                                }
                            }
                        };
                        $scope.toggleContext = function (ev, item) {
                            if ($scope.options.hasContext) {
                                var isValue = ev.target.classList.contains('item-col-value'), isInput = ev.target.classList.contains('form-input-field'), isAction = ev.target.classList.contains('list-action') || ev.target.classList.contains('tool-action') || ev.target.classList.contains('action-icon');
                                if (!isValue && !isInput && !isAction) {
                                    if ($ayTimelineContext.selected.scope && $ayTimelineContext.selected.scope.dropmenu) {
                                        $ayTimelineContext.selected.scope.dropmenu.close();
                                    }
                                    var closePromise = $ayTimelineContext.toggleContext(item, { update: $scope._updater });
                                    $scope.selected.context = $ayTimelineContext.selected.contextId;
                                    if (closePromise) {
                                        closePromise.then($scope._clearState);
                                    }
                                }
                            }
                        };
                        $scope.$on('$destroy', function (event) {
                            if ($scope.options.hasContext) {
                                $ayTimelineContext.close();
                            }
                        });
                        $scope.$watch('options', function (new_val, old_val) {
                            var i, col;
                            if (new_val && new_val.hasOwnProperty('map')) {
                                $scope.options.is_addable = $scope.options.hasOwnProperty('is_addable') ? $scope.options.is_addable : true;
                                $scope.item_id_field = 'item.' + $scope.options.map.value_id_field;
                                $scope.id_name = $scope.options.map.value_field;
                                for (i = 0; i < $scope.options.col_defs.length; i++) {
                                    col = $scope.options.col_defs[i];
                                    col.field = 'item.' + col.field;
                                    if (col.hasOwnProperty('field_label')) {
                                        col.field_label = 'item.' + col.field_label;
                                    }
                                    if (col.hasOwnProperty('link_field')) {
                                        col.link_field = 'item.' + col.link_field;
                                    }
                                    if (col.hasOwnProperty('is_primary') && col.is_primary) {
                                        $scope.primary_col = col;
                                    }
                                }
                                $scope.options.update = function ext_update() {
                                    if ($scope.selected.search) {
                                        $scope.changes.search($scope.selected.search);
                                    } else if ($scope.selected.filter && $scope.selected.filter.id) {
                                        $scope.filter_list($scope.selected.filter);
                                    } else {
                                        $scope.list_values = $scope.options.values;
                                    }
                                };
                                $scope.options.validate_list = {
                                    run: function validate() {
                                        var validate_defer = $q.defer();
                                        if (!$scope.options.validate_list.hasOwnProperty('ay_validate')) {
                                            $timeout(function () {
                                                validate_defer.resolve(false);
                                            }, 1);
                                        } else {
                                            $scope.options.validate_list.ay_validate.run().then(function (results) {
                                                validate_defer.resolve(results.indexOf(true) !== -1);
                                            });
                                        }
                                        return validate_defer.promise;
                                    }
                                };
                                if ($scope.options.is_sortable) {
                                    $scope.list_item_change = function list_item_change(event, item) {
                                        if (event === 'sortupdate') {
                                            $scope.options.values = change_pos($scope.options.values, item);
                                            $scope.options.update();
                                        }
                                    };
                                }
                                if ($scope.options.use_basic_filters) {
                                    $scope.options.filters = $scope.options.filters ? $scope.basic_filters.concat($scope.options.filters) : $scope.basic_filters;
                                }
                                if ($scope.options.filters && $scope.options.filters.length) {
                                    $scope.filter_list($scope.options.filters[0]);
                                } else {
                                    $scope.list_values = $scope.options.values;
                                }
                                if ($scope.options.is_addable) {
                                    $scope.add_item = function add_item() {
                                        if ($scope.options.filters && $scope.options.filters.length) {
                                            $scope.options.add_item($scope.selected.filter);
                                            $scope.filter_list($scope.selected.filter);
                                        } else {
                                            $scope.options.add_item();
                                        }
                                        if ($scope.primary_col) {
                                            find_primary_input();
                                        }
                                    };
                                }
                            }
                        });
                    }
                };
            }
        ]
    };
});