define('modules/form-inputs/directives/entity-filter-readonly', [], function () {
    return {
        component: 'directive',
        name: 'ayFormEntityFilterReadonly',
        fn: [
            'srvcMappingFields',
            '$utls',
            '$ME',
            function (srvcMappingFields, $utls, $ME) {
                return {
                    restrict: 'A',
                    templateUrl: 'form-inputs/views/entity-filter-readonly.html',
                    scope: {
                        item: '=ayFormEntityFilterReadonly',
                        fieldMap: '=fieldMap'
                    },
                    controller: function () {
                    },
                    controllerAs: 'filter',
                    bindToController: true,
                    link: function (scope, elem, attrs) {
                        var index = scope.filter.fieldMap._index_of_by_prop('field', scope.filter.item.map.field);
                        scope.filter.$last = scope.$parent.$last;
                        scope.filter.item.map = scope.filter.fieldMap[index];
                        scope.filter.input_types = srvcMappingFields.input_types;
                        index = scope.filter.item.map.operators._index_of_by_prop('op', scope.filter.item.filter.op);
                        scope.filter.item.op = scope.filter.item.map.operators[index];
                        scope.filter.labels = {
                            field: scope.filter.item.map.label,
                            operator: scope.filter.item.op.label
                        };
                        if (scope.filter.item.op.hasOwnProperty('valueInput')) {
                            switch (scope.filter.item.op.valueInput) {
                            case scope.filter.input_types.date:
                                var format = 'YYYY-MM-DD';
                                scope.filter.labels.value = $utls.moment(scope.filter.item.filter.value, $ME.workspace.timezone).format(format);
                                break;
                            }
                        } else {
                            if (scope.filter.item.op.hasOwnProperty('values')) {
                                index = scope.filter.item.op.values._index_of_by_prop('value', scope.filter.item.value.value);
                                scope.filter.item.value = scope.filter.item.op.values[index];
                            } else if (scope.filter.item.map.hasOwnProperty('values')) {
                                index = scope.filter.item.map.values._index_of_by_prop(scope.filter.item.map.value_id_field, scope.filter.item.value[scope.filter.item.map.value_id_field]);
                                scope.filter.item.value = scope.filter.item.map.values[index];
                            }
                            if (scope.filter.item.map.values && scope.filter.item.value.hasOwnProperty('label')) {
                                scope.filter.labels.value = scope.filter.item.value.label;
                                scope.filter.enabled = scope.filter.item.value.enabled;
                            } else {
                                scope.filter.labels.value = scope.filter.item.value;
                                scope.filter.enabled = true;
                            }
                        }
                    }
                };
            }
        ]
    };
});