'use strict';
define('modules/helpers/constants/log-levels', [], function () {
    return {
        component: 'constant',
        name: 'ay_log_levels',
        fn: {
            error: 0,
            warn: 1,
            info: 2,
            debug: 3
        }
    };
});