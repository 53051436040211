define('modules/rest/services/error-detection', [], function () {
    'use strict';
    return {
        component: 'service',
        name: 'errorDetection',
        fn: [function Error_Detection_Service() {
                function Error_Detection() {
                    this.http_errors = {
                        '-1': {
                            category: 'client',
                            type: 'cancelled',
                            loggable: false,
                            showDetails: false,
                            message: 'The request was cancelled.'
                        },
                        400: {
                            category: 'client',
                            type: 'bad-request',
                            loggable: true,
                            showDetails: true,
                            message: 'The server could not complete the request because it is malformed or incorrect.'
                        },
                        401: {
                            category: 'client',
                            type: 'unauthorized',
                            loggable: false,
                            showDetails: false,
                            message: 'The server could not verify that you are authorized to access this resource.'
                        },
                        402: {
                            category: 'client',
                            type: 'payment-required',
                            loggable: true,
                            showDetails: false
                        },
                        403: {
                            category: 'client',
                            type: 'forbidden',
                            loggable: true,
                            showDetails: false,
                            message: 'Access was denied to this resource.'
                        },
                        404: {
                            category: 'client',
                            type: 'not-found',
                            loggable: false,
                            showDetails: false,
                            message: 'The resource was not found.'
                        },
                        405: {
                            category: 'client',
                            type: 'unsupported-method',
                            loggable: true,
                            showDetails: false
                        },
                        406: {
                            category: 'client',
                            type: 'unacceptable',
                            loggable: true,
                            showDetails: false
                        },
                        407: {
                            category: 'client',
                            type: 'proxy-authentication',
                            loggable: true,
                            showDetails: false
                        },
                        408: {
                            category: 'client',
                            type: 'timeout',
                            loggable: true,
                            showDetails: false,
                            message: 'The server has waited too long for the request to be sent by the client.'
                        },
                        409: {
                            category: 'client',
                            type: 'conflict',
                            loggable: true,
                            showDetails: false,
                            message: 'There was a conflict while trying to complete your request.'
                        },
                        410: {
                            category: 'client',
                            type: 'gone',
                            loggable: false,
                            showDetails: false,
                            message: 'The resource is no longer available.'
                        },
                        411: {
                            category: 'client',
                            type: 'content-length',
                            loggable: true,
                            showDetails: false
                        },
                        412: {
                            category: 'client',
                            type: 'precondition',
                            loggable: false,
                            showDetails: false,
                            message: 'The resource that you modified is out of date.'
                        },
                        413: {
                            category: 'client',
                            type: 'entity-size',
                            loggable: true,
                            showDetails: false
                        },
                        414: {
                            category: 'client',
                            type: 'uri-size',
                            loggable: true,
                            showDetails: false
                        },
                        415: {
                            category: 'client',
                            type: 'unsupported-media',
                            loggable: true,
                            showDetails: false
                        },
                        416: {
                            category: 'client',
                            type: 'range',
                            loggable: true,
                            showDetails: false
                        },
                        417: {
                            category: 'client',
                            type: 'expectation',
                            loggable: true,
                            showDetails: false
                        },
                        419: {
                            category: 'client',
                            type: 'authentication-timeout',
                            loggable: true,
                            showDetails: false
                        },
                        420: {
                            category: 'client',
                            type: 'request-spam',
                            loggable: true,
                            showDetails: false
                        },
                        421: {
                            category: 'client',
                            type: 'misdirected-request',
                            loggable: true,
                            showDetails: false
                        },
                        422: {
                            category: 'client',
                            type: 'unprocessable-entity',
                            loggable: true,
                            showDetails: true,
                            message: 'The server could not process the information contained in the request.'
                        },
                        423: {
                            category: 'client',
                            type: 'locked',
                            loggable: true,
                            showDetails: false
                        },
                        424: {
                            category: 'client',
                            type: 'dependency-failed',
                            loggable: true,
                            showDetails: false
                        },
                        426: {
                            category: 'client',
                            type: 'upgrade-required',
                            loggable: true,
                            showDetails: false
                        },
                        428: {
                            category: 'client',
                            type: 'precondition-required',
                            loggable: true,
                            showDetails: false
                        },
                        429: {
                            category: 'client',
                            type: 'request-spam',
                            loggable: true,
                            showDetails: false
                        },
                        431: {
                            category: 'client',
                            type: 'request-to-large',
                            loggable: true,
                            showDetails: false
                        },
                        440: {
                            category: 'client',
                            type: 'session-expired',
                            loggable: true,
                            showDetails: false
                        },
                        444: {
                            category: 'client',
                            type: 'no-response',
                            loggable: true,
                            showDetails: false
                        },
                        449: {
                            category: 'client',
                            type: 'retry-with',
                            loggable: true,
                            showDetails: false
                        },
                        450: {
                            category: 'client',
                            type: 'parental',
                            loggable: true,
                            showDetails: false
                        },
                        451: {
                            category: 'client',
                            type: 'legal',
                            loggable: true,
                            showDetails: false
                        },
                        494: {
                            category: 'client',
                            type: 'request-to-large',
                            loggable: true,
                            showDetails: false
                        },
                        495: {
                            category: 'client',
                            type: 'cert-error',
                            loggable: true,
                            showDetails: false
                        },
                        496: {
                            category: 'client',
                            type: 'cert-missing',
                            loggable: true,
                            showDetails: false
                        },
                        497: {
                            category: 'client',
                            type: 'http-to-https',
                            loggable: true,
                            showDetails: false
                        },
                        498: {
                            category: 'client',
                            type: 'token-expired',
                            loggable: true,
                            showDetails: false
                        },
                        499: {
                            category: 'client',
                            type: 'token-required',
                            loggable: true,
                            showDetails: false
                        },
                        500: {
                            category: 'server',
                            type: 'internal',
                            loggable: true,
                            showDetails: true,
                            message: 'The server could not complete your request due to an internal error.'
                        },
                        502: {
                            category: 'server',
                            type: 'gateway',
                            loggable: true,
                            showDetails: false,
                            message: 'The server received an invalid response from the upstream server.'
                        },
                        503: {
                            category: 'server',
                            type: 'unavailable',
                            loggable: true,
                            showDetails: false,
                            message: 'The server is currently unavailable. Please try again at a later time.'
                        },
                        504: {
                            category: 'server',
                            type: 'timeout',
                            loggable: true,
                            showDetails: false,
                            message: 'The server has timed out while trying to complete your request.'
                        },
                        505: {
                            category: 'server',
                            type: 'unsupported-version',
                            loggable: true,
                            showDetails: false
                        },
                        506: {
                            category: 'server',
                            type: 'cicular-reference',
                            loggable: true,
                            showDetails: false
                        },
                        507: {
                            category: 'server',
                            type: 'storage',
                            loggable: true,
                            showDetails: false
                        },
                        508: {
                            category: 'server',
                            type: 'infinite-loop',
                            loggable: true,
                            showDetails: false
                        },
                        509: {
                            category: 'server',
                            type: 'bandwidth-limit',
                            loggable: true,
                            showDetails: false
                        },
                        510: {
                            category: 'server',
                            type: 'extensions-missing',
                            loggable: true,
                            showDetails: false
                        },
                        511: {
                            category: 'server',
                            type: 'network-authentication',
                            loggable: true,
                            showDetails: false
                        },
                        520: {
                            category: 'server',
                            type: 'unknown',
                            loggable: true,
                            showDetails: false
                        },
                        598: {
                            category: 'server',
                            type: 'timeout-read',
                            loggable: true,
                            showDetails: false
                        },
                        599: {
                            category: 'server',
                            type: 'timeout-connection',
                            loggable: true,
                            showDetails: false
                        }
                    };
                    this.custom_errors = {
                        0: {
                            category: 'server',
                            type: 'unreachable',
                            loggable: false,
                            showDetails: false,
                            message: 'The server is currently unreachable. Please try again at a later time.'
                        },
                        1000: {
                            category: 'client',
                            type: 'missing-arguments',
                            loggable: true,
                            showDetails: false
                        },
                        1001: {
                            category: 'client',
                            type: 'missing-url',
                            loggable: true,
                            showDetails: false
                        },
                        1002: {
                            category: 'client',
                            type: 'entity-not-found',
                            loggable: true,
                            showDetails: false
                        },
                        1003: {
                            category: 'client',
                            type: 'token-creation',
                            loggable: true,
                            showDetails: false
                        },
                        1004: {
                            category: 'client',
                            type: 'bad-filter',
                            loggable: true,
                            showDetails: false,
                            message: 'Invalid filter.'
                        },
                        1005: {
                            category: 'client',
                            type: 'invalid-arguments',
                            loggable: true,
                            showDetails: false
                        },
                        1006: {
                            category: 'server',
                            type: 'missing-data',
                            loggable: true,
                            showDetails: false
                        },
                        1007: {
                            category: 'client',
                            type: 'no-connection',
                            loggable: false,
                            showDetails: false,
                            message: 'No connection.'
                        },
                        1008: {
                            category: 'client',
                            type: 'permissions',
                            loggable: true,
                            showDetails: false,
                            message: 'You do not have the appropriate permissions to complete this request.'
                        },
                        1009: {
                            category: 'client',
                            type: 'missing-etag',
                            loggable: true,
                            showDetails: false
                        },
                        1010: {
                            category: 'client',
                            type: 'schema-validation',
                            loggable: true,
                            showDetails: false
                        },
                        1011: {
                            category: 'server',
                            type: 'no-data',
                            loggable: true,
                            showDetails: false
                        },
                        1012: {
                            category: 'service',
                            type: 'account-suspension',
                            loggable: false,
                            showDetails: false
                        },
                        2000: {
                            category: 'client',
                            type: 'algolia',
                            loggable: true,
                            showDetails: false
                        }
                    };
                    this.server_custom_errors = {
                        'lilikoi.exceptions.UnfilterableFieldError': {
                            category: 'client',
                            type: 'unfilterable',
                            loggable: false,
                            showDetails: false,
                            message: 'Invalid filter.'
                        }
                    };
                    var self = this;
                    return function detect_error(error_code, meta) {
                        var error = self.http_errors.hasOwnProperty(error_code) ? self.http_errors[error_code] : self.custom_errors.hasOwnProperty(error_code) ? self.custom_errors[error_code] : self.server_custom_errors[error_code] ? self.server_custom_errors[error_code] : undefined;
                        if (error) {
                            var i, meta_keys = meta ? Object.keys(meta) : [];
                            error.status = error_code;
                            error.defaultMessage = 'An unexpected error occurred.';
                            for (i = 0; i < meta_keys.length; i++) {
                                error[meta_keys[i]] = meta[meta_keys[i]];
                            }
                        }
                        return error;
                    };
                }
                return new Error_Detection();
            }]
    };
});