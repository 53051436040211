define('modules/form-inputs/directives/pills', [], function () {
    return {
        component: 'directive',
        name: 'ayFormPills',
        fn: [function () {
                return {
                    restrict: 'A',
                    template: '<div class="pills nav is-hoz is-vertical is-dead"><label class="radio-wrapper white" ng-disabled="isDisabled" ng-repeat="option in optionList track by $index"><input class="radio" type="radio" name="{{radioGroupName}}" ng-model="selected.id" ng-value="option.id" ng-change="optionOnChange(option)" ng-disabled="isDisabled"/><i ng-if="!option.label && option.icon" class="label is-dark {{option.icon}} icon" tooltip data-tip-label="{{option.description}}" data-tip-loc="bottom"></i><span ng-if="!option.icon && option.label" class="label text is-dark" style="line-height:1.4em;">{{option.label}}</span><span ng-if="option.icon && option.label" class="label is-dark"><i class="{{option.icon}} mar is-tiny right"></i><span>{{option.label}}</span><span ng-if="option.hasOwnProperty(\'count\')" class="mar is-tiny left">{{option.count}}</span></span></label></div>',
                    scope: {
                        optionList: '=ayFormPills',
                        optionSelected: '=selected',
                        optionOnChange: '=onChange',
                        isDisabled: '=?'
                    },
                    link: function ($scope, elem, attrs) {
                        $scope.radioGroupName = $scope.$id + 'PillGroup';
                        $scope.selected = { id: null };
                        $scope.$watch('optionSelected.id', function (newVal, oldVal) {
                            if (newVal !== oldVal || angular.isDefined(newVal) && !$scope.selected.id) {
                                $scope.selected = { id: angular.copy(newVal) };
                            }
                        });
                    }
                };
            }]
    };
});