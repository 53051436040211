define('modules/enviroment/directives/loader', [], function () {
    return {
        component: 'directive',
        name: 'ayLoader',
        fn: [function () {
                return {
                    restrict: 'E',
                    scope: { show: '=isLoading' },
                    template: '<div class="loader center-content" ng-class="{\'hide\': !show}"><div><i class="fa-spinner fa-spin text-xxl"></i><div class="mar is-larger top">Loading</div></div></div>',
                    replace: true,
                    link: function ($scope, elem, attrs) {
                    }
                };
            }]
    };
});