define('modules/form-inputs/directives/editor', [], function () {
    return {
        component: 'directive',
        name: 'ayFormEditor',
        fn: [
            'global_utilities',
            '$sce',
            '$timeout',
            '$ME',
            '$ayEnviromentCore',
            '$q',
            '$log',
            function (global_utilities, $sce, $timeout, $ME, $ayEnviromentCore, $q, $log) {
                var placeholderMenus, customPropSet = false, DEFAULT_PLUGINS = 'importcss paste link lists textcolor', placeholderItems = {
                        basic: {
                            account: [
                                {
                                    value: '{{account.assignee_name}}',
                                    text: 'Assignee'
                                },
                                {
                                    value: '{{account.created}}',
                                    text: 'Created'
                                },
                                {
                                    value: '{{account.crm_owner_email}}',
                                    text: 'CRM owner email'
                                },
                                {
                                    value: '{{account.crm_owner_full_name}}',
                                    text: 'CRM owner full name'
                                },
                                {
                                    value: '{{account.currency_code}}',
                                    text: 'Currency code'
                                },
                                {
                                    value: '{{account.current_balance}}',
                                    text: 'Current balance'
                                },
                                {
                                    value: '{{account.customer_since}}',
                                    text: 'Customer since'
                                },
                                {
                                    value: '{{account.description}}',
                                    text: 'Description'
                                },
                                {
                                    value: '{{account.health}}',
                                    text: 'Health'
                                },
                                {
                                    value: '{{account.id}}',
                                    text: 'ID'
                                },
                                {
                                    value: '{{account.insights}}',
                                    text: 'Insights'
                                },
                                {
                                    value: '{{account.last_active}}',
                                    text: 'Last active'
                                },
                                {
                                    value: '{{account.last_engaged}}',
                                    text: 'Last engaged'
                                },
                                {
                                    value: '{{account.stage}}',
                                    text: 'Lifecycle stage'
                                },
                                {
                                    value: '{{account.value}}',
                                    text: $ME.workspace.account_value_metric.toUpperCase()
                                },
                                {
                                    value: '{{account.name}}',
                                    text: 'Name'
                                },
                                {
                                    value: '{{account.next_renewal}}',
                                    text: 'Next renewal'
                                },
                                {
                                    value: '{{account.potential_value}}',
                                    text: 'Potential ' + $ME.workspace.account_value_metric.toUpperCase()
                                },
                                {
                                    value: '{{account.priority}}',
                                    text: 'Priority'
                                },
                                {
                                    value: '{{account.risk}}',
                                    text: 'Risk'
                                },
                                {
                                    value: '{{account.region}}',
                                    text: 'Region'
                                },
                                {
                                    value: '{{account.customer_status}}',
                                    text: 'Status'
                                },
                                {
                                    value: '{{account.active_subscription_name}}',
                                    text: 'Subscription'
                                },
                                {
                                    value: '{{account.tags}}',
                                    text: 'Tags'
                                },
                                {
                                    value: '{{account.tier}}',
                                    text: 'Tier'
                                },
                                {
                                    value: '{{account.type}}',
                                    text: 'Type'
                                },
                                {
                                    value: '{{account.last_invoice_amount}}',
                                    text: 'Last Invoice Amount'
                                },
                                {
                                    value: '{{account.last_invoice_date}}',
                                    text: 'Last Invoice Date'
                                },
                                {
                                    value: '{{account.last_invoice_due_date}}',
                                    text: 'Last Invoice Due Date'
                                },
                                {
                                    value: '{{account.last_invoice_number}}',
                                    text: 'Last Invoice Number'
                                },
                                {
                                    value: '{{account.last_payment_amount}}',
                                    text: 'Last Payment Amount'
                                },
                                {
                                    value: '{{account.last_payment_date}}',
                                    text: 'Last Payment Date'
                                },
                                {
                                    value: '{{account.last_payment_invoice_number}}',
                                    text: 'Last Payment Invoice Number'
                                },
                                {
                                    value: '{{account.last_payment_number}}',
                                    text: 'Last Payment Number'
                                }
                            ],
                            recipient: [
                                {
                                    value: '{{recipient.email}}',
                                    text: 'Email'
                                },
                                {
                                    value: '{{recipient.first_name}}',
                                    text: 'First name'
                                },
                                {
                                    value: '{{recipient.full_name}}',
                                    text: 'Full name'
                                },
                                {
                                    value: '{{recipient.id}}',
                                    text: 'ID'
                                },
                                {
                                    value: '{{recipient.last_name}}',
                                    text: 'Last name'
                                },
                                {
                                    value: '{{recipient.title}}',
                                    text: 'Title'
                                }
                            ]
                        },
                        signature: {
                            sender: [
                                {
                                    value: '{{sender.email}}',
                                    text: 'Email'
                                },
                                {
                                    value: '{{sender.first_name}}',
                                    text: 'First name'
                                },
                                {
                                    value: '{{sender.full_name}}',
                                    text: 'Full name'
                                },
                                {
                                    value: '{{sender.last_name}}',
                                    text: 'Last name'
                                },
                                {
                                    value: '{{sender.linkedin_url}}',
                                    text: 'Linkedin URL'
                                },
                                {
                                    value: '{{sender.phone1}}',
                                    text: 'Phone 1'
                                },
                                {
                                    value: '{{sender.phone2}}',
                                    text: 'Phone 2'
                                },
                                {
                                    value: '{{sender.title}}',
                                    text: 'Title'
                                },
                                {
                                    value: '{{sender.twitter_username}}',
                                    text: 'Twitter username'
                                },
                                {
                                    value: '{{sender.calendar_url}}',
                                    text: 'Calendar URL'
                                },
                                {
                                    value: '{{sender.other_url}}',
                                    text: 'Other URL'
                                }
                            ]
                        },
                        adv: {
                            recipient: [
                                {
                                    value: '{{recipient.created}}',
                                    text: 'Created'
                                },
                                {
                                    value: '{{recipient.department}}',
                                    text: 'Department'
                                },
                                {
                                    value: '{{recipient.last_active}}',
                                    text: 'Last active'
                                },
                                {
                                    value: '{{recipient.last_engaged}}',
                                    text: 'Last engaged'
                                },
                                {
                                    value: '{{recipient.role}}',
                                    text: 'Role'
                                },
                                {
                                    value: '{{recipient.salutation}}',
                                    text: 'Salutation'
                                },
                                {
                                    value: '{{recipient.tags}}',
                                    text: 'Tags'
                                },
                                {
                                    value: '{{recipient.title}}',
                                    text: 'Title'
                                }
                            ],
                            sender: [{
                                    value: '{% include \'signature\' %}',
                                    text: 'Signature'
                                }]
                        }
                    }, menus = {
                        basic: {
                            edit: {
                                title: 'Edit',
                                items: 'undo redo | cut copy paste pastetext | selectall'
                            },
                            insert: {
                                title: 'Insert',
                                items: 'link media | template hr'
                            },
                            format: {
                                title: 'Format',
                                items: 'bold italic underline strikethrough superscript subscript | formats | removeformat'
                            },
                            table: {
                                title: 'Table',
                                items: 'inserttable tableprops deletetable | cell row column'
                            }
                        }
                    };
                placeholderItems.adv.sender = placeholderItems.adv.sender.concat(angular.copy(placeholderItems.signature.sender));
                placeholderItems.adv.sender.sort_by('text');
                menus.placeholderBasic = angular.copy(menus.basic);
                menus.placeholderBasic.placeholderBasic = {
                    title: 'Placeholder',
                    items: 'placeholderBasicAccount placeholderBasicRecipient'
                };
                menus.placeholderAdv = angular.copy(menus.basic);
                menus.placeholderAdv.placeholderAdv = {
                    title: 'Placeholder',
                    items: 'placeholderAdvAccount placeholderAdvRecipient placeholderAdvSender'
                };
                menus.placeholderSignature = angular.copy(menus.basic);
                menus.placeholderSignature.placeholderSignature = {
                    title: 'Placeholder',
                    items: 'placeholderSignatureSender'
                };
                function setPlaceholderMenus() {
                    placeholderMenus = {
                        basic: {
                            id: 'placeholderBasic',
                            registered: false,
                            menu: [
                                {
                                    id: 'placeholderBasicAccount',
                                    text: 'Account',
                                    menu: angular.copy(placeholderItems.basic.account)
                                },
                                {
                                    id: 'placeholderBasicRecipient',
                                    text: 'Recipient',
                                    menu: angular.copy(placeholderItems.basic.recipient)
                                }
                            ]
                        },
                        adv: {
                            id: 'placeholderAdv',
                            registered: false,
                            menu: [
                                {
                                    id: 'placeholderAdvAccount',
                                    text: 'Account',
                                    menu: angular.copy(placeholderItems.basic.account)
                                },
                                {
                                    id: 'placeholderAdvRecipient',
                                    text: 'Recipient',
                                    menu: [].concat(placeholderItems.basic.recipient, placeholderItems.adv.recipient).sort_by('text')
                                },
                                {
                                    id: 'placeholderAdvSender',
                                    text: 'Sender',
                                    menu: angular.copy(placeholderItems.adv.sender)
                                }
                            ]
                        },
                        signature: {
                            id: 'placeholderSignature',
                            registered: false,
                            menu: [{
                                    id: 'placeholderSignatureSender',
                                    text: 'Sender',
                                    menu: angular.copy(placeholderItems.signature.sender)
                                }]
                        }
                    };
                }
                function addCustomProps() {
                    function addField(list, prefix, item) {
                        if (item.enabled && !item.hidden) {
                            switch (item.type) {
                            case 'datetime':
                            case 'string':
                            case 'number':
                            case 'boolean':
                            case 'picklist':
                                list.push({
                                    value: '{{' + prefix + '[\'' + item.id + '\']}}',
                                    text: item.display_name
                                });
                                break;
                            }
                        }
                    }
                    if ($ME.is_logged_in) {
                        customPropSet = true;
                        if ($ME.workspace.account_custom_properties.length) {
                            $ME.workspace.account_custom_properties.map(function (item) {
                                addField(placeholderItems.basic.account, 'account.custom_properties', item);
                            });
                            placeholderItems.basic.account.sort_by('text');
                        }
                        if ($ME.workspace.participant_custom_properties.length) {
                            $ME.workspace.participant_custom_properties.map(function (item) {
                                addField(placeholderItems.adv.recipient, 'recipient.custom_properties', item);
                            });
                            placeholderItems.adv.recipient.sort_by('text');
                        }
                    }
                    setPlaceholderMenus();
                }
                return {
                    restrict: 'A',
                    priority: 500,
                    scope: {
                        source: '=?ayFormEditor',
                        options: '=formOptions'
                    },
                    template: '<textarea ui-tinymce="editor.options" ng-model="editor.html" ng-change="editor.update()"></textarea><input id="{{validatorInputId}}" class="tooltip-input" ng-model="editor.raw_text" ay-validate="options.validator" data-run-alias="{{validatorRunAlias}}" data-container-id="{{validatorContainerId}}" tabindex="-1"/>',
                    link: function ($scope, elem, attrs) {
                        function setStyling() {
                            if (!$scope.options.readonly) {
                                $scope.editor.options.min_height = $scope.options.min_height;
                                $scope.editor.options.height = $scope.options.height;
                            } else {
                                $scope.editor.options.autoresize_overflow_padding = 0;
                                $scope.editor.options.autoresize_bottom_margin = 0;
                                elem.addClass('editor-readonly');
                            }
                        }
                        if (!customPropSet) {
                            addCustomProps();
                        } else {
                            setPlaceholderMenus();
                        }
                        $scope.isMobile = $ayEnviromentCore.isMobile() ? true : false;
                        $scope.source = !$scope.source || !$scope.source.length ? '' : $scope.source;
                        $scope.options = $scope.options || {};
                        $scope.validatorInputId = attrs.validatorInputId;
                        $scope.validatorRunAlias = attrs.validatorRunAlias;
                        $scope.validatorContainerId = attrs.containerId;
                        $scope.toolbar_options = {
                            basic: {
                                plugins: DEFAULT_PLUGINS,
                                toolbar: 'bold italic underline forecolor removeformat h1 | alignleft aligncenter alignright alignjustify | bullist numlist indent outdent | link',
                                mobileToolbar: 'bold italic | alignleft aligncenter alignright | bullist indent outdent',
                                menu: menus.basic
                            },
                            default: {
                                plugins: DEFAULT_PLUGINS + ' image',
                                toolbar: 'bold italic underline removeformat | forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist indent outdent | link image',
                                menu: menus.basic
                            },
                            email: {
                                plugins: DEFAULT_PLUGINS + ' image table visualblocks',
                                toolbar: 'bold italic underline removeformat | forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist indent outdent | link image',
                                placeholderMenu: placeholderMenus.basic,
                                menu: menus.placeholderBasic
                            },
                            auto_email: {
                                plugins: DEFAULT_PLUGINS + ' image table visualblocks',
                                toolbar: 'bold italic underline removeformat | forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist indent outdent | link image',
                                placeholderMenu: placeholderMenus.adv,
                                menu: menus.placeholderAdv
                            },
                            signature: {
                                plugins: DEFAULT_PLUGINS + ' image table visualblocks',
                                toolbar: 'bold italic underline removeformat | forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist indent outdent | link image',
                                placeholderMenu: placeholderMenus.signature,
                                menu: menus.placeholderSignature
                            },
                            readonly: { plugins: DEFAULT_PLUGINS + ' image table visualblocks autoresize' }
                        };
                        $scope.options.toolbar = $scope.options.toolbar || 'default';
                        $scope.options.min_height = $scope.options.min_height || 300;
                        $scope.formatters = {
                            placeholder: {
                                svgLabel: function (text, value) {
                                    var svgImage = global_utilities.textToSvg(text);
                                    svgImage.attr('data-field-id', value);
                                    svgImage.css('position', 'relative');
                                    svgImage.css('top', '4px');
                                    return svgImage[0].outerHTML;
                                },
                                svgCode: function (text) {
                                    var checkRegex = /(data-field-id=")({(#|%|{)\W*(\s*?\w\W*?\s?)+(#|%|})})|({(#|%|{)\W*(\s*?\w\W*?\s?)+(#|%|})})/g, includeRegex = /(<\w* .*?)?({(#|%|{)\W*(\s*?\w\W*?\s?)+(#|%|})})/g, res = includeRegex.exec(text);
                                    function toCodeLabel(val) {
                                        var svgImage, placeholderRegex = /(data-field-id=")({(#|%|{)\W*(\s*?\w\W*?\s?)+(#|%|})})/g;
                                        if (!placeholderRegex.test(val)) {
                                            svgImage = global_utilities.textToSvg('{ Ninja }');
                                            svgImage.attr('data-code', val);
                                            svgImage.css('position', 'relative');
                                            svgImage.css('top', '4px');
                                        }
                                        return svgImage ? svgImage[0].outerHTML : val;
                                    }
                                    return !res || !res[1] ? text.replace(checkRegex, toCodeLabel) : text;
                                },
                                toPlaceholder: function (text) {
                                    var placeholderRegex = /<img (.)*? data-field-id="([\w\W])+?" (.)*?>/g, codeRegex = /<img (.)*? data-code="([\w\W])+?" (.)*?>/g, menu = $scope.toolbar_options[$scope.options.toolbar] && $scope.toolbar_options[$scope.options.toolbar].placeholderMenu ? $scope.toolbar_options[$scope.options.toolbar].placeholderMenu.menu : null;
                                    if (!text || !menu || !placeholderRegex.test(text) && !codeRegex.test(text)) {
                                        return text;
                                    } else {
                                        var formattedText = text.replace(placeholderRegex, function (val) {
                                            var fieldPlaceholderRegex = /(?:data-field-id=")({{\w+\.\w+(\[\'\w+\'\])?}})(?:")/g, fieldIncludeRegex = /(?:data-field-id=")({%\s\w+\s\S\w+\S\s%})(?:")/g, fieldPlaceholder = fieldPlaceholderRegex.exec(val);
                                            if (!fieldPlaceholder) {
                                                fieldPlaceholder = fieldIncludeRegex.exec(val);
                                            }
                                            if (fieldPlaceholder) {
                                                return fieldPlaceholder[1];
                                            } else {
                                                var error = {
                                                        text: val,
                                                        error: { message: 'Unable to convert image to placeholder' }
                                                    }, src = /src="data:image\/svg\+xml;base64,(.+?)"/g.exec(val);
                                                if (src) {
                                                    error.src = {
                                                        raw: src[1],
                                                        svg: window.atob(src[1])
                                                    };
                                                    var placeholderValue, label = /<text (.*)>(.*)<\/text>/g.exec(error.src.svg);
                                                    if (!label) {
                                                        error.placeholderLabel = false;
                                                    } else {
                                                        error.placeholderLabel = label[2];
                                                    }
                                                    if (!menu) {
                                                        error.hasMenu = false;
                                                    } else {
                                                        var menuList = /(\w)+/g.exec(label[2]);
                                                        if (!menuList) {
                                                            error.hasList = false;
                                                        } else {
                                                            var menuGroup = menuList.splice(0, 1)[0], menuIndex = menu._index_of_by_prop('text', menuGroup);
                                                            if (menuIndex === -1) {
                                                                error.menuFound = false;
                                                            } else {
                                                                var itemIndex = menu[menuIndex].menu._index_of_by_prop('text', menuList.input.replace(menuGroup + ' ', ''));
                                                                if (itemIndex === -1) {
                                                                    error.placeholderFound = false;
                                                                } else {
                                                                    placeholderValue = menu[menuIndex].menu[itemIndex].value;
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                                if (placeholderValue) {
                                                    return placeholderValue;
                                                } else {
                                                    $log.error(error);
                                                    return val;
                                                }
                                            }
                                        });
                                        return formattedText.replace(codeRegex, function (val) {
                                            var fieldCodeRegex = /(?:data-code=")({(#|%|{)\W*(\s*?\w\W*?\s?)+(#|%|})})(?:")/g, fieldCode = fieldCodeRegex.exec(val);
                                            if (!fieldCode) {
                                                return val;
                                            } else {
                                                return fieldCode[1];
                                            }
                                        }).replace(/&quot;/g, '"');
                                    }
                                },
                                toSvg: function (text) {
                                    var formattedText, placeholderRegex = /(<\w* .*?)?({{\W*(\s*?\w\W*?\s?)+}})/g, includeRegex = /(<\w* .*?)?({(#|%|{)\W*(\s*?\w\W*?\s?)+(#|%|})})/g, menu = $scope.toolbar_options[$scope.options.toolbar] ? $scope.toolbar_options[$scope.options.toolbar].placeholderMenu.menu : null, isPlaceholder = placeholderRegex.test(text);
                                    if (!isPlaceholder) {
                                        isPlaceholder = includeRegex.test(text);
                                    }
                                    function replaceInput(match, g1, g2) {
                                        if (g1) {
                                            return match;
                                        } else {
                                            var placeholder, index;
                                            for (var i = 0, ii = menu.length; i < ii; i++) {
                                                index = menu[i].menu._index_of_by_prop('value', g2);
                                                if (index !== -1) {
                                                    placeholder = menu[i].menu[index];
                                                    text = menu[i].text + ' ' + placeholder.text;
                                                    break;
                                                }
                                            }
                                            var t = placeholder ? $scope.formatters.placeholder.svgLabel(text, placeholder.value) : match;
                                            return t;
                                        }
                                    }
                                    if (!menu || !isPlaceholder) {
                                        formattedText = angular.copy(text);
                                    } else {
                                        formattedText = this.toPlaceholder(text).replace(placeholderRegex, replaceInput);
                                    }
                                    var t = formattedText.replace(includeRegex, replaceInput);
                                    t = this.svgCode(t);
                                    return t;
                                }
                            }
                        };
                        $scope.editor = {
                            html: $scope.toolbar_options[$scope.options.toolbar].placeholderMenu && $scope.toolbar_options[$scope.options.toolbar].placeholderMenu.menu ? $scope.formatters.placeholder.toSvg($scope.source) : $scope.source,
                            raw_text: '',
                            options: {
                                baseURL: '/static/build/tiny-mce',
                                elementpath: false,
                                menu: false,
                                statusbar: false,
                                trusted: true,
                                entity_encoding: 'raw',
                                content_css: ['/static/build/utilities.css'],
                                readonly: $scope.options.readonly,
                                toolbar: $scope.options.readonly ? false : $scope.toolbar_options[$scope.options.toolbar][$scope.isMobile ? 'mobileToolbar' : 'toolbar'],
                                menubar: $scope.options.readonly || $scope.isMobile ? false : $scope.toolbar_options[$scope.options.toolbar].hasOwnProperty('menubar') ? $scope.toolbar_options[$scope.options.toolbar].menubar : $scope.toolbar_options[$scope.options.toolbar].menu ? true : false,
                                plugins: $scope.options.readonly ? $scope.toolbar_options.readonly.plugins : $scope.toolbar_options[$scope.options.toolbar].plugins,
                                init_instance_callback: function () {
                                    if ($scope.options.on_load) {
                                        $timeout(function () {
                                            $scope.options.on_load();
                                        }, 200);
                                    }
                                    $scope.editor.raw_text = $scope.instance.getContent({ format: 'html' });
                                }
                            },
                            update: function () {
                                $scope.source = $scope.formatters.placeholder.toPlaceholder($scope.instance.getContent({ format: 'html' })).replace(/\xA0/g, String.fromCharCode(32));
                                if ($scope.options.validator && angular.isFunction($scope.options.validator)) {
                                    var validator = $scope.ay_validate;
                                    $scope.editor.raw_text = $scope.instance.getContent({ format: 'html' });
                                    if (!validator) {
                                        validator = $scope.$eval($scope.validatorRunAlias);
                                        if (!validator) {
                                            validator = $scope.$parent.$eval($scope.validatorRunAlias);
                                        }
                                        validator = validator.ay_validate;
                                    }
                                    validator.run();
                                }
                                if ($scope.options.onChange && angular.isFunction($scope.options.onChange)) {
                                    $scope.options.onChange($scope.source);
                                }
                                setStyling();
                            }
                        };
                        if ($scope.options.readonly) {
                            $scope.editor.options.autoresize_overflow_padding = 0;
                            $scope.editor.options.autoresize_bottom_margin = 0;
                            $scope.editor.options.body_class = 'editor-std-format';
                            elem.addClass('editor-readonly');
                        } else {
                            $scope.editor.options.min_height = $scope.options.min_height;
                            if ($scope.toolbar_options[$scope.options.toolbar].menu) {
                                $scope.editor.options.menu = $scope.toolbar_options[$scope.options.toolbar].menu;
                            }
                            if ($scope.toolbar_options[$scope.options.toolbar].placeholderMenu && !$scope.toolbar_options[$scope.options.toolbar].placeholderMenu.registered) {
                                $scope.editor.options.setup = function (editor) {
                                    editor.addButton($scope.toolbar_options[$scope.options.toolbar].placeholderMenu.id, {
                                        text: 'Placeholder',
                                        type: 'menubutton',
                                        menu: $scope.toolbar_options[$scope.options.toolbar].placeholderMenu.menu.map(function (item) {
                                            editor.addMenuItem(item.id, {
                                                text: item.text,
                                                context: $scope.toolbar_options[$scope.options.toolbar].placeholderMenu.id,
                                                menu: item.menu,
                                                onselect: function placeholderSelect(e) {
                                                    if (e.control.settings.value) {
                                                        var text = item.text + ' ' + e.control.settings.text;
                                                        editor.insertContent($scope.formatters.placeholder.svgLabel(text, e.control.settings.value));
                                                        $timeout(function () {
                                                            editor.focus();
                                                        }, 1);
                                                    }
                                                }
                                            });
                                            return item.id;
                                        })
                                    });
                                    $scope.toolbar_options[$scope.options.toolbar].placeholderMenu.registered = true;
                                };
                            }
                        }
                        if ($scope.options.classes) {
                            $scope.editor.options.body_class += ' ' + $scope.options.classes;
                        }
                        $scope.options.validation = $scope.ay_validate;
                        setStyling();
                        $scope.options.update = function () {
                            var updateDefer;
                            function resolveUpdate() {
                                updateDefer.resolve();
                            }
                            function updateHtml() {
                                $scope.editor.html = $scope.toolbar_options[$scope.options.toolbar].placeholderMenu && $scope.toolbar_options[$scope.options.toolbar].placeholderMenu.menu ? $scope.formatters.placeholder.toSvg($scope.source) : $scope.source;
                                setStyling();
                                $timeout(resolveUpdate, 200);
                            }
                            if (!$scope.$$destroyed) {
                                updateDefer = $q.defer();
                                $timeout(updateHtml);
                                return updateDefer.promise;
                            }
                        };
                        $scope.options.reprocess = function () {
                            if (!$scope.$$destroyed) {
                                $scope.source = $scope.formatters.placeholder.toPlaceholder($scope.instance.getContent({ format: 'html' }));
                                return $scope.source;
                            }
                        };
                        $scope.options.focus = function () {
                            function setFocus() {
                                if (!$scope.instance || !$scope.instance.focus) {
                                    $timeout(setFocus, 100);
                                } else {
                                    $scope.instance.focus();
                                }
                            }
                            if (!$scope.$$destroyed) {
                                setFocus();
                            }
                        };
                        $scope.options.$isDestroyed = $scope.$$destroyed;
                        $scope.$on('$destroy', function () {
                            delete $scope.options;
                        });
                    }
                };
            }
        ]
    };
});