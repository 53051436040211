define('modules/rest/services/requester-old', [], function () {
    'use strict';
    return {
        component: 'service',
        name: 'srvcRequester',
        fn: [
            '$rootScope',
            '$http',
            '$location',
            '$log',
            '$q',
            '$timeout',
            '$ay_session',
            'errorDetection',
            '$ay_connection',
            function requester_service($rootScope, $http, $location, $log, $q, $timeout, $ay_session, errorDetection, $ay_connection) {
                var requester = {
                    rest: '/rest/v1/',
                    retry_cnt: 0,
                    retryTimer: null,
                    cursorDefault: 'cursor=100',
                    media_type: {
                        account: 'application/vnd.amity.hub.account+json',
                        activity: 'application/vnd.amity.hub.activity+json',
                        notification: 'application/vnd.amity.hub.notification+json',
                        engagement: 'application/vnd.amity.hub.engagement+json',
                        tenancy: 'application/vnd.amity.hub.tenancy+json',
                        ticket: 'application/vnd.amity.hub.ticket+json',
                        topic: 'application/vnd.amity.hub.topic+json',
                        user: 'application/vnd.amity.hub.user+json',
                        work_item: 'application/vnd.amity.hub.work_item+json',
                        workspace: 'application/vnd.amity.hub.ws+json',
                        participant: 'application/vnd.amity.hub.participant+json',
                        preference: 'application/vnd.amity.hub.preference+json'
                    }
                };
                function format_url(type, call, params) {
                    if (!type) {
                        return null;
                    }
                    var urlString = null;
                    switch (type) {
                    case 'location':
                        if (!call || !params) {
                            return null;
                        }
                        var id = null;
                        if (params.id) {
                            id = params.id;
                        } else if (params.data && params.data.id) {
                            id = params.data.id;
                        }
                        if (!id) {
                            return null;
                        }
                        switch (call) {
                        case 'info':
                            urlString = requester.rest + 'info';
                            break;
                        case 'user':
                            urlString = requester.rest + 'users/' + id;
                            break;
                        case 'workspace':
                            urlString = requester.rest + 'workspaces/' + id;
                            break;
                        case 'account':
                            urlString = requester.rest + 'accounts/' + id;
                            break;
                        case 'tenancy':
                            urlString = requester.rest + 'tenancies/' + id;
                            break;
                        case 'work_item':
                            urlString = requester.rest + 'work_items/' + id;
                            break;
                        case 'notifications':
                            urlString = requester.rest + 'notifications/' + id;
                            break;
                        case 'notification-rules':
                            urlString = requester.rest + 'workspaces/' + id + '/notification_rules';
                            break;
                        case 'engagement':
                            urlString = requester.rest + 'engagements/' + id;
                            break;
                        case 'apps':
                            urlString = requester.rest + 'workspaces/' + id + '/apps';
                            break;
                        case 'integration':
                            if (!params.data.type || !params.data.system) {
                                return null;
                            }
                            urlString = requester.rest + 'workspaces/' + id + '/integrations/' + params.data.type + '/' + params.data.system;
                            break;
                        case 'role':
                            urlString = requester.rest + 'roles/' + id;
                            break;
                        case 'topic':
                            urlString = requester.rest + 'topics/' + id;
                            break;
                        case 'ticket':
                            urlString = requester.rest + 'tickets/' + id;
                            break;
                        case 'activity':
                            urlString = requester.rest + 'activities/' + id;
                            break;
                        case 'participant':
                            urlString = requester.rest + 'participants/' + id;
                            break;
                        case 'stats':
                            urlString = requester.rest + 'stats/';
                            break;
                        case 'play':
                            urlString = requester.rest + 'plays/' + id;
                            break;
                        case 'subscriptions':
                            urlString = requester.rest + 'subscriptions/' + id;
                            break;
                        case 'measurements':
                            urlString = requester.rest + 'workspaces/' + params.workspace_id + '/reports/' + params.report;
                            break;
                        case 'preference':
                            if (!params.workspace_id) {
                                return null;
                            }
                            urlString = requester.rest + 'workspaces/' + params.workspace_id + '/preferences/' + id;
                            break;
                        case 'insight':
                            urlString = requester.rest + 'workspaces/' + id + '/insight';
                            break;
                        case 'notification_params':
                            urlString = requester.rest + 'workspaces/' + id + '/notification_params';
                            break;
                        case 'view':
                            urlString = requester.rest + 'views/' + id;
                            break;
                        case 'marketing-participant':
                            urlString = requester.rest + 'participants/' + id + '/marketing';
                            break;
                        case 'marketing-workspace':
                            urlString = requester.rest + 'workspaces/' + id + '/marketing_systems';
                            break;
                        case 'search':
                            urlString = requester.rest + 'workspaces/' + id + '/search';
                            break;
                        default:
                            return null;
                        }
                        break;
                    case 'schema':
                        if (!call) {
                            return null;
                        }
                        switch (call) {
                        case 'user':
                            urlString = requester.rest + 'schema/users';
                            break;
                        case 'workspace':
                            urlString = requester.rest + 'schema/workspaces';
                            break;
                        case 'account':
                            urlString = requester.rest + 'schema/accounts';
                            break;
                        case 'insight':
                            urlString = requester.rest + 'schema/workspaces/insight';
                            break;
                        default:
                            urlString = requester.rest + 'schema/' + call;
                            break;
                        }
                        break;
                    default:
                        return null;
                    }
                    return urlString;
                }
                function set_cursor(cursor) {
                    var return_val;
                    if (cursor) {
                        var sections = cursor.split(' ');
                        if (sections.length === 3) {
                            return_val = sections[0] + '=' + sections[1] + ' \'' + sections[2].replace('"', '').replace('"', '') + '\'';
                        } else {
                            return_val = false;
                        }
                    }
                    return return_val;
                }
                requester._send_request = function send_request(payload, return_obj) {
                    var request_defer = $q.defer();
                    function set_results(response) {
                        var reachable = true;
                        return_obj.data = response.data;
                        return_obj.status = response.status;
                        return_obj.headers = response.headers;
                        return_obj.error = errorDetection(response.status);
                        return_obj.etag = response.headers('Etag');
                        return_obj.cursor = set_cursor(response.headers('Content-Range'));
                        if (return_obj.data === null && !return_obj.error) {
                            return_obj.error = errorDetection('-1');
                        }
                        if (!return_obj.error) {
                            if (return_obj.etag) {
                                if (return_obj.data && typeof return_obj.data !== 'string') {
                                    return_obj.data.etag = return_obj.etag.trim();
                                } else {
                                    return_obj.data = { etag: return_obj.etag.trim() };
                                }
                            }
                            request_defer.resolve(return_obj);
                        } else {
                            if (return_obj.data && angular.isObject(return_obj.data)) {
                                return_obj.data.error = errorDetection(return_obj.data.exception);
                            }
                            if (return_obj.error.status === 0) {
                                reachable = false;
                            }
                            switch (status) {
                            case 401:
                                var trace = [
                                    '401 detected',
                                    payload.headers
                                ];
                                $ay_session.renew().then(function (renew_results) {
                                    trace.push('session renewed');
                                    if (!renew_results.error) {
                                        trace.push(angular.copy(payload.headers));
                                        payload.headers.Authorization = renew_results.data.Authorization;
                                        trace.push(angular.copy(payload.headers));
                                        trace.push('call resent');
                                        requester._send_request(payload, return_obj).then(function (retry_results) {
                                            trace.push('call completed: ' + retry_results.status);
                                            retry_results.trace = trace;
                                            request_defer.resolve(retry_results);
                                        });
                                    } else {
                                        trace.push(renew_results);
                                        $log.error('session failed to renew after a 401', trace);
                                        request_defer.resolve(renew_results);
                                    }
                                });
                                break;
                            default:
                                request_defer.resolve(return_obj);
                                break;
                            }
                        }
                        $ay_connection.update_status(reachable);
                    }
                    function call_rest() {
                        $http(payload).then(set_results, set_results);
                    }
                    function call_fail() {
                        return_obj.data = {};
                        return_obj.error = errorDetection(1007);
                        request_defer.resolve(return_obj);
                    }
                    if ($ay_connection.online) {
                        call_rest();
                    } else {
                        $ay_connection.wait_for_reconnect().then(call_rest, call_fail);
                    }
                    return request_defer.promise;
                };
                requester.config = function config_request(_callback, reset) {
                    var result = {}, payload = {
                            url: requester.rest + 'config',
                            method: 'GET'
                        }, request_promise = requester._send_request(payload, result);
                    request_promise.then(function request_resolved(resolved) {
                        if (reset) {
                            resolved.data.type = 'reset';
                        }
                        _callback(resolved);
                    });
                };
                requester.find_file = function find_file_request(url, _callback) {
                    var result = {}, payload = {
                            url: url,
                            method: 'GET'
                        }, request_promise = requester._send_request(payload, result);
                    request_promise.then(function request_resolved(resolved) {
                        _callback(resolved);
                    });
                };
                requester.get = function get_request(call, _callback, params) {
                    var url, restHeader = $ay_session.GET();
                    if (params && (params.id || params.id || params.data && (params.data.id || params.data.id))) {
                        url = format_url('location', call, params);
                    } else if (call === 'schema' && params.call) {
                        url = format_url('schema', params.call);
                    } else {
                        url = requester.rest + call;
                        if (!params || !params.range) {
                            restHeader.Range = requester.cursorDefault;
                        }
                        if (params && params.range) {
                            restHeader.Range = 'cursor=' + params.range;
                        }
                        if (params && params.cursor) {
                            restHeader.Range = params.cursor;
                        }
                    }
                    if (!url) {
                        result = {
                            call: call,
                            _callback: _callback,
                            params: params,
                            error: errorDetection(1001)
                        };
                        $log.error(result);
                        _callback(result);
                    } else {
                        if (params && params.options) {
                            url = url + '?' + requester.encodeURI(JSON.stringify(params.options));
                        }
                        var result = {
                                type: params ? params.type : '',
                                _callback: params ? params._callback : null,
                                call_info: {
                                    call: call,
                                    params: params
                                }
                            }, payload = {
                                url: url,
                                headers: restHeader,
                                method: 'GET'
                            }, request_promise = requester._send_request(payload, result);
                        request_promise.then(function request_resolved(resolved) {
                            _callback(resolved);
                        });
                    }
                };
                requester.post = function post_request(call, _callback, params) {
                    if (!call || !params || !params.data) {
                        $log.log('Unable to send post request: Missing arguments', {
                            call: call,
                            params: params,
                            _callback: _callback
                        });
                        params = params || {};
                        params.error = errorDetection(1000);
                        _callback(params);
                    } else {
                        var url, restHeader = $ay_session.GET(), data = params.data, result = {
                                type: params.type || '',
                                _callback: params._callback,
                                call_info: {
                                    call: call,
                                    params: params
                                }
                            };
                        switch (call) {
                        case 'email':
                            if (!params.type) {
                                $log.log('Unable to send email: Missing type', {
                                    call: call,
                                    params: params,
                                    _callback: _callback
                                });
                            } else {
                                data = {
                                    action: null,
                                    template_moniker: null
                                };
                                url = format_url('location', 'user', params.data);
                                switch (params.type) {
                                case 'welcome':
                                    data.action = 'SendPasswordResetEmail';
                                    data.template_moniker = 'welcome';
                                    break;
                                case 'reset':
                                    data.action = 'SendPasswordResetEmail';
                                    data.template_moniker = 'password_reset';
                                    break;
                                }
                            }
                            break;
                        default:
                            if (params.format_url) {
                                url = format_url('location', call, params);
                            } else {
                                url = requester.rest + call;
                            }
                            break;
                        }
                        if (!url || !data) {
                            $log.log('Unable to send post request: Missing URL', {
                                url: url,
                                data: data
                            });
                            result.error = errorDetection(1001);
                            _callback(result);
                        } else {
                            if (data.hasOwnProperty('items')) {
                                restHeader['Content-Type'] = 'application/vnd.amity.collection+json';
                            }
                            var payload = {
                                    url: url,
                                    headers: restHeader,
                                    method: 'POST',
                                    data: data
                                }, request_promise = requester._send_request(payload, result);
                            request_promise.then(function request_resolved(resolved) {
                                _callback(resolved);
                            });
                        }
                    }
                };
                requester.put = function put_request(call, _callback, params) {
                    var url, restHeader = $ay_session.GET(), result = {
                            type: params ? params.type : '',
                            _callback: params ? params._callback : null,
                            call_info: {
                                call: call,
                                params: params
                            }
                        };
                    if (!params.upsert) {
                        restHeader['If-Match'] = params.etag;
                    }
                    switch (call) {
                    case 'preference':
                        url = format_url('location', 'preference', params);
                        break;
                    default:
                        if (!params.data) {
                            $log.error({
                                call: call,
                                _callback: _callback,
                                params: params,
                                error: errorDetection(1001)
                            });
                        } else {
                            if (params.id) {
                                url = format_url('location', call, { id: params.id });
                            } else {
                                url = format_url('location', call, params.data);
                            }
                        }
                        break;
                    }
                    if (!url) {
                        result = {
                            call: call,
                            _callback: _callback,
                            params: params,
                            error: errorDetection(1001)
                        };
                        $log.error(result);
                        _callback(result);
                    } else {
                        var payload = {
                                url: url,
                                headers: restHeader,
                                method: 'PUT',
                                data: params.data
                            }, request_promise = requester._send_request(payload, result);
                        request_promise.then(function request_resolved(resolved) {
                            _callback(resolved);
                        });
                    }
                };
                requester.delete = function delete_request(call, _callback, params) {
                    if (!call || !params || !params.etag) {
                        params = params || {};
                        params.error = errorDetection(1000);
                        _callback(params);
                    } else {
                        var url = format_url('location', call, params), restHeader = $ay_session.GET(), result = {
                                type: params ? params.type : '',
                                _callback: params ? params._callback : null,
                                call_info: {
                                    call: call,
                                    params: params
                                }
                            };
                        if (!url) {
                            result = {
                                call: call,
                                _callback: _callback,
                                params: params,
                                error: errorDetection(1001)
                            };
                            $log.error(result);
                            _callback(result);
                        } else {
                            var payload = {
                                    url: url,
                                    headers: restHeader,
                                    method: 'DELETE',
                                    data: params.data
                                }, request_promise = requester._send_request(payload, result);
                            request_promise.then(function request_resolved(resolved) {
                                _callback(resolved);
                            });
                        }
                    }
                };
                requester.request_password = function password_request(_callback, username) {
                    var error_data = {
                        message: '',
                        type: '',
                        data: {
                            _callback: _callback,
                            username: username
                        }
                    };
                    if (!_callback || !username) {
                        error_data.message = 'Unable to reset password:: Missing arguments';
                        error_data.type = 'missing-arguments';
                        $log.log(error_data.message, error_data);
                        if (_callback) {
                            _callback(error_data);
                        }
                        return null;
                    }
                    var url = requester.rest + 'users/password_reset', result = {
                            status: null,
                            data: null,
                            headers: null
                        }, data = { username: username };
                    $http({
                        method: 'POST',
                        url: url,
                        data: data
                    }).then(function (data, status, headers) {
                        result.status = status;
                        result.data = data;
                        result.headers = headers;
                        _callback(result);
                    }, function (data, status, headers) {
                        result.status = status;
                        result.data = data;
                        result.headers = headers;
                        _callback(result);
                    });
                };
                requester._b64chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-_';
                requester._cb_utob = function cb_utob_encode(value) {
                    var fromCharCode = String.fromCharCode, cc;
                    if (value.length < 2) {
                        cc = value.charCodeAt(0);
                        return cc < 128 ? value : cc < 2048 ? fromCharCode(192 | cc >>> 6) + fromCharCode(128 | cc & 63) : fromCharCode(224 | cc >>> 12 & 15) + fromCharCode(128 | cc >>> 6 & 63) + fromCharCode(128 | cc & 63);
                    } else {
                        cc = 65536 + (value.charCodeAt(0) - 55296) * 1024 + (value.charCodeAt(1) - 56320);
                        return fromCharCode(240 | cc >>> 18 & 7) + fromCharCode(128 | cc >>> 12 & 63) + fromCharCode(128 | cc >>> 6 & 63) + fromCharCode(128 | cc & 63);
                    }
                };
                requester._cb_encode = function cb_encode(value) {
                    var padlen = [
                            0,
                            2,
                            1
                        ][value.length % 3], ord = value.charCodeAt(0) << 16 | (value.length > 1 ? value.charCodeAt(1) : 0) << 8 | (value.length > 2 ? value.charCodeAt(2) : 0), chars = [
                            requester._b64chars.charAt(ord >>> 18),
                            requester._b64chars.charAt(ord >>> 12 & 63),
                            padlen >= 2 ? '=' : requester._b64chars.charAt(ord >>> 6 & 63),
                            padlen >= 1 ? '=' : requester._b64chars.charAt(ord & 63)
                        ];
                    return chars.join('');
                };
                requester._btoa = function btoa_encode(value) {
                    if (window.btoa) {
                        return window.btoa(value);
                    }
                    return value.replace(/[\s\S]{1,3}/g, requester._cb_encode);
                };
                requester.encodeURI = function uri_encode(value) {
                    return this._btoa(value.replace(/[\uD800-\uDBFF][\uDC00-\uDFFFF]|[^\x00-\x7F]/g, requester._cb_utob)).replace(/=/g, '');
                };
                return requester;
            }
        ]
    };
});