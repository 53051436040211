define('modules/form-inputs/directives/input-login', [], function () {
    return {
        component: 'directive',
        name: 'ayFormInputLogin',
        fn: [function () {
                return {
                    restrict: 'E',
                    templateUrl: 'form-inputs/views/input-login.html',
                    require: '?ngModel',
                    scope: {
                        label: '@',
                        isRequired: '@',
                        validationMsg: '@',
                        helpText: '@',
                        isDisabled: '=?'
                    },
                    controller: function () {
                        this.value = '';
                    },
                    controllerAs: 'formInput',
                    bindToController: true,
                    link: function (scope, elem, attrs, ngModel) {
                        scope.formInput.onChange = function (event) {
                            ngModel.$setViewValue(scope.formInput.value, event);
                        };
                        scope.formInput.onFocus = function () {
                            if (attrs.onFocus) {
                                scope.$parent.$eval(attrs.onFocus);
                            }
                        };
                        ngModel.$render = function () {
                            scope.formInput.value = ngModel.$modelValue;
                        };
                        ngModel.$viewChangeListeners.push(function () {
                            if (attrs.onChange) {
                                scope.$parent.$eval(attrs.onChange);
                            }
                        });
                    }
                };
            }]
    };
});