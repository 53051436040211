define('modules/rest/factories/notes', [], function () {
    'use strict';
    return {
        component: 'service',
        name: 'factoryNotes',
        fn: [
            '$log',
            '$schemaValidator',
            '$ay_requester',
            '$q',
            '$timeout',
            '$ME',
            'errorDetection',
            function factory($log, $schemaValidator, $ay_requester, $q, $timeout, $ME, errorDetection) {
                var LOCATION = 'notes';
                function Model_Note(_data, _schema) {
                    this._schema = _schema;
                    this.location = LOCATION + '/';
                    this.etag = null;
                    this.workspace_id = $ME.workspace.id;
                    this.id = null;
                    this.media_type = 'application/vnd.amity.hub.note+json';
                    this.created_at = null;
                    this.entity_type = null;
                    this.entity_id = null;
                    this.title = null;
                    this.html_body = null;
                    this.updated_at = null;
                    this.created_by_user_id = null;
                    this.updated_by_user_id = null;
                    if (_data) {
                        this.set(_data);
                    }
                }
                Model_Note.prototype.set = function (data) {
                    this.id = data.id;
                    this.location = LOCATION + '/' + data.id;
                    this.media_type = data.media_type || this.media_type;
                    this.etag = data.etag;
                    this.workspace_id = data.workspace_id || $ME.workspace.id;
                    this.created_at = data.created_at;
                    this.entity_type = data.entity_type;
                    this.entity_id = data.entity_id;
                    this.title = data.title;
                    this.html_body = data.html_body;
                    this.updated_at = data.updated_at;
                    this.created_by_user_id = data.created_by_user_id;
                    this.updated_by_user_id = data.updated_by_user_id;
                };
                Model_Note.prototype.remove = function () {
                    var self = this;
                    return $ay_requester.DELETE(self.location, {});
                };
                Model_Note.prototype.getEtag = function () {
                    var self = this, etagDefer = $q.defer();
                    if (self.etag) {
                        $timeout(function () {
                            etagDefer.resolve({ etag: self.etag });
                        }, 1);
                    } else {
                        $ay_requester.GET(self.location).then(function (results) {
                            if (!results.error) {
                                self.etag = results.data.etag;
                                results.etag = self.etag;
                            }
                            etagDefer.resolve(results);
                        });
                    }
                    return etagDefer.promise;
                };
                Model_Note.prototype.save = function () {
                    var self = this, saveDefer = $q.defer(), validation_res = $schemaValidator.validate(angular.copy(self), self._schema);
                    self.getEtag().then(function (results) {
                        if (results.error) {
                            saveDefer.resolve(results);
                        } else {
                            if (validation_res.error) {
                                saveDefer.resolve(validation_res);
                            } else {
                                $ay_requester.PUT(self.location, {
                                    etag: results.etag,
                                    data: validation_res.data
                                }).then(function complete_request(results) {
                                    if (!results.error) {
                                        self.set(results.data);
                                        results.data = self;
                                    }
                                    saveDefer.resolve(results);
                                });
                            }
                        }
                    });
                    return saveDefer.promise;
                };
                function Factory_Notes() {
                }
                Factory_Notes.prototype.location = LOCATION;
                Factory_Notes.prototype.entities = {
                    'application/vnd.amity.hub.account+json': 'account',
                    'application/vnd.amity.hub.play+json': 'play',
                    'application/vnd.amity.hub.participant+json': 'participant'
                };
                Factory_Notes.prototype.new_model = function new_model(data) {
                    var self = this;
                    return new Model_Note(data, self._schema);
                };
                Factory_Notes.prototype.getSchema = function getSchema() {
                    var self = this, schemaDefer = $q.defer();
                    function load_schema(results) {
                        if (!results.error) {
                            self._schema = results.data;
                        }
                        schemaDefer.resolve(results);
                    }
                    $ay_requester.GET('schema/' + self.location).then(load_schema);
                    return schemaDefer.promise;
                };
                Factory_Notes.prototype.create = function create_model(data) {
                    var self = this, create_defer = $q.defer();
                    function post() {
                        var validation_res = $schemaValidator.validate(data, self._schema);
                        if (validation_res.error) {
                            $timeout(function () {
                                create_defer.resolve(validation_res);
                            }, 1);
                        } else {
                            $ay_requester.POST(self.location, { data: validation_res.data }).then(function (results) {
                                if (!results.error) {
                                    results.data = self.new_model(results.data);
                                }
                                create_defer.resolve(results);
                            });
                        }
                    }
                    if (self._schema) {
                        post();
                    } else {
                        self._set_schema().then(post);
                    }
                    return create_defer.promise;
                };
                Factory_Notes.prototype._buildOptions = function buildOptions(params) {
                    var self = this, workspace_filter = {
                            field: '/workspace_id',
                            op: 'EQ',
                            value: $ME.workspace.id
                        }, type_filter = {
                            field: '/entity_type',
                            op: 'EQ',
                            value: ''
                        }, entity_filter = {
                            field: '/entity_id',
                            op: 'EQ',
                            value: ''
                        }, creator_filter = {
                            field: '/created_by_user_id',
                            op: 'EQ',
                            value: ''
                        }, updater_filter = {
                            field: '/updated_by_user_id',
                            op: 'EQ',
                            value: ''
                        }, options = {
                            f: [],
                            s: []
                        };
                    if (params.hasOwnProperty('filters')) {
                        options.f = angular.copy(params.filters);
                    }
                    options.f.push(workspace_filter);
                    if (params.type) {
                        type_filter.value = self.entities[params.type];
                        options.f.push(type_filter);
                    }
                    if (params.entity_id) {
                        entity_filter.value = params.entity_id;
                        options.f.push(entity_filter);
                    }
                    if (params.creator_id) {
                        creator_filter.value = params.creator_id;
                        options.f.push(creator_filter);
                    }
                    if (params.updater_id) {
                        updater_filter.value = params.updater_id;
                        options.f.push(updater_filter);
                    }
                    if (params.terms) {
                        options.f.push({
                            field: '/title',
                            op: 'CONTAINS',
                            value: params.terms
                        });
                    }
                    if (params.hasOwnProperty('sorts')) {
                        options.s = angular.copy(params.sorts);
                    } else {
                        options.s = [{
                                field: '/updated_at',
                                descending: true
                            }];
                    }
                    return options;
                };
                Factory_Notes.prototype.get = function get_model(params) {
                    var getPromise, drainPromise, self = this, cancelled = false, getDefer = $q.defer(), location = LOCATION, models = [];
                    function setModels(results) {
                        if (!cancelled) {
                            if (results.error) {
                                getDefer.resolve(results);
                            } else {
                                if (!results.data.hasOwnProperty('items')) {
                                    results.data = self.new_model(results.data);
                                    getDefer.resolve(results);
                                } else {
                                    models = models.concat(results.data.items.map.apply(results.data.items, [
                                        self.new_model,
                                        self
                                    ]));
                                    results.data = models;
                                    if (!params.drain) {
                                        getDefer.resolve(results);
                                    } else {
                                        if (!results.cursor) {
                                            getDefer.resolve(results);
                                        } else {
                                            params.cursor = results.cursor;
                                            drainPromise = $ay_requester.GET(location, params);
                                            drainPromise.then(setModels);
                                        }
                                    }
                                }
                            }
                        }
                    }
                    self.getSchema().then(function (results) {
                        params = params || {};
                        if (!cancelled) {
                            if (params.id) {
                                location += '/' + params.id;
                            } else {
                                params.options = self._buildOptions(params);
                            }
                            getPromise = $ay_requester.GET(location, params);
                            getPromise.then(setModels);
                        }
                    });
                    getDefer.promise.cancel = function () {
                        cancelled = true;
                        if (getPromise && getPromise.hasOwnProperty('cancel')) {
                            getPromise.cancel();
                        }
                        if (drainPromise && drainPromise.hasOwnProperty('cancel')) {
                            drainPromise.cancel();
                        }
                        getDefer.resolve({
                            cancelled: true,
                            error: errorDetection('-1')
                        });
                    };
                    return getDefer.promise;
                };
                return new Factory_Notes();
            }
        ]
    };
});