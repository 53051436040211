define('modules/authentication/services/me', [], function () {
    'use strict';
    return {
        component: 'service',
        name: '$ME',
        fn: [
            '$log',
            '$q',
            '$timeout',
            '$ay_session',
            '$ay_requester',
            'errorDetection',
            'restFactoryUser',
            'modelPreferenceFactory',
            'modelRoleFactory',
            'restFactoryTenancy',
            'modelWorkspace',
            'modelApps',
            'modelInsight',
            'srvcConfig',
            function ($log, $q, $timeout, $ay_session, $ay_requester, errorDetection, restFactoryUser, modelPreferenceFactory, modelRoleFactory, restFactoryTenancy, modelWorkspace, modelApps, modelInsight, srvcConfig) {
                var self = this;
                self.is_logged_in = false;
                self.is_admin = false;
                self.is_super = false;
                self.tenancy = {};
                self.workspace = {};
                self.app_config = {};
                self.preferences = {};
                self.data = {};
                self.insights = {};
                self.roles = [];
                self.impersonator_id = '';
                self.feature_flags = {};
                self._addUtls = function () {
                    self.insights.get_insight_condition_value = function get_insight_condition_value(insight) {
                        var display_value;
                        switch (insight.type) {
                        case 'risk_level':
                            display_value = self.workspace.get_item('account_risk_levels', 'level', insight.threshold, 'name');
                            break;
                        default:
                            display_value = insight.threshold !== undefined ? insight.threshold : insight.value;
                            break;
                        }
                        return display_value === undefined || display_value === null ? ' ' : display_value;
                    };
                };
                self._getPreference = function () {
                    var getDefer = $q.defer();
                    modelPreferenceFactory.config({
                        workspace_id: self.workspace.id,
                        user_id: self.data.id
                    });
                    modelPreferenceFactory.get().then(function (results) {
                        if (!results.error) {
                            self.preferences = results.data;
                        }
                        getDefer.resolve(results);
                    });
                    return getDefer.promise;
                };
                self._getRoles = function () {
                    var getDefer = $q.defer();
                    modelRoleFactory.GET({ tenancy_id: self.tenancy_id }).then(function (results) {
                        if (!results.error) {
                            var index;
                            self.roles = results.data;
                            self.data.roles = [];
                            for (var i = 0, ii = self.data.role_ids.length; i < ii; i++) {
                                index = self.roles._index_of_by_prop('id', self.data.role_ids[i]);
                                if (index !== -1) {
                                    self.data.roles.push(self.roles[index]);
                                    if (self.roles[index].isAdmin) {
                                        self.is_admin = true;
                                    }
                                    if (self.roles[index].isSuper) {
                                        self.is_super = true;
                                    }
                                }
                            }
                        }
                        getDefer.resolve(results);
                    });
                    return getDefer.promise;
                };
                self._getInsights = function () {
                    var getDefer = $q.defer();
                    modelInsight.get({ id: self.workspace.id }).then(function (results) {
                        if (!results.error) {
                            self.insights = results.data;
                        }
                        getDefer.resolve(results);
                    });
                    return getDefer.promise;
                };
                self._getWorkspace = function () {
                    var getDefer = $q.defer();
                    modelWorkspace.GET({ tenancy_id: self.tenancy.id }).then(function (results) {
                        function setUserConfig(results) {
                            var userPreference = self.preferences.apps.get('user'), appVersion = userPreference.state('version');
                            srvcConfig.set_user({
                                id: self.data.id,
                                username: self.data.username,
                                full_name: self.data.full_name,
                                first_name: self.data.first_name,
                                last_name: self.data.last_name,
                                email: self.data.email,
                                created_at: self.data.created_at,
                                workspace_id: self.workspace.id,
                                workspace_name: self.workspace.name,
                                tenancy_name: self.tenancy.name,
                                impersonator_id: self.impersonator_id,
                                uiVersion: appVersion
                            });
                            self.feature_flags['explorers.writable'] = self.is_admin ? true : false;
                            getDefer.resolve(results);
                        }
                        if (results.error) {
                            getDefer.resolve(results);
                        } else {
                            self.workspace = results.data[0];
                            self.data.workspace_id = self.workspace.id;
                            restFactoryUser.workspace_id = self.workspace.id;
                            $q.all([
                                self._getInsights(),
                                self._getRoles()
                            ]).then(function (getPromises) {
                                var errors = false;
                                for (var i = 0, ii = getPromises.length; i < ii; i++) {
                                    if (getPromises[i].error) {
                                        errors = true;
                                        getDefer.resolve(getPromises[i]);
                                    }
                                }
                                if (!errors) {
                                    self._getPreference().then(setUserConfig);
                                }
                            });
                        }
                    });
                    return getDefer.promise;
                };
                self._getApiUsers = function () {
                    return restFactoryUser.getApis();
                };
                self._getUsers = function () {
                    return restFactoryUser.get({
                        drain: true,
                        refresh: true
                    });
                };
                self._getTenancy = function () {
                    var getDefer = $q.defer();
                    function processPromises(getPromises) {
                        var errors = false;
                        for (var i = 0, ii = getPromises.length; i < ii; i++) {
                            if (getPromises[i].error) {
                                errors = true;
                                getDefer.resolve(getPromises[i]);
                            }
                        }
                        if (!errors) {
                            getDefer.resolve(getPromises);
                        }
                    }
                    restFactoryTenancy.get({ id: self.data.tenancy_id }).then(function (results) {
                        if (results.error) {
                            getDefer.resolve(results);
                        } else {
                            self.tenancy = results.data;
                            restFactoryUser.tenancy_id = self.tenancy.id;
                            $q.all([
                                self._getWorkspace(),
                                self._getUsers(),
                                self._getApiUsers()
                            ]).then(processPromises);
                        }
                    });
                    return getDefer.promise;
                };
                self._getUser = function (infoData) {
                    var getDefer = $q.defer();
                    if (infoData.impersonating_user_location) {
                        self.impersonator_id = infoData.impersonating_user_location.replace('/rest/v1/users/', '');
                    }
                    restFactoryUser.get({ id: infoData.authenticated_user_location.replace('/rest/v1/users/', '') }).then(function (results) {
                        if (results.error) {
                            getDefer.resolve(results);
                        } else {
                            self.data = results.data;
                            self._getTenancy().then(getDefer.resolve);
                        }
                    });
                    return getDefer.promise;
                };
                self._reset = function () {
                    self.is_logged_in = false;
                    self.is_admin = false;
                    self.is_super = false;
                    self.tenancy = {};
                    self.workspace = {};
                    self.app_config = {};
                    self.preferences = {};
                    self.data = {};
                    self.insights = {};
                    self.roles = [];
                    self.impersonator_id = '';
                    self.feature_flags = {};
                };
                self._checkUser = function () {
                    var getDefer = $q.defer();
                    $ay_requester.INFO().then(function (results) {
                        if (results.error) {
                            getDefer.resolve(results);
                        } else {
                            if (!results.data.authenticated_user_location) {
                                results.error = errorDetection(1006);
                                getDefer.resolve(results);
                            } else {
                                self._getUser(results.data).then(function (loginRes) {
                                    self.is_logged_in = !loginRes.error ? true : false;
                                    if (!self.is_logged_in) {
                                        self._reset();
                                    } else {
                                        self._addUtls();
                                        if (self.is_super) {
                                            $log.set_level('debug');
                                        } else if (self.is_admin) {
                                            $log.set_level('info');
                                        } else {
                                            $log.set_level('');
                                        }
                                    }
                                    getDefer.resolve(loginRes);
                                });
                            }
                        }
                    });
                    return getDefer.promise;
                };
                self.login = function (username, password) {
                    var loginDefer = $q.defer();
                    self._reset();
                    $ay_requester.LOGIN(username, password).then(function (results) {
                        if (results.error) {
                            loginDefer.resolve(results);
                        } else {
                            $ay_session.CREATE(results.data).then(function (sessionRes) {
                                self._checkUser().then(loginDefer.resolve);
                            });
                        }
                    });
                    return loginDefer.promise;
                };
                self.autoLogin = function () {
                    var loginDefer = $q.defer();
                    self._reset();
                    $ay_session.CREATE().then(function (results) {
                        if (results.error || !results.data.is_initialized()) {
                            loginDefer.resolve(results);
                        } else {
                            self._checkUser().then(loginDefer.resolve);
                        }
                    });
                    return loginDefer.promise;
                };
                self.is_impersonator = function is_impersonator() {
                    return this.impersonator_id ? true : false;
                };
                self.impersonate = function (user) {
                    var id, self = this, impersonateDefer = $q.defer();
                    if (self.roles._index_of_by_prop('name', 'Super Administrator') == -1) {
                        $timeout(function () {
                            impersonateDefer.resolve({ error: errorDetection(1008) });
                        });
                    } else {
                        $ay_requester.IMPERSONATE(user.username, self.data.id).then(function (results) {
                            if (results.error) {
                                impersonateDefer.resolve(results);
                            } else {
                                $ay_session.CREATE(results.data, true).then(function (sessionRes) {
                                    if (sessionRes.data.is_initialized()) {
                                        self._reset();
                                        self._checkUser().then(impersonateDefer.resolve);
                                    } else {
                                        impersonateDefer.resolve({ error: errorDetection(1008) });
                                    }
                                });
                            }
                        });
                    }
                    return impersonateDefer.promise;
                };
                self.getApps = function (appDefs) {
                    var getDefer = $q.defer();
                    modelApps.GET({ id: self.workspace.id }).then(function (results) {
                        if (results.error) {
                            getDefer.resolve(results);
                        } else {
                            for (var i = 0, ii = appDefs.length; i < ii; i++) {
                                var def = appDefs[i], index = results.data.apps._index_of_by_prop('name', def.name);
                                if (index !== -1) {
                                    results.data.apps[index].load(def);
                                } else if (def.force) {
                                    def.data_type = 'application';
                                    def.section = 'beta';
                                    results.data.apps.push(def);
                                }
                                if (self.is_admin && def.name === 'admin') {
                                    var adminApp = {
                                        section: 'main',
                                        type: def.name
                                    };
                                    results.data.addApp(adminApp, def);
                                }
                            }
                            self.app_config = results.data;
                            getDefer.resolve(results);
                        }
                    });
                    return getDefer.promise;
                };
                self.get_feature_state = function (feature_name) {
                    if (self.feature_flags && self.feature_flags.hasOwnProperty(feature_name)) {
                        return self.feature_flags[feature_name];
                    } else {
                        return undefined;
                    }
                };
                self.logout = function () {
                    var logoutDefer = $q.defer();
                    $ay_session.DELETE().then(function (results) {
                        if (!results.error) {
                            self._reset();
                            srvcConfig.reset();
                        }
                        logoutDefer.resolve(results);
                    });
                    return logoutDefer.promise;
                };
            }
        ]
    };
});