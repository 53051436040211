define('modules/rest/factories/workspace', [], function () {
    'use strict';
    return {
        component: 'service',
        name: 'modelWorkspace',
        fn: [
            'global_utilities',
            '$q',
            '$timeout',
            '$ay_requester',
            'srvcRequester',
            '$schemaValidator',
            '$log',
            function workspace_factory(global_utilities, $q, $timeout, $ay_requester, srvcRequester, $schemaValidator, $log) {
                var LOCATION = 'workspaces', factory = {
                        schema: null,
                        media_type: 'application/vnd.amity.hub.ws+json'
                    };
                function create_work_item_template(data) {
                    var work_item_template = {
                        due_in: null,
                        description: '',
                        title: '',
                        id: '',
                        type_id: null
                    };
                    if (data) {
                        work_item_template.due_in = data.hasOwnProperty('due_in') ? data.due_in : null;
                        work_item_template.description = data.hasOwnProperty('description') ? data.description : '';
                        work_item_template.title = data.hasOwnProperty('title') ? data.title : null;
                        work_item_template.type_id = data.type_id || null;
                    }
                    return work_item_template;
                }
                function create_playbook_stage(data) {
                    var i, playbook_stage = {
                            id: '',
                            name: '',
                            description: '',
                            deadline: null,
                            work_item_templates: [],
                            enabled: true,
                            custom_properties: null
                        };
                    playbook_stage.new_work_item = function new_work_item(data) {
                        var work_item = create_work_item_template(data);
                        work_item.id = 'task-template-' + this.work_item_templates._generate_id('id', 'string', '-');
                        this.work_item_templates.push(work_item);
                        return work_item;
                    };
                    if (data) {
                        playbook_stage.work_item_templates = [];
                        playbook_stage.id = data.hasOwnProperty('id') ? data.id : '';
                        playbook_stage.description = data.hasOwnProperty('description') ? data.description : '';
                        playbook_stage.name = data.hasOwnProperty('name') ? data.name : '';
                        playbook_stage.deadline = data.hasOwnProperty('deadline') ? data.deadline : null;
                        playbook_stage.enabled = data.hasOwnProperty('enabled') ? data.enabled : true;
                        if (data.hasOwnProperty('work_item_templates') && data.work_item_templates.length) {
                            for (i = 0; i < data.work_item_templates.length; i++) {
                                playbook_stage.new_work_item(data.work_item_templates[i]);
                            }
                        }
                        for (i in playbook_stage.custom_properties) {
                            if (playbook_stage.custom_properties[i] === null || playbook_stage.custom_properties[i] === undefined) {
                                delete playbook_stage.custom_properties[i];
                            }
                        }
                    }
                    return playbook_stage;
                }
                function create_playbook(data) {
                    var i, playbook = {
                            id: '',
                            description: '',
                            name: '',
                            stages: [],
                            enabled: true,
                            account_stage_id: null,
                            play_created_set_account_stage: false,
                            play_closed_success_set_account_stage_id: null,
                            play_closed_success_create_play_playbook_id: null,
                            deadline: 30,
                            allow_custom_properties: false,
                            custom_properties: []
                        };
                    playbook.new_stage = function new_stage(data) {
                        if (!data) {
                            data = {};
                        }
                        if (!data.id) {
                            data.id = this.stages._generate_id();
                        }
                        return create_playbook_stage(data);
                    };
                    if (data) {
                        playbook.stages = [];
                        playbook.id = data.hasOwnProperty('id') ? data.id : '';
                        playbook.description = data.hasOwnProperty('description') ? data.description : '';
                        playbook.name = data.hasOwnProperty('name') ? data.name : '';
                        playbook.enabled = data.hasOwnProperty('enabled') ? data.enabled : true;
                        playbook.account_stage_id = data.hasOwnProperty('account_stage_id') ? data.account_stage_id : '';
                        playbook.play_created_set_account_stage = data.hasOwnProperty('play_created_set_account_stage') ? data.play_created_set_account_stage : false;
                        playbook.play_closed_success_set_account_stage_id = data.hasOwnProperty('play_closed_success_set_account_stage_id') ? data.play_closed_success_set_account_stage_id : '';
                        playbook.play_closed_success_create_play_playbook_id = data.hasOwnProperty('play_closed_success_create_play_playbook_id') ? data.play_closed_success_create_play_playbook_id : '';
                        playbook.deadline = data.deadline;
                        playbook.allow_custom_properties = data.allow_custom_properties;
                        playbook.custom_properties = data.custom_properties || [];
                        if (data.hasOwnProperty('stages') && data.stages.length) {
                            for (i = 0; i < data.stages.length; i++) {
                                playbook.stages.push(playbook.new_stage(data.stages[i]));
                            }
                        } else {
                            playbook.stages.push(playbook.new_stage({ name: 'First stage' }));
                        }
                    }
                    return playbook;
                }
                function clean_up_playbooks(item) {
                    function cleanTask(task) {
                        delete task.id;
                        if (task.type_id === undefined || task.type_id === null || isNaN(task.type_id)) {
                            delete task.type_id;
                        }
                        if (task.due_in === undefined || task.due_in === null || isNaN(task.due_in)) {
                            delete task.due_in;
                        }
                        return task;
                    }
                    function cleanStage(stage) {
                        if (stage.deadline === undefined || stage.deadline === null || isNaN(stage.deadline)) {
                            delete stage.deadline;
                        }
                        if (stage.work_item_templates.length) {
                            stage.work_item_templates = stage.work_item_templates.map(cleanTask);
                        }
                        delete stage.custom_properties;
                        delete stage.$isValid;
                        return stage;
                    }
                    if (!item.account_stage_id) {
                        delete item.account_stage_id;
                    }
                    if (!item.play_closed_success_set_account_stage_id) {
                        delete item.play_closed_success_set_account_stage_id;
                    }
                    if (!item.play_closed_success_create_play_playbook_id) {
                        delete item.play_closed_success_create_play_playbook_id;
                    }
                    item.stages = item.stages.map(cleanStage);
                    return item;
                }
                function Workspace_Model(data) {
                    var self = this;
                    this.getLevelEnum = function (member) {
                        switch (member) {
                        case 'highest':
                            return 5;
                        case 'high':
                            return 4;
                        case 'medium':
                            return 3;
                        case 'low':
                            return 2;
                        case 'lowest':
                            return 1;
                        }
                    };
                    this.getLevelEnumColor = function (member) {
                        if (member === 5 || member === 'highest') {
                            return '#7D1506';
                        } else if (member === 4 || member === 'high') {
                            return '#D92525';
                        } else if (member === 3 || member === 'medium') {
                            return '#F29F05';
                        } else if (member === 2 || member === 'low') {
                            return '#88A61B';
                        } else if (member === 1 || member === 'lowest') {
                            return '#0B8B0A';
                        } else {
                            return '';
                        }
                    };
                    this.sortLevelEnum = function (item) {
                        return self.getLevelEnum(item.level);
                    };
                    this.assign_color = function (item) {
                        item.color = self.getLevelEnumColor(item.level);
                        item.displayColor = false;
                        return item;
                    };
                    self.data_type = 'workspace';
                    self.location = LOCATION + '/';
                    self.id = null;
                    self.email_blacklist = null;
                    self.scripts = null;
                    self.work_item_types = null;
                    self.created_integrations = null;
                    self.grants = null;
                    self.timezone = null;
                    self.account_tiers = null;
                    self.work_item_stages = null;
                    self.tenancy_id = null;
                    self.description = null;
                    self.account_value_metric = null;
                    self.name = null;
                    self.created_at = null;
                    self.priority_levels = null;
                    self.account_risk_levels = null;
                    self.default_currency_code = null;
                    self.account_types = null;
                    self.engagement_topics = null;
                    self.etag = null;
                    self.playbooks = [];
                    self.media_type = null;
                    self.account_stages = null;
                    self.work_item_archive_threshold = null;
                    self.account_regions = null;
                    self.account_custom_properties = null;
                    self.account_favourite_properties = null;
                    self.account_favourite_custom_reports = null;
                    self.account_roles = null;
                    self.participant_custom_properties = null;
                    self.participant_favourite_properties = null;
                    self.allow_full_participant_formula_recalc = null;
                    self.play_custom_properties = null;
                    self.allow_full_play_formula_recalc = null;
                    self.tag_colors = null;
                    self.enable_time_tracking = null;
                    self.hours_per_work_day = null;
                    self.identify_account_create_mode = null;
                    self.identify_participant_create_mode = null;
                    self.record_activity_auto_create = null;
                    self.allow_staff_activity_operations = null;
                    self.record_activity_skip_ignored_accounts = null;
                    self.html_email_signature = null;
                    self.allow_ui_versions = null;
                    self.default_ui_version = null;
                    self.display_account_attachments = null;
                    self.display_account_reports = null;
                    self.display_invoices = null;
                    self.display_nps_properties = null;
                    self.display_survey_responses = null;
                    self.next_gen_rules = null;
                    self.new_search_api = null;
                    self.payment_cards = [
                        {
                            value: 'american_express',
                            name: 'American Express'
                        },
                        {
                            value: 'discover',
                            name: 'Discover'
                        },
                        {
                            value: 'mastercard',
                            name: 'MasterCard'
                        },
                        {
                            value: 'visa',
                            name: 'Visa'
                        }
                    ];
                    self.customer_statuses = [
                        {
                            id: 1,
                            value: 'active',
                            name: 'active',
                            label: 'Active',
                            enabled: true,
                            color: self.getLevelEnumColor(2),
                            displayColor: false
                        },
                        {
                            id: 2,
                            value: 'inactive',
                            name: 'inactive',
                            label: 'Inactive',
                            enabled: true,
                            color: self.getLevelEnumColor(3),
                            displayColor: false
                        },
                        {
                            id: 3,
                            value: 'churned',
                            name: 'churned',
                            label: 'Churned',
                            enabled: true,
                            color: self.getLevelEnumColor(4),
                            displayColor: false
                        }
                    ];
                    self.participant_types = [
                        {
                            id: 1,
                            value: 'customer',
                            label: 'Customer',
                            enabled: true
                        },
                        {
                            id: 1,
                            value: 'staff',
                            label: 'Staff',
                            enabled: true
                        },
                        {
                            id: 1,
                            value: 'unknown',
                            label: 'Unknown',
                            enabled: true
                        }
                    ];
                    self.participant_roles = null;
                    self.account_property_groups = [
                        {
                            id: 'payment',
                            label: 'Payment',
                            integration: 'integration.Xero',
                            fields: [
                                '/default_payment_method',
                                '/payment_card_type',
                                '/payment_card_number',
                                '/payment_card_expires_at',
                                '/last_payment_invoice_number',
                                '/last_payment_number',
                                '/last_payment_date',
                                '/last_payment_amount'
                            ]
                        },
                        {
                            id: 'billing',
                            label: 'Billing',
                            integration: 'integration.Xero',
                            fields: [
                                '/current_balance',
                                '/overdue_balance',
                                '/lifetime_revenue',
                                '/last_invoice_number',
                                '/last_invoice_date',
                                '/last_invoice_due_date',
                                '/last_invoice_amount',
                                '/last_invoice_balance'
                            ]
                        }
                    ];
                    this.get_item = function get_item(list_name, id_field, id, return_field, check_disabled) {
                        var return_data, pos;
                        if (this.hasOwnProperty(list_name)) {
                            pos = this[list_name]._index_of_by_prop(id_field, id);
                            if (pos !== -1) {
                                if (return_field === null || return_field === undefined) {
                                    return_data = this[list_name][pos];
                                } else {
                                    return_data = this[list_name][pos][return_field];
                                }
                            }
                        }
                        if (check_disabled && !this[list_name][pos].enabled) {
                            return_data = null;
                        }
                        return return_data;
                    };
                    this.get_playbook = function get_playbook(id) {
                        return this.get_item('playbooks', 'id', id);
                    };
                    this.get_list_items = function get_list_items(list_name, sort_by_field, enabled) {
                        var items = [], i;
                        enabled = enabled !== undefined && enabled !== null ? enabled : true;
                        if (this.hasOwnProperty(list_name) && Array.isArray(this[list_name])) {
                            for (i = 0; i < this[list_name].length; i++) {
                                if (this[list_name][i].enabled === enabled) {
                                    items.push(this[list_name][i]);
                                }
                            }
                            if (items.length && sort_by_field) {
                                items.sort_by(sort_by_field);
                            }
                        }
                        return items;
                    };
                    this.get_valid_items = function get_valid_items(list_name, sort_by_field) {
                        return this.get_list_items(list_name, sort_by_field);
                    };
                    this.get_invalid_items = function get_invalid_items(list_name, sort_by_field) {
                        return this.get_list_items(list_name, sort_by_field, false);
                    };
                    this.new_playbook = function new_playbook(data) {
                        if (!data) {
                            data = {};
                        }
                        if (!data.id) {
                            data.id = this.playbooks._generate_id();
                        }
                        var playbook = create_playbook(data);
                        this.playbooks.push(playbook);
                        return playbook;
                    };
                    self.save = function (_callback, params, data) {
                        params = params || {};
                        function sendRequest(results) {
                            if (results.error) {
                                _callback(results);
                            } else {
                                params.etag = results.etag;
                                srvcRequester.put('workspace', self.dispatch, params);
                            }
                        }
                        var validation_res;
                        params.type = params.type || 'update';
                        params._callback = _callback;
                        if (data) {
                            data.playbooks.map(clean_up_playbooks);
                            validation_res = $schemaValidator.validate(data, factory.schema);
                        } else {
                            this.playbooks.map(clean_up_playbooks);
                            validation_res = $schemaValidator.validate(this, factory.schema);
                        }
                        params.data = validation_res.data;
                        if (validation_res.error) {
                            validation_res.updated = false;
                            validation_res.type = params.type;
                            _callback(validation_res);
                        } else {
                            self._getEtag().then(sendRequest);
                        }
                    };
                    self.dispatch = function (results) {
                        switch (results.type) {
                        default:
                            if (!results.status) {
                                results._callback({ updated: false });
                                return null;
                            }
                            if (!results.data || !results.data.etag) {
                                results._callback({
                                    error: results.error,
                                    updated: false
                                });
                                return null;
                            }
                            if (results.data.hasOwnProperty('id')) {
                                self.set(results.data);
                            } else {
                                self.etag = results.data.etag;
                            }
                            if (results.call_info.params.hasOwnProperty('refresh') && results.call_info.params.refresh) {
                                delete results.call_info.params.refresh;
                                results.call_info.params.id = self.id;
                                srvcRequester.get('workspace', self.dispatch, results.call_info.params);
                            } else {
                                results._callback({
                                    updated: true,
                                    call_info: results.call_info,
                                    data: self,
                                    etag: results.data.etag,
                                    type: results.type
                                });
                            }
                            break;
                        }
                    };
                    self.get = function (info, params) {
                        switch (info) {
                        case 'schema':
                            srvcRequester.get('schema', params._callback, { call: 'workspace' });
                            break;
                        default:
                            $log.log('Invalid Workspace get:', info);
                            break;
                        }
                    };
                    if (data) {
                        self.set(data);
                    }
                }
                Workspace_Model.prototype.newCustomProp = function (data) {
                    data = data || {};
                    return {
                        display_name: data.display_name || '',
                        description: data.description || '',
                        type: data.type || '',
                        evaluator: data.evaluator || '',
                        style: data.style || null,
                        enabled: true,
                        read_only: data.read_only || false,
                        hidden: data.hidden || false
                    };
                };
                Workspace_Model.prototype.set = function (data) {
                    var self = this;
                    function setCustomProp(item) {
                        item.isFormula = item.hasOwnProperty('evaluator');
                        return item;
                    }
                    function checkFavList(favList, cusList) {
                        var prefix = '/custom_properties/';
                        for (var i = favList.length - 1, ii = -1; i > ii; i--) {
                            if (favList[i].indexOf(prefix) !== -1) {
                                var index = cusList._index_of_by_prop('id', favList[i].replace(prefix, ''));
                                if (index !== -1 && !cusList[index].enabled) {
                                    favList.splice(i, 1);
                                }
                            }
                        }
                    }
                    if (data) {
                        self.location = LOCATION + '/' + data.id;
                        self.id = data.id;
                        self.tenancy_id = data.tenancy_id;
                        self.media_type = data.media_type;
                        self.etag = data.etag || null;
                        self.created_at = data.created_at;
                        self.name = data.name;
                        self.description = data.description;
                        self.timezone = data.timezone;
                        self.grants = data.grants || [];
                        self.tag_colors = data.tag_colors || {};
                        self.created_integrations = data.created_integrations;
                        self.email_blacklist = data.email_blacklist || [];
                        self.scripts = data.scripts || [];
                        self.html_email_signature = data.html_email_signature;
                        self.allow_ui_versions = data.allow_ui_versions || false;
                        self.default_ui_version = data.default_ui_version;
                        self.display_account_attachments = data.display_account_attachments || false;
                        self.display_account_reports = data.display_account_reports || false;
                        self.display_invoices = data.display_invoices || false;
                        self.display_nps_properties = data.display_nps_properties || false;
                        self.display_survey_responses = data.display_survey_responses || false;
                        self.next_gen_rules = data.next_gen_rules || false;
                        self.new_search_api = data.new_search_api || false;
                        self.account_custom_properties = data.account_custom_properties || [];
                        self.account_favourite_properties = data.account_favourite_properties || [];
                        self.account_favourite_custom_reports = data.account_favourite_custom_reports || [];
                        self.account_regions = data.account_regions || [];
                        self.account_risk_levels = data.account_risk_levels && data.account_risk_levels.length ? data.account_risk_levels.map(self.assign_color) : [];
                        self.account_roles = data.account_roles || [];
                        self.account_stages = data.account_stages || [];
                        self.account_tiers = data.account_tiers || [];
                        self.account_types = data.account_types || [];
                        self.account_value_metric = data.account_value_metric;
                        self.accountMetric = data.account_value_metric.toUpperCase();
                        self.default_currency_code = data.default_currency_code;
                        self.priority_levels = data.priority_levels && data.priority_levels.length ? data.priority_levels.map(self.assign_color) : [];
                        self.participant_custom_properties = data.participant_custom_properties || [];
                        self.participant_favourite_properties = data.participant_favourite_properties || [];
                        self.allow_full_participant_formula_recalc = data.allow_full_participant_formula_recalc;
                        self.participant_roles = data.participant_roles || [];
                        self.play_custom_properties = data.play_custom_properties || [];
                        self.allow_full_play_formula_recalc = data.allow_full_play_formula_recalc;
                        self.playbooks = [];
                        for (var i = 0, ii = data.playbooks.length; i < ii; i++) {
                            self.new_playbook(data.playbooks[i]);
                        }
                        self.work_item_stages = data.work_item_stages;
                        self.work_item_types = data.work_item_types ? data.work_item_types.sort_by('name') : [];
                        self.work_item_archive_threshold = data.work_item_archive_threshold || 0;
                        self.engagement_topics = data.engagement_topics.sort_by('name') || [];
                        self.enable_time_tracking = data.enable_time_tracking;
                        self.hours_per_work_day = data.hours_per_work_day;
                        self.identify_account_create_mode = data.identify_account_create_mode;
                        self.identify_participant_create_mode = data.identify_participant_create_mode;
                        self.record_activity_auto_create = data.record_activity_auto_create;
                        self.allow_staff_activity_operations = data.allow_staff_activity_operations;
                        self.record_activity_skip_ignored_accounts = data.record_activity_skip_ignored_accounts;
                        if (self.account_custom_properties.length) {
                            self.account_custom_properties = self.account_custom_properties.map(setCustomProp);
                        }
                        if (self.participant_custom_properties.length) {
                            self.participant_custom_properties = self.participant_custom_properties.map(setCustomProp);
                        }
                        if (self.play_custom_properties.length) {
                            self.play_custom_properties = self.play_custom_properties.map(setCustomProp);
                        }
                        if (self.account_favourite_properties.length) {
                            self.account_favourite_properties._uniqueList();
                            checkFavList(self.account_favourite_properties, self.account_custom_properties);
                        }
                        if (self.participant_favourite_properties.length) {
                            self.participant_favourite_properties._uniqueList();
                            checkFavList(self.participant_favourite_properties, self.participant_custom_properties);
                        }
                    }
                };
                Workspace_Model.prototype.defaultTagColor = '#F5F5F5';
                Workspace_Model.prototype.tagList = function () {
                    var self = this, tags = self.tag_colors, keys = Object.keys(tags), ln = keys.length;
                    function tagItem(item, index) {
                        return {
                            label: keys[index],
                            color: tags[keys[index]]
                        };
                    }
                    return ln ? Array.apply(null, new Array(keys.length)).map(tagItem).sort_by('label') : [];
                };
                Workspace_Model.prototype._checkCustomSelect = function (params) {
                    var isError = false;
                    if (params.value.type === 'picklist') {
                        for (var i = 0, ii = params.value.items.length; i < ii; i++) {
                            if (params.value.items[i].display_name === '' || params.value.items[i].value === '') {
                                isError = true;
                                break;
                            }
                        }
                    }
                    return isError;
                };
                Workspace_Model.prototype._getEtag = function () {
                    var self = this, etagDefer = $q.defer();
                    function completeUpdate() {
                        etagDefer.resolve({ etag: self.etag });
                    }
                    function completeRequest(results) {
                        if (!results.error) {
                            self.etag = results.data.etag;
                            results.etag = self.etag;
                        }
                        etagDefer.resolve(results);
                    }
                    if (self.etag) {
                        $timeout(completeUpdate);
                    } else {
                        $ay_requester.GET(self.location).then(completeRequest);
                    }
                    return etagDefer.promise;
                };
                Workspace_Model.prototype.update = function (fields) {
                    var patchPromise, self = this, isError = false, updateDefer = $q.defer(), params = {
                            fields: fields,
                            media_type: self.media_type,
                            data: Array.apply(null, new Array(fields.length)).map(createTargets)
                        };
                    function createTargets(item, index) {
                        var validation, schema, target = fields[index], change = {
                                op: target.op ? target.op : 'replace',
                                path: global_utilities.toRestPointer(target.field)
                            };
                        if (change.path.indexOf('playbooks') !== -1) {
                            self.playbooks.map(clean_up_playbooks);
                        }
                        if (change.op === 'move') {
                            change.from = global_utilities.toRestPointer(target.from);
                        } else {
                            if (target.schemaField) {
                                schema = global_utilities.jsonPointer(factory.schema.properties, target.schemaField);
                            }
                            if (target.hasOwnProperty('value')) {
                                switch (schema.type) {
                                case 'array':
                                    validation = $schemaValidator.validate([target.value], schema);
                                    change.value = validation.data[0];
                                    break;
                                default:
                                    validation = $schemaValidator.validate(target.value, schema);
                                    change.value = validation.data;
                                    break;
                                }
                            } else {
                                if (change.op === 'replace' || change.op === 'add' || change.op === 'test') {
                                    if (!schema) {
                                        change.value = global_utilities.jsonPointer(self, target.field);
                                    } else {
                                        validation = $schemaValidator.validate([target.value], schema);
                                        change.value = validation.data;
                                    }
                                }
                            }
                        }
                        if (change.op === 'add' && (target.schemaField === 'account_custom_properties' || target.schemaField === 'participant_custom_properties')) {
                            delete change.value.id;
                        }
                        if ((target.schemaField === 'account_custom_properties' || target.schemaField === 'participant_custom_properties') && self._checkCustomSelect(change)) {
                            isError = true;
                        }
                        return change;
                    }
                    function completeRequest(results) {
                        if (results.error) {
                            updateDefer.resolve(results);
                        } else {
                            $ay_requester.GET(self.location).then(function (getResults) {
                                if (!getResults.error) {
                                    self.set(getResults.data);
                                    getResults.data = self;
                                }
                                updateDefer.resolve(getResults);
                            });
                        }
                    }
                    if (isError) {
                    } else {
                        patchPromise = $ay_requester.PATCH(self.location, params);
                        patchPromise.then(completeRequest);
                    }
                    return updateDefer.promise;
                };
                Workspace_Model.prototype.refresh = function () {
                    var self = this, refreshDefer = $q.defer();
                    $ay_requester.GET(self.location).then(function (results) {
                        if (!results.error) {
                            self.set(results.data);
                            results.data = self;
                            refreshDefer.resolve(results);
                        }
                        refreshDefer.resolve(results);
                    });
                    return refreshDefer.promise;
                };
                Workspace_Model.prototype.isOutOfDate = function () {
                    var self = this, checkDefer = $q.defer();
                    $ay_requester.GET(self.location).then(function (results) {
                        if (results.error) {
                            checkDefer.resolve(false);
                        } else {
                            if (self.etag === null) {
                                self.etag = results.data.etag;
                            }
                            checkDefer.resolve(results.data.etag !== self.etag);
                        }
                    });
                    return checkDefer.promise;
                };
                factory.set = function (results) {
                    if (results.data.items && results.data.items.length) {
                        var workspace_list = [];
                        for (var i = 0; i < results.data.items.length; i++) {
                            var workspace = new Workspace_Model(results.data.items[i]);
                            workspace_list.push(workspace);
                        }
                        results._callback(workspace_list);
                    } else if (results.data) {
                        var workspace = new Workspace_Model(results.data);
                        results._callback({
                            data: workspace,
                            type: results.type
                        });
                    } else {
                        results._callback(results);
                    }
                };
                factory.dispatch = function (results) {
                    switch (results.type) {
                    case 'user-find':
                        if (!results.error) {
                            if (results.data && results.data.items && results.data.items.length) {
                                var workspace_list = [];
                                for (var i = 0; i < results.data.items.length; i++) {
                                    var space = results.data.items[i], workspace = new Workspace_Model(space);
                                    workspace_list.push(workspace);
                                }
                                if (workspace_list.length) {
                                    factory.get('workspace', {
                                        id: workspace_list[0].id,
                                        _callback: results._callback
                                    });
                                } else {
                                    results._callback({
                                        error: {
                                            code: 404,
                                            category: 'client',
                                            type: 'not-found'
                                        }
                                    });
                                }
                            } else {
                                results._callback({
                                    error: {
                                        code: 404,
                                        category: 'client',
                                        type: 'not-found'
                                    }
                                });
                            }
                        } else {
                            results._callback(results);
                        }
                        break;
                    }
                };
                function set_schema_data(results) {
                    if (!results.error) {
                        factory.schema = {
                            $schema: results.data.$schema,
                            description: results.data.description,
                            properties: results.data.oneOf[0].properties,
                            required: results.data.oneOf[0].required,
                            additionalProperties: false,
                            type: 'object'
                        };
                        factory.get(results.call_info.params.get_data.type, results.call_info.params.get_data.params);
                    }
                }
                factory.make = function make_model(data) {
                    return new Workspace_Model(data);
                };
                factory.getSchema = function get_entity_schema() {
                    var s = this, schemaDefer = $q.defer();
                    function setEntitySchema(results) {
                        if (!results.error) {
                            s.schema = {
                                $schema: results.data.$schema,
                                description: results.data.description,
                                properties: results.data.oneOf[0].properties,
                                required: results.data.oneOf[0].required,
                                additionalProperties: false,
                                type: 'object'
                            };
                            s._property_names = Object.keys(s.schema.properties);
                            schemaDefer.resolve(s.schema);
                        } else {
                            schemaDefer.resolve(results);
                        }
                    }
                    if (s.schema) {
                        $timeout(function () {
                            schemaDefer.resolve(s.schema);
                        }, 1);
                    } else {
                        $ay_requester.GET('schema/workspaces').then(setEntitySchema);
                    }
                    return schemaDefer.promise;
                };
                factory.GET = function get_user_data(params) {
                    var self = this, location = 'workspaces', model_defer = $q.defer();
                    if (params.hasOwnProperty('id')) {
                        location += '/' + params.id;
                    }
                    if (params.hasOwnProperty('tenancy_id')) {
                        params.options = {
                            f: [{
                                    field: '/tenancy_id',
                                    op: 'EQ',
                                    value: params.tenancy_id
                                }],
                            s: []
                        };
                    }
                    self.getSchema().then(function (schema) {
                        if (schema.error) {
                            model_defer.resolve(schema);
                        } else {
                            $ay_requester.GET(location, params).then(function (results) {
                                if (!results.error) {
                                    if (results.data.hasOwnProperty('id')) {
                                        results.data = self.make(results.data);
                                    } else if (results.data.hasOwnProperty('items') && results.data.items.length) {
                                        results.data = results.data.items.map(self.make);
                                    }
                                }
                                model_defer.resolve(results);
                            });
                        }
                    });
                    return model_defer.promise;
                };
                factory.get = function (type, params) {
                    var self = this;
                    function sendCall() {
                        switch (type) {
                        case 'workspace':
                            if (!params || !params._callback) {
                                return null;
                            }
                            srvcRequester.get('workspace', factory.set, params);
                            break;
                        case 'workspaces-all':
                            if (!params || !params._callback) {
                                return null;
                            }
                            srvcRequester.get('workspaces', factory.set, params);
                            break;
                        case 'workspace-user':
                            if (!params || !params._callback || !params.tenancy_id || !params.user_id) {
                                return null;
                            }
                            params.type = 'user-find';
                            params.options = {
                                f: [{
                                        field: '/tenancy_id',
                                        op: 'EQ',
                                        value: params.tenancy_id
                                    }],
                                s: []
                            };
                            srvcRequester.get('workspaces', factory.dispatch, params);
                            break;
                        case 'schema':
                            if (!params || !params._callback) {
                                return null;
                            }
                            srvcRequester.get('schema', params._callback, { call: 'workspace' });
                            break;
                        case 'shell':
                            var workspace = new Workspace_Model();
                            if (params) {
                                workspace.set(params.data);
                            }
                            return workspace;
                        }
                    }
                    if (!factory.schema) {
                        self.getSchema().then(sendCall);
                    } else {
                        sendCall();
                    }
                };
                return factory;
            }
        ]
    };
});