'use strict';
define('modules/helpers/services/utls', [
    'moment',
    'momentTimezone',
    'tinyColor',
    'numeral',
    'numeralLang'
], function (moment, momentTimezone, tinyColor, numeral, numeralLang) {
    return {
        component: 'service',
        name: '$utls',
        fn: [
            '$filter',
            '$log',
            function ultsService($filter, $log) {
                Function.prototype.new = function () {
                    var args = arguments, constructor = this;
                    function F() {
                        constructor.apply(this, args);
                    }
                    F.prototype = constructor.prototype;
                    return new F();
                };
                function jsonPointer(item, pointer) {
                    var resVal = angular.copy(item);
                    function replacer(match) {
                        return match ? '' : match;
                    }
                    try {
                        var check, arrFull = [], internalPointer = /\[\S*\s*\]/g, removeInternal = /\W?/g, arr = pointer.split('.');
                        if (!arr.length) {
                            arr = [pointer];
                        }
                        for (var i = 0, ii = arr.length; i < ii; i++) {
                            check = internalPointer.exec(arr[i]);
                            if (!check) {
                                arrFull.push(arr[i]);
                            } else {
                                arrFull.push(arr[i].split(internalPointer)[0], check[0].replace(removeInternal, replacer));
                            }
                        }
                        while (arrFull.length) {
                            resVal = resVal[arrFull.shift()];
                        }
                        return resVal;
                    } finally {
                        return resVal;
                    }
                }
                function List() {
                    for (var i = 0, ii = arguments.length; i < ii; i++) {
                        this.push(arguments[i]);
                    }
                }
                List.prototype = new Array();
                List.prototype.is_list = true;
                List.prototype._getVal = jsonPointer;
                List.prototype.indexBy = function indexBy(pointer, val) {
                    var index = -1;
                    for (var i = 0, ii = this.length; i < ii; i++) {
                        if (this._getVal(this[i], pointer) === val) {
                            index = i;
                            break;
                        }
                    }
                    return index;
                };
                List.prototype.filterBy = function (pointer, val) {
                    var self = this;
                    function filterFn(item) {
                        return angular.equals(self._getVal(item, pointer), val);
                    }
                    return List.new.apply(List, self.filter(filterFn));
                };
                List.prototype.sortBy = function (props, reverse) {
                    var res, self = this;
                    props = props.split(',');
                    function variantSort(a, b) {
                        var val_a, val_b, r, pointer;
                        for (var i = 0, ii = props.length; i < ii; i++) {
                            val_a = self._getVal(a, props[i]);
                            val_b = self._getVal(b, props[i]);
                            if (val_a === null || val_a === undefined) {
                                r = 1;
                                break;
                            }
                            if (val_b === null || val_b === undefined) {
                                r = 0;
                                break;
                            }
                            val_a = typeof val_a === 'string' ? val_a.toLowerCase() : val_a;
                            val_b = typeof val_b === 'string' ? val_b.toLowerCase() : val_b;
                            if (val_a < val_b) {
                                r = -1;
                                break;
                            }
                            if (val_a > val_b) {
                                r = 1;
                                break;
                            }
                            r = 0;
                        }
                        return r;
                    }
                    if (props.length) {
                        res = this.sort(variantSort);
                        return reverse ? res.reverse() : res;
                    } else {
                        return this;
                    }
                };
                this.list = function (items) {
                    items = items || [];
                    return List.new.apply(List, items);
                };
                this.numeral = function (n) {
                    function Num() {
                    }
                    Num.prototype = numeral(n);
                    Num.prototype.is_numeral_ext = true;
                    Num.prototype.stdFormat = function (pat) {
                        pat = pat || '0,0[.]0[00]';
                        return this.format(pat);
                    };
                    return Num.new.apply(Num, [n]);
                };
                this.moment = function (d, tz) {
                    var utl = this;
                    function Mo() {
                    }
                    if (tz) {
                        Mo.prototype = moment.tz(d, tz);
                    } else {
                        Mo.prototype = moment(d);
                    }
                    Mo.prototype.is_moment_ext = true;
                    Mo.prototype.diffDays = function (d) {
                        var self = this, endCp = moment.isMoment(d) ? d : utl.moment(d), startDate = [
                                self.year(),
                                self.month(),
                                self.date()
                            ], endDate = [
                                endCp.year(),
                                endCp.month(),
                                endCp.date()
                            ];
                        return utl.moment(startDate).diff(endDate, 'days');
                    };
                    Mo.prototype.toMorning = function () {
                        this.seconds(0);
                        this.minutes(0);
                        this.hours(0);
                        return this;
                    };
                    Mo.prototype.toMidnight = function () {
                        this.seconds(59);
                        this.minutes(59);
                        this.hours(23);
                        return this;
                    };
                    Mo.prototype.startOfQuarter = function () {
                        var q = this;
                        q.date(1);
                        switch (q.quarter()) {
                        case 1:
                            q.month(0);
                            break;
                        case 2:
                            q.month(3);
                            break;
                        case 3:
                            q.month(6);
                            break;
                        case 4:
                            q.month(9);
                            break;
                        }
                        return q;
                    };
                    Mo.prototype.isSameDate = function (compare) {
                        var res = false, d = this;
                        if (compare._isAMomentObject) {
                            res = d.date() === compare.date() && d.month() === compare.month() && d.year() === compare.year();
                        }
                        return res;
                    };
                    return Mo.new.apply(Mo, [d]);
                };
                this.progress = function (end, start) {
                    var dateDueFilter = $filter('dateDue'), self = this, today = self.moment(), state = {
                            start: self.moment(start),
                            end: end ? self.moment(end) : false,
                            remaining: 0,
                            percentage: 0,
                            msg: {
                                due: '',
                                age: '',
                                remaining: ''
                            }
                        };
                    state.total = state.end ? state.end.diffDays(state.start) + 1 : false;
                    state.actual = today.diffDays(state.start) + 1;
                    if (state.total !== false) {
                        state.remaining = state.end.diffDays(today);
                        state.percentage = state.remaining <= 0 ? 100 : Math.round(state.actual / state.total * 100);
                        if (state.remaining === 1 || state.remaining === 0) {
                            state.msg.remaining = state.remaining === 0 ? 'Today' : '1 day';
                            state.colors = {
                                bar: 'amber s-500',
                                body: 'yellow s-400 accent is-dark',
                                accent: 'yellow s-400 accent is-dark',
                                text: 'yellow-highlight s-700'
                            };
                        } else if (state.remaining <= -1) {
                            state.msg.remaining = state.remaining * -1 + ' day' + (state.remaining < -1 ? 's' : '') + ' overdue';
                            state.colors = {
                                bar: 'red s-700 accent',
                                body: 'red s-200 accent is-light',
                                accent: 'red s-200 accent is-light',
                                text: 'red-highlight accent s-700'
                            };
                        } else if (state.remaining > 1) {
                            state.msg.remaining = state.remaining + ' days';
                            state.colors = {
                                bar: 'green s-500',
                                body: 'white is-dark',
                                accent: 'green s-500 is-light',
                                text: 'green-highlight accent s-700'
                            };
                        }
                        state.msg.due = dateDueFilter(end, today);
                        state.msg.dueTip = (state.remaining < 0 ? 'was' : 'is') + ' due ' + state.msg.due;
                    }
                    state.msg.age = state.actual === 0 ? 'New' : state.actual === 1 ? '1 day' : state.actual + ' days';
                    state.msg.ageTip = state.actual === 0 ? 'was started Today' : 'has been in progress for ' + (state.actual === 1 ? '1 day' : state.actual + ' days');
                    return state;
                };
                this.color = function (c) {
                    function Co() {
                    }
                    Co.prototype = tinyColor(c);
                    Co.prototype.is_tiny_color_ext = true;
                    Co.prototype._isTransparent = function () {
                        return this.toName() === 'transparent' ? true : false;
                    };
                    Co.prototype.readable = function (format, size) {
                        size = size || 'small';
                        format = format || 'rgb';
                        var formatFn, self = this, stdColors = [
                                'rgba(255, 255, 255, 0.90)',
                                'rgba(255, 255, 255, 0.70)',
                                'rgba(255, 255, 255, 0.50)',
                                'rgba(0, 0, 0, 0.87)',
                                'rgba(0, 0, 0, 0.75)',
                                'rgba(0, 0, 0, 0.50)'
                            ], options = {
                                includeFallbackColors: true,
                                level: 'AA',
                                size: size
                            };
                        switch (format) {
                        case 'rgb':
                            formatFn = 'toRgbString';
                            break;
                        case 'hex':
                            formatFn = 'toHexString';
                            break;
                        }
                        return self._isTransparent() ? stdColors[3] : tinyColor.mostReadable(self.toRgb(), stdColors, options)[formatFn]();
                    };
                    Co.prototype.shade = function (contrast) {
                        return this.lighten(contrast).desaturate().toRgbString();
                    };
                    Co.prototype.paintStyle = function () {
                        var self = this, style = {};
                        if (self._isTransparent()) {
                            style['background-color'] = 'transparent';
                            style['border-color'] = 'currentColor';
                        } else {
                            var hex = self.toHex(), copy = new Co(hex);
                            style['background-color'] = hex;
                            style['border-color'] = copy.shade(-20);
                        }
                        style['color'] = self.readable();
                        return style;
                    };
                    Co.prototype.paintElement = function (el) {
                        var self = this, style = self.paintStyle();
                        el.css('background-color', style['background-color']);
                        el.css('border-color', style['border-color']);
                        el.css('color', style['color']);
                    };
                    return Co.new.apply(Co, [c]);
                };
                this.base64 = {
                    encode: function (str) {
                        return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function (match, p1) {
                            return String.fromCharCode('0x' + p1);
                        }));
                    },
                    decode: function (str) {
                        return decodeURIComponent(Array.prototype.map.call(atob(str), function (c) {
                            return '%' + c.charCodeAt(0).toString(16);
                        }).join(''));
                    }
                };
                this.jsonPointer = function (item, pointer) {
                    return jsonPointer(item, pointer);
                };
                this.toRestPointer = function (str) {
                    function convertProp(match) {
                        return match.replace(/(\[\")/g, '/').replace(/(\"\])/g, '');
                    }
                    function convertIndexes(match) {
                        return match.replace(/(\[)/g, '/').replace(/(\])/g, '');
                    }
                    return '/' + str.replace(/^_/g, '').replace(/(\.)/g, '/').replace(/(\[\"\w+\"\])/g, convertProp).replace(/(\[\d+\])/g, convertIndexes);
                };
                this.toJsonPointer = function (str) {
                    function convertIndexes(match) {
                        return match.replace(/^\//g, '[').replace(/\//g, '].');
                    }
                    if (angular.isString(str)) {
                        return str.replace(/^\//g, '').replace(/(\/\d+\/)/g, convertIndexes).replace(/\//g, '.').replace(/\.$/g, '');
                    } else {
                        $log.error({
                            error: {
                                message: 'Tried to convert non-string to json pointer',
                                str: str
                            }
                        });
                        return str;
                    }
                };
                this.matchAll = {
                    run: function (pat, str) {
                        var m, matches = [];
                        do {
                            m = pat.exec(str);
                            if (m) {
                                matches.push(m[1]);
                            }
                        } while (m);
                        return matches;
                    },
                    newLines: function (str) {
                        var pat = /([\n])\w+/gm;
                        return this.run(pat, str);
                    }
                };
                this.randomTime = function (max, min) {
                    max = max || 150;
                    min = min || 1;
                    return Math.floor(Math.random() * max + min);
                };
                this.datePeriods = function (tz) {
                    var utl = this, format = 'YYYY-MM-DD', momentMethods = {
                            days: 'd',
                            months: 'M',
                            years: 'y'
                        };
                    function relativePeriod(anchor, datepicker) {
                        anchor = anchor || utl.moment(undefined, tz);
                        var self = this, cpAnchor = utl.moment(anchor.format(format), tz), cpPivot = utl.moment(anchor.format(format), tz), period = { time_anchor: anchor };
                        cpPivot.hours(0);
                        cpPivot.minutes(0);
                        cpPivot.seconds(0);
                        switch (self.value) {
                        case 'week':
                            cpPivot.days(0).subtract(1, self.momentMethod);
                            period[self.method] = cpAnchor.diffDays(cpPivot);
                            cpPivot.add(1, self.momentMethod);
                            break;
                        case 'month':
                            period[self.method] = cpAnchor.date();
                            cpPivot.date(1);
                            break;
                        case 'quarter':
                            cpPivot.startOfQuarter();
                            cpPivot.subtract(1, momentMethods.days);
                            period[self.method] = cpAnchor.diffDays(cpPivot);
                            cpPivot.add(1, momentMethods.days);
                            break;
                        case 'year':
                            period[self.method] = cpAnchor.month() + 1;
                            cpPivot.month(0);
                            cpPivot.date(1);
                            break;
                        }
                        if (period[self.method] < 0) {
                            period[self.method] *= -1;
                        }
                        period._time_from = cpPivot;
                        if (datepicker) {
                            datepicker.from = period._time_from.format(format);
                        }
                        period.update = false;
                        period.id = self.id;
                        return period;
                    }
                    function relativePeriodPrevious(anchor, datepicker) {
                        anchor = anchor || utl.moment(undefined, tz);
                        var self = this, cpAnchor = utl.moment(anchor.format(format), tz);
                        cpAnchor.hours(23);
                        cpAnchor.minutes(59);
                        cpAnchor.seconds(59);
                        switch (self.value) {
                        case 'week':
                            cpAnchor.days(0).subtract(1, self.momentMethod);
                            break;
                        case 'month':
                            cpAnchor.date(1);
                            cpAnchor.subtract(1, self.momentMethod);
                            break;
                        case 'quarter':
                            cpAnchor.startOfQuarter();
                            cpAnchor.subtract(1, self.momentMethod);
                            break;
                        case 'year':
                            cpAnchor.subtract(1, self.momentMethod);
                            cpAnchor.month(11);
                            cpAnchor.date(31);
                            break;
                        }
                        return relativePeriod.apply(self, [
                            cpAnchor,
                            datepicker
                        ]);
                    }
                    function getPeriod(anchor, datepicker) {
                        anchor = anchor || utl.moment(undefined, tz);
                        var method, self = this, cpAnchor = utl.moment(anchor.format(format), tz), period = { time_anchor: utl.moment(anchor.format(format), tz) };
                        period.id = self.id;
                        switch (self.id) {
                        case 'last_12_months':
                            period.time_anchor.date(1);
                            period.time_anchor.subtract(1, momentMethods.days);
                            period.time_anchor.hours(0);
                            period.time_anchor.minutes(0);
                            period.time_anchor.seconds(0);
                            period[self.method] = self.value;
                            period._time_from = cpAnchor;
                            period._time_from.date(1);
                            period._time_from.subtract(1, momentMethods.months);
                            period._time_from.subtract(1, momentMethods.years);
                            break;
                        default:
                            period.time_anchor.hours(23);
                            period.time_anchor.minutes(59);
                            period.time_anchor.seconds(59);
                            period[self.method] = self.value;
                            period._time_from = cpAnchor;
                            period._time_from.subtract(self.value - 1, self.momentMethod);
                            break;
                        }
                        period._time_from.hours(0);
                        period._time_from.minutes(0);
                        period._time_from.seconds(0);
                        if (datepicker) {
                            datepicker.from = period._time_from.format(format);
                        }
                        return period;
                    }
                    return [
                        {
                            id: 'last_7_days',
                            label: 'Last 7 days',
                            value: 8,
                            method: 'days_before',
                            momentMethod: momentMethods.days,
                            fn: getPeriod
                        },
                        {
                            id: 'last_30_days',
                            label: 'Last 30 days',
                            value: 31,
                            method: 'days_before',
                            momentMethod: momentMethods.days,
                            fn: getPeriod
                        },
                        {
                            id: 'last_90_days',
                            label: 'Last 90 days',
                            value: 91,
                            method: 'days_before',
                            momentMethod: momentMethods.days,
                            fn: getPeriod
                        },
                        {
                            id: 'last_12_months',
                            label: 'Last 12 months',
                            value: 13,
                            method: 'months_before',
                            momentMethod: momentMethods.months,
                            fn: getPeriod
                        },
                        {
                            id: 'this_week',
                            label: 'This week',
                            value: 'week',
                            method: 'days_before',
                            momentMethod: momentMethods.days,
                            fn: relativePeriod
                        },
                        {
                            id: 'this_month',
                            label: 'This month',
                            value: 'month',
                            method: 'days_before',
                            momentMethod: momentMethods.days,
                            fn: relativePeriod
                        },
                        {
                            id: 'this_quarter',
                            label: 'This quarter',
                            value: 'quarter',
                            method: 'days_before',
                            momentMethod: momentMethods.days,
                            fn: relativePeriod
                        },
                        {
                            id: 'this_year',
                            label: 'This year',
                            value: 'year',
                            method: 'months_before',
                            momentMethod: momentMethods.years,
                            fn: relativePeriod
                        },
                        {
                            id: 'previous_week',
                            label: 'Previous week',
                            value: 'week',
                            method: 'days_before',
                            momentMethod: momentMethods.days,
                            fn: relativePeriodPrevious
                        },
                        {
                            id: 'previous_month',
                            label: 'Previous month',
                            value: 'month',
                            method: 'days_before',
                            momentMethod: momentMethods.days,
                            fn: relativePeriodPrevious
                        },
                        {
                            id: 'previous_quarter',
                            label: 'Previous quarter',
                            value: 'quarter',
                            method: 'days_before',
                            momentMethod: momentMethods.days,
                            fn: relativePeriodPrevious
                        },
                        {
                            id: 'previous_year',
                            label: 'Previous year',
                            value: 'year',
                            method: 'months_before',
                            momentMethod: momentMethods.years,
                            fn: relativePeriodPrevious
                        }
                    ];
                };
                this.filterToArrayOps = function (op) {
                    switch (op) {
                    case 'EQ':
                        return 'IN';
                    case 'NE':
                        return 'NIN';
                    }
                    return op;
                };
                this.filterFromArrayOps = function (op) {
                    switch (op) {
                    case 'IN':
                        return 'EQ';
                    case 'NIN':
                        return 'NE';
                    }
                    return op;
                };
                this.widgetFilterModalFudge = function (widget) {
                    if (widget.primary_entity.entity_type === 'engagement') {
                        for (var i = 0, ii = widget.primary_entity.filters.length, filterItem; i < ii; i++) {
                            filterItem = widget.primary_entity.filters[i];
                            if (filterItem.field === '/method') {
                                filterItem.op = this.filterFromArrayOps(filterItem.op);
                                if (Array.isArray(filterItem.value)) {
                                    if (filterItem.value.indexOf('incoming.email') !== -1 || filterItem.value.indexOf('outgoing.email') !== -1) {
                                        filterItem.value = 'email';
                                    } else if (filterItem.value.indexOf('incoming.chat') !== -1 || filterItem.value.indexOf('outgoing.chat') !== -1) {
                                        filterItem.value = 'chat';
                                    }
                                }
                            }
                        }
                    }
                };
                this.widgetFilterModalUnfudge = function (widget) {
                    var primary_entity = widget.primary_entity;
                    if (primary_entity.entity_type === 'engagement') {
                        for (var i = 0, ii = primary_entity.filters.length, filterItem; i < ii; i++) {
                            filterItem = primary_entity.filters[i];
                            if (filterItem.field === '/method') {
                                if (Array.isArray(filterItem.value)) {
                                    filterItem.op = this.filterToArrayOps(filterItem.op);
                                } else {
                                    switch (filterItem.value) {
                                    case 'email':
                                        filterItem.op = this.filterToArrayOps(filterItem.op);
                                        filterItem.value = [
                                            'incoming.email',
                                            'outgoing.email'
                                        ];
                                        break;
                                    case 'chat':
                                        filterItem.op = this.filterToArrayOps(filterItem.op);
                                        filterItem.value = [
                                            'incoming.chat',
                                            'outgoing.chat'
                                        ];
                                        break;
                                    }
                                }
                            }
                        }
                    }
                };
            }
        ]
    };
});