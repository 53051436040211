define('modules/rest/factories/rule-events', [], function () {
    'use strict';
    return {
        component: 'service',
        name: 'RuleEventsFactory',
        fn: [
            '$log',
            '$schemaValidator',
            '$ay_requester',
            '$q',
            '$timeout',
            '$ME',
            '$sce',
            function ($log, $schemaValidator, $ay_requester, $q, $timeout, $ME, $sce) {
                var LOCATION = 'rule_event', media_type = 'application/vnd.amity.hub.rule_event+json';
                function RuleEventModel(data, schema) {
                    this.schema = schema;
                    this.id = null;
                    this.media_type = media_type;
                    this.workspace_id = $ME.workspace.id;
                    this.created_at = null;
                    this.event_type = null;
                    this.rule_id = null;
                    this.rule_name = null;
                    this.rule_action_type = null;
                    this.entity_id = null;
                    this.entity_name = null;
                    this.entity_type = null;
                    this.message = null;
                    if (data) {
                        this._set(data);
                    }
                }
                RuleEventModel.prototype._set = function (data) {
                    var self = this;
                    self.etag = data.etag;
                    self.id = data.id;
                    self.location = LOCATION + '/' + data.id;
                    self.media_type = data.media_type || self.media_type;
                    self.workspace_id = data.workspace_id || $ME.workspace.id;
                    self.created_at = data.created_at;
                    self.event_type = data.event_type;
                    self.rule_id = data.rule_id;
                    self.rule_name = data.rule_name;
                    self.rule_action_type = data.rule_action_type;
                    self.entity_id = data.entity_id;
                    self.entity_name = data.entity_name;
                    self.entity_type = data.entity_type;
                    self.message = data.message;
                    self.formatEntityLink();
                    self.formatEventTitle();
                    self.formatEventDescription();
                };
                RuleEventModel.prototype.formatEntityLink = function formatEntityLink() {
                    var self = this;
                    self.entityLink = '';
                    switch (self.entity_type) {
                    case 'account':
                        self.entityLink = '<a class="blue-highlight s-600" href="/csm/accounts/' + self.entity_id + '/events">' + self.entity_name + '</a></span>';
                        break;
                    }
                };
                RuleEventModel.prototype.formatEventRuleAction = function formatEventRuleAction() {
                    var self = this;
                    self.ruleActionDescription = '<span class="is-bold">';
                    switch (self.rule_action_type) {
                    case 'create_work_item':
                        self.ruleActionDescription += 'creating a task';
                        break;
                    case 'create_note':
                        self.ruleActionDescription += 'creating a note';
                        break;
                    case 'create_play':
                        self.ruleActionDescription += 'creating a play';
                        break;
                    case 'add_tags':
                        self.ruleActionDescription += 'adding tags';
                        break;
                    case 'remove_tags':
                        self.ruleActionDescription += 'removing tags';
                        break;
                    case 'set_tags':
                        self.ruleActionDescription += 'setting tags';
                        break;
                    case 'send_external_target':
                        self.ruleActionDescription += 'sending data to an external target';
                        break;
                    }
                    self.ruleActionDescription += '</span>';
                };
                RuleEventModel.prototype.formatEventTitle = function formatEventTitle() {
                    var self = this, title = '<span>';
                    switch (self.event_type) {
                    case 'action_error':
                        title += 'An error occurred executing the ' + self.ruleActionDescription + ' action: ' + self.message;
                        break;
                    case 'action_executed':
                        title += 'Executed the ' + self.ruleActionDescription + ' action';
                        break;
                    case 'action_scheduled':
                        title += '';
                        break;
                    case 'action_skipped':
                        title += 'Skipped the ' + self.ruleActionDescription + ' action : ' + self.message;
                        break;
                    case 'completed':
                        title += 'Rule completed';
                        break;
                    }
                    title += ' for ' + self.entityLink;
                    self.title = $sce.trustAsHtml(title);
                };
                RuleEventModel.prototype.formatEventDescription = function formatTimelineTitle() {
                    var self = this, description = '<span>';
                    description += 'The rule <span class="is-bold">' + self.rule_name + '</span>';
                    switch (self.event_type) {
                    case 'action_error':
                        description += ' encountered an error';
                        break;
                    case 'action_executed':
                        description += ' was executed';
                        break;
                    case 'action_scheduled':
                        description += ' has been scheduled';
                        break;
                    case 'action_skipped':
                        description += ' was skipped';
                        break;
                    case 'completed':
                        description += ' was completed';
                        break;
                    }
                    description += '</span>';
                    self.description = $sce.trustAsHtml(description);
                };
                RuleEventModel.prototype.getEtag = function () {
                    var self = this, etagDefer = $q.defer();
                    if (self.etag) {
                        $timeout(function () {
                            etagDefer.resolve({ etag: self.etag });
                        }, 1);
                    } else {
                        $ay_requester.GET(self.location).then(function (results) {
                            if (!results.error) {
                                self.etag = results.data.etag;
                                results.etag = self.etag;
                            }
                            etagDefer.resolve(results);
                        });
                    }
                    return etagDefer.promise;
                };
                RuleEventModel.prototype.save = function () {
                    var self = this, saveDefer = $q.defer(), validationRes = $schemaValidator.validate(angular.copy(self), self.schema);
                    self.getEtag().then(function (results) {
                        if (results.error) {
                            saveDefer.resolve(results);
                        } else {
                            if (validationRes.error) {
                                saveDefer.resolve(validationRes);
                            } else {
                                $ay_requester.PUT(self.location, {
                                    etag: results.etag,
                                    data: validationRes.data
                                }).then(function completeRequest(results) {
                                    if (!results.error) {
                                        self._set(results.data);
                                        results.data = self;
                                    }
                                    saveDefer.resolve(results);
                                });
                            }
                        }
                    });
                    return saveDefer.promise;
                };
                RuleEventModel.prototype.remove = function () {
                    var self = this;
                    return $ay_requester.DELETE(self.location, {});
                };
                function RuleEventFactory() {
                }
                RuleEventFactory.prototype.location = LOCATION;
                RuleEventFactory.prototype.media_type = media_type;
                RuleEventFactory.prototype._newModel = function (data) {
                    var self = this;
                    return new RuleEventModel(data, self.schema);
                };
                RuleEventFactory.prototype._buildOptions = function (params) {
                    var options = {
                        f: [{
                                field: '/workspace_id',
                                op: 'EQ',
                                value: $ME.workspace.id
                            }],
                        s: [{
                                field: '/created_at',
                                descending: true
                            }]
                    };
                    if (params.hasOwnProperty('id')) {
                        options.f.push({
                            field: '/id',
                            op: 'EQ',
                            value: params.id
                        });
                    }
                    if (params.hasOwnProperty('ruleId')) {
                        options.f.push({
                            field: '/rule_id',
                            op: 'EQ',
                            value: params.ruleId
                        });
                    }
                    if (params.hasOwnProperty('ruleName')) {
                        options.f.push({
                            field: '/rule_name',
                            op: 'CONTAINS',
                            value: params.ruleName
                        });
                    }
                    if (params.hasOwnProperty('entityId')) {
                        options.f.push({
                            field: '/entity_id',
                            op: 'EQ',
                            value: params.entityId
                        });
                    }
                    return options;
                };
                RuleEventFactory.prototype.getSchema = function () {
                    var s = this, schemaDefer = $q.defer();
                    function setEntitySchema(results) {
                        if (!results.error) {
                            s.schema = results.data;
                            schemaDefer.resolve(s.schema);
                        } else {
                            schemaDefer.resolve(results);
                        }
                    }
                    if (s.schema) {
                        $timeout(function () {
                            schemaDefer.resolve(s.schema);
                        });
                    } else {
                        $ay_requester.GET('schema/' + LOCATION).then(setEntitySchema);
                    }
                    return schemaDefer.promise;
                };
                RuleEventFactory.prototype.get = function (params) {
                    var drainPromise, self = this, getDefer = $q.defer(), location = LOCATION, models = [];
                    function setModels(results) {
                        if (results.error) {
                            getDefer.resolve(results);
                        } else {
                            if (!results.data.hasOwnProperty('items')) {
                                results.data = self._newModel(results.data);
                                getDefer.resolve(results);
                            } else {
                                models = models.concat(results.data.items.map.apply(results.data.items, [
                                    self._newModel,
                                    self
                                ]));
                                results.data = models;
                                if (!params.drain) {
                                    getDefer.resolve(results);
                                } else {
                                    if (!results.cursor) {
                                        getDefer.resolve(results);
                                    } else {
                                        params.cursor = results.cursor;
                                        drainPromise = $ay_requester.GET(location, params);
                                        drainPromise.then(setModels);
                                    }
                                }
                            }
                        }
                    }
                    self.getSchema().then(function (results) {
                        params = params || {};
                        if (params.id) {
                            location += '/' + params.id;
                        } else {
                            params.options = self._buildOptions(params);
                            params.range = 25;
                        }
                        $ay_requester.GET(location, params).then(setModels);
                    });
                    return getDefer.promise;
                };
                RuleEventFactory.prototype.create = function (data) {
                    var self = this, createDefer = $q.defer();
                    function post() {
                        data.media_type = self.schema.description;
                        data.workspace_id = $ME.workspace.id;
                        var validationRes = $schemaValidator.validate(data, self.schema);
                        if (validationRes.error) {
                            $timeout(function () {
                                createDefer.resolve(validationRes);
                            }, 1);
                        } else {
                            $ay_requester.POST(LOCATION, { data: validationRes.data }).then(function (results) {
                                if (!results.error) {
                                    results.data = self._newModel(results.data);
                                }
                                createDefer.resolve(results);
                            });
                        }
                    }
                    self.getSchema().then(post);
                    return createDefer.promise;
                };
                return new RuleEventFactory();
            }
        ]
    };
});