define('modules/rest/factories/integrations', [], function () {
    'use strict';
    return {
        component: 'service',
        name: 'modelIntegrations',
        fn: [
            '$log',
            '$schemaValidator',
            '$ay_requester',
            '$q',
            '$timeout',
            '$ME',
            function integrations($log, $schemaValidator, $ay_requester, $q, $timeout, $ME) {
                function Model_Integration(result, details, id) {
                    this.data = {};
                    this.is_connected = false;
                    this.is_super_configured = false;
                    this.per_user_connect = false;
                    this.details = angular.copy(details);
                    this.id = id;
                    if (!result.error) {
                        this.data = result.data;
                        this.etag = result.etag;
                    }
                    this.check_connection();
                }
                Model_Integration.prototype.check_connection = function check_connection() {
                    var self = this, connection_field_value, per_user_connect_field_value;
                    if (!Object.keys(self.data).length) {
                        self.is_connected = false;
                        self.per_user_connect = false;
                        self.is_super_configured = false;
                    } else {
                        self.is_super_configured = true;
                        if (!self.details.external_connect) {
                            self.is_connected = true;
                            self.per_user_connect = false;
                        } else {
                            self.details.show_connection = true;
                            self.per_user_connect = false;
                            if (self.data.hasOwnProperty(self.details.external_connect.per_user_connect_field)) {
                                per_user_connect_field_value = self.data[self.details.external_connect.per_user_connect_field];
                                if (per_user_connect_field_value) {
                                    self.per_user_connect = true;
                                }
                            }
                            if (self.data.hasOwnProperty(self.details.external_connect.connection_field)) {
                                connection_field_value = self.data[self.details.external_connect.connection_field];
                                if (connection_field_value) {
                                    self.is_connected = true;
                                } else {
                                    self.is_connected = false;
                                }
                            } else {
                                self.is_connected = false;
                            }
                        }
                    }
                };
                Model_Integration.prototype.get_connection = function get_connection(completion_url) {
                    var self = this;
                    return $ay_requester.POST(self.details.location, {
                        data: {
                            action: 'connect',
                            completion_url: completion_url
                        },
                        retrieveEntity: false
                    });
                };
                function Factory_Integration() {
                    this.integrations = {};
                }
                Factory_Integration.prototype.get_location = function get_location() {
                    return 'workspaces/' + $ME.workspace.id + '/integrations';
                };
                Factory_Integration.prototype.getCredentials = function (endpoint) {
                    var self = this, location = self.get_location() + '/' + endpoint;
                    return $ay_requester.POST(location, {
                        data: { action: 'list_credentials' },
                        retrieveEntity: false
                    });
                };
                Factory_Integration.prototype.get_integrations = function get_integrations() {
                    var LOCATION = this.get_location();
                    return {
                        'CRM.Base': {
                            label: 'Base CRM',
                            location: LOCATION + '/CRM/Base',
                            avatar: 'static/build/assets/images/base-logo.png',
                            description: 'Syncs Base CRM contacts with Amity accounts and people.',
                            external_link: 'https://support.getamity.com/hc/en-us/articles/207086533',
                            external_connect: false,
                            show_connection: true
                        },
                        'CRM.dynamics': {
                            label: 'Microsoft Dynamics CRM',
                            location: LOCATION + '/CRM/dynamics',
                            avatar: '/static/build/assets/images/dynamics-365-logo.png',
                            description: 'Syncs Microsoft Dynamics CRM accounts and contacts with Amity accounts and people.',
                            external_link: 'https://support.getamity.com/hc/en-us/articles/115003808311',
                            external_connect: {
                                enabled_field: 'admin_consent_url',
                                connection_field: 'admin_consent'
                            },
                            show_connection: true
                        },
                        'CRM.HubSpot': {
                            label: 'Hubspot CRM',
                            location: LOCATION + '/CRM/HubSpot',
                            avatar: 'static/build/assets/images/hubspot-logo.png',
                            description: 'Syncs HubSpot CRM companies, contacts and deals with Amity accounts, people and subscriptions.',
                            external_link: 'https://support.getamity.com/hc/en-us/articles/206589603',
                            external_connect: false,
                            show_connection: true
                        },
                        'CRM.pipedrive': {
                            label: 'Pipedrive',
                            location: LOCATION + '/CRM/pipedrive',
                            avatar: '/static/build/assets/images/pipedrive-logo.png',
                            description: 'Syncs Pipedrive organizations and contacts with Amity accounts and people.',
                            external_link: 'https://support.getamity.com/hc/en-us/articles/115003523412',
                            external_connect: false,
                            show_connection: true
                        },
                        'CRM.Salesforce': {
                            label: 'Salesforce',
                            location: LOCATION + '/CRM/Salesforce',
                            avatar: 'static/build/assets/images/salesforce-logo.png',
                            description: 'Syncs Salesforce accounts, cases, contacts and opportunities with Amity accounts, people, subscriptions and tickets.',
                            external_link: 'https://support.getamity.com/hc/en-us/articles/202172143',
                            external_connect: false,
                            show_connection: true
                        },
                        'CRM.Zoho': {
                            label: 'Zoho CRM',
                            location: LOCATION + '/CRM/Zoho',
                            avatar: 'static/build/assets/images/zoho-logo.png',
                            description: 'Syncs Zoho CRM accounts and contacts with Amity accounts and people.',
                            external_link: 'https://support.getamity.com/hc/en-us/articles/203583589',
                            external_connect: false,
                            show_connection: true
                        },
                        'Billing.Chargify': {
                            label: 'Chargify',
                            location: LOCATION + '/Billing/Chargify',
                            avatar: 'static/build/assets/images/chargify-logo.png',
                            description: 'Syncs Chargify subscriptions with Amity subscriptions.',
                            external_link: 'https://support.getamity.com/hc/en-us/articles/210349663',
                            external_connect: false,
                            show_connection: true
                        },
                        'Billing.Recurly': {
                            label: 'Recurly',
                            location: LOCATION + '/Billing/Recurly',
                            avatar: 'static/build/assets/images/recurly-logo.png',
                            description: 'Syncs Recurly subscriptions with Amity subscriptions.',
                            external_link: 'https://support.getamity.com/hc/en-us/articles/209734703',
                            external_connect: false,
                            show_connection: true
                        },
                        'Billing.Xero': {
                            label: 'Xero',
                            location: LOCATION + '/Billing/Xero',
                            avatar: 'static/build/assets/images/xero-logo.png',
                            description: 'Syncs balances, last invoice and last payment information from Xero contacts with Amity accounts.',
                            external_link: 'https://support.getamity.com/hc/en-us/articles/209969653',
                            external_connect: false,
                            show_connection: true
                        },
                        'Billing.Zuora': {
                            label: 'Zuora',
                            location: LOCATION + '/Billing/Zuora',
                            avatar: 'static/build/assets/images/zuora-logo.jpg',
                            description: 'Syncs Zuora subscriptions and payment card information with Amity subscriptions and accounts.',
                            external_link: 'https://support.getamity.com/hc/en-us/articles/206222006',
                            external_connect: false,
                            show_connection: true
                        },
                        'DataProcessing.AvatierDataCleanup': {
                            label: 'Avatier Data Cleanup',
                            location: LOCATION + '/DataProcessing/AvatierDataCleanup',
                            avatar: 'static/build/assets/images/amity_logo_400x135.png',
                            description: 'Provides custom data processing and cleanup for Avatier workspace data.',
                            external_link: 'https://support.getamity.com/hc/en-us/articles',
                            external_connect: false,
                            show_connection: true
                        },
                        'Email.GoogleApps': {
                            label: 'Google G Suite Mail',
                            location: LOCATION + '/Email/GoogleApps',
                            avatar: 'static/build/assets/images/gmail-logo.png',
                            description: 'Imports email from Google G Suite Mail into Amity engagements.',
                            external_link: 'https://support.getamity.com/hc/en-us/articles/202172153',
                            external_connect: false,
                            show_connection: true
                        },
                        'Email.Office365': {
                            label: 'Microsoft Office 365',
                            location: LOCATION + '/Email/Office365',
                            avatar: 'static/build/assets/images/office-365-logo.png',
                            description: 'Imports email from Office 365 into Amity engagements.',
                            external_link: 'https://support.getamity.com/hc/en-us/articles/208260926',
                            external_connect: {
                                enabled_field: 'admin_consent_url',
                                connection_field: 'office365_tenant_id',
                                per_user_connect_field: 'per_user_auth'
                            },
                            show_connection: true
                        },
                        'Communication.Intercom': {
                            label: 'Intercom',
                            location: LOCATION + '/Communication/Intercom',
                            avatar: 'static/build/assets/images/intercom-logo.png',
                            description: 'Sync Intercom conversations and events with Amity engagements and activities.',
                            external_link: 'https://support.getamity.com/hc/en-us/articles/209972173',
                            external_connect: {
                                enabled_field: 'admin_consent_url',
                                connection_field: 'fully_connected'
                            },
                            show_connection: true
                        },
                        'Marketing.HubSpot': {
                            label: 'Hubspot Marketing',
                            location: LOCATION + '/Marketing/HubSpot',
                            avatar: 'static/build/assets/images/hubspot-logo.png',
                            description: 'Retrieve HubSpot marketing information within the Amity person profile and add people to HubSpot lists and workflows.',
                            external_link: 'https://support.getamity.com/hc/en-us/articles/206589603',
                            external_connect: false,
                            show_connection: true
                        },
                        'Marketing.Marketo': {
                            label: 'Marketo',
                            location: LOCATION + '/Marketing/Marketo',
                            avatar: 'static/build/assets/images/marketo-logo.jpg',
                            description: 'Retrieve Marketo marketing information within the Amity person profile and add people to Marketo campaigns and static lists.',
                            external_link: 'https://support.getamity.com/hc/en-us/articles/206578863',
                            external_connect: false,
                            show_connection: true
                        },
                        'Support.Desk': {
                            label: 'Salesforce Desk',
                            location: LOCATION + '/Support/Desk',
                            avatar: 'static/build/assets/images/salesforce-desk-logo.png',
                            description: 'Syncs Salesforce Desk cases with Amity tickets.',
                            external_link: 'https://support.getamity.com/hc/en-us/articles/202195009',
                            external_connect: false,
                            show_connection: true
                        },
                        'Support.freshdesk': {
                            label: 'Freshdesk',
                            location: LOCATION + '/Support/freshdesk',
                            avatar: '/static/build/assets/images/freshdesk-logo.png',
                            description: 'Syncs Freshdesk tickets with Amity tickets.',
                            external_link: 'https://support.getamity.com/hc/en-us/articles/115001952612',
                            external_connect: false,
                            show_connection: true
                        },
                        'Support.help_scout': {
                            label: 'Help Scout',
                            location: LOCATION + '/Support/help_scout',
                            avatar: '/static/build/assets/images/help-scout-logo.png',
                            description: 'Syncs Help Scout conversations with Amity tickets.',
                            external_link: 'https://support.getamity.com/hc/en-us/articles/115003519172',
                            external_connect: false,
                            show_connection: true
                        },
                        'Support.JiraServiceDesk': {
                            label: 'JIRA Service Desk',
                            location: LOCATION + '/Support/JiraServiceDesk',
                            avatar: 'static/build/assets/images/jira-logo.png',
                            description: 'Syncs JIRA Service Desk issues with Amity tickets.',
                            external_link: 'https://support.getamity.com/hc/en-us/articles/206589953',
                            external_connect: false,
                            show_connection: true
                        },
                        'Support.Zendesk': {
                            label: 'Zendesk',
                            location: LOCATION + '/Support/Zendesk',
                            avatar: 'static/build/assets/images/zendesk-logo.png',
                            description: 'Syncs Zendesk tickets with Amity tickets.',
                            external_link: 'https://support.getamity.com/hc/en-us/articles/203473967',
                            external_connect: false,
                            show_connection: true
                        },
                        'Usage.Mixpanel': {
                            label: 'Mixpanel',
                            location: LOCATION + '/Usage/Mixpanel',
                            avatar: 'static/build/assets/images/mixpanel-logo.jpg',
                            description: 'Converts Mixpanel events into Amity activities.',
                            external_link: 'https://support.getamity.com/hc/en-us/articles/209734663',
                            external_connect: false,
                            show_connection: true
                        },
                        'Usage.Intacct': {
                            label: 'Intacct',
                            location: LOCATION + '/Billing/Intacct',
                            avatar: 'static/build/assets/images/intacct-logo.png',
                            description: 'Syncs balances and last invoice information from Intacct customers with Amity accounts.',
                            external_link: 'https://support.getamity.com/hc/en-us/articles/214320106',
                            external_connect: false,
                            show_connection: true
                        },
                        'Usage.Fullstory': {
                            label: 'FullStory',
                            location: LOCATION + '/Usage/fullstory',
                            avatar: 'static/build/assets/images/fullstory-logo.png',
                            description: 'Adds links to FullStory session recordings to Amity person profiles.',
                            external_link: 'https://support.getamity.com/hc/en-us/articles/115003508126',
                            external_connect: false,
                            show_connection: true
                        },
                        'ERP.Netsuite': {
                            label: 'Oracle NetSuite',
                            location: LOCATION + '/ERP/netsuite',
                            avatar: 'static/build/assets/images/oracle-netsuite-logo.png',
                            description: 'Syncs Oracle NetSuite customer, contact and support case records with Amity accounts, people, and tickets.',
                            external_link: 'https://support.getamity.com/hc/en-us/articles/115000726331',
                            external_connect: false,
                            show_connection: true
                        }
                    };
                };
                Factory_Integration.prototype.build_query_list = function build_query_list(params) {
                    var i, type, self = this, list = [], integrations = self.get_integrations(), integrations_ids = Object.keys(integrations).sort();
                    if (params) {
                        if (params.hasOwnProperty('id')) {
                            if (integrations.hasOwnProperty(params.id)) {
                                list.push(integrations[params.id].location);
                            }
                        } else if (params.hasOwnProperty('type')) {
                            for (i = 0; i < integrations_ids.length; i++) {
                                type = integrations_ids[i].split('-')[1];
                                if (type === params.type) {
                                    list.push(integrations[integrations_ids[i]].location);
                                }
                            }
                        }
                    }
                    if (!list.length) {
                        for (i = 0; i < integrations_ids.length; i++) {
                            list.push(integrations[integrations_ids[i]].location);
                        }
                    }
                    return list;
                };
                Factory_Integration.prototype.get = function (params) {
                    var i, self = this, promises = [], get_defer = $q.defer(), integrations = self.get_integrations(), list = self.build_query_list(params);
                    function load_results(results) {
                        var i, j, location, details, models = [], integrations_ids = Object.keys(integrations).sort();
                        for (i = 0; i < results.length; i++) {
                            location = results[i].call_info.location;
                            for (j = 0; j < integrations_ids.length; j++) {
                                details = integrations[integrations_ids[j]];
                                if (details.location === location) {
                                    models.push(new Model_Integration(results[i], details, integrations_ids[j]));
                                    break;
                                }
                            }
                        }
                        get_defer.resolve(models);
                    }
                    for (i = 0; i < list.length; i++) {
                        promises.push($ay_requester.GET(list[i], params));
                    }
                    $q.all(promises).then(load_results);
                    return get_defer.promise;
                };
                return new Factory_Integration();
            }
        ]
    };
});