define('modules/form-inputs/directives/input-autocomplete', [], function () {
    return {
        component: 'directive',
        name: 'ayFormInputAutocomplete',
        fn: [function () {
                return {
                    restrict: 'E',
                    templateUrl: 'form-inputs/views/input-autocomplete.html',
                    transclude: true,
                    scope: {
                        label: '@',
                        helpText: '@',
                        isDisabled: '=?',
                        isRequired: '@',
                        isFocused: '@'
                    },
                    controller: function () {
                    },
                    controllerAs: 'formInput',
                    bindToController: true,
                    link: function (scope, elem, attrs) {
                        var isObserving = false, activeClass = 'ng-not-empty', invalidClass = 'ay-invalid', input = angular.element(elem.find('input'));
                        function updateValidation(val) {
                            scope.formInput.validationMsg = val;
                            if (scope.formInput.validationMsg) {
                                elem.addClass(invalidClass);
                            } else {
                                elem.removeClass(invalidClass);
                            }
                        }
                        function getModelValue() {
                            var data = input.data();
                            if (!isObserving && data.$ngModelController && data.$ngModelController.$$attr) {
                                data.$ngModelController.$$attr.$observe('validationMsg', updateValidation);
                                isObserving = true;
                            }
                            return data.$ngModelController;
                        }
                        function change(event) {
                            var inputCtrl = getModelValue();
                            if (inputCtrl.$viewValue) {
                                scope.formInput.inputClass = activeClass;
                            } else if (event) {
                                switch (event.type) {
                                case 'blur':
                                    scope.formInput.inputClass = '';
                                    break;
                                case 'focus':
                                    scope.formInput.inputClass = activeClass;
                                    break;
                                default:
                                    if (event === inputCtrl.$modelValue) {
                                        scope.formInput.inputClass = activeClass;
                                    } else {
                                        scope.formInput.inputClass = '';
                                    }
                                    break;
                                }
                            } else {
                                scope.formInput.inputClass = '';
                            }
                            if (!scope.$$phase && !scope.$root.$$phase) {
                                scope.$apply();
                            }
                        }
                        input.on('focus', change);
                        input.on('blur', change);
                        input.scope().$watch(input.attr('ng-model'), change);
                    }
                };
            }]
    };
});